import React, { Component } from "react";
import gql from "graphql-tag";
import {  graphql } from "react-apollo";
import {flowRight as compose} from 'lodash';
import {
  Header,
  Grid,
  Input,
  Select,
  Dropdown,
  Divider,
  Segment,
  Form
} from "semantic-ui-react";
import DatePicker from "react-datepicker";
import ReactTable from "react-table";
import "react-table/react-table.css";
import "./table.css";
import Moment from "moment";
import { extendMoment } from "moment-range";

import getReportPreadvices from "./Queries/fetchAllDeparturesMbs";
import withQuery from "../modules/HOC/withQuery";
import LoadingMessage from "../modules/shared/LoadingMessage";
import ExportToExcel from "./ExportToExcel";
import {
  EXPECTEDColumns,
  ICDNColumns,
  OnRailColumns,
  MombasaColumns,
  DeliveredColumns,
  InvoicedColumns
} from "./columns";
const moment = extendMoment(Moment);
const ref = React.createRef();
var containerOptions = [];
var clientOptions = [];
var operatorOptions = [];
var columns = DeliveredColumns;
class SummaryReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [...this.props.entries],
      pageNum: 1,
      position: "",
      eQStatus: "",
      fEStatus: "",
      containerSize: "",
      freeSelectValue: "vessel",
      daysSinceReceipt: "",
      dateOption: "",
      containerStatus: "",
      defaultEndDate: moment(new Date()).format("DD-MM-YYYY")
    };
  }
  dateDifference = (date1, date2) => {
    let dt1 = new Date(date1);
    let dt2 = new Date(date2);
    return Math.floor(
      (Date.UTC(dt2.getFullYear(), dt2.getMonth(), dt2.getDate()) -
        Date.UTC(dt1.getFullYear(), dt1.getMonth(), dt1.getDate())) /
        (1000 * 60 * 60 * 24)
    );
  };
  onPostitionChange = (e, data) => {
    this.setState({ position: data.value });
  };
  setDateOptionsValue = (e, data) => {
    const { value } = data;
    this.setState({ dateOption: value });
  };
  setOptionsValue = (e, data) => {
    const { name, value } = data;
    this.setState({ [name]: value });
  };
  setFreeSelectValue = (e, data) => {
    const { value } = data;

    this.setState({ freeSelectValue: value });
  };
  setContainerValue = (e, data) => {
    containerOptions.forEach(element => {
      if (element.value === data.value) {
        this.setState({ containerSize: element.id });
        this.setState({ containerCode: element.text });
        this.setState({ containerValue: element.value });
      }
    });
  };
  setContainerStatusValue = (e, data) => {
    console.log(data);
    this.setState({ containerStatus: data.value });
    // containerStatusOptions.forEach(element => {
    //   if (element.value === data.value) {

    //   }
    // });
  };
  setOperatorValue = (e, data) => {
    operatorOptions.forEach(element => {
      if (element.value === data.value) {
        this.setState({ operatorCode: element.value });
      }
    });
  };
  setClientValue = (e, data) => {
    clientOptions.forEach(element => {
      if (element.value === data.value) {
        this.setState({ clientCode: element.text });
      }
    });
  };
  handleDateToChange = date => {
    this.setState({ endDate: date });
  };
  handleDateFromChange = date => {
    this.setState({ startDate: date });
  };

  render() {
    console.log(this.props);
    const positionOptions = [
      { id: 1, value: "EXPECTED", text: "EXPECTED" },
      { id: 2, value: "ICDN", text: "ICDN" },
      { id: 3, value: "ONRAIL", text: "ONRAIL" },
      { id: 4, value: "MOMBASA", text: "MOMBASA" },
      { id: 5, value: "DELIVERED", text: "DELIVERED" },
      { id: 6, value: "INVOICED", text: "INVOICED" }
    ];
    const dateOptions = [
      { id: 1, value: "inDateICD", text: "Arr NBO" },
      { id: 2, value: "outTimeICD", text: "Dept NBO" },
      { id: 3, value: "arrivalDateMSA", text: "Arr MSA" },
      { id: 4, value: "dateDelivered", text: "Dept MSA" }
    ];
    const EQStatusOptions = [
      { text: "Export", value: "Export" },
      { text: "Import", value: "Import" }
    ];
    const FEStatusOptions = [
      { text: "Empty", value: "Empty" },
      { text: "Full", value: "Full" }
    ];
    const containerStatusOptions = [
      { key: "ontime", text: "Ontime", value: "Ontime" },
      { key: "overdue", text: "Overdue", value: "Overdue" }
    ];
    const selectOptions = [
      { key: "vessel", text: "Vessel", value: "vessel" },
      { key: "weight", text: "Weight", value: "weight" },
      { key: "containerNum", text: "Container", value: "containerNum" }
    ];

    if (this.props.containerFeed.loading === false) {
      let tempOp = this.props.containerFeed.getAllContainers;
      containerOptions = [];
      tempOp.map(element => {
        return containerOptions.push({
          id: element.id,
          key: element.id,
          text: element.containerCode,
          value: element.containerCode
        });
      });
    }

    if (this.props.clientFeed.loading === false) {
      let tempOp = this.props.clientFeed.getAllClients;
      clientOptions = [];
      operatorOptions = [];
      tempOp.map(element => {
        return clientOptions.push({
          id: element.id,
          key: element.id,
          text: element.clientName,
          value: element.clientName
        });
      });

      tempOp.map(element => {
        return operatorOptions.push({
          id: element.id,
          key: element.id,
          text: element.clientCode,
          value: element.clientCode
        });
      });
    }
    if (this.state.data) {
      let { data } = this.state;
      if (this.state.clientCode) {
        data = data.filter(row => {
          return row.clientCode.includes(this.state.clientCode);
        });
      }
      if (this.state.operatorCode) {
        data = data.filter(row => {
          return row.operatorCode.includes(this.state.operatorCode);
        });
      }
      if (this.state.fEStatus) {
        data = data.filter(row => {
          return row.fEStatus.includes(this.state.fEStatus);
        });
      }
      if (this.state.eQStatus) {
        data = data.filter(row => {
          return row.eQStatus.includes(this.state.eQStatus);
        });
      }

      if (this.state.position) {
        if (this.state.position === "EXPECTED") {
          data = data.filter(row => {
            return !row.bblEIR;
          });
          columns = EXPECTEDColumns;
        }
        if (this.state.position === "ICDN") {
          data = data.filter(row => {
            return row.bblEIR && !row.wagonNum;
          });
          columns = ICDNColumns;
        }
        if (this.state.position === "ONRAIL") {
          data = data.filter(row => {
            return row.wagonNum && row.arrivalStatus === "false";
          });
          columns = OnRailColumns;
        }
        if (this.state.position === "MOMBASA") {
          data = data.filter(row => {
            return row.arrivalStatus === "true" && !row.dateDelivered;
          });
          columns = MombasaColumns;
        }
        if (this.state.position === "DELIVERED") {
          data = data.filter(row => {
            return row.dateDelivered && !row.invoiceNum;
          });
          columns = DeliveredColumns;
        }
        if (this.state.position === "INVOICED") {
          data = data.filter(row => {
            console.log("Invoice Num: ", row.invoiceNum);

            return row.invoiceNum;
          });
          columns = InvoicedColumns;
        }
      }
      if (this.state.containerSize) {
        data = data.filter(row => {
          return row.containerSize.includes(this.state.containerSize);
        });
      }
      if (this.state.vessel) {
        data = data.filter(row => {
          return row.vessel.includes(this.state.vessel);
        });
      }
      if (this.state.freeSearch) {
        if (this.state.freeSelectValue === "weight") {
          data = data.filter(row => {
            return row.weight.includes(this.state.freeSearch);
          });
        }
        if (this.state.freeSelectValue === "vessel") {
          data = data.filter(row => {
            console.log(row.vessel.includes(this.state.freeSearch));
            return row.vessel.includes(this.state.freeSearch);
          });
        }
        if (this.state.freeSelectValue === "containerNum") {
          data = data.filter(row => {
            return row.containerNum.includes(this.state.freeSearch);
          });
        }
      }
      if (this.state.containerStatus !== "") {
        if (this.state.containerStatus === "Overdue") {
          let currentDate = moment().format("YYYY-MM-DD");

          data = data.filter(row => {
            return (
              !row.dateDelivered &&
              row.inDateICD !== "Invalid date" &&
              this.dateDifference(row.inDateICD, currentDate) >= 5
            );
          });
        } else if (this.state.containerStatus === "Ontime") {
          let currentDate = moment().format("YYYY-MM-DD");
          data = data.filter(row => {
            return (
              !row.dateDelivered &&
              row.inDateICD !== "Invalid date" &&
              this.dateDifference(row.inDateICD, currentDate) <= 4
            );
          });
        }
      }
      if (this.state.startDate) {
        const minDate = moment(this.state.startDate).format("YYYY-MM-DD");
        const maxDate = this.state.endDate
          ? moment(this.state.endDate).format("YYYY-MM-DD")
          : moment().format("YYYY-MM-DD");

        if (this.state.dateOption === "inDateICD") {
          data = data.filter(row => {
            const currentDate = moment(row.inDateICD).format("YYYY-MM-DD");
            return moment(currentDate).isBetween(minDate, maxDate);
          });
        } else if (this.state.dateOption === "outTimeICD") {
          data = data.filter(row => {
            const currentDate = moment(row.outTimeICD).format("YYYY-MM-DD");
            return moment(currentDate).isBetween(minDate, maxDate);
          });
        } else if (this.state.dateOption === "arrivalDateMSA") {
          data = data.filter(row => {
            const currentDate = moment(row.arrivalDateMSA).format("YYYY-MM-DD");
            return moment(currentDate).isBetween(minDate, maxDate);
          });
        } else if (this.state.dateOption === "dateDelivered") {
          data = data.filter(row => {
            const currentDate = moment(row.dateDelivered).format("YYYY-MM-DD");
            return moment(currentDate).isBetween(minDate, maxDate);
          });
        }
      }
      return (
        <Grid centered textAlign="center" style={{ height: "80%" }}>
          <Grid.Column>
            <Header as="h2" color="green" textAlign="center">
              Summarized Reports
            </Header>
            <hr color="pink" />
            <Segment>
              <>
                <Divider color="olive" />
                <Form>
                  <Segment.Group horizontal fluid>
                    <Segment>
                      <Form.Field>
                        <Dropdown
                          value={this.state.dateOption}
                          search
                          selection
                          options={dateOptions}
                          onChange={this.setDateOptionsValue.bind(this)}
                        />
                      </Form.Field>
                      <Form.Field>
                        <DatePicker
                          selected={this.state.startDate}
                          onChange={this.handleDateFromChange}
                        />
                        <label>Date From: </label>{" "}
                      </Form.Field>
                      <Form.Field>
                        <DatePicker
                          selected={this.state.endDate}
                          onChange={this.handleDateToChange}
                        />
                        <label>Date To: </label>{" "}
                      </Form.Field>
                    </Segment>
                    <Segment>
                      <Form.Field>
                        <label>EQ Status</label>
                        <Dropdown
                          name={"eQStatus"}
                          value={this.state.eQStatus}
                          search
                          selection
                          options={EQStatusOptions}
                          onChange={this.setOptionsValue.bind(this)}
                        />
                      </Form.Field>
                      <Form.Field>
                        <label>FE Status</label>
                        <Dropdown
                          name={"fEStatus"}
                          value={this.state.fEStatus}
                          search
                          selection
                          options={FEStatusOptions}
                          onChange={this.setOptionsValue.bind(this)}
                        />
                      </Form.Field>
                    </Segment>
                    <Segment>
                      <Form.Field>
                        <label>Position</label>
                        <Dropdown
                          value={this.state.postition}
                          search
                          selection
                          options={positionOptions}
                          onChange={this.onPostitionChange.bind(this)}
                        />
                      </Form.Field>
                      <Form.Field>
                        <label>Container Size</label>
                        <Dropdown
                          value={this.state.containerValue}
                          search
                          selection
                          options={containerOptions}
                          onChange={this.setContainerValue.bind(this)}
                        />
                      </Form.Field>
                    </Segment>
                    <Segment>
                      <Form.Field>
                        <label>Client Code</label>
                        <Dropdown
                          value={this.state.clientCode}
                          search
                          selection
                          options={clientOptions}
                          onChange={this.setClientValue.bind(this)}
                        />
                      </Form.Field>

                      <Form.Field>
                        <label>Operator Code</label>
                        <Dropdown
                          value={this.state.operatorCode}
                          search
                          selection
                          options={operatorOptions}
                          onChange={this.setOperatorValue.bind(this)}
                        />
                      </Form.Field>
                    </Segment>
                    <Segment>
                      <Form.Field>
                        <Input type="text">
                          <input
                            value={this.state.freeSearch}
                            onChange={e =>
                              this.setState({ freeSearch: e.target.value })
                            }
                          />
                          <Select
                            compact
                            options={selectOptions}
                            defaultValue="vessel"
                            onChange={this.setFreeSelectValue.bind(this)}
                          />
                        </Input>
                      </Form.Field>
                      <Form.Field>
                        <label>Processing Status</label>
                        <Dropdown
                          name={"containerStatus"}
                          value={this.state.containerStatus}
                          search
                          selection
                          options={containerStatusOptions}
                          onChange={this.setContainerStatusValue.bind(this)}
                        />
                      </Form.Field>
                    </Segment>
                  </Segment.Group>
                </Form>

                <hr color="pink" />
                <div>
                  <Header as="h3" color="green" textAlign="center">
                    Container List
                  </Header>
                  <ReactTable
                    ref={ref}
                    columns={columns}
                    pageSizeOptions={[
                      5,
                      10,
                      20,
                      25,
                      50,
                      100,
                      250,
                      500,
                      1000,
                      2000,
                      3000
                    ]}
                    data={data}
                    sortable
                    defaultPageSize={10}
                    noDataText={"Loading ..."}
                    className="-striped -highlight"
                  >
                    {(state, filteredData) => {
                      this.ReactTable = state.pageRows.map(data => {
                        return data._original;
                      });
                      return (
                        <div>
                          {filteredData()}
                          <ExportToExcel
                            data={this.ReactTable}
                            type={this.state.position}
                          />
                        </div>
                      );
                    }}
                  </ReactTable>
                </div>
              </>
            </Segment>
          </Grid.Column>
        </Grid>
      );
    } else {
      return <LoadingMessage />;
    }
  }
}
const CONTAINERFEED = gql`
  query getAllContainers {
    getAllContainers {
      id
      containerSize
      containerCode
    }
  }
`;
const CLIENTFEED = gql`
  query getAllClients {
    getAllClients {
      id
      clientCode
      clientName
    }
  }
`;
export default compose(
  graphql(CONTAINERFEED, {
    name: "containerFeed"
  }),
  graphql(CLIENTFEED, {
    name: "clientFeed"
  })
)(withQuery(SummaryReport, getReportPreadvices, "getReportPreadvices"));
