import { Message, Icon } from 'semantic-ui-react'
import React from 'react'

const ErrorMessage = error => {
	return (
		<Message icon negative>
			<Icon name="wait" />
			<Message.Content>
				<Message.Header>{error}</Message.Header>
				Is the backend server running?
			</Message.Content>
		</Message>
	)
}

export default ErrorMessage
