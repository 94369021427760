// Confirmation.jsx
import React, { Component } from "react";
import { Button, List, Grid, Segment, Header } from "semantic-ui-react";
import moment from "moment";

class Confirmation extends Component {
  saveAndContinue = e => {
    e.preventDefault();

    this.props.onSubmit();
  };

  back = e => {
    e.preventDefault();
    this.props.prevStep();
  };

  render() {
    const {
      values: {
        clientCode,
        containerNum,
        containerCode,
        containerValue,
        operatorCode,
        weight,
        fEStatus,
        eQStatus,
        pOD,
        pOL,
        vessel,
        refNum,
        sOExpiryDate,
        preadviseDate
      }
    } = this.props;

    console.log(this.props);

    return (
      <Grid
        textAlign="center"
        style={{ height: "100%" }}
        verticalAlign="middle"
      >
        <Grid.Column style={{ maxWidth: 800 }}>
          <Segment>
            <Header as="h4" color="green" textAlign="center">
              Confirm your Details
            </Header>

            <p>
              Click Confirm if the following details have been correctly entered
            </p>
            <List className="ui centered">
              <List.Item>
                <List.Content>Client Code: {clientCode}</List.Content>
              </List.Item>
              <List.Item>
                <List.Content>Container No: {containerNum}</List.Content>
              </List.Item>
              {/*   <List.Item>
            <List.Content>Container Size: {containerSize}</List.Content>
          </List.Item> */}
              <List.Item>
                <List.Content>Container Weight: {weight}</List.Content>
              </List.Item>
              <List.Item>
                <List.Content>Container Code : {containerCode} </List.Content>
              </List.Item>
              <List.Item>
                <List.Content>Container Size : {containerValue} </List.Content>
              </List.Item>
              <List.Item>
                <List.Content>Operator Code: {operatorCode}</List.Content>
              </List.Item>
              <List.Item>
                <List.Content>FE Status: {fEStatus}</List.Content>
              </List.Item>
              <List.Item>
                <List.Content>EQ Status: {eQStatus}</List.Content>
              </List.Item>
              <List.Item>
                <List.Content>Place of Destination: {pOD}</List.Content>
              </List.Item>
              <List.Item>
                <List.Content>Place of Loading: {pOL}</List.Content>
              </List.Item>
              <List.Item>
                <List.Content>Vessel: {vessel}</List.Content>
              </List.Item>
              <List.Item>
                <List.Content>Reference Number: {refNum}</List.Content>
              </List.Item>
              <List.Item>
                <List.Content>
                  Storage Order Expire:
                  {moment(sOExpiryDate).format("MMMM Do YYYY")}
                </List.Content>
              </List.Item>
              <List.Item>
                <List.Content>
                  Preadvise Date: {moment(preadviseDate).format("MMMM Do YYYY")}
                </List.Content>
              </List.Item>
            </List>

            <Button onClick={this.back}>Back</Button>
            <Button onClick={this.saveAndContinue}>Confirm</Button>
          </Segment>
        </Grid.Column>
      </Grid>
    );
  }
}

export default Confirmation;
