import { Message, Icon } from "semantic-ui-react";
import React from "react";
const LoadingMessage = () => {
  return (
    <Message icon info>
      <Icon name="wait" />
      <Message.Content>
        <Message.Header>Unauthorised Access</Message.Header>
        You do not have sufficient permissions to view this.
      </Message.Content>
    </Message>
  );
};

export default LoadingMessage;
