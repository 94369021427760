import React, { Component } from "react"

import ReactHtmlTableToExcel from "react-html-table-to-excel"

class ExportAuditTrailToExcel extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }
  render() {
    console.log("Excel Props", this.props.data)
    return (
      <div stylee={{ marginRight: "25px" }}>
        <ReactHtmlTableToExcel
          id="test-table-xls-button"
          className="export"
          table="table-to-xls"
          fileName="filteredData"
          sheet="tablexls"
          buttonText="Export"
        />
        <table hidden="true" id="table-to-xls">
          <thead>
            <tr>
              <th>No. </th>

              <th>CONTAINER NO.</th>
              <th>ADDED</th>
              <th>ExtraDetails</th>
              <th>ARRIVAL ICD</th>
              <th>DEPARTURE ICD</th>
              <th>ARRIVAL MSA</th>
              <th>GUARANTEE</th>
              <th>DEPT MSA</th>
              <th>DELIVERY </th>
            </tr>
          </thead>
          <tbody>
            {this.props.data.map((d, index) => {
              return (
                <tr key={d.id}>
                  <td>{index}</td>
                  <td>{d.containerNum}</td>
                  <td>{d.addedby !== null ? d.addedby.name : ""}</td>
                  <td>
                    {d.extradetailsBy !== null ? d.extradetailsBy.name : ""}
                  </td>
                  <td>
                    {d.recievedICDBy !== null ? d.recievedICDBy.name : ""}
                  </td>
                  <td>{d.exitICDby !== null ? d.exitICDby.name : ""}</td>
                  <td>
                    {d.recievedMSABy !== null ? d.recievedMSABy.name : ""}
                  </td>
                  <td>{d.exitMSABy !== null ? d.exitMSABy.name : ""}</td>
                  <td>
                    {d.deliverynotemsaby !== null
                      ? d.deliverynotemsaby.name
                      : ""}
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
    )
  }
}

export default ExportAuditTrailToExcel
