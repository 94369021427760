import { schemaDefinitionNotAloneMessage } from "graphql/validation/rules/LoneSchemaDefinition"
import React, { useState } from "react"
import { Table } from "semantic-ui-react"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory from "react-bootstrap-table2-paginator"
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import { graphql } from "react-apollo"
import {
  Form,
  Button,
  Grid,
  Dropdown,
  Header,
  Divider,
} from "semantic-ui-react"
import { flowRight as compose } from "lodash"
import * as XLSX from "xlsx"
import "./depot.css"
import InlineError from "../../shared/InlineError"
import gql from "graphql-tag"
const _ = require("lodash")
var voyageOptions = []
var deliveringOptions = []
function DepotStockUpload(props) {
  const [items, setItems] = useState([])
  const [errors, seterrors] = useState({
    fileUpload: "",
    vessel: "",
    delivering: "",
  })
  const [voyage_id, setvoyage_id] = useState()
  const [vesselName, setvesselName] = useState()
  const [party_delivering_id, setparty_delivering_id] = useState()
  const [party_delivering_name, setparty_delivering_name] = useState()
  const [loading, setloading] = useState(false)
  const validate = () => {
    const errors = {}
    if (_.isEmpty(items)) errors.fileUpload = "Please upload file"
    if (_.isEmpty(voyage_id)) errors.vessel = "Can't be blank"
    if (_.isEmpty(party_delivering_id)) errors.delivering = "Can't be blank"
    if (Object.keys(errors).length === 0) {
      // setloading(true)
      addDepotUpload()
    }
    return errors
  }
  const submit = () => {
    setloading(true)

    const errors = validate()
    console.log(errors)
    seterrors((prevState) => ({ ...prevState, ...errors }))
    setloading(false)
  }

  const addDepotUpload = () => {
    items.map(async (item) => {
      console.log(item)
      let result = await props.addDepotUpload({
        variables: {
          party_delivering_id: party_delivering_id,
          operator_code: item["Operator"],
          container_size: item["Size"],
          weight: item["Weight"],
          height: item["Height"],
          voyage_id: voyage_id,
          container_number: item["Container"],
          eqstatus: item["EQStatus"],
          festatus: item["FEStatus"],
          pol: item["POL"],
          pod: item["POD"],
          preadvise_date: item["Preadvised"],
          reference_no: item["Reference"],
          vessel_code: item["VesselCode"],
        },
      })
      console.log(result)
    })
    props.history.push("/prestack/container/list")
  }

  const setVoyageValue = (e, data) => {
    voyageOptions.forEach((element) => {
      if (element.value === data.value) {
        setvoyage_id(element.id)
        setvesselName(element.value)
        seterrors((prevState) => ({ ...prevState, vessel: "" }))
      }
    })
  }
  const setPartyDeliveringValue = (e, data) => {
    deliveringOptions.forEach((element) => {
      if (element.value === data.value) {
        setparty_delivering_id(element.id)
        setparty_delivering_name(element.value)
        seterrors((prevState) => ({ ...prevState, delivering: "" }))
      }
    })
  }
  const readExcel = (file) => {
    console.log("FILE: ", file)
    const promise = new Promise((resolve, reject) => {
      const fileReader = new FileReader()
      fileReader.readAsArrayBuffer(file)
      fileReader.onload = (e) => {
        const bufferArray = e.target.result

        const wb = XLSX.read(bufferArray, { type: "buffer" })
        const wsname = wb.SheetNames[0]

        const ws = wb.Sheets[wsname]
        const data = XLSX.utils.sheet_to_json(ws)
        resolve(data)
      }
      fileReader.onerror = (error) => {
        reject(error)
      }
    })
    promise.then((d) => {
      console.log(d)
      setItems(d)
      seterrors((prevState) => ({ ...prevState, fileUpload: "" }))
    })
  }
  if (props.getAvailableVesselVoyages.loading === false) {
    console.log(props.getAvailableVesselVoyages)
    let tempOp = props.getAvailableVesselVoyages.getAvailableVesselVoyages
    voyageOptions = []

    tempOp.map((element) => {
      return voyageOptions.push({
        id: element.voyage_id,
        key: element.voyage_id,
        text: element.vesselName,
        value: element.vesselName,
      })
    })
  }
  if (props.getAllPartyDelivering.loading === false) {
    console.log(props.getAllPartyDelivering)
    let tempOp = props.getAllPartyDelivering.getAllPartyDelivering
    deliveringOptions = []

    tempOp.map((element) => {
      return deliveringOptions.push({
        id: element.party_delivering_id,
        key: element.party_delivering_id,
        text: element.party_delivering_name,
        value: element.party_delivering_name,
      })
    })
  }
  return (
    <Grid textAlign="center" verticalAlign="middle" style={{ height: "100%" }}>
      <Grid.Column style={{ maxWidth: 800 }}>
        <Header as="h1" color="green" textAlign="center">
          Enter upload details
        </Header>
        <Divider />
        <Form onSubmit={submit} loading={loading}>
          <Form.Field error={!!errors.vessel}>
            <label>Vessel Name</label>
            <Dropdown
              value={vesselName}
              search
              selection
              options={voyageOptions}
              onChange={setVoyageValue}
            />
            {errors.vessel && <InlineError text={errors.vessel} />}
          </Form.Field>

          <Form.Field error={!!errors.delivering}>
            <label>Party Delivering</label>
            <Dropdown
              value={party_delivering_name}
              search
              selection
              options={deliveringOptions}
              onChange={setPartyDeliveringValue}
            />
            {errors.delivering && <InlineError text={errors.delivering} />}
          </Form.Field>

          <Form.Field error={!!errors.fileUpload}>
            <input
              type="file"
              onChange={(e) => {
                const file = e.target.files[0]
                readExcel(file)
              }}
            />
          </Form.Field>
          <Button fluid positive>
            Save{" "}
          </Button>
        </Form>
      </Grid.Column>
    </Grid>
  )
}
const getAllPartyDelivering = gql`
  query getAllPartyDelivering {
    getAllPartyDelivering {
      party_delivering_id
      party_delivering_name
    }
  }
`
const getAvailableVesselVoyages = gql`
  query getAvailableVesselVoyages {
    getAvailableVesselVoyages {
      voyage_id
      vesselName
      estimated_arrival
      estimated_departure
      actual_arrival
      actual_departure
    }
  }
`
const addDepotUpload = gql`
  mutation addDepotUpload(
    $party_delivering_id: String
    $voyage_id: String
    $container_number: String
    $operator_code: String
    $container_size: String
    $weight: String
    $height: String
    $eqstatus: String
    $festatus: String
    $pol: String
    $pod: String
    $preadvise_date: String
    $reference_no: String
    $vessel_code: String
  ) {
    addDepotUpload(
      party_delivering_id: $party_delivering_id
      operator_code: $operator_code
      container_size: $container_size
      weight: $weight
      height: $height
      voyage_id: $voyage_id
      container_number: $container_number
      eqstatus: $eqstatus
      festatus: $festatus
      pol: $pol
      pod: $pod
      preadvise_date: $preadvise_date
      reference_no: $reference_no
      vessel_code: $vessel_code
    )
  }
`
export default compose(
  graphql(getAllPartyDelivering, {
    name: "getAllPartyDelivering",
  }),
  graphql(addDepotUpload, {
    name: "addDepotUpload",
  }),
  graphql(getAvailableVesselVoyages, {
    name: "getAvailableVesselVoyages",
  })
)(DepotStockUpload)
