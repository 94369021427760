import gql from "graphql-tag";
export default gql`
  query getInvoice {
    getInvoice {
      id
      clientCode
      containerNum
      containerSize
      dateDelivered
      preAdviseNum
      fileBillingNumber
      deliveryNoteID
      bblEIR
      vessel
      inDateICD
      position
    }
  }
`;
