import React from "react";
import { Query } from "react-apollo";

import LoadingMessage from "../shared/LoadingMessage";
import ErrorMessage from "../shared/ErrorMessage";
import EmptyMessage from '../shared/EmptyMessage'

const withQuery = (WrappedComponent, query, queryname) => {
  class WithQuery extends React.Component {
    render() {
      return (
        <Query query={query}>
          {({ loading, data, error, fetchMore }) => {
            console.log("WITH QUERY:::: ::::", data)
            console.log("Loading", loading);
            if (loading) return <LoadingMessage />;
            console.log("Error", error);
            if (error) return <ErrorMessage />;
            console.log("Loading", data[queryname]);
            if(!data) return <EmptyMessage />;
            return (
              <WrappedComponent {...this.props} entries={data[queryname]} />
            );
          }}
        </Query>
      );
    }
  }
  return WithQuery;
};

export default withQuery;
