import gql from "graphql-tag";

export default gql`
  query getDeliveryMsa {
    getDeliveryMsa {
      id
      clientCode
      containerNum
      remarks
      wagonNum
      arrivalDateMSA
      vessel
    }
  }
`;
