import React, { Component, Fragment } from 'react'
import {
    Segment, Header, Divider,
    Grid,
} from 'semantic-ui-react'
import { Chart } from "react-google-charts";
import gql from 'graphql-tag';

export const data = [
    ["Designation Point/Clients",
        "20GO", "22GO",
        "22RO", "42GO",
        "42UO", "45G1",
        "Grand Total"
    ],
    ["CMA", 10, 1, 5, 5, 1, 2, 24],
    ["Global Frieght", 10, 1, 5, 5, 1, 2, 24],
    ["RSS", 10, 1, 5, 5, 1, 2, 24],
    ["Gen Cargo", 10, 1, 5, 5, 1, 2, 24],
];
export const options = {
    curveType: "function",
    legend: { position: "bottom" },
    pageSize: 10,
};
export const piedata = [
    ["Client", "No of Containers"],
    ["CMA", 11],
    ["Global Cargo", 2],
    ["RSS", 2],
    ["SPEDAG", 2],

];
export const pieoptions = {
    // title: "Prestack Volume",
    is3D: true,
};

class MonthlyStats extends Component {
    constructor(props) {
        super(props);
    }
    state = {}
    render() {
        return (
            <Fragment>
                <Segment textAlign='center'>
                    <Grid columns={2} stackable textAlign='center'>
                        <Divider vertical></Divider>
                        <Grid.Row verticalAlign='middle'>
                            <Grid.Column>
                                <Header as='h3'>PRESTACK VOLUME HANDLED/PERFORMANCE FOR DEC2022</Header>
                                <Chart
                                    chartType="Table"
                                    width="100%"
                                    height="400px"
                                    data={data}
                                    options={options}


                                />
                            </Grid.Column>

                            <Grid.Column>
                                <Chart
                                    chartType="PieChart"
                                    data={piedata}
                                    options={pieoptions}
                                    width={"100%"}
                                    height={"400px"}
                                />
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>



                </Segment>
                <Segment textAlign='center'>
                    <Grid columns={2} stackable textAlign='center'>
                        <Divider vertical></Divider>
                        <Grid.Row verticalAlign='middle'>
                            <Grid.Column>
                                <Header as='h3'>DEPOT VOLUME HANDLED/PERFORMANCE FOR DEC2022</Header>
                                <Chart
                                    chartType="Table"
                                    width="100%"
                                    height="400px"
                                    data={data}
                                    options={options}


                                />
                            </Grid.Column>

                            <Grid.Column>
                                <Chart
                                    chartType="PieChart"
                                    data={piedata}
                                    options={pieoptions}
                                    width={"100%"}
                                    height={"400px"}
                                />
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>



                </Segment>
                <Segment textAlign='center'>
                    <Grid columns={2} stackable textAlign='center'>
                        <Divider vertical></Divider>
                        <Grid.Row verticalAlign='middle'>
                            <Grid.Column>
                                <Header as='h3'>DEPOT VOLUME HANDLED/PERFORMANCE FOR DEC2022</Header>
                                <Chart
                                    chartType="Table"
                                    width="100%"
                                    height="400px"
                                    data={data}
                                    options={options}


                                />
                            </Grid.Column>

                            <Grid.Column>
                                <Chart
                                    chartType="PieChart"
                                    data={piedata}
                                    options={pieoptions}
                                    width={"100%"}
                                    height={"400px"}
                                />
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>



                </Segment>
            </Fragment>
        );
    }
}
const getMonthlyVolume = gql`
query getMonthlyVolume($month: String, $year: String) {
  getMonthlyVolume(month: $month, year: $year) {
    clientCode
    containerCode
    position
    monthlyTotal
  }
}
`
export default MonthlyStats;