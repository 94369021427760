import React, { Component } from "react"
import Validator from "validator"
import { Link } from "react-router-dom"
import gql from "graphql-tag"
import { graphql } from "react-apollo"
import "react-toastify/dist/ReactToastify.css"
import { toast } from "react-toastify"
import InlineError from "../shared/InlineError"

import { Form, Grid, Header, Message, Segment, Icon } from "semantic-ui-react"

class ResetPassword extends Component {
  constructor(props) {
    super(props)
    this.state = {
      password: "",
      confirmPassword: "",

      errors: {},
      loading: false,
    }
    this.onChange = this.onChange.bind()
  }
  onChange = (e) =>
    this.setState({
      [e.target.name]: e.target.value,
    })
  validate = () => {
    const errors = {}

    if (!this.state.password) errors.password = "Can't be blank!"
    if (!this.state.confirmPassword) errors.confirmPassword = "Can't be blank!"
    if (!this.state.password === this.state.confirmPassword)
      errors.confirmPassword = "Passwords must match!"

    return errors
  }
  onSubmit = async () => {
    const errors = this.validate()
    this.setState({ errors })
    if (Object.keys(errors).length === 0) {
      const { password } = this.state

      const result = await this.props.resetPassword({
        variables: { password, reset_token: this.props.match.params.token },
      })
      console.log(result.data.resetPassword)
      if (result.data.resetPassword.ok) {
        this.props.history.push("/login")
      } else if (result.data.resetPassword.errors) {
        toast.error(
          "Link expired! Try again from forgot password on the login page",
          {
            position: toast.POSITION.TOP_CENTER,
            autoClose: false,
          }
        )
      }
    }
  }
  render() {
    const { errors, loading } = this.state
    console.log(this.props)
    return (
      <div className="login-form">
        <style>{`
        body > div,
        body > div > div,
        body > div > div > div.login-form {
          height: 100%;
        }
      `}</style>
        <Grid
          textAlign="center"
          style={{ height: "100%" }}
          verticalAlign="middle"
        >
          <Grid.Column style={{ maxWidth: 450 }}>
            <Header as="h2" color="green" textAlign="center">
              Enter new password
            </Header>

            <Form onSubmit={this.onSubmit} loading={loading}>
              <Segment stacked>
                {errors.global && (
                  <Message negative>
                    <Message.Header>Something went wrong</Message.Header>
                    <p>{errors.global}</p>
                  </Message>
                )}

                <Form.Field error={!!errors.password}>
                  <label htmlFor="password">Password</label>
                  <input
                    type="password"
                    id="password"
                    name="password"
                    placeholder="Make it secure"
                    value={this.state.password}
                    onChange={this.onChange}
                  />
                  {errors.password && <InlineError text={errors.password} />}
                </Form.Field>
                <Form.Field error={!!errors.confirmPassword}>
                  <label htmlFor="password">Confirm Password</label>
                  <input
                    type="password"
                    id="confirmPassword"
                    name="confirmPassword"
                    placeholder="Make it secure"
                    value={this.state.confirmPassword}
                    onChange={this.onChange}
                  />
                  {errors.confirmPassword && (
                    <InlineError text={errors.confirmPassword} />
                  )}
                </Form.Field>
                <Form.Button fluid positive>
                  Submit
                </Form.Button>
              </Segment>
            </Form>
          </Grid.Column>
        </Grid>
      </div>
    )
  }
}
const resetPassword = gql`
  mutation resetPassword($password: String, $reset_token: String) {
    resetPassword(password: $password, reset_token: $reset_token) {
      ok
      errors {
        message
      }
    }
  }
`
export default graphql(resetPassword, { name: "resetPassword" })(ResetPassword)
