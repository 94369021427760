import React, { Component } from "react"
import { Form, Message, Header, Dropdown, Divider } from "semantic-ui-react"
import gql from "graphql-tag"
import moment from "moment"
import { graphql } from "react-apollo"

import dispatchDepotStack from "../BerthTallying/fetchContainers"
import InlineError from "../../shared/InlineError"

var RestowOptions = [
  { text: "Cut & Run", value: "Cut & Run" },
  { text: "Accident", value: "Accident" },
  { text: "Missing Container", value: "Missing Container" },
]
class RestowDetails extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      errors: {},
    }
  }
  validate = () => {
    const errors = {}

    if (!this.state.remarks) errors.remarks = "Can't be blank"
    if (!this.state.reason) errors.reason = "Can't be blank"

    return errors
  }
  onSubmit = () => {
    console.log("Submitting")
    const errors = this.validate()
    console.log("ERRORS::", errors)
    if (Object.keys(errors).length === 0) {
      this._restowDepotContainer()
    }
    this.setState({ errors })
  }
  handleChange = (input) => (event) => {
    this.setState({ [input]: event.target.value })
  }

  setOptionsValue = (e, data) => {
    const { name, value } = data
    this.setState({ [name]: value })
  }
  render() {
    const { errors } = this.state
    return (
      <>
        <Header as="h3" color="green" textAlign="center">
          Confirm {this.props.match.params.container_number}
        </Header>
        <Divider />
        <Form>
          {errors.global && (
            <Message negative>
              <Message.Header> Something went wrong </Message.Header>
              <p>{errors.global}</p>
            </Message>
          )}
          <Form.Field error={!!errors.reason}>
            <label>Reason</label>
            <Dropdown
              name={"reason"}
              value={this.state.reason}
              search
              selection
              options={RestowOptions}
              onChange={this.setOptionsValue.bind(this)}
            />
            {errors.reason && <InlineError text={errors.reason} />}
          </Form.Field>
          <Form.Field error={!!errors.remarks}>
            <label>Remarks</label>
            <input
              value={this.state.remarks}
              type="text"
              onChange={this.handleChange("remarks")}
            />
            {errors.remarks && <InlineError text={errors.remarks} />}
          </Form.Field>

          <Form.Button fluid positive onClick={this.onSubmit}>
            Submit
          </Form.Button>
        </Form>
      </>
    )
  }
  _restowDepotContainer = async () => {
    const { reason, remarks } = this.state
    await this.props
      .restowDepotContainer({
        variables: {
          // date_restowed,
          // time_restowed,
          id: this.props.match.params.id,
          status: "RESTOW",
          remarks: reason + "-" + remarks,
        },
        refetchQueries: [{ query: dispatchDepotStack }],
      })
      .then((result) => {
        console.log(result)
      })
      .catch((error) => {
        console.log(error)
      })
    this.props.history.push("/prestack/operations/shutlist")
  }
}

const restowDepotContainer = gql`
  mutation restowDepotContainer(
    $id: ID!
    $container_number: String
    $remarks: String
    $status: String
  ) {
    restowDepotContainer(
      id: $id
      container_number: $container_number
      remarks: $remarks
      status: $status
    )
  }
`
export default graphql(restowDepotContainer, {
  name: "restowDepotContainer",
})(RestowDetails)
