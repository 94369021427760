import gql from "graphql-tag";

export default gql`
  query getTruckers {
    getAllTruckers {
      id
      truckerName
      truckerLongName
      truckerCode
      telNo
    }
  }
`;
