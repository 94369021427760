import React, { Component } from "react"
import Validator from "validator"
import gql from "graphql-tag"
import { graphql } from "react-apollo"
import "react-toastify/dist/ReactToastify.css"
import { toast } from "react-toastify"
import { Form, Grid, Header, Message, Segment, Icon } from "semantic-ui-react"

import InlineError from "../../shared/InlineError"
toast.configure()
class ForgotMobileComponent extends Component {
  constructor(props) {
    super(props)
    this.state = {
      data: {
        email: "",
        password: "",
      },
      errors: {
        // global: store.get("Login")
      },
      loading: false,
    }
    this.onChange = this.onChange.bind()
  }
  onChange = (e) =>
    this.setState({
      data: { ...this.state.data, [e.target.name]: [e.target.value] },
    })

  onSubmit = async () => {
    const errors = this.validate(this.state.data)
    this.setState({ errors })
    if (Object.keys(errors).length === 0) {
      const { data } = this.state
      console.log(data.email)
      const result = await this.props.forgotMobilePassword({
        variables: { email: data.email[0] },
      })
      console.log(result.data.forgotMobilePassword)
      if (result.data.forgotMobilePassword.ok) {
        toast.info("Check you email account for your password reset", {
          position: toast.POSITION.TOP_CENTER,
          autoClose: false,
        })
      } else if (result.data.forgotMobilePassword.errors) {
        toast.error("Email doesn't exist", {
          position: toast.POSITION.TOP_CENTER,
          autoClose: false,
        })
      }
    }
  }
  validate = (data) => {
    const errors = {}

    if (!Validator.isEmail(data.email[0])) errors.password = "Invalid email"
    return errors
  }
  render() {
    const { data, loading } = this.state
    let errors = this.state
    return (
      <div className="login-form">
        <style>{`
        body > div,
        body > div > div,
        body > div > div > div.login-form {
          height: 100%;
        }
      `}</style>
        <Grid
          textAlign="center"
          style={{ height: "100%" }}
          verticalAlign="middle"
        >
          <Grid.Column style={{ maxWidth: 450 }}>
            <Header as="h2" color="green" textAlign="center">
              Forgot Password
            </Header>

            <Form onSubmit={this.onSubmit} loading={loading}>
              <Segment stacked>
                {errors.global && (
                  <Message negative>
                    <Message.Header>Something went wrong</Message.Header>
                    <p>{errors.global}</p>
                  </Message>
                )}
                <Form.Field error={!!errors.email}>
                  <label htmlFor="email">Email</label>
                  <input
                    type="email"
                    id="email"
                    name="email"
                    placeholder="example@example.com"
                    value={data.email}
                    onChange={this.onChange}
                  />
                  {errors.email && <InlineError text={errors.email} />}
                </Form.Field>

                <Form.Button fluid positive>
                  Submit
                </Form.Button>
              </Segment>
            </Form>
          </Grid.Column>
        </Grid>
      </div>
    )
  }
}
const forgotMobilePassword = gql`
  mutation forgotMobilePassword($email: String!) {
    forgotMobilePassword(email: $email) {
      ok
      errors {
        message
      }
    }
  }
`
export default graphql(forgotMobilePassword, { name: "forgotMobilePassword" })(
  ForgotMobileComponent
)
