import React, { Component } from "react";
import {
  Form,
  Button,
  Grid,
  Dropdown,
  Header,
  Divider
} from "semantic-ui-react";

import InlineError from "../shared/InlineError";
class ClientDetails extends Component {
  saveAndContinue = e => {
    e.preventDefault();
    this.props.nextStep();
  };
  render() {
    const { errors, state, clientOptions } = this.props;
    return (
      <Grid.Column style={{ maxWidth: 800 }}>
        <Header as="h1" color="green" textAlign="center">
          Enter Client Details
        </Header>
        <Divider />
        <Form>
          <Form.Field error={!!errors.clientCode}>
            <label>Client Code</label>
            <Dropdown
              value={state.clientCode}
              search
              selection
              options={clientOptions}
              onChange={this.props.setClientValue.bind(this)}
            />
            {errors.clientCode && <InlineError text={errors.clientCode} />}
          </Form.Field>
          <Form.Field error={!!errors.partyDelivering}>
            <label>Party Delivering</label>
            <input
              value={state.partyDelivering}
              type="text"
              onChange={this.props.handleChange("partyDelivering")}
            />
            {errors.partyDelivering && (
              <InlineError text={errors.partyDelivering} />
            )}
          </Form.Field>

          <Form.Field error={!!errors.vessel}>
            <label>Vessel</label>
            <input
              value={state.vessel}
              type="text"
              onChange={this.props.handleChange("vessel")}
            />
            {errors.vessel && <InlineError text={errors.vessel} />}
          </Form.Field>
          <Button onClick={this.saveAndContinue}>Save And Continue </Button>
        </Form>
      </Grid.Column>
    );
  }
}

export default ClientDetails;
