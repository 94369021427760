import React, { Component } from "react";

import gql from "graphql-tag";
import { graphql } from "react-apollo";
import {flowRight as compose} from 'lodash';
import ReactTable from "react-table";
import "react-table/react-table.css";
import DatePicker from "react-datepicker";
import Moment from "moment";
import { extendMoment } from "moment-range";
import {
  Header,
  Grid,
  Input,
  Select,
  Dropdown,
  Divider,
  Segment,
  Form
} from "semantic-ui-react";
import LoadingMessage from "../shared/LoadingMessage";
import withQuery from "../HOC/withQuery";

import getDeliveryNote from "./Queries/fetchAllDelivered";
import ExportDeliveries from "./ExportDeliveriesToExcel";

const moment = extendMoment(Moment);

var containerOptions = [];
var clientOptions = [];
class ExportDeliveriesList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [...this.props.entries],
      pageNum: 1,
      dateOption: "",
      position: "",

      freeSelectValue: "Delivery Note",
      defaultEndDate: moment(new Date()).format("DD-MM-YYYY")
    };
    this.renderEditable = this.renderEditable.bind(this);
  }
  onPostitionChange = (e, data) => {
    this.setState({ position: data.value });
  };
  setFreeSelectValue = (e, data) => {
    const { value } = data;

    this.setState({ freeSelectValue: value });
  };
  setContainerValue = (e, data) => {
    containerOptions.forEach(element => {
      if (element.value === data.value) {
        this.setState({ containerSize: element.id });
        this.setState({ containerCode: element.text });
        this.setState({ containerValue: element.value });
      }
    });
  };

  setClientValue = (e, data) => {
    clientOptions.forEach(element => {
      if (element.value === data.value) {
        this.setState({ clientCode: element.text });
      }
    });
  };
  handleDateToChange = date => {
    this.setState({ endDate: date });
  };
  handleDateFromChange = date => {
    this.setState({ startDate: date });
  };
  renderEditable(cellInfo) {
    return (
      <div
        // style={{ backgroundColor: "#fafafa" }}
        contentEditable
        suppressContentEditableWarning
        onBlur={e => {
          const data = [...this.state.data];
          data[cellInfo.index][cellInfo.column.id] = e.target.innerHTML;
          console.log("Editing:::::::::", data);
          this.setState({ data });
        }}
      />
    );
  }
  render() {
    // if (!this.props.permissions) return <PermissionsMessage />;
    const selectOptions = [
      { key: "deliveryNoteID", text: "Delivery Note", value: "deliveryNoteID" },
      { key: "containerNum", text: "Container Number", value: "containerNum" }
    ];

    if (this.props.clientFeed.loading === false) {
      let tempOp = this.props.clientFeed.getAllClients;
      clientOptions = [];
      tempOp.map(element => {
        return clientOptions.push({
          id: element.id,
          key: element.id,
          text: element.clientName,
          value: element.clientName
        });
      });
    }
    if (this.state.data) {
      let { data } = this.state;
      if (this.state.clientCode) {
        data = data.filter(row => {
          return row.clientCode.includes(this.state.clientCode);
        });
      }
      if (this.state.position) {
        if (this.state.position === "INVOICED") {
          data = data.filter(row => {
            return row.invoiceNum;
          });
        }
        if (this.state.position === "NOT INVOICED") {
          data = data.filter(row => {
            return !row.invoiceNum;
          });
        }
      }
      if (this.state.containerSize) {
        data = data.filter(row => {
          return row.containerSize.includes(this.state.containerSize);
        });
      }
      if (this.state.freeSearch) {
        if (this.state.freeSelectValue === "deliveryNoteID") {
          data = data.filter(row => {
            return row.deliveryNoteID.includes(this.state.freeSearch);
          });
        }
        if (this.state.freeSelectValue === "containerNum") {
          data = data.filter(row => {
            return row.containerNum.includes(this.state.freeSearch);
          });
        }
      }
      if (this.state.startDate) {
        const minDate = moment(this.state.startDate).format("YYYY-MM-DD");
        const maxDate = this.state.endDate
          ? moment(this.state.endDate).format("YYYY-MM-DD")
          : moment().format("YYYY-MM-DD");
        data = data.filter(row => {
          const currentDate = moment(row.dateDelivered).format("YYYY-MM-DD");
          return moment(currentDate).isBetween(minDate, maxDate);
        });
      }
      return (
        <Grid centered textAlign="center" style={{ height: "80%" }}>
          <Grid.Column>
            <Header as="h2" color="green" textAlign="center">
              Deliveries Reports
            </Header>
            <hr color="pink" />
            <Segment>
              <>
                <Divider color="olive" />
                <Form>
                  <Segment.Group horizontal fluid>
                    <Segment>
                      <Form.Field>
                        <DatePicker
                          selected={this.state.startDate}
                          onChange={this.handleDateFromChange}
                        />
                        <label>Date From: </label>{" "}
                      </Form.Field>
                      <Form.Field>
                        <DatePicker
                          selected={this.state.endDate}
                          onChange={this.handleDateToChange}
                        />
                        <label>Date To: </label>{" "}
                      </Form.Field>
                    </Segment>

                    <Segment>
                      <Form.Field>
                        <label>Client Code</label>
                        <Dropdown
                          value={this.state.clientCode}
                          search
                          selection
                          options={clientOptions}
                          onChange={this.setClientValue.bind(this)}
                        />
                      </Form.Field>
                    </Segment>
                    <Segment>
                      <Form.Field>
                        <Input type="text">
                          <input
                            value={this.state.freeSearch}
                            onChange={e =>
                              this.setState({ freeSearch: e.target.value })
                            }
                          />
                          <Select
                            compact
                            options={selectOptions}
                            defaultValue="vessel"
                            onChange={this.setFreeSelectValue.bind(this)}
                          />
                        </Input>
                      </Form.Field>
                    </Segment>
                  </Segment.Group>
                </Form>
                <hr color="pink" />
                <div>
                  <ReactTable
                    data={data}
                    columns={[
                      {
                        Header: "Client",
                        accessor: "clientCode"
                      },
                      {
                        Header: "CONTAINER NO.",
                        accessor: "containerNum"
                      },

                      {
                        Header: "SIZE",
                        accessor: "containerSize"
                      },

                      {
                        Header: "DATE DELIVERED",
                        accessor: "dateDelivered"
                        // Cell: this.renderEditable
                      },

                      {
                        Header: "Remarks",
                        accessor: "remarks"
                      },
                      {
                        Header: "Guarantee Form",
                        accessor: "guaranteeForm"
                      },
                      {
                        Header: "Guarantee Form Validity",
                        accessor: "guaranteeFormValidity"
                      },
                      {
                        Header: "Delivery Note",
                        accessor: "deliveryNoteID"
                      },
                      {
                        Header: "Truck Reg No",
                        accessor: "modeOfTransportIdMsa"
                      }
                    ]}
                    defaultPageSize={10}
                    pageSizeOptions={[5, 10, 20, 25, 50, 100, 250, 500, 1000]}
                    sortable
                    filterable
                  >
                    {(state, filteredData) => {
                      this.ReactTable = state.pageRows.map(data => {
                        return data._original;
                      });
                      return (
                        <div>
                          {filteredData()}
                          <ExportDeliveries
                            data={this.ReactTable}
                            type={this.state.position}
                          />
                        </div>
                      );
                    }}
                  </ReactTable>
                </div>
              </>
            </Segment>
          </Grid.Column>
        </Grid>
      );
    } else {
      return <LoadingMessage />;
    }
  }
  _editPreadvise = async (newValue, column, row) => {
    const { id } = row;
    let args = { id: id, [column.dataField]: newValue };
    console.log("Arguments", args);
    if (
      this.props.user.role === "ACCOUNTANT" ||
      this.props.user.role === "SUPERUSER"
    ) {
      await this.props.editPreadvise({
        variables: {
          id: id,

          [column.dataField]: newValue
        },
        refetchQuery: [{ getDeliveryNote }]
      });
      this.props.history.push("/invoices/list");
    }
  };
}
const CONTAINERFEED = gql`
  query getAllContainers {
    getAllContainers {
      id
      containerSize
      containerCode
    }
  }
`;
const CLIENTFEED = gql`
  query getAllClients {
    getAllClients {
      id
      clientCode
      clientName
    }
  }
`;
const EDITPREADVISE = gql`
  mutation editPreadvise(
    $id: ID!
    $eirNum: String
    $deliveryNoteID: String
    $inDateICD: String
  ) {
    editPreadvise(
      id: $id
      eirNum: $eirNum
      deliveryNoteID: $deliveryNoteID
      inDateICD: $inDateICD
    )
  }
`;

export default compose(
  graphql(CONTAINERFEED, {
    name: "containerFeed"
  }),
  graphql(CLIENTFEED, {
    name: "clientFeed"
  }),
  graphql(EDITPREADVISE, { name: "editPreadvise" })
)(withQuery(ExportDeliveriesList, getDeliveryNote, "getDeliveryNote"));
