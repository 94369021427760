import React, { Component } from "react";
import { Link } from "react-router-dom";
import {mobileSignup} from '../../Actions/auth';
import { Button, Form, Message, Segment, Dropdown } from "semantic-ui-react";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import {toast} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import { connect } from "react-redux";
import gql from "graphql-tag"
import {  graphql } from "react-apollo"

import Layout from "./Layout";
import InlineError from "../../shared/InlineError";

var clientOptions = [];
class Signup extends Component {
  constructor(props){
    super(props)
    this.state={
      email:"",
      password:"",
      phoneNumber:"",
      clientId:"",
      clientCode:"",
      userName:"",
      errors:{},
      loading:false
    }
  }
  onSubmit=()=>{
    console.log("SUBMITTING")
    const errors = this.validate()
    console.log("ERRORS", errors)
    if(Object.keys(errors).length ===0){
      this.props.mobileSignup(this.state).then(data => {
        console.log("RESULT::::", data)
        
        if (data.ok  ===false){
          toast.error(mobileSignup.errors[0].message, {position:toast.POSITION.TOP_CENTER,
            autoClose:false
            })
       
        } else{
          toast.success('Signup Successful. Check your email to activate your account', {position:toast.POSITION.TOP_CENTER,
            autoClose:false
            })
        }
      })
    }
  }
  validate = ()=>{
    const errors={}
    if(!this.state.userName) errors.userName="Can't be blank!";
    if(!this.state.clientCode) errors.clientCode="Can't be blank!";
    if(!this.state.email) errors.email="Invalid email!";
    if(!this.state.password) errors.password="Can't be blank!";
    if(!this.state.confirmPassword) errors.confirmPassword="Can't be blank!";
    if(!this.state.password === this.state.confirmPassword) errors.confirmPassword="Passwords must match!"
    if(!this.state.phoneNumber) errors.phoneNumber="Can't be blank!";

    return errors;
  }

  setClientValue = (e, data) => {
    console.log("clientOptions: ", data);
    clientOptions.forEach(element => {
      if (element.value === data.value) {
        this.setState({ 
          clientCode: element.text,
          clientId:element.id
         });
      }

      console.log(this.state);
    });
  };
    render() {
      const {errors}=this.state
      if (this.props.clientFeed.loading === false) {
        console.log(this.props.clientFeed);
        let tempOp = this.props.clientFeed.getAllClients;
        clientOptions = [];
  
        tempOp.map(element => {
          return clientOptions.push({
            id: element.id,
            key: element.id,
            text: element.clientName,
            value: element.clientName
          });
        });
      }
        return (
          <Layout header="Sign up to get started">
            <Form onSubmit={this.onSubmit} >
          <Segment stacked>
          {errors.global && (
                  <Message negative>
                    <Message.Header>Something went wrong</Message.Header>
                    <p>{errors.global}</p>
                  </Message>
                )}
                <Form.Field error={!!errors.clientCode}>
                    <label>Company Name</label>
                    <Dropdown
                    value={this.state.clientCode}
                    search
                    selection
                    clearable
                    searchInput='text'
                    noResultsMessage='Name not found.'
                    options={clientOptions}
                    onChange={this.setClientValue.bind(this)}
                    />
                    {errors.clientCode && <InlineError text={errors.clientCode} />}
                </Form.Field>
                <Form.Field error={!!errors.username}>
                  <label htmlFor="username">Username</label>
                  <input
                    type="text"
                    id="username"
                    icon="user"
                    name="username"
                    
                    placeholder="example@example.com"
                    value={this.state.username}
                    onChange={e => this.setState({ userName: e.target.value })}
                  />
                  {errors.username && <InlineError text={errors.username} />}
                </Form.Field>

                <Form.Field error={!!errors.email}>
                  <label htmlFor="email">Email</label>
                  <input
                    type="email"
                    id="email"
                    icon="email"
                    name="email"
                    placeholder="example@example.com"
                    value={this.state.email}
                    onChange={e => this.setState({ email: e.target.value })}
                  />
                  {errors.email && <InlineError text={errors.email} />}
                </Form.Field>
                <Form.Field error={!!errors.phoneNumber}>
                  <PhoneInput
                    placeholder="Phone number"
                    value={this.state.phoneNumber}
                    onChange={value => this.setState({ phoneNumber: value })}
                  />
                  {errors.phoneNumber && (
                    <InlineError text={errors.phoneNumber} />
                  )}
                </Form.Field>
                <Form.Field error={!!errors.password}>
                  <label htmlFor="password">Password</label>
                  <input
                    type="password"
                    id="password"
                    name="password"
                    placeholder="Make it secure"
                    value={this.state.password}
                    onChange={e => this.setState({ password: e.target.value })}
                  />
                  {errors.password && <InlineError text={errors.password} />}
                </Form.Field>
                <Form.Field error={!!errors.confirmPassword}>
                  <label htmlFor="password">Confirm Password</label>
                  <input
                    type="password"
                    id="password"
                    name="password"
                    placeholder="Make it secure"
                    onChange={e => {
                      
                      this.setState({ confirmPassword: e.target.value })
                  }}
                    
                  />
                  {errors.confirmPassword && <InlineError text={errors.confirmPassword} />}
                </Form.Field>
                <Button fluid positive onClick={()=>this.onSubmit}>
                  Submit
                </Button>
                <Message size="big">
              <Link to="/selfservice/login">Already Registered?</Link>
            </Message>
          </Segment>

        </Form>
         
    
            
          </Layout>
        );
      }
    
}

const CLIENTFEED = gql`
  query getAllClients {
    getAllClients {
      id
      clientCode
      clientName
      serviceType
      clientType
    }
  }
`;
export default graphql(CLIENTFEED, {
  name: "clientFeed"
})(connect(null, {mobileSignup})(Signup))