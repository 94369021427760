import React, { Component } from "react"
import moment from "moment"
import ReactHtmlTableToExcel from "react-html-table-to-excel"

class ExportStatus extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }
  stackedDays = (d) => {
    var now = moment().format("YYYY-MM-DD") //todays date
    console.log("NOW", now)
    var end = moment(d.gate_in_stack)
    console.log("END", d.gate_in_stack)
    var duration = moment.duration(end.diff(now))
    var days = duration.asDays()
    console.log("DURATION:::", Math.abs(days))
    if (isNaN(days)) return 0
    return Math.abs(days)
    // moment(d.inDateICD).fromNow()
  }
  render() {
    return (
      <div stylee={{ marginRight: "25px" }}>
        <ReactHtmlTableToExcel
          id="test-table-xls-button"
          className="export"
          table="table-to-xls"
          fileName="filteredData"
          sheet="tablexls"
          buttonText="Export"
        />
        <table hidden="true" id="table-to-xls">
          <thead>
            <tr>
              <th>No.</th>
              <th>CONTAINER NO.</th>
              <th>SIZE</th>
              <th>Weight</th>
              <th>EQStatus</th>
              <th>FEStatus</th>
              <th>POL</th>
              <th>POD</th>
              <th>VESSEL</th>
              <th>REF NO.</th>
              <th>PRE-ADVISE DATE</th>
              <th>PARTY DELIVERING</th>
              <th>GATE IN</th>
              <th>TIME</th>
              <th>BBL EIR NO. </th>
              <th>TRUCK ID OUT</th>
              <th>CARRIER ID OUT </th>
              <th>ETD STACK</th>
              <th>DATE LOADED</th>
              <th>TIME</th>
              <th> STACKED DAYS</th>
              <th>POSITION</th>
            </tr>
          </thead>
          <tbody>
            {this.props.data.map((preadvise, index) => {
              return (
                <tr key={preadvise.id}>
                  <td>{index + 1}</td>
                  <td>{preadvise.container.container_number}</td>
                  <td>{preadvise.container.container_size}</td>
                  <td>{preadvise.container.weight}</td>
                  <td>{preadvise.eqstatus}</td>
                  <td>{preadvise.festatus}</td>
                  <td>{preadvise.pol}</td>
                  <td>{preadvise.pod}</td>

                  <td>{preadvise.vessel_code}</td>
                  <td>{preadvise.reference_no}</td>
                  <td>
                    {moment(preadvise.preadviseDate).format("DD.MM.YYYY")}
                  </td>
                  <td>{preadvise.partyDelivering.party_delivering_name}</td>
                  <td>{preadvise.gate_in_stack}</td>
                  <td>{preadvise.gate_in_time}</td>
                  <td>{preadvise.bbl_eir}</td>
                  <td>{preadvise.mode_of_transport_id_out}</td>
                  <td>{preadvise.carrier_id_out}</td>

                  <td>{preadvise.gate_in_stack}</td>
                  <td>{preadvise.date_loaded}</td>
                  <td>{preadvise.time_loaded}</td>
                  <td>{this.stackedDays(preadvise)}</td>

                  <td>{preadvise.bay_position}</td>

                  <td></td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
    )
  }
}

export default ExportStatus
