
import gql from 'graphql-tag'
export default gql`
query getMobileOrdersList {
  getMobileOrdersList {
    sourceType
    containerFileName
    validationstatus
    booked
    fileLocation
    sOExpiryDate
    partyDeliveringName
    numberbooked
  }
}
`