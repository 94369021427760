import gql from "graphql-tag";

export default gql`
  query getDeptMbsContainers {
    getDeptMbsContainers {
      id
      clientCode
      containerNum
      preAdviseNum
      bblEIR
      deliveryDriverName
      deliveryDriverID
      partyDeliveringName
      inDateICD
      modeOfTransportId
    }
  }
`;
