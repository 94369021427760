import gql from "graphql-tag"

export default gql`
  query RestowStack {
    RestowStack {
      id
      container {
        container_number
      }
      mode_of_transport_id_out
      date_out
    }
  }
`
