import React from "react"
import { Switch, Route, HashRouter as Router } from "react-router-dom"
import LandingPage from "./LandingPage"
import Login from "./modules/Authentication/LoginPage"
import ChangePassword from "./modules/Authentication/ChangePassword"
import SelfServiceLogin from "./modules/ClientSelfRequest/Authentication/Login.jsx"
import ForgotMobileComponent from "./modules/ClientSelfRequest/Authentication/Forgot"
import ForgotPassword from "./modules/Authentication/forgotPassword"
import Signup from "./modules/ClientSelfRequest/Authentication/Signup"
import Done from "./modules/ClientSelfRequest/Authentication/Done"
import Warning from "./modules/ClientSelfRequest/Warning"
import Welcome from "./modules/ClientSelfRequest/Authentication/confirmation"
import ResetPassword from "./modules/Authentication/resetPassword"
import ResetSelfServicePassword from "./modules/ClientSelfRequest/Authentication/ResetSelfServicePassword"
const App = () => (
  <>
    <Router>
      <Switch>
        <Route
          exact={true}
          path="/selfservice/login"
          component={SelfServiceLogin}
        />
        <Route path="/selfservice/check" component={Warning} />
        <Route path="/selfservice/done" component={Done} />
        <Route
          path="/selfservice/confirm/:confirmationToken"
          component={Welcome}
        />
        <Route path="/resetpassword/:token" component={ResetPassword} />
        <Route path="/selfservice/signup" component={Signup} />
        <Route
          exact
          path="/selfservice/forgot"
          component={ForgotMobileComponent}
        />
        <Route
          path="/selfservice/resetpassword/:token"
          component={ResetSelfServicePassword}
        />
        <Route path="/login" component={Login} />
        <Route exact={true} path="/forgot" component={ForgotPassword} />
        <Route path="/change-password" component={ChangePassword} />
        <Route path="/" component={LandingPage} />
      </Switch>
    </Router>
  </>
)

export default App
