import React, { Component } from "react";
import moment from "moment";
import ReactHtmlTableToExcel from "react-html-table-to-excel";

class ExportToExcel extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    console.log("Excel Props", this.props.data);
    return (
      <div style={{ marginRight: "25px" }}>
        <ReactHtmlTableToExcel
          id="test-table-xls-button"
          className="export"
          table="table-to-xls"
          fileName="filteredData"
          sheet="tablexls"
          buttonText="Export"
        />
        <table hidden="true" id="table-to-xls">
          <thead>
            <tr>
              <th>NO</th>
              <th>CONTAINER NO.</th>
              <th>PARTY DELIVERING</th>
              <th colSpan={2}>MEA</th>
              <th>MLR</th>
              <th>MUR</th>             
              <th colSpan={2}>MIT</th>
              <th colSpan={2}>MRX</th>
            
            </tr>
          </thead>
          <tbody>
            {this.props.data.map((preadvise, index) => {
              return (
                <tr key={preadvise.id}>
                  <th>{index + 1}</th>
                  <td>{preadvise.containerNum}</td>
                  <td>{preadvise.partyDeliveringName}</td>
                  <td>
                    {preadvise.inTimeICD !== "Invalid date" &&
                    preadvise.inTimeICD !== null
                      ? moment(preadvise.inTimeICD).format("DD-MMM-YY")
                      : ""}
                  </td>
                  <td>
                    {preadvise.inTimeICD !== "Invalid date" &&
                    preadvise.inTimeICD !== null
                      ? moment(preadvise.inTimeICD).format("h:mm")
                      : ""}
                  </td>
                                   
                  <td>{preadvise.inTimeICD !== "Invalid date" &&
                      preadvise.inTimeICD !== null
                        ? moment(preadvise.inTimeICD).format("DD-MMM-YY")
                        : ""}
                      
                    </td>
                
                  <td>
                    {preadvise.loadingTime !== "Invalid date" &&
                    preadvise.loadingTime !== null
                      ? moment(preadvise.loadingTime).format("DD-MMM-YY")
                      : ""}
                  </td>
                
                  <td>
                    {preadvise.inTimeMsa !== "Invalid date" &&
                    preadvise.inTimeMsa !== null &&
                    preadvise.inTimeMsa !== ""
                      ? moment(preadvise.inTimeMsa).format("DD-MMM-YY")
                      : ""}
                  </td>
                  <td>
                    {preadvise.inTimeMsa !== "Invalid date" &&
                    preadvise.inTimeMsa !== null &&
                    preadvise.inTimeMsa !== ""
                      ? moment(preadvise.inTimeMsa).format("h:mm")
                      : ""}
                  </td>
                  <td>
                    {preadvise.outTimeMsa !== "Invalid date" &&
                    preadvise.outTimeMsa !== null &&
                    preadvise.outTimeMsa !== ""
                      ? moment(preadvise.outTimeMsa).format("DD-MMM-YY")
                      : ""}
                  </td>
                  <td>
                    {preadvise.eirTime }
                  </td>
                
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  }
}

export default ExportToExcel;
