import React, { Component } from "react";
import gql from "graphql-tag";
import { graphql } from "react-apollo";
import {
  Form,
  Segment,
  Grid,
  Header,
  Message,
  Dropdown,
  Divider
} from "semantic-ui-react";
import DatePicker from "react-datepicker";
import "rc-time-picker/assets/index.css";
import TimePicker from "rc-time-picker";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";

import PermissionsMessage from "../shared/PermissionsMessage";
import getDeptMbsContainers from "./Queries/fetchAllDeparturesNrb";
import InlineError from "../shared/InlineError";
class DepartureNrb extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loadingTime: "",
      outTimeICD: "",
      modeOfTransportOut: "",
      transportTypeOut: "",
      etaMSA: "",
      wagonNum: "",
      status: "",

      errors: {},
      loading: false
    };
  }

  setTransportMode = (e, data) => {
    this.setState({ modeOfTransportOut: data.value });
  };
  onTransportTypeChange = (e, data) => {
    this.setState({ transportTypeOut: data.value });
  };
  loadingTimeChange = time => this.setState({ loadingTime: time });
  outTimeICDChange = outTimeICD => this.setState({ outTimeICD });
  etaMSAChange = etaMSA => this.setState({ etaMSA });
  validate = () => {
    const errors = {};

    if (!this.state.loadingTime) errors.loadingTime = "Can't be blank";
    if (!this.state.outTimeICD) errors.outTimeICD = "Can't be blank";
    if (!this.state.modeOfTransportOut)
      errors.modeOfTransportOut = "Can't be blank";
    if (!this.state.transportTypeOut)
      errors.transportTypeOut = "Can't be blank";
    if (!this.state.etaMSA) errors.etaMSA = "Can't be blank";
    if (!this.state.status) errors.status = "Can't be blank";

    if (Object.keys(errors).length === 0) {
      this._addDepartures();
    }
    return errors;
  };

  onSubmit = () => {
    const errors = this.validate();

    this.setState({ errors });
  };
  render() {
    if (!this.props.permissions) return <PermissionsMessage />;
    const { loading, errors } = this.state;
    const modeOfTransportOptions = [
      { id: 1, value: "ROAD", text: "ROAD" },
      { id: 2, value: "RAIL", text: "RAIL" }
    ];
    const transportTypeOptions = [
      { id: 1, value: "TRUCK", text: "TRUCK" },
      { id: 2, value: "TRAIN", text: "TRAIN" }
    ];
    return (
      <>
        {" "}
        <Header as="h3" color="green" textAlign="center">
          Add Departure Details
        </Header>
        <Form size="large" onSubmit={this.onSubmit} loading={loading}>
          {errors.global && (
            <Message negative>
              <Message.Header> Something went wrong </Message.Header>
              <p>{errors.global}</p>
            </Message>
          )}
          <Grid
            textAlign="center"
            style={{ height: "100%" }}
            columns={2}
            divided
          >
            <Grid.Row>
              <Grid.Column>
                <Divider />

                <Segment stacked>
                  <Form.Field error={!!errors.modeOfTransportOut}>
                    <label>Mode</label>
                    <Dropdown
                      value={this.state.modeOfTransportOut}
                      search
                      selection
                      options={modeOfTransportOptions}
                      onChange={this.setTransportMode.bind(this)}
                    />
                    {errors.modeOfTransportOut && (
                      <InlineError text={errors.modeOfTransportOut} />
                    )}
                  </Form.Field>
                  <Form.Field error={!!errors.outTimeICD}>
                    <label>Gate Out</label>{" "}
                    <DatePicker
                      selected={this.state.outTimeICD}
                      onChange={this.outTimeICDChange}
                    />
                    {errors.outTimeICD && (
                      <InlineError text={errors.outTimeICD} />
                    )}
                  </Form.Field>
                  <Divider />
                  <Form.Field error={!!errors.loadingTime}>
                    <label>Loading Time</label>

                    <TimePicker
                      // style={{ width: 100 }}
                      showSecond={false}
                      defaultValue={moment()}
                      className="xxx"
                      onChange={this.loadingTimeChange}
                    />
                    {errors.loadingTime && (
                      <InlineError text={errors.loadingTime} />
                    )}
                  </Form.Field>
                  <Divider />
                  <Form.Field error={!!errors.etaMSA}>
                    <label>Arrival Time(Est.)</label>

                    <DatePicker
                      selected={this.state.etaMSA}
                      onChange={this.etaMSAChange}
                    />
                    {errors.etaMSA && <InlineError text={errors.etaMSA} />}
                  </Form.Field>
                  <Divider />
                </Segment>
              </Grid.Column>
              <Grid.Column>
                <Divider />

                <Segment stacked>
                  <Form.Field error={!!errors.transportTypeOut}>
                    <label>Transport Type</label>
                    <Dropdown
                      value={this.state.transportTypeOut}
                      search
                      selection
                      options={transportTypeOptions}
                      onChange={this.onTransportTypeChange.bind(this)}
                    />
                    {errors.transportTypeOut && (
                      <InlineError text={errors.transportTypeOut} />
                    )}
                  </Form.Field>
                  <Form.Field error={!!errors.wagonNum}>
                    <label>Wagon Number</label>
                    <input
                      value={this.state.wagonNum}
                      onChange={e =>
                        this.setState({ wagonNum: e.target.value })
                      }
                    />
                    {errors.wagonNum && <InlineError text={errors.wagonNum} />}
                  </Form.Field>
                  <Form.Field error={!!errors.status}>
                    <label>Status</label>
                    <input
                      value={this.state.status}
                      type="text"
                      onChange={e => this.setState({ status: e.target.value })}
                    />
                    {errors.status && <InlineError text={errors.status} />}
                  </Form.Field>
                </Segment>
              </Grid.Column>
            </Grid.Row>
          </Grid>
          <Form.Button fluid positive>
            Submit
          </Form.Button>
        </Form>
      </>
    );
  }
  _addDepartures = async () => {
    const {
      loadingTime,
      outTimeICD,
      modeOfTransportOut,
      transportTypeOut,
      etaMSA,
      wagonNum,
      status
    } = this.state;
    await this.props.addMutation({
      variables: {
        id: this.props.match.params.id,
        loadingTime,
        outTimeICD,
        modeOfTransportOut,
        transportTypeOut,
        etaMSA,
        wagonNum,
        status
      },
      refetchQueries: [{ query: getDeptMbsContainers }]
    });
    this.props.history.push("/departures/nrb/list");
  };
}

const addMutation = gql`
  mutation(
    $id: ID!
    $loadingTime: String!
    $outTimeICD: String!
    $modeOfTransportOut: String!
    $transportTypeOut: String!
    $etaMSA: String!
    $wagonNum: String!
    $status: String!
  ) {
    departureNrb(
      id: $id
      loadingTime: $loadingTime
      outTimeICD: $outTimeICD
      modeOfTransportOut: $modeOfTransportOut
      transportTypeOut: $transportTypeOut
      etaMSA: $etaMSA
      wagonNum: $wagonNum
      status: $status
    )
  }
`;
export default graphql(addMutation, { name: "addMutation" })(DepartureNrb);
