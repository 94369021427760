import React from "react";
import { Grid, Message } from "semantic-ui-react";

const Info = props => {
  console.log(props);
  return (
    <Grid textAlign="center" style={{ height: "100%" }} verticalAlign="middle">
      <Grid.Column style={{ maxWidth: 600 }}>
        <Message negative size="large">
          <Message.Header>Sorry you can't add that container!</Message.Header>
          <p>Container currently in transit!</p>
        </Message>
      </Grid.Column>
    </Grid>
  );
};

export default Info;
