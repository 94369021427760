import React, { Component } from "react"
import gql from "graphql-tag"
import { graphql } from "react-apollo"
import { flowRight as compose } from "lodash"
import { connect } from "react-redux"

import {
  Form,
  Divider,
  Header,
  Grid,
  Message,
  Segment,
} from "semantic-ui-react"

import InlineError from "../../shared/InlineError"

class ChangeSelfServicePassword extends Component {
  constructor(props) {
    super(props)
    this.state = {
      password: "",
      confirmPassword: "",
      userId: "",
      userName: "",
      errors: {},
      loading: false,
    }
  }

  validate = async () => {
    const errors = {}
    console.log("VALIDATE")

    if (!this.state.password) errors.password = "Can't be blank"
    if (!this.state.confirmPassword) errors.confirmPassword = "Can't be blank"
    if (this.state.confirmPassword !== this.state.password)
      errors.confirmPassword = "Passwords must match"
    console.log("ERRORS: ", errors)

    return errors
  }
  onSubmit = () => {
    console.log("ON SUBMIT")
    const errors = this.validate()
    this.setState({ errors })
    if (Object.keys(errors).length === 0) {
      this.setState({ loading: true })

      this._changeMobilePassword()
    }
  }
  render() {
    const { loading, errors } = this.state
    console.log(this.props)

    return (
      <div className="login-form">
        <style>{`
      body > div,
      body > div > div,
      body > div > div > div.login-form {
        height: 100%;
      }
    `}</style>
        <Grid
          textAlign="center"
          style={{ height: "100%" }}
          verticalAlign="middle"
        >
          <Grid.Column style={{ maxWidth: 450 }}>
            <Header as="h2" color="green" textAlign="center">
              Change Password
            </Header>
            <Divider />
            <Form size="large" onSubmit={this.onSubmit} loading={loading}>
              <Segment stacked>
                {errors.global && (
                  <Message negative>
                    <Message.Header> Something went wrong </Message.Header>
                    <p>{errors.global}</p>
                  </Message>
                )}

                <Form.Field error={!!errors.password}>
                  <label>Password</label>
                  <input
                    type="password"
                    value={this.state.password}
                    onChange={(e) =>
                      this.setState({ password: e.target.value })
                    }
                  />
                  {errors.password && <InlineError text={errors.password} />}
                </Form.Field>
                <Divider />
                <Form.Field error={!!errors.confirmPassword}>
                  <label>Confirm Password</label>
                  <input
                    type="password"
                    value={this.state.confirmPassword}
                    onChange={(e) =>
                      this.setState({ confirmPassword: e.target.value })
                    }
                  />
                  {errors.confirmPassword && (
                    <InlineError text={errors.confirmPassword} />
                  )}
                </Form.Field>

                <Form.Button fluid positive>
                  Submit
                </Form.Button>
              </Segment>
            </Form>
          </Grid.Column>
        </Grid>
      </div>
    )
  }
  _changeMobilePassword = async () => {
    const { password } = this.state
    const userId = this.props.user.id
    this.props.changeMobilePassword({
      variables: {
        password,
        userId,
      },
    })
    this.props.history.push("/selfservice/login")
  }
}

const CHANGEMUTATION = gql`
  mutation changeMobilePassword($password: String!, $userId: ID) {
    changeMobilePassword(password: $password, userId: $userId) {
      ok
      token
    }
  }
`
function mapStateToProps(state) {
  return {
    user: state.user.user,
  }
}
export default compose(
  graphql(CHANGEMUTATION, { name: "changeMobilePassword" })
)(connect(mapStateToProps)(ChangeSelfServicePassword))
