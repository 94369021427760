import gql from "graphql-tag";

export default gql`
 
 query getArchivedReport {
  getArchivedReport {
    id
    inTimeICD
    bblEIR
    inDateICD
    pOL
    containerNum
    containerSize
    pOD
    clientCode
    fileBillingNumber
    vessel
    modeOfTransportId
    deliveryDriverName
    partyDeliveringName
    deliveryNoteID
    operatorCode
    recievedICDBy {
      name
    }
    containerDamages {
      id
      repair {
        id
        materialCost
        manhours
        description
        materialCost
        repairCode
      }
    }
  }
}

`;