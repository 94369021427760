import React, { useState } from 'react';
import {
    Form,
    Button,
    Grid,
    Dropdown,
    Header,
    Divider,
} from "semantic-ui-react"
import { flowRight as compose } from 'lodash';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import * as XLSX from "xlsx"
import gql from "graphql-tag";
import { graphql } from "react-apollo";
import InlineError from '../shared/InlineError';
const _ = require("lodash")

var clientOptions = [];
var operatorOptions = [];
var containerSizes = {
    "22": "ec4d3b03-20f8-47cb-9395-d1750be096a3",
    "42": "c03200de-518a-4e04-a259-2aac6198ce37",
    "45": "ba92a197-eb58-40b5-bd71-da1f8f230c40"
}
function BulkUploadPreadvice(props) {
    const [items, setItems] = useState([])
    const [errors, seterrors] = useState({
        fileUpload: '',
        vessel: '',
        partyDelivering: '',
        clientCode: '',
        sOExpiryDate: '',
        preadviseDate: '',
        operator_code: '',
        preAdviseNum: '',
        fileBillingNumber: ''
    })
    const [clientCode, setclientCode] = useState()
    const [sOExpiryDate, setsOExpiryDate] = useState()
    const [preadviseDate, setpreadviseDate] = useState()
    const [vessel, setvessel] = useState()
    const [operator_code, setoperator_code] = useState()
    const [partyDelivering, setpartyDelivering] = useState()
    const [preAdviseNum, setPreadviseNum] = useState()
    const [fileBillingNumber, setFileBillingNumber] = useState()
    const [loading, setloading] = useState(false)

    const validate = () => {
        const errors = {}

        console.log("EXPIRY:::::", sOExpiryDate)
        console.log(sOExpiryDate === '')
        if ('' === items) errors.fileUpload = "Please upload file"
        if ('' === vessel) errors.vessel = "Can't be blank"
        if ('' === operator_code) errors.operator_code = "Can't be blank"
        if ('' === partyDelivering) errors.partyDelivering = "Can't be blank"
        if ('' === preadviseDate) errors.preadviseDate = "Can't be blank"
        if ('' === sOExpiryDate) errors.sOExpiryDate = "Can't be blank"
        if ('' === clientCode) errors.clientCode = "Can't be blank"
        if ('' === preAdviseNum) errors.preAdviseNum = "Can't be blank"
        if ('' === fileBillingNumber) errors.fileBillingNumber = "Can't be blank"
        if (Object.keys(errors).length === 0) {
            // setloading(true)
            addContainer()
        }
        return errors
    }
    const addContainer = () => {

        items.map(async (item) => {
            console.log(item)
            let weight;
            if (item["Size"] === "22") {
                weight = "2200"
            } else {
                weight = "3900"
            }
            let result = await props.addContainer({
                variables: {
                    clientCode: clientCode,
                    containerNum: item["Container"],
                    containerSize: containerSizes[item["Size"]],
                    weight: weight,
                    operatorCode: operator_code,
                    eQStatus: "KEEMK",
                    fEStatus: "KEMBA",
                    pOL: "KEEMK",
                    pOD: "KEMBA",
                    sOExpiryDate: sOExpiryDate,
                    partyDelivering: partyDelivering,
                    vessel: vessel,
                    preadviseDate: preadviseDate,
                    preAdviseNum: preAdviseNum,
                    fileBillingNumber: fileBillingNumber
                },
            })
            console.log(result)
        })
        props.history.push("/transaction/list")
    }
    const setClientValue = (e, data) => {

        clientOptions.forEach(element => {
            if (element.value === data.value) {
                setclientCode(element.text)
            }
        });
    };
    const setOperatorValue = (e, data) => {
        console.log("operatorOptions: ", operatorOptions);
        operatorOptions.forEach(element => {
            console.log(element)
            if (element.value === data.value) {
                setoperator_code(element.value)
            }
        });
    };
    const handlesOExpiryDateChange = (date) => {

        setsOExpiryDate(date)
    }
    const handlesPreadviseDateChange = date => {
        setpreadviseDate(date)
    };
    const readExcel = (file) => {
        console.log("FILE: ", file)
        const promise = new Promise((resolve, reject) => {
            const fileReader = new FileReader()
            fileReader.readAsArrayBuffer(file)
            fileReader.onload = (e) => {
                const bufferArray = e.target.result

                const wb = XLSX.read(bufferArray, { type: "buffer" })
                const wsname = wb.SheetNames[0]

                const ws = wb.Sheets[wsname]
                const data = XLSX.utils.sheet_to_json(ws)
                resolve(data)
            }
            fileReader.onerror = (error) => {
                reject(error)
            }
        })
        promise.then((d) => {
            console.log(d)
            setItems(d)
            seterrors((prevState) => ({ ...prevState, fileUpload: "" }))
        })
    }

    if (props.clientFeed.loading === false) {
        console.log(props.clientFeed);
        let tempOp = props.clientFeed.getAllClients;
        clientOptions = [];

        tempOp.map(element => {
            return clientOptions.push({
                id: element.id,
                key: element.id,
                text: element.clientName,
                value: element.clientName
            });
        });
    }
    if (props.operatorFeed.loading === false) {
        let tempOp = props.operatorFeed.getAllOperators;
        operatorOptions = [];
        tempOp.map(element => {
            return operatorOptions.push({
                id: element.id,
                key: element.id,
                text: element.operatorCode,
                value: element.operatorCode
            });
        });
    }
    const submit = () => {
        setloading(true)

        const errors = validate()
        console.log(errors)
        seterrors((prevState) => ({ ...prevState, ...errors }))
        setloading(false)
    }
    return (
        <Grid textAlign="center" verticalAlign="top" style={{ height: "100%" }}>
            <Grid.Column >
                <Header as="h1" color="green" textAlign="center">
                    Enter upload details
                </Header>
                <Divider />
                <Form onSubmit={submit} loading={loading}>
                    <Form.Group widths='equal'>
                        <Form.Field error={!!errors.vessel}>
                            <label>Client Name</label>
                            <Dropdown
                                value={clientCode}
                                search
                                selection
                                options={clientOptions}
                                onChange={setClientValue}
                            />
                            {errors.vessel && <InlineError text={errors.vessel} />}
                        </Form.Field>
                        <Form.Field error={!!errors.operatorCode}>
                            <label>Operator Code</label>
                            <Dropdown
                                value={operator_code}
                                search
                                selection
                                options={operatorOptions}
                                onChange={setOperatorValue}
                            />
                            {errors.operatorCode && <InlineError text={errors.operatorCode} />}
                        </Form.Field>
                    </Form.Group>
                    <Form.Group widths='equal'>
                        <Form.Field error={!!errors.partyDelivering}>
                            <label>Party Delivering</label>
                            < input
                                value={partyDelivering}
                                type="text"
                                onChange={e => setpartyDelivering(e.target.value)}
                            />
                            {errors.partyDelivering && <InlineError text={errors.partyDelivering} />}
                        </Form.Field>
                        <Form.Field error={!!errors.vessel}>
                            <label>Vessel</label>
                            < input
                                value={vessel}
                                type="text"
                                onChange={e => setvessel(e.target.value)}
                            />
                            {errors.vessel && <InlineError text={errors.vessel} />}
                        </Form.Field>
                    </Form.Group>
                    <Form.Group widths='equal'>
                        <Form.Field error={!!errors.preAdviseNum}>
                            <label>Reference</label>
                            < input
                                value={preAdviseNum}
                                type="text"
                                onChange={e => setPreadviseNum(e.target.value)}
                            />
                            {errors.preAdviseNum && <InlineError text={errors.preAdviseNum} />}
                        </Form.Field>
                        <Form.Field error={!!errors.fileBillingNumber}>
                            <label>File Billing No.</label>
                            < input
                                value={fileBillingNumber}
                                type="text"
                                onChange={e => setFileBillingNumber(e.target.value)}
                            />
                            {errors.fileBillingNumber && <InlineError text={errors.fileBillingNumber} />}
                        </Form.Field>
                    </Form.Group>
                    <Form.Group widths='equal'>

                        <Form.Field error={!!errors.sOExpiryDate}>
                            <label>Storage Order Expiry Date</label>{" "}
                            <DatePicker
                                selected={sOExpiryDate}
                                onChange={date => handlesOExpiryDateChange(date)}
                            />
                        </Form.Field>
                        <Form.Field error={!!errors.preadviseDate}>
                            <label>Preadvise Date</label>{" "}
                            <DatePicker
                                selected={preadviseDate}
                                onChange={date => handlesPreadviseDateChange(date)}
                            />
                        </Form.Field>
                    </Form.Group>
                    <Form.Group widths='equal'>
                        <Form.Field error={!!errors.fileUpload}>
                            <input
                                type="file"
                                onChange={(e) => {
                                    const file = e.target.files[0]
                                    readExcel(file)
                                }}
                            />
                        </Form.Field>
                    </Form.Group>

                    <Button fluid positive>
                        Save{" "}
                    </Button>
                </Form>
            </Grid.Column>
        </Grid>
    );
}
const CREATEPREADVISE = gql`
  mutation addContainer(
    $clientCode: String!
    $containerNum: String!
    $containerSize: String!
    $operatorCode: String!
    $weight: String!
    $eQStatus: String!
    $fEStatus: String!
    $pOL: String!
    $pOD: String!
    $vessel: String!
    $partyDelivering: String!
    $sOExpiryDate: String!
    $preadviseDate: String!
    $preAdviseNum: String!
    $fileBillingNumber: String!
  ) {
    createPreadvise(
      clientCode: $clientCode
      containerNum: $containerNum
      containerSize: $containerSize
      weight: $weight
      eQStatus: $eQStatus
      fEStatus: $fEStatus
      pOL: $pOL
      operatorCode: $operatorCode
      pOD: $pOD
      vessel: $vessel
      partyDeliveringName: $partyDelivering
      sOExpiryDate: $sOExpiryDate
      preadviseDate: $preadviseDate
      preAdviseNum: $preAdviseNum
      fileBillingNumber: $fileBillingNumber
    ) {
      ok
      message
    }
  }
`;

const CLIENTFEED = gql`
  query getAllClients {
    getAllClients {
      id
      clientCode
      clientName
    }
  }
`;

const OPERATORFEED = gql`
  query getAllClients {
    getAllOperators {
      id
      operatorCode
      operatorName
    }
  }
`;

export default compose(
    graphql(CLIENTFEED, {
        name: "clientFeed"
    }),
    graphql(OPERATORFEED, {
        name: "operatorFeed"
    }),
    graphql(CREATEPREADVISE, { name: "addContainer" })
)(BulkUploadPreadvice);