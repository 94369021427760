import React, { Component } from "react";
import gql from "graphql-tag";
import { graphql } from "react-apollo";
import { flowRight as compose } from 'lodash';
import { Link } from "react-router-dom";
import { Icon } from "semantic-ui-react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import cellEditFactory from "react-bootstrap-table2-editor";

import LoadingMessage from "../modules/shared/LoadingMessage";
import withQuery from "../modules/HOC/withQuery";
import axios from "axios";
// import gql from "graphql-tag";

import getArchivedPdfDetail from "./Queries/fetchArchivedPdf";
import { saveAs } from "file-saver";

class ArchivedCreatePdf extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [...this.props.entries],
      pageNum: 1,
    };
  }

  createAndDownloadPdf = async (cont) => {
    console.log('cont:: ', cont)
    const { id } = cont
    const data = await this.props.getArchivedPdfDetail({
      variables: {
        id: id
      }
    })
    console.log('data:: ', data)
    data.sendemail = false;
    axios
      .post("https://cts.hawilisha.com/pdf/create-pdf", data)
      .then(
        () =>
          axios(`https://cts.hawilisha.com/pdf/fetch-pdf/${data.bblEIR}`, {
            method: "GET",
            responseType: "blob", //Force to receive data in a Blob Format
          })
        // axios.get("http://64.227.21.145/:5000/fetch-pdf", {
        //   responseType: "blob",
        // })
      )
      .then((res) => {
        const pdfBlob = new Blob([res.data], { type: "application/pdf" });
        saveAs(pdfBlob, "newPdf.pdf");
      }).catch(error => {
        console.log(error)
      })
  };
  render() {
    console.log(this.state);
    if (this.state.data) {
      const { data } = this.state;
      const CaptionElement = () => (
        <h3
          style={{
            borderRadius: "0.25em",
            textAlign: "center",
            color: "green",
            border: "1px solid green",
            padding: "0.5em",
          }}
        >
          Container Damages
        </h3>
      );
      const columns = [
        {
          text: "container Number",
          dataField: "containerNum",
          sort: true,
        },

        {
          text: "Client Code.",
          dataField: "clientCode",
          sort: true,
          editable: true,
        },

        {
          text: "Operator Code.",
          dataField: "operatorCode",
          sort: true,
          editable: true,
        },

        {
          text: "",
          dataField: "df",
          isDummyField: true,
          formatter: (cellContent, row) => {
            return (
              <Link to={{
                pathname: `downloadpdf/${row.id}/`,
              }}>
                Get PDF
                <Icon name="angle double right" color="green" />
              </Link>
            );
          },
        },
      ];
      const defaultSorted = [
        {
          dataField: "DamageCode",
          order: "desc",
        },
      ];
      const { SearchBar } = Search;
      const pagination = paginationFactory({
        sizePerPage: 5,
      });
      return (
        <>
          <CaptionElement />

          <ToolkitProvider
            keyField="id"
            data={data}
            columns={columns}
            defaultSorted={defaultSorted}
            search={{ defaultSearch: "" }}
          >
            {(props) => (
              <div>
                <div align="right">
                  <SearchBar {...props.searchProps} />
                </div>
                <hr />
                <BootstrapTable
                  {...props.baseProps}
                  striped
                  hover
                  condensed
                  pagination={pagination}
                  noDataIndication="Table is Empty"
                  cellEdit={cellEditFactory({
                    mode: "dbclick",
                    blurToSave: true,
                    beforeSaveCell: (oldValue, newValue, row, column) => {
                      console.log("column: ", column);
                      console.log("Before Saving Cell!!:", oldValue);
                      console.log("New Value: ", newValue);
                      console.log("Id: ", row.id);
                      this._editPreadvise(newValue, column, row);
                    },
                  })}
                />
              </div>
            )}
          </ToolkitProvider>
        </>
      );
    } else {
      return <LoadingMessage />;
    }
  }
}


const getArchivedPdf = gql`
query getArchivedPdf {
  getArchivedPdf {
    id
    containerNum
    clientCode
    operatorCode
    status
  }
}

`
// const 

export default compose(
  graphql(getArchivedPdfDetail, { name: "getArchivedPdfDetail" })
)(withQuery(ArchivedCreatePdf, getArchivedPdf, "getArchivedPdf"))
