import gql from "graphql-tag"

export default gql`
query getClientDelivered {
  getClientDelivered {
    id
    clientCode
    operatorCode
    vessel
    createdAt 
    createdTime
    containerNum
    containerSize
    eirFileLocation
    
  }
}
`