import React, { Component } from "react"
import gql from "graphql-tag"
import { Link } from "react-router-dom"
import { Icon } from "semantic-ui-react"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory from "react-bootstrap-table2-paginator"
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import withQuery from "../../HOC/withQuery"
import departureDepotStack from "./fetchContainers"
class PrestackRecieved extends Component {
  constructor(props) {
    super(props)
    this.state = { data: [...this.props.entries], pageNum: 1 }
  }
  render() {
    const { data } = this.state
    const CaptionElement = () => (
      <h3
        style={{
          borderRadius: "0.25em",
          textAlign: "center",
          color: "green",
          border: "1px solid green",
          padding: "0.5em",
        }}
      >
        Loading List
      </h3>
    )
    const { SearchBar, ClearSearchButton } = Search
    const defaultSorted = [
      {
        dataField: "preadvise_date",
        order: "desc",
      },
    ]
    const pagination = paginationFactory({
      sizePerPage: 5,
    })
    const columns = [
      {
        text: "Container Number",
        dataField: "container.container_number",
        sort: true,
        headerStyle: {
          backgroundColor: "#c8e6c9",
        },
      },
      {
        text: "Party Delivering",
        dataField: "partyDelivering.party_delivering_name",
        sort: true,
        headerStyle: {
          backgroundColor: "#c8e6c9",
        },
      },
      {
        text: "Seal",
        dataField: "seal_number",
        sort: true,
        headerStyle: {
          backgroundColor: "#c8e6c9",
        },
      },
      {
        text: "Recieved",
        dataField: "gate_in_stack",
        sort: true,
        headerStyle: {
          backgroundColor: "#c8e6c9",
        },
      },
      {
        text: "Action",
        dataField: "df",
        isDummyField: true,
        headerStyle: {
          backgroundColor: "#c8e6c9",
        },
        formatter: (cellContent, row) => {
          return (
            <Link
              to={`/prestack/operations/departures/${row.id}/${row.container.container_number}`}
            >
              Dispatch
              <Icon name="angle double right" color="green" />
            </Link>
          )
        },
      },
    ]
    return (
      <>
        <CaptionElement />
        <ToolkitProvider
          keyField="voyage_id"
          data={data}
          columns={columns}
          defaultSorted={defaultSorted}
          search={{ defaultSearch: "" }}
        >
          {(props) => (
            <div>
              <div align="right">
                <SearchBar {...props.searchProps} />
                <ClearSearchButton {...props.searchProps} />
              </div>
              <hr />
              <BootstrapTable
                {...props.baseProps}
                striped
                hover
                condensed
                pagination={pagination}
                noDataIndication="Table is Empty"
              />
            </div>
          )}
        </ToolkitProvider>
      </>
    )
  }
}

export default withQuery(
  PrestackRecieved,
  departureDepotStack,
  "departureDepotStack"
)
