import gql from "graphql-tag";

export default gql`
  query getUsers {
    userFeed {
      id
      email
      role
      name
      confirmed
      locked
    }
  }
`;
