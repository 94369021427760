import gql from "graphql-tag";
export default gql`
  query getInvoicedReport {
    getInvoicedReport {
      id
      invoiceNum
      clientCode
      containerNum
      containerSize
      dateDelivered
      preAdviseNum
      fileBillingNumber
      deliveryNoteID
      bblEIR
      vessel
      inDateICD
      remarks
      guaranteeForm
      guaranteeFormValidity
      modeOfTransportIdMsa
    }
  }
`;

