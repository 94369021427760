import gql from "graphql-tag";

export default gql`
  query getArchivedReport(
    $inDateICD: String
    $outTimeICD: String
    $arrivalDateMSA: String
    $dateDelivered: String
    $eQStatus: String
    $fEStatus: String
    $clientCode: String
    $operatorCode: String
    $containerSize: String
    $vessel: String
    $position: String
  ) {
    getArchivedReport(
      inDateICD: $inDateICD
      outTimeICD: $outTimeICD
      arrivalDateMSA: $arrivalDateMSA
      dateDelivered: $dateDelivered
      eQStatus: $eQStatus
      fEStatus: $fEStatus
      clientCode: $clientCode
      operatorCode: $operatorCode
      containerSize: $containerSize
      vessel: $vessel
      position: $position
    ) {
      id
      clientCode
      operatorCode
      containerNum
      containerSize
      weight
      eQStatus
      fEStatus
      pOL
      pOD
      sOExpiryDate
      vessel
      preAdviseNum
      preadviseDate
      partyDeliveringName
      inDateICD
      inTimeICD
      bblEIR
      etdNbo
      outTimeICD
      loadingTime
      etaMSA
      arrivalStatus
      arrivalDateMSA
      inTimeMsa
      wagonNum
      modeOfTransportOutMsa
      transportTypeOutMsa
      carrierIDOutMsa
      modeOfTransportIdMsa
      position
      fileBillingNumber
      dateDelivered
      deliveryNoteID
      invoiceNum
      remarks
    }
  }
`;
