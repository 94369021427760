import React, { Component } from "react";
import {
  Form,
  Segment,
  Grid,
  Header,
  Message,
  Divider
} from "semantic-ui-react";
import "react-phone-number-input/style.css";

import gql from "graphql-tag";
import { graphql } from "react-apollo";

import InlineError from "../shared/InlineError";
import getAllDamages from "./queries/fetchAllDamages";

class AddDamage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      repairCode: "",
      description: "",
      manhours: "",
      materialCost: "", 
      loading: false,
      errors: {}
    };
  }
  validate = () => {
    const errors = {};

    if (!this.state.repairCode) errors.repairCode = "Can't be blank";
    if (!this.state.description) errors.description = "Can't be blank";
    if (!this.state.manhours) errors.manhours = "Can't be blank";
    if (!this.state.materialCost) errors.materialCost = "Can't be blank";
    if (Object.keys(errors).length === 0) {
      this.setState({ loading: true });
      this._createDamage();
    }
    return errors;
  };
  onSubmit = () => {
    const errors = this.validate();
    this.setState({ errors });
  };
  render() {
    // if (!this.props.permissions) return <PermissionsMessage />;
    const { errors, loading } = this.state;
    return (
      <Grid
        textAlign="center"
        style={{ height: "100%" }}
        verticalAlign="middle"
      >
        <Grid.Column style={{ maxWidth: 900 }}>
          <Header as="h2" color="green" textAlign="center">
            Create Damage
          </Header>
          <Divider />
          <Form size="large" onSubmit={this.onSubmit} loading={loading}>
            {errors.global && (
              <Message negative>
                <Message.Header> Something went wrong </Message.Header>
                <p>{errors.global}</p>
              </Message>
            )}
            <Segment.Group horizontal>
              <Segment>
                <Form.Field error={!!errors.repairCode}>
                  <label>Repair Code</label>
                  <input
                    value={this.state.repairCode}
                    onChange={e =>
                      this.setState({ repairCode: e.target.value })
                    }
                  />
                  {errors.repairCode && (
                    <InlineError text={errors.repairCode} />
                  )}
                </Form.Field>

                <Form.Field error={!!errors.description}>
                  <label>Description</label>
                  <input
                    value={this.state.description}
                    type="text"
                    onChange={e =>
                      this.setState({ description: e.target.value })
                    }
                  />
                  {errors.description && (
                    <InlineError text={errors.description} />
                  )}
                </Form.Field>
                <Form.Field error={!!errors.manhours}>
                  <label>Man Hours </label>
                  <input
                    value={this.state.manhours}
                    type="text"
                    onChange={e =>
                      this.setState({ manhours: e.target.value })
                    }
                  />
                  {errors.manhours && (
                    <InlineError text={errors.manhours} />
                  )}
                </Form.Field>
                <Form.Field error={!!errors.materialCost}>
                  <label>Material Cost </label>
                  <input
                    value={this.state.materialCost}
                    type="text"
                    onChange={e =>
                      this.setState({ materialCost: e.target.value })
                    }
                  />
                  {errors.materialCost && (
                    <InlineError text={errors.materialCost} />
                  )}
                </Form.Field>
              </Segment>
            </Segment.Group>
            <Form.Button fluid positive>
              Submit
            </Form.Button>
            <Segment stacked />
          </Form>
        </Grid.Column>
      </Grid>
    );
  }
  _createDamage = async () => {
    const {
      repairCode,
      description,
      manhours,
      materialCost
    } = this.state;
    await this.props.addDamage({
      variables: {
        repairCode,
        description,
        manhours,
        materialCost
      },

      refetchQueries: [{ query: getAllDamages }]
    });
    this.props.history.push("/damages/list");
  };
}

const CREATEDAMAGE = gql`
  mutation addDamage(
    $repairCode: String!
    $description: String!
    $manhours: Float!
    $materialCost: Int!
  ) {
    addDamage(
      repairCode: $repairCode
      description: $description
      manhours: $manhours
      materialCost: $materialCost
    )
  }
`;

export default graphql(CREATEDAMAGE, { name: "addDamage" })(AddDamage);
