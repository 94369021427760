import React, { Component } from "react";
import {
  Form,
  Segment,
  Grid,
  Header,
  Message,
  Divider
} from "semantic-ui-react";
import gql from "graphql-tag";
import { graphql } from "react-apollo";

import "react-datepicker/dist/react-datepicker.css";

import InlineError from "../shared/InlineError";
import getTruckers from "./Queries/fetchTruckers";
import PermissionsMessage from "../shared/PermissionsMessage";
class AddTrucker extends Component {
  constructor(props) {
    super(props);
    this.state = {
      truckerName: "",
      truckerLongName: "",
      truckerCode: "",
      telNo: "",
      loading: false,
      errors: {}
    };
  }
  validate = () => {
    const errors = {};

    if (!this.state.truckerName) errors.truckerName = "Can't be blank";
    if (!this.state.truckerLongName) errors.truckerLongName = "Can't be blank";
    if (!this.state.truckerCode) errors.truckerCode = "Can't be blank";
    if (!this.state.telNo) errors.telNo = "Can't be blank";

    this.setState({ loading: true });
    this._updateGuaranteeDetails();
    return errors;
  };
  onSubmit = () => {
    const errors = this.validate();
    this.setState({ errors });
  };

  render() {
    if (!this.props.permissions) return <PermissionsMessage />;
    const { errors, loading } = this.state;
    return (
      <Grid
        textAlign="center"
        style={{ height: "100%" }}
        verticalAlign="middle"
      >
        <Grid.Column style={{ maxWidth: 600 }}>
          <Header as="h2" color="green" textAlign="center">
            Add Trucker
          </Header>
          <Divider />
          <Form size="large" onSubmit={this.onSubmit} loading={loading}>
            {errors.global && (
              <Message negative>
                <Message.Header> Something went wrong </Message.Header>
                <p>{errors.global}</p>
              </Message>
            )}
            <Form.Field error={!!errors.truckerName}>
              <label>Name</label>
              <input
                autoheight={true}
                value={this.state.truckerName}
                onChange={e => this.setState({ truckerName: e.target.value })}
              />
              {errors.truckerName && <InlineError text={errors.truckerName} />}
            </Form.Field>
            <Form.Field error={!!errors.truckerLongName}>
              <label>Long Name</label>
              <input
                autoheight={true}
                value={this.state.truckerLongName}
                onChange={e =>
                  this.setState({ truckerLongName: e.target.value })
                }
              />
              {errors.truckerLongName && (
                <InlineError text={errors.truckerLongName} />
              )}
            </Form.Field>
            <Form.Field error={!!errors.truckerCode}>
              <label>Code</label>
              <input
                autoheight={true}
                value={this.state.truckerCode}
                onChange={e => this.setState({ truckerCode: e.target.value })}
              />
              {errors.truckerCode && <InlineError text={errors.truckerCode} />}
            </Form.Field>
            <Form.Field error={!!errors.telNo}>
              <label>Phone Number:</label>
              <input
                autoheight={true}
                value={this.state.telNo}
                onChange={e => this.setState({ telNo: e.target.value })}
              />
              {errors.telNo && <InlineError text={errors.telNo} />}
            </Form.Field>
            <Form.Button fluid positive>
              Submit
            </Form.Button>
            <Segment stacked />
          </Form>
        </Grid.Column>
      </Grid>
    );
  }
  _updateGuaranteeDetails = async () => {
    const { truckerCode, truckerName, truckerLongName, telNo } = this.state;
    await this.props.addTrucker({
      variables: {
        truckerCode,
        truckerName,
        truckerLongName,
        telNo
      },

      refetchQueries: [{ query: getTruckers }]
    });
    this.props.history.push("/truckers/list");
  };
}

const CREATETRUCKER = gql`
  mutation addTrucker(
    $truckerName: String!
    $truckerLongName: String!
    $truckerCode: String!
    $telNo: String
  ) {
    addTrucker(
      truckerName: $truckerName
      truckerLongName: $truckerLongName
      telNo: $telNo
      truckerCode: $truckerCode
    )
  }
`;
export default graphql(CREATETRUCKER, { name: "addTrucker" })(AddTrucker);
