// Success.jsx
import React, { Component } from "react";
import {
  Form,
  Grid,
  Button,
  Header,
  Message,
  Divider
} from "semantic-ui-react";
class Success extends Component {
  back = e => {
    e.preventDefault();
    this.props.prevStep();
  };
  render() {
    console.log(this.props);
    const { errors } = this.props;
    return (
      <Grid
        textAlign="center"
        style={{ height: "100%" }}
        verticalAlign="middle"
      >
        <Grid.Column style={{ maxWidth: 600 }}>
          <Header as="h2" color="green" textAlign="center">
            Submitted
          </Header>
          <Divider />
     
          <Form size="large" onSubmit={this.onSubmit}>
            {errors.global ? (
             <Message negative>
             <Message.Header> An error occured please review your input </Message.Header>
            
           </Message>
            ) : (
              
               <Message positive>
               <Message.Header> Records Submitted Successfully </Message.Header>
               <p>{errors.global}</p>
             </Message>
            )}

            <Button fluid positive onClick={this.back}>
              Back
            </Button>
          </Form>
        </Grid.Column>
      </Grid>
    );
  }
}

export default Success;
