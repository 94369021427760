import React, { Component } from "react";
import moment from "moment";
import ReactHtmlTableToExcel from "react-html-table-to-excel";

class ExportToExcel extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    console.log("Excel Props", this.props.data);
    return (
      <div style={{ marginRight: "25px" }}>
        <ReactHtmlTableToExcel
          id="test-table-xls-button"
          className="export"
          table="table-to-xls"
          fileName="filteredData"
          sheet="tablexls"
          buttonText="Export"
        />
        <table hidden="true" id="table-to-xls">
          <thead>
            <tr>
              <tr>NO</tr>
              <th>CLIENT</th>
              <th>FILE BILLING NO.</th>
              <th>PRE-ADVISE NO</th>
              <th>CONTAINER NO.</th>
              <th>SIZE</th>
              <th>OPR</th>

              <th>GATE IN ICD</th>
              <th>BBL EIR NO. </th>
              <th>ETD NBO</th>
              <th>ETA MSA</th>
              <th>WAGON NO.</th>
              <th>CURRENT STATUS</th>

              <th>GUARANTEE FORM</th>
              <th>GUARANTEE FORM VALIDITY</th>
              <th>POSITION</th>

              <th>DATE DELIVERED</th>
              <th>TRACTOR D/NOTE</th>
              <th>TRUCKER</th>

              <th>EIR NO</th>
              <th>EIR DATE</th>
              <th>INVOICE NO</th>
            </tr>
          </thead>
          <tbody>
            {this.props.data.map(preadvise => {
              return (
                <tr key={preadvise.id}>
                  <td>{preadvise.clientCode}</td>
                  <td>{preadvise.fileBillingNumber}</td>
                  <td>{preadvise.preAdviseNum}</td>
                  <td>{preadvise.containerNum}</td>
                  <td>{preadvise.containerSize}</td>
                  <td>{preadvise.operatorCode}</td>

                  <td>
                    {preadvise.inDateICD !== "Invalid date" &&
                    preadvise.inDateICD !== null
                      ? moment(preadvise.inDateICD).format("DD-MMM-YY")
                      : ""}
                  </td>
                  <td>{preadvise.bblEIR}</td>
                  <td>
                    {preadvise.inDateICD !== "Invalid date" &&
                    preadvise.inDateICD !== null
                      ? moment(preadvise.inDateICD).format("DD-MMM-YY")
                      : ""}
                  </td>
                  <td>
                    {preadvise.etaMSA !== "Invalid date" &&
                    preadvise.etaMSA !== null
                      ? moment(preadvise.etaMSA).format("DD-MMM-YY")
                      : ""}
                  </td>
                  <td>{preadvise.wagonNum}</td>
                  <td>{preadvise.status}</td>

                  <td>
                    {preadvise.guaranteeForm !== "Invalid date" &&
                    preadvise.guaranteeForm !== null &&
                    preadvise.guaranteeForm !== ""
                      ? moment(preadvise.guaranteeForm).format("DD-MMM-YY")
                      : ""}
                  </td>

                  <td>
                    {preadvise.guaranteeFormValidity !== "Invalid date" &&
                    preadvise.guaranteeFormValidity !== null &&
                    preadvise.guaranteeFormValidity !== ""
                      ? moment(preadvise.guaranteeFormValidity).format(
                          "DD-MMM-YY"
                        )
                      : ""}
                  </td>
                  <td>{preadvise.position}</td>

                  <td>
                    {preadvise.dateDelivered !== "Invalid date" &&
                    preadvise.dateDelivered !== null &&
                    preadvise.dateDelivered !== ""
                      ? moment(preadvise.dateDelivered).format("DD-MMM-YY")
                      : ""}
                  </td>
                  <td>{preadvise.deliveryNoteID}</td>
                  <td>{preadvise.truckerName}</td>
                  <td>{preadvise.eirNum}</td>
                  <td>
                    {preadvise.eirDate !== "Invalid date" &&
                    preadvise.eirDate !== null &&
                    preadvise.eirDate !== ""
                      ? moment(preadvise.eirDate).format("DD-MMM-YY")
                      : ""}
                  </td>
                  <td>{preadvise.invoiceNum}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  }
}

export default ExportToExcel;
