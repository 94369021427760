import gql from "graphql-tag";

export default gql`
query getDTIPdfContainers {
  getDTIPdfContainers {
    id
    gate_in_time
    bbl_eir
    gate_in_stack
    pol
    container_number
    containerSize
    pod
    clientCode
    reference_no
    vessel_code
    mode_of_transport_id
    delivery_driver_name
    dispatch_driver_name
    seal_number
    partyDeliveringName
    clientCode
    recieveddepotby {
      name
    }
    containerDamages {
      id
      repair {
        id
        materialCost
        manhours
        description
        materialCost
        repairCode
      }
    }
  }
}

`;
