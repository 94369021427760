import React, { Component } from "react";
import {
  Form,
  Segment,
  Grid,
  Header,
  Message,
  Divider,
  TextArea
} from "semantic-ui-react";
import gql from "graphql-tag";
import { graphql } from "react-apollo";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import PermissionsMessage from "../shared/PermissionsMessage";
import getGuarantee from "./Queries/fetchGuarantee";

class AddGuarantee extends Component {
  constructor(props) {
    super(props);
    this.state = {
      guaranteeForm: "",
      guaranteeFormValidity: "",
      remarks: "",
      destination: "",
      loading: false,
      errors: {}
    };
  }
  validate = () => {
    const errors = {};

    if (!this.state.guaranteeForm) errors.guaranteeForm = "Can't be blank";
    if (!this.state.guaranteeFormValidity)
      errors.guaranteeFormValidity = "Can't be blank";
    if (!this.state.destination) errors.destination = "Can't be blank";

    this.setState({ loading: true });
    this._updateGuaranteeDetails();
    return errors;
  };
  onSubmit = () => {
    const errors = this.validate();
    this.setState({ errors });
  };

  handleGuaranteeFormChange = date => {
    this.setState({ guaranteeForm: date });
  };
  handleGuaranteeFormValidityChange = date => {
    this.setState({ guaranteeFormValidity: date });
  };
  render() {
    if (!this.props.permissions) return <PermissionsMessage />;
    const { errors, loading } = this.state;
    return (
      <Grid
        textAlign="center"
        style={{ height: "100%" }}
        verticalAlign="middle"
      >
        <Grid.Column style={{ maxWidth: 600 }}>
          <Header as="h2" color="green" textAlign="center">
            Add Guarantee
          </Header>
          <Divider />
          <Form size="large" onSubmit={this.onSubmit} loading={loading}>
            {errors.global && (
              <Message negative>
                <Message.Header> Something went wrong </Message.Header>
                <p>{errors.global}</p>
              </Message>
            )}

            <Form.Field error={!!errors.guaranteeForm}>
              <label>Guarantee Recieved Date</label>{" "}
              <DatePicker
                selected={this.state.guaranteeForm}
                onChange={this.handleGuaranteeFormChange}
              />
            </Form.Field>

            <Form.Field error={!!errors.guaranteeFormValidity}>
              <label>Guarantee Form Validity</label>{" "}
              <DatePicker
                selected={this.state.guaranteeFormValidity}
                onChange={this.handleGuaranteeFormValidityChange}
              />
            </Form.Field>
            <Form.Field error={!!errors.guaranteeFormValidity}>
              <label>Remarks</label>{" "}
              <TextArea
                placeholder="Tell us more"
                onChange={e => this.setState({ remarks: e.target.value })}
              />
            </Form.Field>

            <Form.Button fluid positive>
              Submit
            </Form.Button>
            <Segment stacked />
          </Form>
        </Grid.Column>
      </Grid>
    );
  }
  _updateGuaranteeDetails = async () => {
    const { guaranteeForm, guaranteeFormValidity, remarks } = this.state;
    await this.props.addGuaranteee({
      variables: {
        id: this.props.match.params.id,
        guaranteeForm,
        guaranteeFormValidity,
        remarks
      },

      refetchQueries: [{ query: getGuarantee }]
    });
    this.props.history.push("/guarantee/list");
  };
}

const CREATEGUARANTEE = gql`
  mutation addGuaranteee(
    $id: ID!
    $guaranteeForm: String!
    $guaranteeFormValidity: String!
    $remarks: String
  ) {
    updateGuarantee(
      id: $id
      guaranteeForm: $guaranteeForm
      guaranteeFormValidity: $guaranteeFormValidity
      remarks: $remarks
    )
  }
`;

export default graphql(CREATEGUARANTEE, { name: "addGuaranteee" })(
  AddGuarantee
);
