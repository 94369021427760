
import  gql  from 'graphql-tag';
import React, { Component } from 'react';
import {connect} from 'react-redux'
import { graphql } from 'react-apollo'
import {flowRight as compose} from 'lodash';
import { withRouter } from 'react-router';
import InlineError from "../shared/InlineError"
import { Table, Message, Icon, Button, Form, Segment, TextArea, Dropdown} from 'semantic-ui-react'

import getPreadviseByContainerFileName from './Queries/fetchOrderContainers'
const storingOrderOptions = [
  { key: 'valid', text: 'Meets 96hrs (4 days) validity ', value: 'valid' },
  { key: 'invalid', text: 'Less than the minimum 96hrs(4 days)', value: 'invalid' },
  { key: 'expired', text: 'Expired prior to handing over the container to BBL', value: 'expired' },
]
class  BookedContainers extends Component {
  constructor(props) {
    super(props);
    this.state = {  
      SOStatus: '',
      remarks:"",
      errors:{},
    }
  }
  // remarksHandler = (e, data) => {
  //   this.setState({remarks: event.target.value});
  // }
  setSOValues = (e, data)=>{
    storingOrderOptions.forEach((element)=>{
      if (element.value == data.value){
        this.setState({SOStatus:element.value})
      }
    })
  }
  validate = ()=>{
    const errors = {}
    if (!this.state.SOStatus) errors.SOStatus="Can't be blank"
    return errors
  }
  onSubmit =(validationStatus)=>{
    const errors = this.validate()
    if (Object.keys(errors).length === 0 || this.props.location.state.sourceType =='SHIPPINGLINE' ) {
    this._validatePreadvise(validationStatus)
    }
    this.setState({ errors })
  }
  _validatePreadvise = async(validationStatus)=>{
    console.log("Validation STATUS:::::",validationStatus)
    const containerFileName=this.props.containerFileName
    const sourceType = this.props.location.state.sourceType
    const {remarks, SOStatus}=this.state
    await this.props.validatePreadvise({
      variables:{containerFileName, remarks, status:validationStatus, SOstatus: SOStatus, sourceType}
    }).then((result) => {
      console.log(result);
    })
    .catch((error) => {
      console.log(error);
    });
    this.props.history.push("/bookings/list")
  }
  
  render() { 
    const { errors } = this.state
    const loadingMessage = (
      <Message icon info>
        <Icon name="circle notched" loading />
        <Message.Content>
          <Message.Header>Just one second</Message.Header>
          We are fetching that content for you.
        </Message.Content>
      </Message>
    );

    const emptyMessage = (
      <Message icon info>
        <Icon name="warning circle" />
        <Message.Content>
          <Message.Header>No Containers Found</Message.Header>
          
        </Message.Content>
      </Message>
    );

    const timeoutMessage = (
      <Message icon negative>
        <Icon name="wait" />
        <Message.Content>
          <Message.Header>{this.props.getPreadviseByContainerFileName.errorl}</Message.Header>
          Is the backend server running?
        </Message.Content>
      </Message>
    );
    if (this.props.getPreadviseByContainerFileName && this.props.getPreadviseByContainerFileName.loading) {
      return <div>{loadingMessage}</div>;
    }

    if (this.props.getPreadviseByContainerFileName && this.props.getPreadviseByContainerFileName.error) {
      return <div>{timeoutMessage}</div>;
    }

    if (this.props.getPreadviseByContainerFileName.getPreadviseByContainerFileName.length === 0) {
      return <div>{emptyMessage}</div>;
    }

    return ( 
      <Segment>
    <Table color="teal">
    <Table.Header>
      <Table.Row>
      <Table.HeaderCell>Client</Table.HeaderCell>
        <Table.HeaderCell>Operator</Table.HeaderCell>
        <Table.HeaderCell>Container Number</Table.HeaderCell>
        <Table.HeaderCell>Container Size</Table.HeaderCell>
      </Table.Row>
    </Table.Header>

    <Table.Body>
    {this.props.getPreadviseByContainerFileName.getPreadviseByContainerFileName.map(item => (
              <Table.Row>
              <Table.Cell>{item.clientCode}</Table.Cell>
              <Table.Cell>{item.operatorCode}</Table.Cell>
              <Table.Cell>{item.containerNum}</Table.Cell>
              <Table.Cell>{item.containerSize}</Table.Cell>
            </Table.Row>
 
          ))}
    
    </Table.Body>
  </Table> 
  {this.props.user.user.user.role &&
  <>
  <Form>
  { errors.global && (
              <Message negative>
                  <Message.Header> Something went wrong </Message.Header>
                  <p>{errors.global}</p>
              </Message>
    )
    }
    
      <Form.Field>
    <TextArea placeholder='Remarks' 
         onChange={e =>
          this.setState({ remarks: e.target.value })
        }
    />
    </Form.Field>
    {
      this.props.location.state.sourceType !='SHIPPINGLINE' &&
      <Form.Field error={!!errors.SOStatus}>
        <label>S.O Status</label>
        <Dropdown
            value={this.state.SOStatus}
            search
            selection
            options={storingOrderOptions}
            onChange={this.setSOValues.bind(this)}
        />
        {errors.SOStatus && <InlineError text={errors.SOStatus} />}
    </Form.Field>
    }
    
 
  </Form>
  <Segment.Group horizontal>
 
    <Segment>
      <Button 
      fluid 
      positive
      onClick={()=>this.onSubmit(true)}

      >Validate Request</Button> 
    </Segment>
    <Segment>
      <Button 
      fluid 
      negative
      onClick={()=>this.onSubmit(false)}

      >Decline Request</Button> 
    </Segment>
  </Segment.Group>
 
  </>
  }
   </Segment>
  );
  }
}

const  validatePreadvise = gql`
mutation validatePreadvise(
  $containerFileName: String!
  $status: Boolean!
  $remarks: String
  $SOstatus: String
  $sourceType: String
) {
  validatePreadvise(
    containerFileName: $containerFileName
    status: $status
    remarks: $remarks
    SOstatus: $SOstatus
    sourceType:$sourceType
  )
}
`;
const mapStateToProps = (state) => {
  return {
      user: state.user
  }
}
 export default compose(
  graphql(validatePreadvise, {name:"validatePreadvise"}),
  graphql(getPreadviseByContainerFileName, {
  name:"getPreadviseByContainerFileName", 
  options: ({containerFileName }) => ({ variables: { containerFileName:containerFileName } })
 }))(withRouter(connect(mapStateToProps, null)(BookedContainers)))
