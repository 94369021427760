import React, { useState } from "react"
import {
  Header,
  Grid,
  Form,
  Dropdown,
  Message,
  Input,
  Divider,
} from "semantic-ui-react"
import gql from "graphql-tag"
import { graphql } from "react-apollo"
import { flowRight as compose } from "lodash"

import InlineError from "../../shared/InlineError"
const _ = require("lodash")

var EQStatusOptions = [
  { text: "Export", value: "Export" },
  { text: "Import", value: "Import" },
]
var FEStatusOptions = [
  { text: "Empty", value: "Empty" },
  { text: "Full", value: "Full" },
]
var POLOptions = [
  { text: "KEMBA", value: "KEMBA" },
  { text: "KEEMK", value: "KEEMK" },
  { text: "KERNA", value: "KERNA" },
]

var PODOptions = [
  { text: "KEMBA", value: "KEMBA" },
  { text: "KEEMK", value: "KEEMK" },
  { text: "KERNA", value: "KERNA" },
]
var BookTypeOptions = [
  { text: "Ordinary", value: "Ordinary" },
  { text: "DTI", value: "DTI" },
]
var voyageOptions = []
var deliveringOptions = []
var operatorOptions = []
var containerOptions = []
const addDepotStack = gql`
  mutation addDepotStack(
    $container_number: String
    $operator_code: String
    $operator_id: String
    $container_size: String
    $weight: String
    $height: String
    $party_delivering_id: String
    $voyage_id: String
    $eqstatus: String
    $festatus: String
    $pol: String
    $pod: String
    $vessel_code: String
    $book_type: String
  ) {
    addDepotStack(
      container_number: $container_number
      operator_code: $operator_code
      operator_id: $operator_id
      container_size: $container_size
      weight: $weight
      height: $height
      party_delivering_id: $party_delivering_id
      voyage_id: $voyage_id
      eqstatus: $eqstatus
      festatus: $festatus
      pol: $pol
      pod: $pod
      vessel_code: $vessel_code
      book_type: $book_type
    )
  }
`
function BookPrestackContainer(props) {
  const [vessel_code, setVesselCode] = useState()
  const [errors, setErrors] = useState({})
  const [voyage_id, setvoyage_id] = useState()
  const [festatus, setFestatus] = useState()
  const [eqstatus, setEqstatus] = useState()
  const [pol, setPol] = useState()
  const [pod, setPod] = useState()
  const [container_number, setContainer_number] = useState()
  const [vesselName, setvesselName] = useState()
  const [weight, setWeight] = useState()
  const [height, setHeight] = useState()
  const [party_delivering_id, setparty_delivering_id] = useState()
  const [party_delivering_name, setparty_delivering_name] = useState()
  const [loading, setloading] = useState(false)
  const [operator_code, setOperatorCode] = useState()
  const [operator_id, setOperatorId] = useState()
  const [container_size, setContainerSize] = useState()
  const [containerCode, setContainerCode] = useState()
  const [containerValue, setContainerValue] = useState()
  const [bookType, setBookType] = useState()
  const setPODOptions = (e, data) => {
    const { name, value } = data
    setPod(value)
  }
  const setBookTypeOptions = (e, data) => {
    const { name, value } = data
    setBookType(value)
  }
  const setPOLOptions = (e, data) => {
    const { name, value } = data
    setPol(value)
  }
  const setFestatusOptions = (e, data) => {
    const { name, value } = data
    setFestatus(value)
  }
  const setEqstatusOptions = (e, data) => {
    const { name, value } = data
    setEqstatus(value)
  }
  const setOperatorValue = (e, data) => {
    operatorOptions.forEach((element) => {
      if (element.value === data.value) {
        // this.setState({ operator_id: element.id })
        setOperatorId(element.id)
        setOperatorCode(element.value)
      }
    })
  }
  const setContainer = (e, data) => {
    console.log("containerOptions: ", containerOptions)
    containerOptions.forEach((element) => {
      if (element.value === data.value) {
        setContainerSize(element.id)
        setContainerCode(element.text)
        setContainerValue(element.value)
      }

      // console.log(this.state)
    })
  }
  const setVoyageValue = (e, data) => {
    voyageOptions.forEach((element) => {
      if (element.value === data.value) {
        console.log(element)
        setvoyage_id(element.id)
        setvesselName(element.value)
        setErrors((prevState) => ({ ...prevState, vessel: "" }))
      }
    })
  }
  const setPartyDeliveringValue = (e, data) => {
    console.log(data)
    deliveringOptions.forEach((element) => {
      if (element.value === data.value) {
        console.log(element)
        setparty_delivering_id(element.id)
        setparty_delivering_name(element.value)
        // setErrors((prevState) => ({ ...prevState, delivering: "" }))
      }
    })
  }
  const containerNumberChange = (data) => {
    const errors = {}
    console.log(data.target.value)
    const container_number = data.target.value

    if (container_number.length === 11) {
      if (
        container_number.substring(0, 4).match(/^[a-zA-Z]+$/) == null ||
        container_number.substring(0, 4).match(/^[a-zA-Z]+$/) === false
      ) {
        setErrors((prevState) => ({
          ...prevState,
          container_number: "Wrong entry for container number",
        }))
        // errors.container_number = "Wrong entry for container number"
      } else {
        if (Number.isNaN(parseInt(container_number.substring(4)))) {
          setErrors((prevState) => ({
            ...prevState,
            container_number: "Wrong entry for container number",
          }))
        } else {
          setErrors((prevState) => ({
            ...prevState,
            container_number: "",
          }))
        }
      }
    } else {
      setErrors((prevState) => ({
        ...prevState,
        container_number: "Wrong entry for container number",
      }))
    }
    setContainer_number(container_number)
  }
  if (props.getAvailableVesselVoyages.loading === false) {
    if (props.getAvailableVesselVoyages.error) return <p>Error loading</p>
    console.log(props.getAvailableVesselVoyages)
    let tempOp = props.getAvailableVesselVoyages.getAvailableVesselVoyages
    voyageOptions = []

    tempOp.map((element) => {
      return voyageOptions.push({
        id: element.voyage_id,
        key: element.voyage_id,
        text: element.vesselName,
        value: element.vesselName,
      })
    })
  }
  if (props.getAllPartyDelivering.loading === false) {
    if (props.getAllPartyDelivering.error) return <p>Error loading</p>
    console.log(props.getAllPartyDelivering)
    let tempOp = props.getAllPartyDelivering.getAllPartyDelivering
    deliveringOptions = []

    tempOp.map((element) => {
      return deliveringOptions.push({
        id: element.party_delivering_id,
        key: element.party_delivering_id,
        text: element.party_delivering_name,
        value: element.party_delivering_name,
      })
    })
  }

  if (props.getAllPrestackOperator.loading === false) {
    let tempOp = props.getAllPrestackOperator.getAllPrestackOperator
    operatorOptions = []
    tempOp.map((element) => {
      return operatorOptions.push({
        id: element.operator_id,
        key: element.operator_id,
        text: element.operator_code,
        value: element.operator_code,
      })
    })
  }
  if (props.getAllContainers.loading === false) {
    let tempOp = props.getAllContainers.getAllContainers
    containerOptions = []
    tempOp.map((element) => {
      return containerOptions.push({
        id: element.id,
        key: element.id,
        text: element.containerCode,
        value: element.containerCode,
      })
    })
  }
  const validate = () => {
    const errors = {}
    if (_.isEmpty(container_number)) errors.container_number = "Can't be blank"
    if (_.isEmpty(operator_code)) errors.operator_code = "Can't be blank"
    if (_.isEmpty(container_size)) errors.container_size = "Can't be blank"
    if (_.isEmpty(weight)) errors.weight = "Can't be blank"
    if (_.isEmpty(height)) errors.height = "Can't be blank"
    if (_.isEmpty(party_delivering_id)) errors.delivering = "Can't be blank"
    if (_.isEmpty(voyage_id)) errors.vessel = "Can't be blank"
    if (_.isEmpty(eqstatus)) errors.eqstatus = "Can't be blank"
    if (_.isEmpty(festatus)) errors.festatus = "Can't be blank"
    if (_.isEmpty(pol)) errors.pol = "Can't be blank"
    if (_.isEmpty(pod)) errors.pod = "Can't be blank"
    if (_.isEmpty(vessel_code)) errors.vessel_code = "Can't be blank"
    if (_.isEmpty(bookType)) errors.bookType = "Can't be blank"

    if (Object.keys(errors).length === 0) {
      addContainer()
      props.history.push("/prestack/preadvise/list")
    }
    return errors
  }
  const submit = () => {
    setloading(true)
    setErrors({})
    const errors = validate()
    console.log(errors)
    setErrors((prevState) => ({ ...prevState, ...errors }))
    setloading(false)
  }
  const addContainer = async () => {
    let result = await props.addDepotStack({
      variables: {
        container_number,
        operator_code,
        container_size,
        weight,
        height,
        operator_id,
        party_delivering_id,
        voyage_id,
        eqstatus,
        festatus,
        pol,
        pod,
        vessel_code,
        book_type: bookType,
      },
    })
    props.history.push("/prestack/preadvise/list")
  }
  return (
    <Form size="tiny" onSubmit={submit}>
      <Header as="h2" color="green" textAlign="center">
        Book Container
      </Header>
      <Divider />
      {errors.global && (
        <Message negative>
          <Message.Header> Something went wrong </Message.Header>
          <p>{errors.global}</p>
        </Message>
      )}
      <Form.Group widths="equal">
        <Form.Field required error={!!errors.vessel}>
          <label>Vessel Name</label>
          <Dropdown
            value={vesselName}
            search
            selection
            options={voyageOptions}
            onChange={setVoyageValue}
          />
          {errors.vessel && <InlineError text={errors.vessel} />}
        </Form.Field>
        <Form.Field required error={!!errors.delivering}>
          <label>Party Delivering</label>
          <Dropdown
            value={party_delivering_name}
            search
            selection
            options={deliveringOptions}
            onChange={setPartyDeliveringValue}
          />
          {errors.delivering && <InlineError text={errors.delivering} />}
        </Form.Field>
      </Form.Group>
      <Form.Group widths="equal">
        <Form.Field required error={!!errors.operator_code}>
          <label>Operator</label>
          <Dropdown
            value={operator_code}
            search
            selection
            options={operatorOptions}
            onChange={setOperatorValue}
          />
          {errors.operator_code && <InlineError text={errors.operator_code} />}
        </Form.Field>
        <Form.Field error={!!errors.container_size}>
          <label>Container Size</label>
          <Dropdown
            value={containerValue}
            search
            selection
            options={containerOptions}
            onChange={setContainer}
          />
          {errors.container_size && (
            <InlineError text={errors.container_size} />
          )}
        </Form.Field>
      </Form.Group>
      <Form.Group widths="equal">
        <Form.Field required error={!!errors.vessel_code}>
          <label>Vessel Code </label>
          <Input
            focus
            placeholder="MV..."
            value={vessel_code}
            onChange={(e) => setVesselCode(e.target.value)}
          />
          {errors.vessel_code && <InlineError text={errors.vessel_code} />}
        </Form.Field>
        <Form.Field required error={!!errors.container_number}>
          <label>Container No </label>
          <Input
            focus
            // placeholder="MV..."
            value={container_number}
            onChange={containerNumberChange}
          />
          {errors.container_number && (
            <InlineError text={errors.container_number} />
          )}
        </Form.Field>
      </Form.Group>
      <Form.Group widths="equal">
        <Form.Field required error={!!errors.festatus}>
          <label>FE Status</label>
          <Dropdown
            value={festatus}
            search
            selection
            options={FEStatusOptions}
            onChange={setFestatusOptions}
          />
          {errors.festatus && <InlineError text={errors.festatus} />}
        </Form.Field>
        <Form.Field required error={!!errors.weight}>
          <label>Weight </label>
          <Input
            focus
            // placeholder="MV..."
            type="number"
            value={weight}
            onChange={(e) => setWeight(e.target.value)}
          />
          {errors.weight && <InlineError text={errors.weight} />}
        </Form.Field>
      </Form.Group>
      <Form.Group widths="equal">
        <Form.Field required error={!!errors.eqstatus}>
          <label>EQ Status</label>
          <Dropdown
            value={eqstatus}
            search
            selection
            options={EQStatusOptions}
            onChange={setEqstatusOptions}
          />
          {errors.eqstatus && <InlineError text={errors.eqstatus} />}
        </Form.Field>
        <Form.Field required error={!!errors.height}>
          <label>Height </label>
          <Input
            focus
            // placeholder="MV..."
            type="text"
            value={height}
            onChange={(e) => setHeight(e.target.value)}
          />
          {errors.height && <InlineError text={errors.height} />}
        </Form.Field>
      </Form.Group>
      <Form.Group widths="equal">
        <Form.Field required error={!!errors.pol}>
          <label>POL</label>
          <Dropdown
            value={pol}
            search
            selection
            options={POLOptions}
            onChange={setPOLOptions}
          />
          {errors.pol && <InlineError text={errors.pol} />}
        </Form.Field>
        <Form.Field required error={!!errors.pod}>
          <label>POD</label>
          <Dropdown
            value={pod}
            search
            selection
            options={PODOptions}
            onChange={setPODOptions}
          />
          {errors.pod && <InlineError text={errors.pod} />}
        </Form.Field>
      </Form.Group>
      <Form.Group widths="equal">
        <Form.Field required error={!!errors.bookType}>
          <label>Booking Type</label>
          <Dropdown
            value={bookType}
            search
            selection
            options={BookTypeOptions}
            onChange={setBookTypeOptions}
          />
          {errors.pod && <InlineError text={errors.pod} />}
        </Form.Field>
      </Form.Group>

      <Form.Button fluid positive onClick={submit}>
        Submit
      </Form.Button>
    </Form>
  )
}
const getAllPartyDelivering = gql`
  query getAllPartyDelivering {
    getAllPartyDelivering {
      party_delivering_id
      party_delivering_name
    }
  }
`
const getAvailableVesselVoyages = gql`
  query getAvailableVesselVoyages {
    getAvailableVesselVoyages {
      voyage_id
      vesselName
      estimated_arrival
      estimated_departure
      actual_arrival
      actual_departure
    }
  }
`
const getAllPrestackOperator = gql`
  query getAllPrestackOperator {
    getAllPrestackOperator {
      operator_id
      operator_code
    }
  }
`
const getAllContainers = gql`
  query getAllContainers {
    getAllContainers {
      id
      containerSize
      containerCode
    }
  }
`
export default compose(
  graphql(getAllContainers, {
    name: "getAllContainers",
  }),
  graphql(getAllPrestackOperator, {
    name: "getAllPrestackOperator",
  }),
  graphql(getAllPartyDelivering, {
    name: "getAllPartyDelivering",
  }),
  graphql(addDepotStack, {
    name: "addDepotStack",
  }),
  graphql(getAvailableVesselVoyages, {
    name: "getAvailableVesselVoyages",
  })
)(BookPrestackContainer)
