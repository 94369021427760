import React, { Component } from "react";
import {
  Form,
  Segment,
  Grid,
  Header,
  Message,
  Divider
} from "semantic-ui-react";
import gql from "graphql-tag";
import { graphql } from "react-apollo";

import InlineError from "../shared/InlineError";
import getAllPreadvices from "./Queries/fetchAllPreadvices";
import PermissionsMessage from "../shared/PermissionsMessage";

class AddPreadviseDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: parseFloat(this.props.match.params.id),

      preAdviseNum: "",
      fileBillingNumber: "",

      loading: false,
      errors: {}
    };
  }
  validate = () => {
    const errors = {};

    if (!this.state.preAdviseNum) errors.preAdviseNum = "Can't be blank";
    if (!this.state.fileBillingNumber)
      errors.fileBillingNumber = "Can't be blank";
    if (Object.keys(errors).length === 0) {
      this.setState({ loading: true });
      this._updateAdmin();
    }
    return errors;
  };
  onSubmit = () => {
    const errors = this.validate();
    this.setState({ errors });
  };

  handlefileBillingNumberChange = date => {
    this.setState({ fileBillingNumber: date });
  };
  render() {
    if (!this.props.permissions) return <PermissionsMessage />;
    const { errors, loading } = this.state;
    return (
      <Grid
        textAlign="center"
        style={{ height: "100%" }}
        verticalAlign="middle"
      >
        <Grid.Column style={{ maxWidth: 600 }}>
          <Header as="h2" color="green" textAlign="center">
            Add Preadvise Details
          </Header>
          <Divider />
          <Form size="large" onSubmit={this.onSubmit} loading={loading}>
            {errors.global && (
              <Message negative>
                <Message.Header> Something went wrong </Message.Header>
                <p>{errors.global}</p>
              </Message>
            )}

            <Form.Field error={!!errors.fileBillingNumber}>
              <label>File Billing Number</label>{" "}
              <input
                type="text"
                value={this.state.fileBillingNumber}
                rows={2}
                onChange={e =>
                  this.setState({ fileBillingNumber: e.target.value })
                }
              />
            </Form.Field>
            <Form.Field error={!!errors.preAdviseNum}>
              <label>Preadvise Number</label>
              <input
                type="text"
                value={this.state.preAdviseNum}
                rows={2}
                onChange={e => this.setState({ preAdviseNum: e.target.value })}
              />
              {errors.preAdviseNum && (
                <InlineError text={errors.preAdviseNum} />
              )}
            </Form.Field>
            <Form.Button fluid positive>
              Submit
            </Form.Button>
            <Segment stacked />
          </Form>
        </Grid.Column>
      </Grid>
    );
  }
  _updateAdmin = async () => {
    const { fileBillingNumber, preAdviseNum } = this.state;
    await this.props.addDetails({
      variables: {
        id: this.props.match.params.id,
        fileBillingNumber,
        preAdviseNum
      },

      refetchQueries: [{ query: getAllPreadvices }]
    });
    this.props.history.push("/transaction/list");
  };
}

const addDetails = gql`
  mutation addContainer(
    $id: ID!
    $fileBillingNumber: String!
    $preAdviseNum: String!
  ) {
    updatePreadvise(
      id: $id
      fileBillingNumber: $fileBillingNumber
      preAdviseNum: $preAdviseNum
    )
  }
`;

export default graphql(addDetails, { name: "addDetails" })(AddPreadviseDetails);
