import gql from "graphql-tag"
export default gql`
  query getDepotWorkDetails {
    getDepotWorkDetails {
      container_number
      clientCode
      addeddepotby {
        id
        name
      }
      extradepotdetailsby {
        id
        name
      }
      recieveddepotby {
        id
        name
      }
      exitprestackby {
        id
        name
      }
      dispatchedprestackby {
        id
        name
      }
      restowedprestackby {
        id
        name
      }
      rebookedprestackby {
        id
        name
      }
      lastupdateddepotby {
        id
        name
      }
    }
  }
`
