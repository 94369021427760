import React, { Component } from "react";
import {
  Form,
  Segment,
  Grid,
  Header,
  Message,
  Divider
} from "semantic-ui-react";
import gql from "graphql-tag";
import { graphql } from "react-apollo";
import DatePicker from "react-datepicker";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";
import "rc-time-picker/assets/index.css";
import TimePicker from "rc-time-picker";
import InlineError from "../shared/InlineError";
import getDeliveryNote from "../Administration/Queries/fetchAllDelivered";
import PermissionsMessage from "../shared/PermissionsMessage";

class addEIROPERATIONS extends Component {
  constructor(props) {
    super(props);
    this.state = {
      eirNum: "",
      eirDate: "",
      eirTime:"",
      loading: false,
      errors: {}
    };
  }
  validate = () => {
    const errors = {};

    if (!this.state.eirNum) errors.eirNum = "Can't be blank";
    if (!this.state.eirDate) errors.eirDate = "Can't be blank";
    if (!this.state.eirTime) errors.eirTime = "Can't be blank";

    this.setState({ loading: true });
    this._updateAdmin();
    return errors;
  };
  onSubmit = () => {
    const errors = this.validate();
    this.setState({ errors });
  };

  handleeirDateChange = date => {
    this.setState({ eirDate: date });
  };
  handleeirTimeChange = time => {
    this.setState({ eirTime: time });
  };
  render() {
    if (!this.props.permissions) return <PermissionsMessage />;
    const { errors, loading } = this.state;
    return (
      <Grid
        textAlign="center"
        style={{ height: "100%" }}
        verticalAlign="middle"
      >
        <Grid.Column style={{ maxWidth: 600 }}>
          <Header as="h2" color="green" textAlign="center">
            Add EIR
          </Header>
          <Divider />
          <Form size="large" onSubmit={this.onSubmit} loading={loading}>
            {errors.global && (
              <Message negative>
                <Message.Header> Something went wrong </Message.Header>
                <p>{errors.global}</p>
              </Message>
            )}

            <Form.Field error={!!errors.eirDate}>
              <label>EIR Date</label>{" "}
              <DatePicker
                selected={this.state.eirDate}
                onChange={this.handleeirDateChange}
              />
            </Form.Field>
            <Form.Field error={!!errors.eirNum}>
              <label>EIR NUM/DELIVERY NOTE</label>
              <input
                type="text"
                value={this.state.eirNum}
                rows={2}
                onChange={e => this.setState({ eirNum: e.target.value })}
              />
              {errors.eirNum && <InlineError text={errors.eirNum} />}
            </Form.Field>
            <Divider />
                  <Form.Field error={!!errors.eirTime}>
                    <label>Loading Time</label>

                    <TimePicker
                      // style={{ width: 100 }}
                      showSecond={false}
                      defaultValue={moment()}
                      className="xxx"
                      onChange={this.handleeirTimeChange}
                    />
                    {errors.eirTime && (
                      <InlineError text={errors.eirTime} />
                    )}
                  </Form.Field>
            <Form.Button fluid positive>
              Submit
            </Form.Button>
            <Segment stacked />
          </Form>
        </Grid.Column>
      </Grid>
    );
  }
  _updateAdmin = async () => {
    const { eirDate, eirNum, eirTime } = this.state;
    await this.props.addEIR({
      variables: {
        id: this.props.match.params.id,
       
        
        eirNum,
        eirDate,
        eirTime:moment(eirTime).format("h:mm")
      },

      refetchQueries: [{ query: getDeliveryNote }]
    });
    this.props.history.push("/delivered/cma");
  };
}

const addEIRMUTATION = gql`
  mutation updatePrestackDeliveryMombasa($id: ID!, $eirNum: String, $eirDate: String, $eirTime: String) {
    
    updatePrestackDeliveryMombasa(id: $id, eirNum: $eirNum, eirDate: $eirDate, eirTime:$eirTime)
  }
`;

export default graphql(addEIRMUTATION, { name: "addEIR" })(addEIROPERATIONS);
