import React, { useState } from "react"

import { Link } from "react-router-dom"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory from "react-bootstrap-table2-paginator"
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import cellEditFactory, { Type } from "react-bootstrap-table2-editor"
import gql from "graphql-tag"
import withQuery from "../../HOC/withQuery"
import { Button } from "semantic-ui-react"
function Vessels(props) {
  const [items, setItems] = useState([...props.entries])
  const [pageNum, setPageNum] = useState(1)

  const CaptionElement = () => (
    <h3
      style={{
        borderRadius: "0.25em",
        textAlign: "center",
        color: "green",
        border: "1px solid green",
        padding: "0.5em",
      }}
    >
      Vessel List
    </h3>
  )
  const { SearchBar, ClearSearchButton } = Search

  const defaultSorted = [
    {
      dataField: "vessel_name",
      order: "desc",
    },
  ]
  const pagination = paginationFactory({
    sizePerPage: 5,
  })

  const columns = [
    {
      text: "Vessel",
      dataField: "vessel_name",
      sort: true,
      headerStyle: {
        backgroundColor: "#c8e6c9",
      },
    },
    // {
    //   text: "Est. Arrival",
    //   dataField: "estimated_arrival",
    //   sort: true,
    //   headerStyle: {
    //     backgroundColor: "#c8e6c9",
    //   },
    // },
  ]

  return (
    <div>
      <CaptionElement />
      <ToolkitProvider
        keyField="NO"
        data={items}
        columns={columns}
        defaultSorted={defaultSorted}
        search={{ defaultSearch: "" }}
      >
        {(props) => (
          <div>
            <div>
              <div align="right">
                <SearchBar {...props.searchProps} />
                <ClearSearchButton {...props.searchProps} />
              </div>
            </div>
            <hr />
            <div>
              <BootstrapTable
                className="expanded-container"
                {...props.baseProps}
                striped
                hover
                condensed
                pagination={pagination}
                noDataIndication="Table is Empty"
              />
            </div>
          </div>
        )}
      </ToolkitProvider>
      <Link to="/prestack/vessels/new">
        <Button
          positive
          content="Add new vessel"
          icon="right add"
          labelPosition="right"
        />
      </Link>
    </div>
  )
}

const getAllVessels = gql`
  query getAllVessels {
    getAllVessels {
      vessel_id
      vessel_name
    }
  }
`
export default withQuery(Vessels, getAllVessels, "getAllVessels")
