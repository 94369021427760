import React, { Component } from "react"
import gql from "graphql-tag"
import { graphql } from "react-apollo"

import {
  Form,
  Divider,
  Dropdown,
  Header,
  Grid,
  Message,
  Segment,
} from "semantic-ui-react"

import PermissionsMessage from "../shared/PermissionsMessage"
import InlineError from "../shared/InlineError"
import getUsers from "./queries/fetchUsers"
class Create extends Component {
  constructor(props) {
    super(props)
    this.state = {
      firstName: "",
      lastName: "",
      email: "",
      userRole: "",
      password: "",
      confirmPassword: "",
      errors: {},
      loading: false,
    }
  }
  setRole = (e, data) => {
    this.setState({ userRole: data.value })
  }
  validate = () => {
    const errors = {}

    if (!this.state.firstName) errors.firstName = "Can't be blank"
    if (!this.state.lastName) errors.lastName = "Can't be blank"
    if (!this.state.email) errors.email = "Can't be blank"
    if (!this.state.userRole) errors.userRole = "Can't be blank"
    if (!this.state.password) errors.password = "Can't be blank"
    if (!this.state.confirmPassword) errors.confirmPassword = "Can't be blank"
    if (this.state.password !== this.state.confirmPassword)
      errors.password = "Passwords must match!"

    if (Object.keys(errors).length === 0) {
      this.setState({ loading: true })
      this._createUser()
    }

    return errors
  }
  onSubmit = () => {
    const errors = this.validate()
    this.setState({ errors })
  }
  render() {
    if (!this.props.permissions) return <PermissionsMessage />
    const { loading, errors } = this.state
    const ROLEOPTIONS = [
      { id: 1, value: "CUSTOMERSERVICEMSA", text: "CUSTOMERSERVICEMSA" },
      { id: 2, value: "CUSTOMERSERVICENRB", text: "CUSTOMERSERVICENRB" },
      { id: 3, value: "OPERATIONSNRB", text: "OPERATIONSNRB" },
      { id: 4, value: "OPERATIONSMSA", text: "OPERATIONSMSA" },
      { id: 5, value: "SUPERVISOR", text: "SUPERVISOR" },
      { id: 6, value: "ACCOUNTANT", text: "ACCOUNTANT" },
      { id: 7, value: "SUPERUSER", text: "SUPERUSER" },
      { id: 8, value: "DIRECTOR", text: "DIRECTOR" },
      { id: 9, value: "ADMIN", text: "ADMIN" },
    ]
    return (
      <>
        <Grid
          textAlign="center"
          style={{ height: "100%" }}
          verticalAlign="middle"
        >
          <Grid.Column style={{ maxWidth: 600 }}>
            <Header as="h2" color="green" textAlign="center">
              Add User
            </Header>
            <Divider />
            <Form size="large" onSubmit={this.onSubmit} loading={loading}>
              {errors.global && (
                <Message negative>
                  <Message.Header> Something went wrong </Message.Header>
                  <p>{errors.global}</p>
                </Message>
              )}
              <Form.Field error={!!errors.firstName}>
                <label>First Name</label>
                <input
                  value={this.state.firstName}
                  onChange={(e) => this.setState({ firstName: e.target.value })}
                />
                {errors.firstName && <InlineError text={errors.firstName} />}
              </Form.Field>
              <Divider />
              <Form.Field error={!!errors.lastName}>
                <label>Last Name</label>
                <input
                  value={this.state.lastName}
                  onChange={(e) => this.setState({ lastName: e.target.value })}
                />
                {errors.lastName && <InlineError text={errors.lastName} />}
              </Form.Field>
              <Divider />
              <Form.Field error={!!errors.email}>
                <label>Email</label>
                <input
                  type="email"
                  value={this.state.email}
                  onChange={(e) => this.setState({ email: e.target.value })}
                />
                {errors.email && <InlineError text={errors.email} />}
              </Form.Field>
              <Divider />
              <Form.Field error={!!errors.password}>
                <label>Password</label>
                <input
                  type="password"
                  value={this.state.password}
                  onChange={(e) => this.setState({ password: e.target.value })}
                />
                {errors.password && <InlineError text={errors.password} />}
              </Form.Field>
              <Divider />
              <Form.Field error={!!errors.confirmPassword}>
                <label>Confirm Password</label>
                <input
                  type="password"
                  value={this.state.confirmPassword}
                  onChange={(e) =>
                    this.setState({ confirmPassword: e.target.value })
                  }
                />
                {errors.confirmPassword && (
                  <InlineError text={errors.confirmPassword} />
                )}
              </Form.Field>
              <Divider />
              <Form.Field error={!!errors.userRole}>
                <label>User Role</label>
                <Dropdown
                  value={this.state.userRole}
                  search
                  selection
                  options={ROLEOPTIONS}
                  onChange={this.setRole.bind(this)}
                />
                {errors.userRole && <InlineError text={errors.userRole} />}
              </Form.Field>
              <Form.Button fluid positive>
                Submit
              </Form.Button>
              <Segment stacked />
            </Form>
          </Grid.Column>
        </Grid>
      </>
    )
  }
  _createUser = async () => {
    const { firstName, lastName, email, userRole, password } = this.state
    this.props.addUser({
      variables: {
        firstName,
        lastName,
        email,
        password,
        userRole,
        department: "BBLSGR",
      },
      refetchQueries: [{ query: getUsers }],
    })
    this.props.history.push("/users/list")
  }
}

const ADDMUTATION = gql`
  mutation addUser(
    $firstName: String!
    $lastName: String!
    $email: String!
    $userRole: String!
    $password: String!
    $department: String
  ) {
    signup(
      firstName: $firstName
      lastName: $lastName
      email: $email
      userRole: $userRole
      password: $password
      department: $department
    ) {
      ok
    }
  }
`
export default graphql(ADDMUTATION, { name: "addUser" })(Create)
