import gql from "graphql-tag";

export default gql`
  query getArrivalContainers {
    getArrivalContainers {
      id
      clientCode
      clientEmail
      fileBillingNumber
      preAdviseNum
      containerNum
      containerSize
      operatorCode
      partyDeliveringName
      bblEIR
      vessel
      bblEIR
      deliveryDriverName
      deliveryDriverID
      truckerDriverName
      truckerDriverID
      deliveryNoteID
      status
      preadviseDate
      pOL
      pOD
    }
  }
`;
