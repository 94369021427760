import gql from "graphql-tag"

export default gql`
query getAllMobilePreadvices {
  getAllMobilePreadvices {
    id
    mobileUser{
      id
      name
    }
    clientCode
    fileBillingNumber
    preAdviseNum
    preadviseDate
    containerNum
    vessel
    createdAt
    createdTime
    containerSize
    operatorCode
    bblEIR
    deliveryDriverName
    deliveryDriverID
    truckerDriverName
    truckerDriverID
    deliveryNoteID
    fileLocation
    
  }
}
`