import gql from "graphql-tag";

export default gql`
  query fetchGetDaySummary {
    getDaySummary {
      expected
      inicd
      onrail
      inmombasa
      delivered
      invoiced
    }
  }
`;
