import gql from "graphql-tag"

export default gql`
  query getAllDepotStack {
    getAllDepotStack {
      container {
        container_number
        container_size
        weight
      }
      eqstatus
      festatus
      pol
      pod
      vessel_code
      reference_no
      preadvise_date
      partyDelivering {
        party_delivering_id
        party_delivering_name
      }
      gate_in_stack
      gate_in_time
      mode_of_transport_id
      bbl_eir
      edt_stack
      date_out
      time_out
      mode_of_transport
      type_of_transport
      carrier_id
      mode_of_transport_id_out
      carrier_id_out
      bay_position
      date_loaded
      time_loaded
      remarks
    }
  }
`
