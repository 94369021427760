import React, { Component } from 'react';
import gql from "graphql-tag";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import cellEditFactory, { Type } from "react-bootstrap-table2-editor";
import withQuery from "../../HOC/withQuery";
import LoadingMessage from "../../shared/LoadingMessage";
import { Icon, Button } from "semantic-ui-react";
import {  graphql } from "react-apollo";
import { Link } from "react-router-dom";
import getMobileOrdersListByUser from './Queries/fetchMobileBookingList'
class ClientBookingList extends Component {
    constructor(props) {
        super(props);
        this.state = {  
            data: [...this.props.entries],
            pageNum: 1
        }
    }
    render() { 
        if (this.state.data) {
            const { data } = this.state
            const CaptionElement = () => (
                <h3
                  style={{
                    borderRadius: "0.25em",
                    textAlign: "center",
                    color: "green",
                    border: "1px solid green",
                    padding: "0.5em"
                  }}
                >
                  Booking List
                </h3>
              );
            const { SearchBar, ClearSearchButton } = Search;
            const columnsForSuperUser = [
                {
                  text: "Party Delivering",
                  dataField: "partyDeliveringName",
                  sort: true,
                },
                {
                    text: "Booked",
                    dataField: "booked",
                    sort: true
                },
                {
                    text: "Number Booked",
                    dataField: "numberbooked",
                    sort: true
                  },
                  {
                    text: "Remarks",
                    dataField: "remarks",
                    sort: true
                  },
                {
                  text: "Status",
                  dataField: "validationstatus",
                  sort: true,
                  formatter:(cellContent, row)=>{
                    
                    if (row.validationstatus === null) return "IN REVIEW"
                    if (!row.validationstatus) return "DECLINED"
                    if (row.validationstatus) return "VALIDATED"
                  
                  }
                  
                },
               
                {
                  text: "",
                  dataField: "df",
                  isDummyField: true,
                  formatter: (cellContent, row) => {
                    return (
                      <Link 
                      to={{
                          pathname:`/selfservice/bookings/details/${row.containerFileName}`,
                          state:{
                            fileLocation:row.fileLocation,
                            validityDate:row.sOExpiryDate,
                            partyDeliveringName:row.partyDeliveringName,
                            containerFileName:row.containerFileName
          
                          }
                        }}
                      >
                        View Details
                        <Icon name="angle double right" color="green" />
                      </Link>
                    );
                  }
                }
              ];
              const columns = [
                {
                  text: "Party Delivering",
                  dataField: "partyDeliveringName",
                  sort: true,
                },
                {
                    text: "Booked",
                    dataField: "booked",
                    sort: true
                },
                {
                    text: "Number Booked",
                    dataField: "numberbooked",
                    sort: true
                  },
                // {
                //   text: "Storing Order Expiry Date",
                //   dataField: "sOExpiryDate",
                //   sort: true,
                  
                // },
               
                // {
                //   text: "",
                //   dataField: "df",
                //   isDummyField: true,
                //   formatter: (cellContent, row) => {
                //     return (
                //       <Link to={`/transaction/new/${row.id}`}>
                //         View Details
                //         <Icon name="angle double right" color="green" />
                //       </Link>
                //     );
                //   }
                // }
              ];
            const defaultSorted = [
                {
                  dataField: "booked",
                  order: "desc"
                }
              ];
            const pagination = paginationFactory({
            sizePerPage: 8
            });
        
                return (
                    <>
                      <CaptionElement />
                      <ToolkitProvider
                        keyField="id"
                        data={data}
                        columns={columnsForSuperUser}
                        defaultSorted={defaultSorted}
                        search={{ defaultSearch: "" }}
                      >
                        {props => (
                          <div>
                            <div align="right">
                              <SearchBar {...props.searchProps} />
                              <ClearSearchButton {...props.searchProps} />
                            </div>
                            <hr />
                            <BootstrapTable
                              {...props.baseProps}
                              striped
                              hover
                              condensed
                              pagination={pagination}
                              noDataIndication="Table is Empty"
                           
                            />
                          </div>
                        )}
                      </ToolkitProvider>
                    </>
                  );
           
        }else{
            return <LoadingMessage />;
        }
    }
}
 
export default withQuery(ClientBookingList, getMobileOrdersListByUser, "getMobileOrdersListByUser")