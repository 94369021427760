import gql from "graphql-tag";

export default gql`
  query getArrivalMbsContainers {
    getArrivalMbsContainers {
      id
      clientCode
      containerNum
      containerSize
      outDateICD
      wagonNum
    }
  }
`;
