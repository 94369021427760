import React, { Component } from "react"
import Validator from "validator"
import { Link } from "react-router-dom"
import { Form, Grid, Header, Message, Segment, Icon } from "semantic-ui-react"

import store from "store"
import InlineError from "../shared/InlineError"

class LoginForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      data: {
        email: "",
        password: "",
      },
      errors: {
        // global: store.get("Login")
      },
      loading: false,
    }
    this.onChange = this.onChange.bind()
  }
  onChange = (e) =>
    this.setState({
      data: { ...this.state.data, [e.target.name]: [e.target.value] },
    })

  onSubmit = () => {
    const errors = this.validate(this.state.data)
    this.setState({ errors })
    if (Object.keys(errors).length === 0) {
      const response = this.props.submit(this.state.data)
      console.log(response)
    }
  }
  validate = (data) => {
    const errors = {}
    if (!data.password) errors.password = "Can't be blank"
    if (!Validator.isEmail(data.email[0])) errors.password = "Invalid email"
    return errors
  }
  render() {
    const { data, loading } = this.state
    let errors = this.state
    errors.global = store.get("Login")

    return (
      <div className="login-form">
        <style>{`
        body > div,
        body > div > div,
        body > div > div > div.login-form {
          height: 100%;
        }
      `}</style>
        <Grid
          textAlign="center"
          style={{ height: "100%" }}
          verticalAlign="middle"
        >
          <Grid.Column style={{ maxWidth: 450 }}>
            <Header as="h2" color="green" textAlign="center">
              <Icon name="home" color="green" circular /> Log-in to your account
            </Header>

            <Form onSubmit={this.onSubmit} loading={loading}>
              <Segment stacked>
                {errors.global && (
                  <Message negative>
                    <Message.Header>Something went wrong</Message.Header>
                    <p>{errors.global}</p>
                  </Message>
                )}
                <Form.Field error={!!errors.email}>
                  <label htmlFor="email">Email</label>
                  <input
                    type="email"
                    id="email"
                    name="email"
                    placeholder="example@example.com"
                    value={data.email}
                    onChange={this.onChange}
                  />
                  {errors.email && <InlineError text={errors.email} />}
                </Form.Field>
                <Form.Field error={!!errors.password}>
                  <label htmlFor="password">Password</label>
                  <input
                    type="password"
                    id="password"
                    name="password"
                    placeholder="Make it secure"
                    value={data.password}
                    onChange={this.onChange}
                  />
                  {errors.password && <InlineError text={errors.password} />}
                </Form.Field>
                <Form.Button fluid positive>
                  Submit
                </Form.Button>
                <Message size="big">
                  <Link to="/forgot">Forgot Password?</Link>
                </Message>
              </Segment>
            </Form>
          </Grid.Column>
        </Grid>
      </div>
    )
  }
}

export default LoginForm
