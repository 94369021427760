import React, { Component } from "react"
import {
  Form,
  Button,
  Dropdown,
  Grid,
  Header,
  Divider,
  Segment,
  Message,
} from "semantic-ui-react"
import { flowRight as compose } from "lodash"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import gql from "graphql-tag"
import { graphql } from "react-apollo"

import InlineError from "../../shared/InlineError"

var vesselOptions = []
class AddVesselVoyage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      errors: {},
      vessel_name: "",
      vessel_id: "",
    }
  }
  setVesselValue = (e, data) => {
    console.log("vesselOptions: ", vesselOptions)
    vesselOptions.forEach((element) => {
      if (element.value === data.value) {
        this.setState({ vessel_id: element.id })
        this.setState({ vessel_name: element.value })
      }
    })
  }
  handlesEstimatedArr = (date) => {
    this.setState({ estimated_arrival: date })
  }
  handlesEstimatedDept = (date) => {
    this.setState({ estimated_departure: date })
  }

  validate = () => {
    const errors = {}

    if (!this.state.vessel_id) errors.vessel_id = "Can't be blank"
    if (!this.state.estimated_arrival)
      errors.estimated_arrival = "Can't be blank"
    if (!this.state.estimated_departure)
      errors.estimated_departure = "Can't be blank"

    if (Object.keys(errors).length === 0) {
      console.log("Errors Length: ", errors.length)
      console.log("Errors : ", errors)
      this._addVesselVoyage()
    } else {
      this.setState({ errors })
    }
  }
  render() {
    const { errors } = this.state
    if (this.props.getAllVessels.loading === false) {
      console.log(this.props.getAllVessels)
      let tempOp = this.props.getAllVessels.getAllVessels
      vesselOptions = []
      tempOp.map((element) => {
        return vesselOptions.push({
          id: element.vessel_id,
          key: element.vessel_id,
          text: element.vessel_name,
          value: element.vessel_name,
        })
      })
    }
    return (
      <Grid
        textAlign="center"
        style={{ height: "100%" }}
        verticalAlign="middle"
      >
        <Grid.Column style={{ maxWidth: 600 }}>
          <Header as="h1" color="green" textAlign="center">
            Enter Voyage Details
          </Header>
          <Divider />
          <Form size="large">
            {errors.global && (
              <Message negative>
                <Message.Header> Something went wrong </Message.Header>
                <p>{errors.global}</p>
              </Message>
            )}
            <Form.Field error={!!errors.vessel_id}>
              <label>Vessel</label>
              <Dropdown
                value={this.state.vessel_name}
                search
                selection
                options={vesselOptions}
                onChange={this.setVesselValue.bind(this)}
              />
              {errors.vessel_id && <InlineError text={errors.vessel_id} />}
            </Form.Field>
            <Form.Field error={!!errors.estimated_arrival}>
              <label>Estimated Arrival</label>{" "}
              <DatePicker
                selected={this.state.estimated_arrival}
                onChange={this.handlesEstimatedArr}
              />
            </Form.Field>
            <Form.Field error={!!errors.estimated_departure}>
              <label>Estimated Departure</label>{" "}
              <DatePicker
                selected={this.state.estimated_departure}
                onChange={this.handlesEstimatedDept}
              />
            </Form.Field>
            <Button positive fluid onClick={this.validate}>
              Submit
            </Button>
          </Form>
        </Grid.Column>
      </Grid>
    )
  }
  _addVesselVoyage = async () => {
    const { vessel_id, estimated_arrival, estimated_departure } = this.state
    await this.props.addVesselVoyage({
      variables: { vessel_id, estimated_arrival, estimated_departure },
      refetchQueries: [
        {
          query: gql`
            query getAllVesselVoyages {
              getAllVesselVoyages {
                voyage_id
                vesselName
                estimated_arrival
                estimated_departure
              }
            }
          `,
        },
      ],
    })
    this.props.history.push("/prestack/voyages/list")
  }
}

const getAllVessels = gql`
  query {
    getAllVessels {
      vessel_id
      vessel_name
    }
  }
`
const addVesselVoyage = gql`
  mutation addVesselVoyage(
    $vessel_id: String
    $estimated_arrival: String
    $estimated_departure: String
  ) {
    addVesselVoyage(
      vessel_id: $vessel_id
      estimated_arrival: $estimated_arrival
      estimated_departure: $estimated_departure
    )
  }
`
export default compose(
  graphql(getAllVessels, {
    name: "getAllVessels",
  }),
  graphql(addVesselVoyage, {
    name: "addVesselVoyage",
  })
)(AddVesselVoyage)
