import React, { Component } from 'react';
import gql from "graphql-tag";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import cellEditFactory, { Type } from "react-bootstrap-table2-editor";
import withQuery from "../HOC/withQuery";
import LoadingMessage from "../shared/LoadingMessage";
import { Icon, Dropdown } from "semantic-ui-react";
import { graphql } from "react-apollo";
import _ from 'lodash';
import { Link } from "react-router-dom";
import getMobileOrdersList from './Queries/fetchMobileBookingList'

class BookingList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [...this.props.entries],
      pageNum: 1
    }
  }

  render() {
    if (this.state.data) {
      const { data } = this.state;
      console.log(data)
      const newData = _.filter(data, function (o) { return o.validationstatus === null; })
      console.log("NEW DATA", newData)
      const { role } = this.props.user;
      const CaptionElement = () => (
        <h3
          style={{
            borderRadius: "0.25em",
            textAlign: "center",
            color: "green",
            border: "1px solid green",
            padding: "0.5em"
          }}
        >
          Booking List
        </h3>
      );
      const { SearchBar, ClearSearchButton } = Search;
      const columnsForSuperUser = [
        {
          text: "Party Delivering",
          dataField: "partyDeliveringName",
          sort: true,
        },
        {
          text: "Booked",
          dataField: "booked",
          sort: true
        },
        {
          text: "Number Booked",
          dataField: "numberbooked",
          sort: true
        },
        // {
        //   text: "Storing Order Expiry Date",
        //   dataField: "sOExpiryDate",
        //   sort: true,

        // },

        {
          text: "",
          dataField: "df",
          isDummyField: true,
          formatter: (cellContent, row) => {
            console.log(row.fileLocation)
            return (
              <Link
                to={{
                  pathname: `/bookings/details/${row.containerFileName}`,
                  state: {
                    fileLocation: row.fileLocation,
                    validityDate: row.sOExpiryDate,
                    partyDeliveringName: row.partyDeliveringName,
                    containerFileName: row.containerFileName,
                    sourceType: row.sourceType

                  }
                }}
              >
                View Details
                <Icon name="angle double right" color="green" />
              </Link>
            );
          }
        }
      ];
      const columns = [
        {
          text: "Party Delivering",
          dataField: "partyDeliveringName",
          sort: true,
        },
        {
          text: "Booked",
          dataField: "booked",
          sort: true
        },
        {
          text: "Number Booked",
          dataField: "numberbooked",
          sort: true
        },
        // {
        //   text: "Storing Order Expiry Date",
        //   dataField: "sOExpiryDate",
        //   sort: true,

        // },

        // {
        //   text: "",
        //   dataField: "df",
        //   isDummyField: true,
        //   formatter: (cellContent, row) => {
        //     return (
        //       <Link to={`/transaction/new/${row.id}`}>
        //         View Details
        //         <Icon name="angle double right" color="green" />
        //       </Link>
        //     );
        //   }
        // }
      ];
      const defaultSorted = [
        {
          dataField: "booked",
          order: "desc"
        }
      ];
      const pagination = paginationFactory({
        sizePerPage: 8
      });
      if (role === "SUPERUSER") {
        return (
          <>
            <CaptionElement />
            <ToolkitProvider
              keyField="id"
              data={newData}
              columns={columnsForSuperUser}
              defaultSorted={defaultSorted}
              search={{ defaultSearch: "" }}
            >
              {props => (
                <div>
                  <div align="right">
                    <SearchBar {...props.searchProps} />
                    <ClearSearchButton {...props.searchProps} />
                  </div>
                  <hr />
                  <BootstrapTable
                    {...props.baseProps}
                    striped
                    hover
                    condensed
                    pagination={pagination}
                    noDataIndication="Table is Empty"

                  />
                </div>
              )}
            </ToolkitProvider>
          </>
        );
      }
      else {
        return (
          <>
            <CaptionElement />
            <ToolkitProvider
              keyField="id"
              data={newData}
              columns={columnsForSuperUser}
              defaultSorted={defaultSorted}
              search={{ defaultSearch: "" }}
            >
              {props => (
                <div>
                  <div align="right">
                    <SearchBar {...props.searchProps} />
                    <ClearSearchButton {...props.searchProps} />
                  </div>
                  <hr />
                  <BootstrapTable
                    {...props.baseProps}
                    striped
                    hover
                    condensed
                    pagination={pagination}
                    noDataIndication="Table is Empty"

                  />
                </div>
              )}
            </ToolkitProvider>
          </>
        );
      }

    } else {
      return <LoadingMessage />;
    }
  }
}

export default withQuery(BookingList, getMobileOrdersList, "getMobileOrdersList")