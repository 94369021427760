import React, { Component } from "react";
import {
  Form,
  Segment,
  Grid,
  Header,
  Message,
  Divider
} from "semantic-ui-react";
import gql from "graphql-tag";
import { graphql } from "react-apollo";
import "react-datepicker/dist/react-datepicker.css";

import PermissionsMessage from "../shared/PermissionsMessage";
import InlineError from "../shared/InlineError";
import getToInvoice from "./Queries/fetchAllInvoices";

class AddInvoice extends Component {
  constructor(props) {
    super(props);
    this.state = {
      invoiceNum: "",
      // eirDate: "",

      loading: false,
      errors: {}
    };
  }
  validate = () => {
    const errors = {};

    if (!this.state.invoiceNum) errors.invoiceNum = "Can't be blank";
    // if (!this.state.eirDate) errors.eirDate = "Can't be blank";

    this.setState({ loading: true });
    this._updateAdmin();
    return errors;
  };
  onSubmit = () => {
    const errors = this.validate();
    this.setState({ errors });
  };

  handleeirDateChange = date => {
    this.setState({ eirDate: date });
  };
  render() {
    if (!this.props.permissions) return <PermissionsMessage />;
    const { errors, loading } = this.state;
    return (
      <Grid
        textAlign="center"
        style={{ height: "100%" }}
        verticalAlign="middle"
      >
        <Grid.Column style={{ maxWidth: 600 }}>
          <Header as="h2" color="green" textAlign="center">
            Add Invoice Number
          </Header>
          <Divider />
          <Form size="large" onSubmit={this.onSubmit} loading={loading}>
            {errors.global && (
              <Message negative>
                <Message.Header> Something went wrong </Message.Header>
                <p>{errors.global}</p>
              </Message>
            )}
            {/* 
            <Form.Field error={!!errors.eirDate}>
              <label>EIR Date</label>{" "}
              <DatePicker
                selected={this.state.eirDate}
                onChange={this.handleeirDateChange}
              />
            </Form.Field> */}
            <Form.Field error={!!errors.invoiceNum}>
              <label>Invoice Number</label>
              <input
                type="text"
                value={this.state.invoiceNum}
                rows={2}
                onChange={e => this.setState({ invoiceNum: e.target.value })}
              />
              {errors.invoiceNum && <InlineError text={errors.invoiceNum} />}
            </Form.Field>
            <Form.Button fluid positive>
              Submit
            </Form.Button>
            <Segment stacked />
          </Form>
        </Grid.Column>
      </Grid>
    );
  }
  _updateAdmin = async () => {
    const { invoiceNum } = this.state;
    await this.props.updateInvoice({
      variables: {
        id: this.props.match.params.id,
        invoiceNum
      },

      refetchQueries: [{ query: getToInvoice }]
    });
    this.props.history.push("/invoices/list");
  };
}

const updateInvoice = gql`
  mutation addContainer($id: ID!, $invoiceNum: String!) {
    updateInvoice(id: $id, invoiceNum: $invoiceNum)
  }
`;

export default graphql(updateInvoice, { name: "updateInvoice" })(AddInvoice);
