import React, { Component } from "react";
import {
  Header,
  Grid,
  Input,
  Segment,
  Form
} from "semantic-ui-react";
import ReactTable from "react-table";
import "react-table/react-table.css";

import { Icon } from "semantic-ui-react";

import LoadingMessage from "../shared/LoadingMessage";
import withQuery from "../HOC/withQuery";
import getWorkDetails from "./Queries/fetchEditDetails";
import ExportAuditTrailToExcel from "./ExportAuditTrail";
import { auditColumns } from "./auditColumns";
const ref = React.createRef();
class EditDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [...this.props.entries],

      pageNum: 1,
      searchValue: ""
    };
  }
  render() {
    if (this.state.data) {
      let { data } = this.state;
      if (this.state.searchValue !== "") {
        data = data.filter(row => {
          return row.containerNum.includes(this.state.searchValue);
        });
      }

      return (
        <>
          <Header as="h2" color="green" textAlign="center">
            Audit Trail Reports
          </Header>
          <hr color="pink" />
          <Grid centered textAlign="center" style={{ height: "80%" }}>
            <Grid.Column>
              <Segment width="100%">
                <Form>
                  <Segment.Group horizontal fluid>
                    <Segment></Segment>
                    <Segment></Segment>
                    <Segment>
                      <Form.Field>
                        <Input
                          onChange={e =>
                            this.setState({ searchValue: e.target.value })
                          }
                          icon={<Icon name="search" inverted circular link />}
                          placeholder="Search..."
                        />
                      </Form.Field>
                    </Segment>
                  </Segment.Group>
                </Form>
                <hr color="pink" />
                <div>
                  <Header as="h3" color="green" textAlign="center">
                    Container List
                  </Header>
                  <ReactTable
                    ref={ref}
                    columns={auditColumns}
                    pageSizeOptions={[
                      5,
                      10,
                      20,
                      25,
                      50,
                      100,
                      250,
                      500,
                      1000,
                      2000,
                      3000
                    ]}
                    data={data}
                    sortable
                    defaultPageSize={10}
                    noDataText={"Loading ..."}
                    className="-striped -highlight"
                  >
                    {(state, filteredData, instance) => {
                      this.ReactTable = state.pageRows.map(data => {
                        return data._original;
                      });
                      return (
                        <div>
                          {filteredData()}
                          <ExportAuditTrailToExcel
                            data={this.ReactTable}
                            type={this.state.position}
                          />
                        </div>
                      );
                    }}
                  </ReactTable>
                </div>
              </Segment>
            </Grid.Column>
          </Grid>
        </>
      );
    } else {
      return <LoadingMessage />;
    }
  }
}

export default withQuery(EditDetails, getWorkDetails, "getWorkDetails");
