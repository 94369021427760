import React, { Component } from "react";
import gql from "graphql-tag";
import { graphql } from "react-apollo";
import {flowRight as compose} from 'lodash';
import {
  Form,
  Segment,
  Grid,
  Header,
  Message,
  Dropdown,
  Divider
} from "semantic-ui-react";
import DatePicker from "react-datepicker";
import "rc-time-picker/assets/index.css";
import TimePicker from "rc-time-picker";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";

import getTruckers from "../Trucker/Queries/fetchTruckers";
import PermissionsMessage from "../shared/PermissionsMessage";
import getDeptMbsContainers from "./Queries/fetchDeptsMsa";
import InlineError from "../shared/InlineError";

var truckerOptions = [];
class DepartureMbs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dateDelivered: "",
      outTimeMsa: "",
      position: "",
      modeOfTransportIdOutMsa: "",
      modeOfTransportOutMsaText: "",
      transportTypeOutMsa: "",

      carrierIDOutMsa: "",
      modeOfTransportOutMsa: "",
      truckerDriverName: "",
      truckerdriverID: "",

      errors: {},
      loading: false
    };
  }
  setTruckerValue = (e, data) => {
    truckerOptions.forEach(element => {
      if (element.value === data.value) {
        this.setState({ carrierIDOutMsa: element.id });
        this.setState({ truckerValue: element.value });
      }
    });
  };
  validate = () => {
    const errors = {};

    if (!this.state.dateDelivered) errors.dateDelivered = "Can't be blank";
    if (!this.state.outTimeMsa) errors.outTimeMsa = "Can't be blank";
    if (!this.state.position) errors.position = "Can't be blank";
    if (!this.state.modeOfTransportIdOutMsa)
      errors.modeOfTransportIdOutMsa = "Can't be blank";
    if (!this.state.transportTypeOutMsa)
      errors.transportTypeOutMsa = "Can't be blank";

    if (!this.state.carrierIDOutMsa) errors.carrierIDOutMsa = "Can't be blank";
    if (!this.state.modeOfTransportOutMsa)
      errors.modeOfTransportOutMsa = "Can't be blank";
    if (!this.state.truckerDriverName)
      errors.truckerDriverName = "Can't be blank";
    if (!this.state.truckerdriverID) errors.truckerdriverID = "Can't be blank";
  
    if (Object.keys(errors).length === 0) {
      this._addDepartures();
    }
    return errors;
  };

  setTransportMode = (e, data) => {
    this.setState({
      modeOfTransportOutMsa: data.value,
      modeOfTransportOutMsaText: data.text
    });
  };
  onPostitionChange = (e, data) => {
    this.setState({ position: data.value });
  };
  onTransportTypeChange = (e, data) => {
    this.setState({ transportTypeOutMsa: data.value });
  };
  outTimeMsaChange = time => this.setState({ outTimeMsa: time });
  dateDeliveredChange = dateDelivered => this.setState({ dateDelivered });
  onSubmit = () => {
    const errors = this.validate();

    this.setState({ errors });
  };
  render() {
    if (!this.props.permissions) return <PermissionsMessage />;
    const { loading, errors } = this.state;
    const modeOfTransportOptions = [
      { id: 1, value: "ROAD", text: "ROAD" },
      { id: 2, value: "RAIL", text: "RAIL" }
    ];
    const positionOptions = [
      { id: 1, value: "PRESTACK", text: "PRESTACK" },
      { id: 2, value: "DEPO", text: "DEPO" }
    ];
    const transportTypeOptions = [
      { id: 1, value: "TRUCK", text: "TRUCK" },
      { id: 2, value: "TRAIN", text: "TRAIN" }
    ];
    if (this.props.truckerFeed.loading === false) {
      let tempOp = this.props.truckerFeed.getAllTruckers;
      truckerOptions = [];
      tempOp.map(element => {
        return truckerOptions.push({
          id: element.id,
          key: element.id,
          text: element.truckerName,
          value: element.truckerCode
        });
      });
    }
    return (
      <>
        {" "}
        <Header as="h3" color="green" textAlign="center">
          Add Departure Details
        </Header>
        <Form size="large" onSubmit={this.onSubmit} loading={loading}>
          {errors.global && (
            <Message negative>
              <Message.Header> Something went wrong </Message.Header>
              <p>{errors.global}</p>
            </Message>
          )}
          <Grid
            textAlign="center"
            style={{ height: "100%" }}
            columns={2}
            divided
          >
            <Grid.Row>
              <Grid.Column>
                <Divider />

                <Segment stacked>
                  <Form.Field error={!!errors.modeOfTransportOutMsa}>
                    <label>Mode</label>
                    <Dropdown
                      value={this.state.modeOfTransportOutMsaText}
                      search
                      selection
                      options={modeOfTransportOptions}
                      onChange={this.setTransportMode.bind(this)}
                    />
                    {errors.modeOfTransportOutMsa && (
                      <InlineError text={errors.modeOfTransportOutMsa} />
                    )}
                  </Form.Field>
                  <Form.Field error={!!errors.dateDelivered}>
                    <label>Delivery Date</label>{" "}
                    <DatePicker
                      selected={this.state.dateDelivered}
                      onChange={this.dateDeliveredChange}
                    />
                    {errors.dateDelivered && (
                      <InlineError text={errors.dateDelivered} />
                    )}
                  </Form.Field>
                  <Divider />
                  <Form.Field error={!!errors.outTimeMsa}>
                    <label>Delivery Time</label>

                    <TimePicker
                      showSecond={false}
                      defaultValue={moment()}
                      className="xxx"
                      onChange={this.outTimeMsaChange}
                    />
                    {errors.outTimeMsa && (
                      <InlineError text={errors.outTimeMsa} />
                    )}
                  </Form.Field>
                  <Divider />
                  <Form.Field error={!!errors.position}>
                    <label>Position</label>
                    <Dropdown
                      value={this.state.postition}
                      search
                      selection
                      options={positionOptions}
                      onChange={this.onPostitionChange.bind(this)}
                    />
                    {errors.position && <InlineError text={errors.position} />}
                  </Form.Field>

                  <Divider />
                  <Form.Field error={!!errors.transportType}>
                    <label>Transport Type</label>
                    <Dropdown
                      value={this.state.transportType}
                      search
                      selection
                      options={transportTypeOptions}
                      onChange={this.onTransportTypeChange.bind(this)}
                    />
                    {errors.transportType && (
                      <InlineError text={errors.transportType} />
                    )}
                  </Form.Field>
                </Segment>
              </Grid.Column>
              <Grid.Column>
                <Divider />

                <Segment stacked>
                  <Form.Field error={!!errors.modeOfTransportIdOutMsa}>
                    <label>Registration Number</label>
                    <input
                      value={this.state.modeOfTransportIdOutMsa}
                      onChange={e =>
                        this.setState({
                          modeOfTransportIdOutMsa: e.target.value
                        })
                      }
                    />
                    {errors.modeOfTransportIdOutMsa && (
                      <InlineError text={errors.modeOfTransportIdOutMsa} />
                    )}
                  </Form.Field>
                  <Form.Field error={!!errors.carrierIDOutMsa}>
                    <label>Carrier ID</label>
                    <Dropdown
                      value={this.state.truckerValue}
                      search
                      selection
                      options={truckerOptions}
                      onChange={this.setTruckerValue.bind(this)}
                    />
                    {errors.carrierIDOutMsa && (
                      <InlineError text={errors.carrierIDOutMsa} />
                    )}
                  </Form.Field>
                  <Form.Field error={!!errors.truckerDriverName}>
                    <label>Driver Name</label>
                    <input
                      value={this.state.truckerDriverName}
                      onChange={e =>
                        this.setState({
                          truckerDriverName: e.target.value
                        })
                      }
                    />
                    {errors.truckerDriverName && (
                      <InlineError text={errors.truckerDriverName} />
                    )}
                  </Form.Field>
                  <Form.Field error={!!errors.truckerdriverID}>
                    <label>Driver Id</label>
                    <input
                      value={this.state.truckerdriverID}
                      onChange={e =>
                        this.setState({
                          truckerdriverID: e.target.value
                        })
                      }
                    />
                    {errors.truckerdriverID && (
                      <InlineError text={errors.truckerdriverID} />
                    )}
                  </Form.Field>
                </Segment>
              </Grid.Column>
            </Grid.Row>
          </Grid>
          <Form.Button fluid positive>
            Submit
          </Form.Button>
        </Form>
      </>
    );
  }
  _addDepartures = async () => {
    const {
      dateDelivered,
      outTimeMsa,

      position,
      modeOfTransportIdOutMsa,
      transportTypeOutMsa,
      carrierIDOutMsa,
      modeOfTransportOutMsa,
      truckerDriverName,
      truckerdriverID
    } = this.state;
    console.log("modeOfTransportIdOutMsa: ", modeOfTransportIdOutMsa);
    await this.props.addMutation({
      variables: {
        id: this.props.match.params.id,

        dateDelivered,
        outTimeMsa,

        position,
        modeOfTransportIdOutMsa,
        transportTypeOutMsa,
        carrierIDOutMsa,
        modeOfTransportOutMsa,
        truckerDriverName,
        truckerdriverID
      },
      refetchQueries: [{ query: getDeptMbsContainers }]
    });
    this.props.history.push("/departures/mbs/list");
  };
}

const addMutation = gql`
  mutation(
    $id: ID!
    $dateDelivered: String!
    $outTimeMsa: String!
    $position: String!
    $modeOfTransportIdOutMsa: String!
    $transportTypeOutMsa: String!
    $carrierIDOutMsa: String!
    $modeOfTransportOutMsa: String!
    $truckerDriverName: String!
    $truckerdriverID: String!
  ) {
    deliveryContainer(
      id: $id
      dateDelivered: $dateDelivered
      outTimeMsa: $outTimeMsa

      position: $position
      modeOfTransportIdMsa: $modeOfTransportIdOutMsa
      transportTypeOutMsa: $transportTypeOutMsa
      carrierIDOutMsa: $carrierIDOutMsa
      modeOfTransportOutMsa: $modeOfTransportOutMsa
      truckerDriverName: $truckerDriverName
      truckerDriverID: $truckerdriverID
    )
  }
`;
export default compose(
  graphql(getTruckers, { name: "truckerFeed" }),
  graphql(addMutation, { name: "addMutation" })
)(DepartureMbs);
