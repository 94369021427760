import { Container, Header, Segment,  Dropdown, Divider } from 'semantic-ui-react';

import React, {Component} from 'react'

const clientOptions =[
  {
    key: '1',
    text: 'TBL - Through Bill of Lading',
    value: 'TBL - Through Bill of Lading'
    
  },
  {
    key: '2',
    text: 'Merchant Haulage',
    value: 'Merchant Haulage'
    
  }
   ]
class MainRequest extends Component {
  constructor(props) {
    super(props);
    this.state = {  }
  }
  setClientOptionChange=(e, data)=>{
    console.log(data)
    clientOptions.forEach(element=>{
       if(data.value === "Merchant Haulage"){
        this.props.history.push("/selfservice/orderform/relayorder");
      }
      else if(data.value === "TBL - Through Bill of Lading"){
        this.props.history.push("/selfservice/orderform/shippingline");
      }
    })
  }
  relayorder
  
  render() { 
    
    return ( 
      <Container>
      <br />
      <Header color="teal" textAlign="center">Make Order</Header>
      <Segment >
        <Header textAlign="center" as='h4'>Choose Client Type</Header>
      <Dropdown
       placeholder='Select Client'
       fluid
       selection
       options={clientOptions}
       onChange = {this.setClientOptionChange.bind(this)}
     />
     <Divider></Divider>
     
     </Segment>
     </Container>
     );
  }
}
 
export default MainRequest;

