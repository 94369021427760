import React, { Component } from "react";
import gql from "graphql-tag";
import { graphql } from "react-apollo";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import { Icon } from "semantic-ui-react";
import { Link } from "react-router-dom";
import LoadingMessage from "../shared/LoadingMessage";
import withQuery from "../HOC/withQuery";

import cellEditFactory from "react-bootstrap-table2-editor";

import getDeliveryNote from "./Queries/fetchAllDelivered";

class DeliveredList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [...this.props.entries],

      pageNum: 1
    };
  }
  render() {
    // if (!this.props.permissions) return <PermissionsMessage />;
    console.log(this.props);
    if (this.state.data) {
      const { data } = this.state;
      console.log(data);
      const CaptionElement = () => (
        <h3
          style={{
            borderRadius: "0.25em",
            textAlign: "center",
            color: "green",
            border: "1px solid green",
            padding: "0.5em"
          }}
        >
          Mombasa Container Departure to Depo
        </h3>
      );
      const columns = [
        {
          text: "Client ",
          dataField: "clientCode",
          sort: true
        },
        {
          text: "Container No. ",
          dataField: "containerNum",
          sort: true
        },
        {
          text: "GateOut Msa. ",
          dataField: "dateDelivered",
          sort: true
        },
        {
          text: "Remarks",
          dataField: "remarks",
          sort: true,
          editable: true
        },
        {
          text: "Date Delivered",
          dataField: "dateDelivered",
          sort: true,
          editable: true
        },

        {
          text: "Guaranty Form ",
          dataField: "guaranteeForm",
          sort: true,
          editable: true
        },
        {
          text: "Guaranty Validity ",
          dataField: "guaranteeFormValidity",
          sort: true,
          editable: true
        },
        {
          text: "Delivery Note ",
          dataField: "deliveryNoteID",
          sort: true,
          editable: true
        },
        {
          text: "Truck Reg No.",
          dataField: "modeOfTransportIdMsa",
          sort: true,
          editable: true
        },

        {
          text: "",
          dataField: "df",
          isDummyField: true,
          formatter: (cellContent, row) => {
            return (
              <Link to={`/administer/${row.id}`}>
                Add EIR Details
                <Icon name="angle double right" color="green" />
              </Link>
            );
          }
        }
      ];
      const defaultSorted = [
        {
          dataField: "clientCode",
          order: "desc"
        }
      ];
      const { SearchBar } = Search;
      const pagination = paginationFactory({
        sizePerPage: 5
      });
      return (
        <>
          <CaptionElement />

          <ToolkitProvider
            keyField="id"
            data={data}
            columns={columns}
            defaultSorted={defaultSorted}
            search={{ defaultSearch: "" }}
          >
            {props => (
              <div>
                <div align="right">
                  <SearchBar {...props.searchProps} />
                </div>
                <hr />
                <BootstrapTable
                  {...props.baseProps}
                  striped
                  hover
                  condensed
                  pagination={pagination}
                  noDataIndication="Table is Empty"
                  cellEdit={cellEditFactory({
                    mode: "dbclick",
                    blurToSave: true,
                    beforeSaveCell: (oldValue, newValue, row, column) => {
                      console.log("column: ", column);
                      console.log("Before Saving Cell!!:", oldValue);
                      console.log("New Value: ", newValue);
                      console.log("Id: ", row.id);
                      this._editPreadvise(newValue, column, row);
                    }
                  })}
                />
              </div>
            )}
          </ToolkitProvider>
        </>
      );
    } else {
      return <LoadingMessage />;
    }
  }
  _editPreadvise = async (newValue, column, row) => {
    let {
      id,
      deliveryNoteID,
      remarks,
      modeOfTransportIdMsa,
      guaranteeFormValidity,
      guaranteeForm
    } = row;

    if (!guaranteeFormValidity) guaranteeFormValidity = null;
    if (!guaranteeForm) guaranteeForm = null;
    console.log(this.props);
    if (
      this.props.user.role === "CUSTOMERSERVICEMSA" ||
      this.props.user.role === "SUPERUSER"
    ) {
      await this.props.editPreadvise({
        variables: {
          id: id,
          deliveryNoteID,
          remarks,
          modeOfTransportIdMsa,
          guaranteeFormValidity,
          guaranteeForm,
          [column.dataField]: newValue
        },
        refetchQuery: [{ getDeliveryNote }]
      });
      this.props.history.push("/delivered/list");
    }
  };
}
const EDITPREADVISE = gql`
  mutation editPreadvise(
    $id: ID!
    $deliveryNoteID: String
    $remarks: String
    $modeOfTransportIdMsa: String
    $guaranteeFormValidity: String
    $guaranteeForm: String
    $dateDelivered: String
  ) {
    editPreadvise(
      id: $id
      deliveryNoteID: $deliveryNoteID
      remarks: $remarks
      modeOfTransportIdMsa: $modeOfTransportIdMsa
      guaranteeFormValidity: $guaranteeFormValidity
      guaranteeForm: $guaranteeForm
      dateDelivered: $dateDelivered
    )
  }
`;
export default graphql(EDITPREADVISE, { name: "editPreadvise" })(
  withQuery(DeliveredList, getDeliveryNote, "getDeliveryNote")
);
