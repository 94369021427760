import React, { Component } from 'react';
import {
    Form,
    Grid,
    Header,
    Message,
    Divider
  } from "semantic-ui-react";

class Warning extends Component {
    constructor(props) {
        super(props);
        this.state = {  }
    }
    render() { 
        console.log(this.props.location.state);
        const { errors } = this.props.location.state;
        
            return (
              <Grid
                textAlign="center"
                style={{ height: "100%" }}
                verticalAlign="middle"
              >
                <Grid.Column style={{ maxWidth: 600 }}>
                  <Header as="h2" color="red" textAlign="center">
                    Signup Failed
                  </Header>
                  <Divider />
                  <Form size="large" >
                    
                      <Message negative>
                        <Message.Header> Sign up failed!</Message.Header>
                        <p>{errors}</p>
                      </Message>
                    
        
                  </Form>
                </Grid.Column>
              </Grid>
         );
    }
}
 
export default Warning;