import React, { useState } from "react"
import gql from "graphql-tag"
import { graphql } from "react-apollo"
import {
  Form,
  Input,
  Message,
  Grid,
  Header,
  Divider,
  Button,
} from "semantic-ui-react"

import InlineError from "../../shared/InlineError"

const addPartyDelivering = gql`
  mutation addPartyDelivering(
    $party_delivering_name: String
    $party_delivering_email: String
  ) {
    addPartyDelivering(
      party_delivering_name: $party_delivering_name
      party_delivering_email: $party_delivering_email
    )
  }
`

const getPartyDelivering = gql`
  query getAllPartyDelivering {
    getAllPartyDelivering {
      party_delivering_id
      party_delivering_name
    }
  }
`
function AddPartyDelivering(props) {
  const [partyDeliveringName, setPartyDeliveringName] = useState()
  const [partyDeliveringEmail, setPartyDeliveringEmail] = useState()
  const [loading, setLoading] = useState()
  const [errors, setErrors] = useState({})

  const validate = async () => {
    const errors = {}

    if (!partyDeliveringName) errors.partyDeliveringName = "Can't be blank!"
    if (!partyDeliveringEmail) errors.partyDeliveringEmail = "Can't be blank!"
    if (Object.keys(errors).length === 0) {
      await props.addPartyDelivering({
        variables: {
          party_delivering_name: partyDeliveringName,
          party_delivering_email: partyDeliveringEmail,
        },
        refetchQueries: [
          {
            query: getPartyDelivering,
          },
        ],
      })
      props.history.push("/prestack/partydelivering/list")
    }
    return errors
  }
  const submit = () => {
    setLoading(true)
    const errors = validate()
    setErrors((prevErrors) => ({ ...prevErrors, ...errors }))
    setLoading(false)
  }
  return (
    <Grid textAlign="center" style={{ height: "100%" }} verticalAlign="top">
      <Grid.Column style={{ maxWidth: 600 }}>
        <Header as="h2" color="green" textAlign="center">
          Enter Vessel Details
        </Header>
        <Divider />
        <Form size="large">
          {errors.global && (
            <Message negative>
              <Message.Header> Something went wrong </Message.Header>
              <p>{errors.global}</p>
            </Message>
          )}
          <Form.Field inline required error={!!errors.partyDeliveringName}>
            <label>Party Delivering Name</label>
            <Input
              focus
              placeholder="Speedex"
              value={partyDeliveringName}
              onChange={(e) => setPartyDeliveringName(e.target.value)}
            />
            {errors.partyDeliveringName && (
              <InlineError text={errors.partyDeliveringName} />
            )}
          </Form.Field>
          <Form.Field inline required error={!!errors.partyDeliveringEmail}>
            <label>Party Delivering Email</label>
            <Input
              focus
              placeholder="x@x.co.ke"
              value={partyDeliveringEmail}
              onChange={(e) => setPartyDeliveringEmail(e.target.value)}
            />
            {errors.partyDeliveringEmail && (
              <InlineError text={errors.partyDeliveringEmail} />
            )}
          </Form.Field>
          <Form.Button positive fluid onClick={submit}>
            Submit
          </Form.Button>
        </Form>
      </Grid.Column>
    </Grid>
  )
}

export default graphql(addPartyDelivering, {
  name: "addPartyDelivering",
})(AddPartyDelivering)
