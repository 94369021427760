import gql from "graphql-tag";

export default gql`
  query getCodecoFields {
    getCodecoFields {
      id
      clientCode
      operatorCode
      containerNum
      containerSize
      weight
      eQStatus
      fEStatus
      pOL
      pOD
      sOExpiryDate
      vessel
      preAdviseNum
      preadviseDate
      partyDeliveringName
      inDateICD
      inTimeICD
      bblEIR
      etdNbo
      outTimeICD
      loadingTime
      etaMSA
      arrivalDateMSA
      inTimeMsa
      wagonNum
      modeOfTransportOutMsa
      transportTypeOutMsa
      carrierIDOutMsa
      modeOfTransportIdMsa
      position
      dateDelivered
      deliveryNoteID
      arrivalStatus
    }
  }
`;
