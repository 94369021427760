import React from "react";
import { Grid, Message } from "semantic-ui-react";

const ErrorProcessingMessage = props => {
  console.log(props);
  return (
    <Grid textAlign="center" style={{ height: "100%" }} verticalAlign="middle">
      <Grid.Column style={{ maxWidth: 600 }}>
        <Message negative size="large">
          <Message.Header>
            Sorry you can't serve your request at this time!
          </Message.Header>
          <p>Error Processing your request</p>
        </Message>
      </Grid.Column>
    </Grid>
  );
};

export default ErrorProcessingMessage;
