import React, { Component } from 'react';
import {Grid, Header, Table } from 'semantic-ui-react'
import moment from 'moment'
import Pdf from './Pdf.js'

import BookedContainers from './BookedContainers'
class ClientBookingDetails extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            partyDeliveringName:"",
            validityDate:"",
            containerFileName:"",
         }
    }
    
    render() { 
        // let savedState =  JSON.stringify(this.props.location.state)
        // localStorage.setItem('myState', savedState);
        // console.log(this.props)
        let {fileLocation} = this.props.location.state
        let filepath = fileLocation.split("/")
        return (  
            
            <Grid divided='vertically' celled='internally'  >
                <Header>Booking</Header>
                <Grid.Row columns={2}>
                <Grid.Column>
                <Pdf location={filepath[filepath.length-1]}/>
                </Grid.Column>
                <Grid.Column>
                <Table color="teal"  inverted>
                    {/* <Table.Header fullWidth>
                        <Table.Row>
                            <Table.HeaderCell>Client Details</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header> */}

                       <Table.Body>
                        <Table.Row>
                            <Table.Cell>Party Delivering</Table.Cell>
                            <Table.Cell>{this.props.location.state.partyDeliveringName}</Table.Cell>
                        
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>Storage Order/Guarantee Form Validity</Table.Cell>
                            <Table.Cell>{(moment(this.props.location.state.validityDate, moment.ISO_8601, true).isValid()) ? moment(this.props.location.state.validityDate).format("MMM Do YYYY") : "TBA"}</Table.Cell>
                            
                        </Table.Row>
                        </Table.Body>
                    </Table>
                    <Header as="h3">Booked Containers</Header>
                    <BookedContainers containerFileName={this.props.location.state.containerFileName}/>
                </Grid.Column>
                </Grid.Row>
            

           
            </Grid>
         
        );
    }
}
 
export default ClientBookingDetails;


