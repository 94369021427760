import { ApolloClient, InMemoryCache, ApolloLink } from "apollo-boost"
import { createUploadLink } from "apollo-upload-client"
import store from "store"
const cache = new InMemoryCache()
//Test
// const httpLink = new HttpL

//Live
// const httpLink = new createUploadLink({ uri: "/server/" });

const httpLink = new createUploadLink({
  uri: "https://cts.hawilisha.com/server/",
})

// const httpLink = new createUploadLink({ uri: "http://localhost:4000" })
// x
//test
// const httpLink = new createUp÷oadLink({ uri: "http://104.131.32.21:4000/" })
// const logState = store.get("token");
//configure apollo with authentication token
const middlewareAuthLink = new ApolloLink((operation, forward) => {
  const token = store.get("token")

  const authorizationHeader = token ? `Bearer ${token}` : null
  operation.setContext({
    headers: {
      authorization: authorizationHeader,
      "keep-alive": "true",
    },
  })
  return forward(operation)
})

const httpLinkWithAuthToken = middlewareAuthLink.concat(httpLink)
export const client = new ApolloClient({
  link: httpLinkWithAuthToken,
  cache,
})
