import React, { Component } from "react"
import gql from "graphql-tag"
import _ from "lodash"
import { graphql } from "react-apollo"
import { flowRight as compose } from "lodash"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory from "react-bootstrap-table2-paginator"
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import cellEditFactory, { Type } from "react-bootstrap-table2-editor"
import LoadingMessage from "../shared/LoadingMessage"
import withQuery from "../HOC/withQuery"
import { Icon } from "semantic-ui-react"
import { Link } from "react-router-dom"
import getClientDelivered from "./Queries/fetchMobileInNrb"
var containerOptions = []
var clientOptions = []
var operatorOptions = []
class MobileOrdersArrivalsMsaList extends Component {
  constructor(props) {
    super(props)
    this.state = {
      data: [],
      pageNum: 1,
    }
  }
  componentDidMount() {
    if (this.props.entries) this.setState({ data: [...this.props.entries] })
  }

  render() {
    console.log(this.state.data)
    if (this.props.containerFeed.loading === false) {
      let tempOp = this.props.containerFeed.getAllContainers
      containerOptions = []
      tempOp.map((element) => {
        return containerOptions.push({
          label: element.containerCode,
          value: element.containerCode,
        })
      })
    }
    if (this.props.clientFeed.loading === false) {
      let tempOp = this.props.clientFeed.getAllClients
      clientOptions = []
      tempOp.map((element) => {
        return clientOptions.push({
          label: element.clientName,
          value: element.clientName,
        })
      })
    }
    if (this.props.operatorFeed.loading === false) {
      let tempOp = this.props.operatorFeed.getAllOperators
      operatorOptions = []
      tempOp.map((element) => {
        return operatorOptions.push({
          label: element.operatorCode,
          value: element.operatorCode,
        })
      })
    }
    if (this.state.data) {
      const { data } = this.state
      const { role } = this.props.user

      const CaptionElement = () => (
        <h3
          style={{
            borderRadius: "0.25em",
            textAlign: "center",
            color: "green",
            border: "1px solid green",
            padding: "0.5em",
          }}
        >
          Delivered Containers
        </h3>
      )
      const { SearchBar, ClearSearchButton } = Search
      const columnsForSuperUser = [
        {
          text: "Client Code",
          dataField: "clientCode",
          sort: true,
          editor: {
            type: Type.SELECT,
            options: [...clientOptions],
          },
        },

        {
          text: "Vessel",
          dataField: "vessel",
          sort: true,
        },
        {
          text: "Container No.",
          dataField: "containerNum",
          sort: true,
          editable: true,
        },
        {
          text: "Size",
          dataField: "containerSize",
          sort: true,
          editor: {
            type: Type.SELECT,
            options: [...containerOptions],
          },
        },
        {
          text: "Operator",
          dataField: "operatorCode",
          sort: true,
          editor: {
            type: Type.SELECT,
            options: [...operatorOptions],
          },
        },
        {
          text: "Booked",
          dataField: "createdAt",
          sort: true,
        },
        {
          text: "Booked Time",
          dataField: "createdTime",
          sort: true,
        },
        // {
        //   text: "Preadvise No.",
        //   dataField: "preAdviseNum",
        //   sort: true,
        //   editable: true
        // },
        // {
        //   text: "P. Date",
        //   dataField: "preadviseDate",
        //   sort: true,
        //   editable: true
        // },
        {
          text: "",
          dataField: "df",
          isDummyField: true,
          formatter: (cellContent, row) => {
            if (row.eirFileLocation) {
              let url = row.eirFileLocation.split("/")
              return (
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={`https://cts.hawilisha.com/pdf/documents/${
                    url[url.length - 1]
                  }`}
                >
                  View Interchange
                  <Icon name="angle double right" color="green" />
                </a>
              )
            }
          },
        },
        // {
        //   dataField: "id",
        //   text: "Remove",
        //   formatter: (cellContent, row) => {
        //     return (
        //       <Button negative onClick={() => this._removePreadvise(row)}>
        //         Cancel
        //       </Button>
        //     );
        //   }
        // }
      ]
      const columns = [
        {
          text: "Client Code",
          dataField: "clientCode",
          sort: true,
          editor: {
            type: Type.SELECT,
            options: [...clientOptions],
          },
        },
        {
          text: "File Bil No.",
          dataField: "fileBillingNumber",
          sort: true,
          editable: true,
        },
        {
          text: "Vessel",
          dataField: "vessel",
          sort: true,
          editable: true,
        },
        {
          text: "Container No.",
          dataField: "containerNum",
          sort: true,
          editable: true,
        },
        {
          text: "Size",
          dataField: "containerSize",
          sort: true,
          editor: {
            type: Type.SELECT,
            options: [...containerOptions],
          },
        },
        {
          text: "Operator",
          dataField: "operatorCode",
          sort: true,
          editor: {
            type: Type.SELECT,
            options: [...operatorOptions],
          },
        },
        {
          text: "Booked",
          dataField: "createdAt",
          sort: true,
        },
        {
          text: "Booked Time",
          dataField: "createdTime",
          sort: true,
        },
        {
          text: "Preadvise No.",
          dataField: "preAdviseNum",
          sort: true,
          editable: true,
        },
        {
          text: "P. Date",
          dataField: "preadviseDate",
          sort: true,
          editable: true,
        },
        {
          text: "",
          dataField: "df",
          isDummyField: true,
          formatter: (cellContent, row) => {
            return (
              <Link to={`/transaction/new/${row.id}`}>
                Add Billing Number
                <Icon name="angle double right" color="green" />
              </Link>
            )
          },
        },
      ]
      const defaultSorted = [
        {
          dataField: "clientCode",
          order: "desc",
        },
      ]
      const pagination = paginationFactory({
        sizePerPage: 5,
      })
      if (role === "SUPERUSER") {
        return (
          <>
            <CaptionElement />
            <ToolkitProvider
              keyField="id"
              data={data}
              columns={columnsForSuperUser}
              defaultSorted={defaultSorted}
              search={{ defaultSearch: "" }}
            >
              {(props) => (
                <div>
                  <div align="right">
                    <SearchBar {...props.searchProps} />
                    <ClearSearchButton {...props.searchProps} />
                  </div>
                  <hr />
                  <BootstrapTable
                    {...props.baseProps}
                    striped
                    hover
                    condensed
                    pagination={pagination}
                    noDataIndication="Table is Empty"
                    cellEdit={cellEditFactory({
                      mode: "dbclick",
                      blurToSave: true,
                      beforeSaveCell: (oldValue, newValue, row, column) => {
                        console.log("column: ", column)
                        console.log("Before Saving Cell!!:", oldValue)
                        console.log("New Value: ", newValue)
                        console.log("Id: ", row.id)
                        this._editPreadvise(newValue, column, row)
                      },
                    })}
                  />
                </div>
              )}
            </ToolkitProvider>
          </>
        )
      } else {
        return (
          <>
            <CaptionElement />
            <ToolkitProvider
              keyField="id"
              data={data}
              columns={columnsForSuperUser}
              defaultSorted={defaultSorted}
              search={{ defaultSearch: "" }}
            >
              {(props) => (
                <div>
                  <div align="right">
                    <SearchBar {...props.searchProps} />
                    <ClearSearchButton {...props.searchProps} />
                  </div>
                  <hr />
                  <BootstrapTable
                    {...props.baseProps}
                    striped
                    hover
                    condensed
                    pagination={pagination}
                    noDataIndication="Table is Empty"
                  />
                </div>
              )}
            </ToolkitProvider>
          </>
        )
      }
    } else {
      return <LoadingMessage />
    }
  }
  _editPreadvise = async (newValue, column, row) => {
    const { id } = row
    let columnEdited
    console.log("EDIT SAVE:::", column)
    if (column.text === "Client Code") {
      columnEdited = _.filter(
        this.props.clientFeed.getAllClients,
        function (o) {
          console.log("O.clientName", o.clientName)
          return o.clientName === newValue
        }
      )
    }
    console.log(this.props.clientFeed.getAllClients)
    switch (column.dataField) {
      case "operatorCode": {
        columnEdited = _.filter(
          this.props.operatorFeed.getAllOperators,
          function (o) {
            return o.operatorCode === newValue
          }
        )
        break
      }
      case "containerSize": {
        columnEdited = _.filter(
          this.props.containerFeed.getAllContainers,
          function (o) {
            return o.containerCode === newValue
          }
        )
        break
      }
      default:
        break
    }

    const {
      fileBillingNumber,
      preAdviseNum,
      preadviseDate,
      vessel,
      containerNum,
    } = row
    let args
    console.log(columnEdited)
    if (column.dataField === "containerSize") {
      args = { [column.dataField]: columnEdited[0].id }
    } else if (column.dataField === "clientCode") {
      args = { [column.dataField]: columnEdited[0].clientName }
    } else if (column.dataField === "operatorCode") {
      args = { [column.dataField]: columnEdited[0].operatorCode }
    } else {
      args = { [column.dataField]: newValue }
    }

    await this.props.editPreadvise({
      variables: {
        id,
        fileBillingNumber,
        preAdviseNum,
        preadviseDate,
        vessel,
        containerNum,
        ...args,
      },
      refetchQuery: [{ getClientDelivered }],
    })
    this.props.history.push("/transaction/list")
  }
  _removePreadvise = async (args) => {
    const { id } = args
    await this.props.removePreadvise({
      variables: { id },
      refetchQuery: [{ getClientDelivered }],
    })
    this.props.history.push("/transaction/list")
  }
}
const EDITPREADVISE = gql`
  mutation editPreadvise(
    $id: ID!
    $fileBillingNumber: String
    $preAdviseNum: String
    $preadviseDate: String
    $vessel: String
    $clientCode: String
    $containerNum: String
    $containerSize: String
    $operatorCode: String
  ) {
    editPreadvise(
      id: $id
      fileBillingNumber: $fileBillingNumber
      preAdviseNum: $preAdviseNum

      containerNum: $containerNum
      containerSize: $containerSize
      operatorCode: $operatorCode
      clientCode: $clientCode
      vessel: $vessel
      preadviseDate: $preadviseDate
    )
  }
`
const CONTAINERFEED = gql`
  query getAllContainers {
    getAllContainers {
      id
      containerSize
      containerCode
    }
  }
`
const CLIENTFEED = gql`
  query getAllClients {
    getAllClients {
      id
      clientCode
      clientName
    }
  }
`
const OPERATORFEED = gql`
  query getAllClients {
    getAllOperators {
      id
      operatorCode
      operatorName
    }
  }
`
const DELETEPREADVISE = gql`
  mutation removePreadvise($id: ID!) {
    removePreadvise(id: $id)
  }
`
export default compose(
  graphql(CONTAINERFEED, {
    name: "containerFeed",
  }),
  graphql(CLIENTFEED, {
    name: "clientFeed",
  }),
  graphql(OPERATORFEED, {
    name: "operatorFeed",
  }),
  graphql(EDITPREADVISE, { name: "editPreadvise" }),
  graphql(DELETEPREADVISE, { name: "removePreadvise" })
)(
  withQuery(
    MobileOrdersArrivalsMsaList,
    getClientDelivered,
    "getClientDelivered"
  )
)
