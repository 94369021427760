import gql from "graphql-tag";

export default gql`
  query getGuarantee {
    getGuarantee {
      id
      preAdviseNum
      containerNum
      clientCode
      arrivalDateMSA
    }
  }
`;
