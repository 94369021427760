import React from "react";
import store from "store";
import { AUTH_TOKEN } from "../src/modules/shared/constants";
import { Header, Button, Icon } from "semantic-ui-react";

const handleLogout = history => () => {
  store.remove("loggedIn");
  store.remove("user")
  localStorage.removeItem(AUTH_TOKEN);
  localStorage.removeItem("state")
  window.location.reload();
};
const Banner = ({ history }) => {
  return (
    <React.Fragment>
      <Button color="green" floated="right" onClick={handleLogout(history)}>
        {" "}
        Log Out{" "}
      </Button>
      <div>
        <Header as="h2" icon textAlign="center">
          <Icon name="home" color="green" circular />

          <Header.Content>Box Back</Header.Content>
        </Header>
      </div>
    </React.Fragment>
  );
};

export default Banner;
