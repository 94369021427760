import gql from "graphql-tag"

export default gql`
  query dispatchDepotStack {
    dispatchDepotStack {
      id
      vessel_code
      container {
        container_number
      }
      mode_of_transport_id_out
      date_out
    }
  }
`
