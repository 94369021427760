import React, { Component } from "react"

import ReactHtmlTableToExcel from "react-html-table-to-excel"

class ExportDepotAuditTrail extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }
  render() {
    console.log("Excel Props", this.props.data)
    return (
      <div stylee={{ marginRight: "25px" }}>
        <ReactHtmlTableToExcel
          id="test-table-xls-button"
          className="export"
          table="table-to-xls"
          fileName="filteredData"
          sheet="tablexls"
          buttonText="Export"
        />
        <table hidden="true" id="table-to-xls">
          <thead>
            <tr>
              <th>No. </th>

              <th>CONTAINER NO.</th>
              <th>ADDED</th>
              <th>ExtraDetails</th>
              <th>ARRIVAL PRESTACK</th>
              <th>EXIT PRESTACK</th>
              <th>LOADED</th>
              <th>RESTOWED</th>
              <th>REBOOKED</th>
            </tr>
          </thead>
          <tbody>
            {this.props.data.map((d, index) => {
              return (
                <tr key={d.id}>
                  <td>{index}</td>
                  <td>{d.container_number}</td>
                  <td>{d.addeddepotby !== null ? d.addeddepotby.name : ""}</td>
                  <td>
                    {d.extradepotdetailsby !== null
                      ? d.extradepotdetailsby.name
                      : ""}
                  </td>
                  <td>
                    {d.recieveddepotby !== null ? d.recieveddepotby.name : ""}
                  </td>
                  <td>
                    {d.exitprestackby !== null ? d.exitprestackby.name : ""}
                  </td>
                  <td>
                    {d.dispatchedprestackby !== null
                      ? d.dispatchedprestackby.name
                      : ""}
                  </td>
                  <td>
                    {d.restowedprestackby !== null
                      ? d.restowedprestackby.name
                      : ""}
                  </td>
                  <td>
                    {d.rebookedprestackby !== null
                      ? d.rebookedprestackby.name
                      : ""}
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
    )
  }
}

export default ExportDepotAuditTrail
