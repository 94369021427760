import React, { useState } from "react"
import gql from "graphql-tag"
import { graphql } from "react-apollo"
import { flowRight as compose } from "lodash"
import {
  Form,
  Input,
  Message,
  Grid,
  Header,
  Divider,
  Dropdown,
} from "semantic-ui-react"
import InlineError from "../../shared/InlineError"
import RestowStack from "./fetchRestowList"
var voyageOptions = []
function ContainerRestow(props) {
  const [vesselName, setVesselName] = useState()
  const [vesselCode, setVesselCode] = useState()
  const [voyage_id, setvoyage_id] = useState()
  const [loading, setLoading] = useState()
  const [errors, setErrors] = useState({})

  const setVoyageValue = (e, data) => {
    voyageOptions.forEach((element) => {
      if (element.value === data.value) {
        setvoyage_id(element.id)
        setVesselName(element.value)
        setErrors((prevState) => ({ ...prevState, vessel: "" }))
      }
    })
  }

  const validate = async () => {
    const errors = {}

    if (!vesselName) errors.vesselName = "Can't be blank!"
    if (Object.keys(errors).length === 0) {
      console.log("Rebooking")
      await props.rebookContainer({
        variables: {
          vessel_code: vesselCode,
          voyage_id,
          id: props.match.params.id,
        },
        refetchQueries: [
          {
            query: RestowStack,
          },
        ],
      })
      props.history.push("/prestack/customerservice/restowlist")
    }
    return errors
  }

  const submit = () => {
    setLoading(true)
    const errors = validate()
    setErrors((prevErrors) => ({ ...prevErrors, ...errors }))
    setLoading(false)
  }
  if (props.getAvailableVesselVoyages.loading === false) {
    console.log(props.getAvailableVesselVoyages)
    let tempOp = props.getAvailableVesselVoyages.getAvailableVesselVoyages
    voyageOptions = []

    tempOp.map((element) => {
      return voyageOptions.push({
        id: element.voyage_id,
        key: element.voyage_id,
        text: element.vesselName,
        value: element.vesselName,
      })
    })
  }
  return (
    <Grid textAlign="center" style={{ height: "100%" }} verticalAlign="top">
      <Grid.Column style={{ maxWidth: 600 }}>
        <Header as="h2" color="green" textAlign="center">
          Enter Vessel Details
        </Header>
        <Divider />
        <Form size="large">
          {errors.global && (
            <Message negative>
              <Message.Header> Something went wrong </Message.Header>
              <p>{errors.global}</p>
            </Message>
          )}
          <Form.Field inline required error={!!errors.vessel}>
            <label>Vessel Name</label>
            <Dropdown
              value={vesselName}
              search
              selection
              options={voyageOptions}
              onChange={setVoyageValue}
            />
            {errors.vessel && <InlineError text={errors.vessel} />}
          </Form.Field>
          <Form.Field inline required error={!!errors.vesselName}>
            <label>Vessel Code </label>
            <Input
              focus
              placeholder="MV..."
              value={vesselCode}
              onChange={(e) => setVesselCode(e.target.value)}
            />
            {errors.vesselName && <InlineError text={errors.vesselName} />}
          </Form.Field>
          <Form.Button positive fluid onClick={submit}>
            Submit
          </Form.Button>
        </Form>
      </Grid.Column>
    </Grid>
  )
}

const rebookContainer = gql`
  mutation rebookContainer(
    $id: ID!
    $container_number: String
    $voyage_id: String
    $vessel_code: String
  ) {
    rebookContainer(
      id: $id
      container_number: $container_number
      voyage_id: $voyage_id
      vessel_code: $vessel_code
    )
  }
`

const getAvailableVesselVoyages = gql`
  query getAvailableVesselVoyages {
    getAvailableVesselVoyages {
      voyage_id
      vesselName
      estimated_arrival
      estimated_departure
      actual_arrival
      actual_departure
    }
  }
`
export default compose(
  graphql(rebookContainer, {
    name: "rebookContainer",
  }),
  graphql(getAvailableVesselVoyages, {
    name: "getAvailableVesselVoyages",
  })
)(ContainerRestow)
