import { Message, Icon } from 'semantic-ui-react'
import React from 'react'

const EmptyMessage = () => {
	return (
		<Message icon info>
			<Icon name="warning circle" />
			<Message.Content>
				<Message.Header>No Records Found</Message.Header>
				<p>Add some new records to get started.</p>
			</Message.Content>
		</Message>
	)
}

export default EmptyMessage
