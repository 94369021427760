import React, { Component } from 'react';
import {Grid, Form, Button, Segment, Header} from 'semantic-ui-react'
import { Document, Page } from 'react-pdf';
import {toast} from 'react-toastify'

import "rc-time-picker/assets/index.css";
import InlineError from '../../shared/InlineError';

class CashOrderDetails extends Component {
    saveAndContinue = e => {
      e.preventDefault();
      this.props.nextStep();
     
        // if (
         
        //   !this.props.values.file)
        //   {
        //     toast.error("Please key in all the details", {position:toast.POSITION.TOP_CENTER,
        //       autoClose:false
        //       })
        //   }
        //     else{
             
        //     }
       
          
        };
    render() { 
        const {values, errors}=this.props
        return ( 
            <Grid centered columns={2}>
                <Grid.Column>
                <Header color="green" textAlign="center"> Other Details</Header>
                <Segment>
                <Form size="large" >
                <Form.Field>
                <Document file={values.file} 
                     onLoadSuccess={this.props.onDocumentLoadSuccess} 
                     noData={<h4>Please upload Storing Order/Guarantee Form</h4>}>
                    <Page pageNumber={values.pageNumber} />
                </Document>
        
                {values.file ? <p>Page {values.pageNumber} of {values.numPages}</p> : null}
                <input type="file" onChange={this.props.onFileChange}>
                </input>
                </Form.Field>
     
          

               {this.props.clientType==="RELAY" && 
               (
                <Form.Field error={!!errors.fileBillingNo}>
                <label>BL NO</label>
                <input
                value={values.fileBillingNo}
                type="text"
                onChange={this.props.handleChange("fileBillingNo")}
                />
                {errors.fileBillingNo && (
                <InlineError text={errors.fileBillingNo} />
                )}
            </Form.Field>
               )
               } 

                
                <Segment.Group horizontal>
              
                  <Segment>
                    <Button fluid color='google plus' onClick={this.saveAndContinue}>Next </Button>
                    </Segment>
                </Segment.Group> 
                    
                </Form>
                </Segment>
                
                </Grid.Column>
            </Grid>
         );
    }
}
 
export default CashOrderDetails;