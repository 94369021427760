import React, { PureComponent } from "react";

export default class ConfirmFailed extends PureComponent {
  render() {
    return (
      <div style={{ display: "flex" }}>
        <div
          className="App"
          style={{
            width: 600,
            margin: "auto",
            marginTop: "10%",
            borderStyle: "solid",
            borderColor: "#3498db",
            borderRadius: "20px",
            padding: "15px",
            paddingTop: "120px"
          }}
        >
          <h1>Confirmation Failed</h1>
          <p>
            Your confirmation failed. It is likely that your confirmation email
            expired. Please contact admin
          </p>
        </div>
      </div>
    );
  }
}
