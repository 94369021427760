export const auditColumns = [
  // {
  //   Header: "NO",
  //   id: "rowNum",
  //   accessor: d => d.rowNum
  // },
  {
    Header: "CONTAINER NO.",
    id: "container_number",
    accessor: (d) => d.container_number,
  },
  {
    Header: "ADDED",
    id: "addedby",
    accessor: (d) => (d.addeddepotby !== null ? d.addeddepotby.name : ""),
  },
  {
    Header: "Extra",
    id: "extradepotdetailsby",
    accessor: (d) =>
      d.extradepotdetailsby !== null ? d.extradepotdetailsby.name : "",
  },
  {
    Header: "Arr Prestack",
    id: "recieveddepotby",
    accessor: (d) => (d.recieveddepotby !== null ? d.recieveddepotby.name : ""),
  },

  {
    Header: "Exit Prestack",
    id: "exitprestackby",
    accessor: (d) => (d.exitprestackby !== null ? d.exitprestackby.name : ""),
  },

  {
    Header: "Loaded",
    id: "dispatchedprestackby",
    accessor: (d) =>
      d.dispatchedprestackby !== null ? d.dispatchedprestackby.name : "",
  },
  {
    Header: "Restowed",
    id: "restowedprestackby",
    accessor: (d) =>
      d.restowedprestackby !== null ? d.restowedprestackby.name : "",
  },
  {
    Header: "Rebooked",
    id: "rebookedprestackby",
    accessor: (d) =>
      d.rebookedprestackby !== null ? d.rebookedprestackby.name : "",
  },
]
