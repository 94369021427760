import React, { Component } from "react";
import ReactTable from "react-table";
import "react-table/react-table.css";
import moment from "moment";
import LoadingMessage from "../shared/LoadingMessage";
import withQuery from "../HOC/withQuery";
import { Header, Segment, Dropdown } from "semantic-ui-react";

import getCodecoFields from "./Queries/fetchAllDeparturesMbs";

import ExportCMAToExcel from "./ExportCMAToExcel";
class CMACodecoReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [...this.props.entries],
      pageNum: 1
    };
  }
  onPostitionChange = (e, data) => {
    this.setState({ position: data.value });
  };
  render() {
    const positionOptions = [
      { id: 1, value: "GATEINICD", text: "GATE IN ICD" },
      { id: 2, value: "GATEOUTICD", text: "GATE OUT ICD" },
      { id: 3, value: "GATEINMSA", text: "GATE IN MSA" },
      { id: 4, value: "GATEOUTMSA", text: "GATE OUT MSA" }
    ];
    if (this.state.data) {
      let { data } = this.state;
      console.log(data);
      if (this.state.position) {
        if (this.state.position === "GATEINICD") {
          data = data.filter(row => {
            return (
              row.bblEIR &&
              (row.outTimeICD === "Invalid date" ||
                row.outTimeICD === null ||
                row.outTimeICD === "")
            );
          });
        }
        if (this.state.position === "GATEOUTICD") {
          data = data.filter(row => {
            return (
              row.wagonNum &&
              (row.arrivalDateMSA === "Invalid date" ||
                row.arrivalDateMSA === null ||
                row.arrivalDateMSA === "")
            );
          });
        }
        if (this.state.position === "GATEINMSA") {
          data = data.filter(row => {
            return (
              row.arrivalStatus === "true" &&
              (row.dateDelivered === "Invalid date" ||
                row.dateDelivered === null ||
                row.dateDelivered === "")
            );
          });
        }
        if (this.state.position === "GATEOUTMSA") {
          data = data.filter(row => {
            return row.arrivalStatus === "true" && row.dateDelivered;
          });
        }
      }

      const columns = [
        {
          Header: "CONTAINER NO.",
          id: "containerNum",
          accessor: d => d.containerNum
        },

        {
          Header: "SIZE",
          id: "containerSize",
          accessor: d => d.containerSize
        },

        {
          Header: "Weight",
          id: "weight",
          accessor: d => d.weight
        },

        {
          Header: "EQStatus",
          id: "eQStatus",
          accessor: d => d.eQStatus
        },

        {
          Header: "FEStatus",
          id: "fEStatus",
          accessor: d => d.fEStatus
        },

        {
          Header: "POL",
          id: "pOL",
          accessor: d => d.pOL
        },

        {
          Header: "POD",
          id: "pOD",
          accessor: d => d.pOD
        },

        {
          Header: "S/O EXPIRY DATE",
          id: "sOExpiryDate",
          accessor: d => moment(d.sOExpiryDate).format("DD-MMM-YY")
        },

        {
          Header: "VESSEL",
          id: "vessel",
          accessor: d => d.vessel
        },

        {
          Header: "REF NO.",
          id: "preAdviseNum",
          accessor: d => d.preAdviseNum
        },

        {
          Header: "PRE-ADVISE DATE",
          id: "preadviseDate",
          accessor: d => moment(d.preadviseDate).format("DD-MMM-YY")
        },

        {
          Header: "PARTY DELIVERING",
          id: "partyDeliveringName",
          accessor: d => d.partyDeliveringName
        },

        {
          Header: "GATE IN ICD",
          id: "inDateICD",
          accessor: d => moment(d.inDateICD).format("DD-MMM-YY")
        },

        {
          Header: "TIME",
          id: "inTimeICD",

          accessor: d => moment(d.inTimeICD).format("h:mm")
        },

        {
          Header: "BBL EIR NO.",
          id: "bblEIR",
          accessor: d => d.bblEIR
        },

        {
          Header: "ETD NBO",
          id: "inDateICD",
          accessor: d => moment(d.inDateICD).format("DD-MMM-YY")
        },

        {
          Header: "GATE OUT ICD",
          id: "loadingTime",
          accessor: d => moment(d.loadingTime).format("DD-MMM-YY")
        },
        {
          Header: "TIME",
          id: "outTimeICD",
          accessor: d => moment(d.outTimeICD).format("h:mm")
        },

        {
          Header: "ETA MSA",
          id: "etaMSA",
          accessor: d => moment(d.etaMSA).format("DD-MMM-YY")
        },
        {
          Header: "GATE IN MSA",
          id: "arrivalDateMSA",
          accessor: d => moment(d.arrivalDateMSA).format("DD-MMM-YY")
        },
        {
          Header: "TIME",
          id: "inTimeMsa",
          accessor: d => moment(d.inTimeMsa).format("h:mm")
        },
        {
          Header: "WAGON NO.",
          id: "wagonNum",
          accessor: d => d.wagonNum
        },
        {
          Header: "ModeOfTrans",
          id: "modeOfTransportOut",
          accessor: d => d.modeOfTransportOutMsa
        },
        {
          Header: "TypeOfTrans",
          id: "transportTypeOut",
          accessor: d => d.transportTypeOutMsa
        },
        {
          Header: "CarrierID",
          id: "carrierIDOutMsa",
          accessor: d => d.carrierIDOutMsa
        },
        {
          Header: "ModeOfTransID",
          id: "modeOfTransportIdOutMsa",
          accessor: d => d.modeOfTransportIdMsa
        },
        {
          Header: "POSITION",
          id: "position",
          accessor: d => d.position
        },
        {
          Header: "DATE DELIVERED",
          id: "dateDelivered",
          accessor: d => moment(d.dateDelivered).format("DD-MMM-YY"),
          Cell: this.renderEditable
        },
        {
            Header: "TIME DELIVERED",
            id: "dateDelivered",
            accessor: d => moment(d.dateDelivered).format("h:mm"),
            Cell: this.renderEditable
          },
        {
          Header: "D/NOTE NO.",
          id: "deliveryNoteID",
          accessor: d => d.deliveryNoteID
        },
        {
          Header: "Client",
          id: "clientCode",
          accessor: d => d.clientCode
        }
      ];
      return (
        <>
          <Header as="h3" color="green" textAlign="center">
            Export CODECO File Report
          </Header>
          <Segment.Group horizontal>
            <Segment></Segment>
            <Segment></Segment>
            <Segment>
              <Dropdown
                value={this.state.postition}
                search
                selection
                options={positionOptions}
                onChange={this.onPostitionChange.bind(this)}
              />
            </Segment>
          </Segment.Group>
          <hr color="pink" />
          <ReactTable
            columns={columns}
            data={data}
            pageSizeOptions={[
              5,
              10,
              20,
              25,
              50,
              100,
              250,
              500,
              1000,
              2000,
              3000
            ]}
            sortable
            filterable
            defaultPageSize={5}
            noDataText={"Loading ..."}
            className="-striped -highlight"
          >
            {(state, filteredData, instance) => {
              this.ReactTable = state.pageRows.map(data => {
                return data._original;
              });
              return (
                <div>
                  {filteredData()}
                  <ExportCMAToExcel data={this.ReactTable} />
                </div>
              );
            }}
          </ReactTable>
        </>
      );
    } else {
      return <LoadingMessage />;
    }
  }
}

export default withQuery(CMACodecoReport, getCodecoFields, "getCodecoFields");
