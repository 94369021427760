import React, { Component } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";

import LoadingMessage from "../shared/LoadingMessage";
import withQuery from "../HOC/withQuery";

import getTruckers from "./Queries/fetchTruckers";
class TruckList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [...this.props.entries],
      pageNum: 1
    };
  }
  render() {
    console.log(this.props);
    if (this.state.data) {
      const { data } = this.state;

      const CaptionElement = () => (
        <h3
          style={{
            borderRadius: "0.25em",
            textAlign: "center",
            color: "green",
            border: "1px solid green",
            padding: "0.5em"
          }}
        >
          Trucker List
        </h3>
      );
      const columns = [
        {
          text: "Name ",
          dataField: "truckerName",
          sort: true
        },
        {
          text: "Long Name ",
          dataField: "truckerLongName",
          sort: true
        },

        {
          text: "Code",
          dataField: "truckerCode",
          sort: true
        },
        {
          text: "Phone No.",
          dataField: "telNo",
          sort: true
        }
      ];
      const defaultSorted = [
        {
          dataField: "truckerCode",
          order: "desc"
        }
      ];
      const { SearchBar } = Search;
      const pagination = paginationFactory({
        sizePerPage: 5
      });
      return (
        <>
          <CaptionElement />

          <ToolkitProvider
            keyField="id"
            data={data}
            columns={columns}
            defaultSorted={defaultSorted}
            search={{ defaultSearch: "" }}
          >
            {props => (
              <div>
                <div align="right">
                  <SearchBar {...props.searchProps} />
                </div>
                <hr />
                <BootstrapTable
                  {...props.baseProps}
                  striped
                  hover
                  condensed
                  pagination={pagination}
                  noDataIndication="Table is Empty"
                />
              </div>
            )}
          </ToolkitProvider>
        </>
      );
    } else {
      return <LoadingMessage />;
    }
  }
}

export default withQuery(TruckList, getTruckers, "getAllTruckers");
