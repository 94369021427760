import gql from "graphql-tag"
import React, { useState } from "react"
import { Form, Button, Grid, Header, Divider } from "semantic-ui-react"
import { graphql } from "react-apollo"
import DatePicker from "react-datepicker"
import TimePicker from "rc-time-picker"
import "rc-time-picker/assets/index.css"
import "react-datepicker/dist/react-datepicker.css"
import moment from "moment"

import InlineError from "../../shared/InlineError"

const _ = require("lodash")
function OpenVoyage(props) {
  console.log(props)
  const [actual_arrival, setDeparture] = useState()
  const [actual_arrival_time, setDepartureTime] = useState()
  const [loading, setLoading] = useState(false)
  const [errors, setErrors] = useState({})

  const submit = (e, data) => {
    setLoading(true)

    const errors = validate()
    console.log(errors)
    setErrors((prevState) => ({ ...prevState, ...errors }))
    setLoading(false)
  }

  const validate = async () => {
    if (!actual_arrival) errors.actual_arrival = "Can't be blank"
    if (!actual_arrival_time) errors.actual_arrival_time = "Can't be blank"

    if (Object.keys(errors).length === 0) {
      setLoading(true)

      await props.openVesselVoyage({
        variables: {
          voyage_id: props.match.params.voyage_id,
          actual_departure_time: moment(actual_arrival_time).format("HH:mm"),
          actual_departure: moment(actual_arrival).format("YYYY-MM-DD"),
        },
      })
      props.history.push("/prestack/operations/voyages")
    }

    return errors
  }

  return (
    <Grid textAlign="center" style={{ height: "100%" }} verticalAlign="top">
      <Grid.Column style={{ maxWidth: 600 }}>
        <Header as="h1" color="green">
          Enter Other Details
        </Header>
        <Divider />
        <Form size="large" textAlign="center">
          <Form.Field error={!!errors.actual_arrival}>
            <label>Arrival Date</label>{" "}
            <DatePicker selected={actual_arrival} onChange={setDeparture} />
          </Form.Field>
          <Form.Field error={!!errors.actual_arrival_time}>
            <label>Arrival Time</label>

            <TimePicker
              // style={{ width: 100 }}
              showSecond={false}
              defaultValue={moment()}
              className="xxx"
              onChange={setDepartureTime}
            />
            {errors.actual_arrival_time && (
              <InlineError text={errors.actual_arrival_time} />
            )}
          </Form.Field>
          <Form.Button fluid positive onClick={submit}>
            Submit
          </Form.Button>
        </Form>
      </Grid.Column>
    </Grid>
  )
}

const openVesselVoyage = gql`
  mutation openVesselVoyage(
    $voyage_id: String
    $actual_arrival_time: String
    $actual_arrival: String
  ) {
    openVesselVoyage(
      voyage_id: $voyage_id
      actual_arrival_time: $actual_arrival_time
      actual_arrival: $actual_arrival
    )
  }
`
export default graphql(openVesselVoyage, { name: "openVesselVoyage" })(
  OpenVoyage
)
