import React, { Component } from "react";
import {
  Grid} from "semantic-ui-react";
import gql from "graphql-tag";
import { graphql } from "react-apollo";

import getAllMobilePreadvices from "./Queries/fetchMobilePreadvises";
import PermissionsMessage from "../shared/PermissionsMessage";
import LogisticalDetails from "./LogisticalDetails";
import ShippingDetails from "./ShippingDetails";
var EQStatusOptions = [
  { text: "Export", value: "Export" },
  { text: "Import", value: "Import" }
];
var FEStatusOptions = [
  { text: "Empty", value: "Empty" },
  { text: "Full", value: "Full" }
];

var POLOptions = [
  { text: "KEMBA", value: "KEMBA" },
  { text: "KEEMK", value: "KEEMK" },
  { text: "KERNA", value: "KERNA" }
];

var PODOptions = [
  { text: "KEMBA", value: "KEMBA" },
  { text: "KEEMK", value: "KEEMK" },
  { text: "KERNA", value: "KERNA" }
];
class AddShippingDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: parseFloat(this.props.match.params.id),
      step: 1,
      weight: "",
      eQStatus: "",
      fEStatus: "",
      pOL: "",
      pOD: "",
      preAdviseNum: "",
      preadviseDate:"",
      fileBillingNumber: "",
      sOExpiryDate: "",
      loading: false,
      errors: {}
    };
  }
  validate = () => {
    console.log("VALIDATING")
    const errors = {};
    if (!this.state.weight) errors.weight = "Can't be blank";
    if (!this.state.eQStatus) errors.eQStatus = "Can't be blank";
    if (!this.state.fEStatus) errors.fEStatus = "Can't be blank";
    if (!this.state.pOD) errors.pOD = "Can't be blank";
    if (!this.state.pOL) errors.pOL = "Can't be blank";
    if (!this.state.vessel) errors.vessel = "Can't be blank";
    if (!this.state.sOExpiryDate) errors.sOExpiryDate = "Can't be blank";
    if (!this.state.preadviseDate) errors.preadviseDate = "Can't be blank";
    // if (!this.state.preAdviseNum) errors.preAdviseNum = "Can't be blank";
    // if (!this.state.fileBillingNumber)
    //   errors.fileBillingNumber = "Can't be blank";
      console.log("ERRORS", errors)
    if (Object.keys(errors).length === 0) {
      this.setState({ loading: true });
      this._updateAdmin();
    }
    return errors;
  };
  onSubmit = () => {
    const errors = this.validate();
    this.setState({ errors });
  };
  handlesPreadviseDateChange = date => {
    this.setState({ preadviseDate: date });
  };
  handlefileBillingNumberChange = date => {
    this.setState({ fileBillingNumber: date });
  };
  handlesOExpiryDateChange = date => {
    this.setState({ sOExpiryDate: date });
  };
  setOptionsValue = (e, data) => {
    const { name, value } = data;
    this.setState({ [name]: value });
  };

  prevStep = () => {
    const { step } = this.state;
    this.setState({
      step: step - 1
    });
  };
 handleChange = input=>event=>{
  this.setState({ [input]: event.target.value });
 }
 setFieldValueOnChange = async (fieldName, value) => {
    await this.setState({ [fieldName]: value });
  };
  nextStep = () => {
    const { step } = this.state;
    this.setState({
      step: step + 1
    });
  };
  prevStep = () => {
    const { step } = this.state;
    this.setState({
      step: step - 1
    });
  };
  render() {
    if (!this.props.permissions) return <PermissionsMessage />;
    
    const { step } = this.state;

    switch(step){
      case 1:
        return(
          <Grid
          textAlign="center"
          verticalAlign="top"
          style={{ height: "100%" }}
        >
            <LogisticalDetails
              EQStatusOptions={EQStatusOptions}
              FEStatusOptions={FEStatusOptions}
              POLOptions={POLOptions}
              PODOptions={PODOptions}
              values={this.state}
              setOptionsValue={this.setOptionsValue}
              errors={this.state.errors}
              nextStep={this.nextStep}
              prevStep={this.prevStep}
            />
          </Grid>
        )
      case 2: 
        return (
          <Grid
          textAlign="center"
          verticalAlign="top"
          style={{ height: "100%" }}
        >
          <ShippingDetails
              values={this.state}
              errors={this.state.errors}
              state={this.state}
              handleChange={this.handleChange}
              handlesPreadviseDateChange={this.handlesPreadviseDateChange}
              handlesOExpiryDateChange={this.handlesOExpiryDateChange}
              nextStep={this.nextStep}
              prevStep={this.prevStep}
              onSubmit={this.onSubmit}
            />
          </Grid>
        )
        default:
          return (
            <Grid
              textAlign="center"
              style={{ height: "100%" }}
              verticalAlign="middle"
            >
              Loading ...
            </Grid>
          );
    }
    
  }
  _updateAdmin = async () => {
    const { 
      weight, eQStatus, fEStatus, pOL,
      pOD, vessel, preadviseDate } = this.state;

    await this.props.addShippingContainerDetails({
      variables: {
        id: this.props.match.params.id,
        weight,
        eQStatus,
        fEStatus,
        pOL,
        pOD,
        vessel,
       
        preadviseDate
      },

      refetchQueries: [{ query: getAllMobilePreadvices }]
    });
    this.props.history.push("/clienttransaction/list");
  };
}

const addShippingContainerDetails = gql`
mutation addShippingContainerDetails(
  $id: ID!
  $weight: String
  $eQStatus: String
  $fEStatus: String
  $pOL: String
  $pOD: String
  $vessel: String
  $preadviseDate: String
) {
  addShippingContainerDetails(
    id: $id
    weight: $weight
    eQStatus: $eQStatus
    fEStatus: $fEStatus
    pOL: $pOL
    pOD: $pOD
    vessel: $vessel
    preadviseDate: $preadviseDate
  ) {
    ok
    message
  }
}

`;

export default graphql(addShippingContainerDetails, { name: "addShippingContainerDetails" })(AddShippingDetails);
