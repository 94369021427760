
import  gql  from 'graphql-tag';
import React, { Component } from 'react';
import { graphql } from 'react-apollo'
import {flowRight as compose} from 'lodash';
import { withRouter } from 'react-router';
import { Table, Message, Icon, Button } from 'semantic-ui-react'

import getPreadviseByContainerFileName from './Queries/fetchOrderContainers'
class  BookedContainers extends Component {
  constructor(props) {
    super(props);
    this.state = {  }
  }
  onSubmit =()=>{
    this._validatePreadvise()
  }
  _validatePreadvise = async()=>{
    const containerFileName=this.props.containerFileName
    await this.props.validatePreadvise({
      variables:{containerFileName}
    }).then((result) => {
      console.log(result);
    })
    .catch((error) => {
      console.log(error);
    });
    this.props.history.push("/bookings/list")
  }
  render() { 
    const loadingMessage = (
      <Message icon info>
        <Icon name="circle notched" loading />
        <Message.Content>
          <Message.Header>Just one second</Message.Header>
          We are fetching that content for you.
        </Message.Content>
      </Message>
    );

    const emptyMessage = (
      <Message icon info>
        <Icon name="warning circle" />
        <Message.Content>
          <Message.Header>No Containers Found</Message.Header>
          
        </Message.Content>
      </Message>
    );

    const timeoutMessage = (
      <Message icon negative>
        <Icon name="wait" />
        <Message.Content>
          <Message.Header>{this.props.getPreadviseByContainerFileName.errorl}</Message.Header>
          Is the backend server running?
        </Message.Content>
      </Message>
    );
    if (this.props.getPreadviseByContainerFileName && this.props.getPreadviseByContainerFileName.loading) {
      return <div>{loadingMessage}</div>;
    }

    if (this.props.getPreadviseByContainerFileName && this.props.getPreadviseByContainerFileName.error) {
      return <div>{timeoutMessage}</div>;
    }

    if (this.props.getPreadviseByContainerFileName.getPreadviseByContainerFileName.length === 0) {
      return <div>{emptyMessage}</div>;
    }

    return ( 
      <>
    <Table color="teal">
    <Table.Header>
      <Table.Row>
      <Table.HeaderCell>Client</Table.HeaderCell>
        <Table.HeaderCell>Operator</Table.HeaderCell>
        <Table.HeaderCell>Container Number</Table.HeaderCell>
        <Table.HeaderCell>Container Size</Table.HeaderCell>
      </Table.Row>
    </Table.Header>

    <Table.Body>
    {this.props.getPreadviseByContainerFileName.getPreadviseByContainerFileName.map(item => (
              <Table.Row>
              <Table.Cell>{item.clientCode}</Table.Cell>
              <Table.Cell>{item.operatorCode}</Table.Cell>
              <Table.Cell>{item.containerNum}</Table.Cell>
              <Table.Cell>{item.containerSize}</Table.Cell>
            </Table.Row>
 
          ))}
    
    </Table.Body>
  </Table> 
  <Button 
  fluid 
  positive
  onClick={this.onSubmit}

  >Validate Request</Button> 
  </>
  );
  }
}

const  validatePreadvise = gql`
mutation validatePreadvise($containerFileName: String!) {
  validatePreadvise(containerFileName: $containerFileName)
}
`;
 export default compose(
  graphql(validatePreadvise, {name:"validatePreadvise"}),
  graphql(getPreadviseByContainerFileName, {
  name:"getPreadviseByContainerFileName", 
  options: ({containerFileName }) => ({ variables: { containerFileName:containerFileName } })
 }))(withRouter(BookedContainers))
