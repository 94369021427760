import React, { Component } from "react"
import moment from "moment"
import ReactHtmlTableToExcel from "react-html-table-to-excel"

class ExportToExcel extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }
  render() {
    return (
      <div stylee={{ marginRight: "25px" }}>
        <ReactHtmlTableToExcel
          id="test-table-xls-button"
          className="export"
          table="table-to-xls"
          fileName="filteredData"
          sheet="tablexls"
          buttonText="Export"
        />
        <table hidden="true" id="table-to-xls">
          <thead>
            <tr>
              <th>No.</th>
              <th>CONTAINER NO.</th>
              <th>SIZE</th>
              <th>Weight</th>
              <th>EQStatus</th>
              <th>FEStatus</th>
              <th>POL</th>
              <th>POD</th>
              {/* <th>S/O EXPIRY DATE</th> */}
              <th>VESSEL</th>
              <th>REF NO.</th>
              <th>PRE-ADVISE DATE</th>
              <th>PARTY DELIVERING</th>
              <th>GATE IN ICD</th>
              <th>TIME</th>
              <th>BBL EIR NO. </th>
              <th>ETD STACK</th>
              <th>GATE OUT ICD</th>
              <th>TIME</th>
              <th>ModeOfTrans</th>
              <th>TypeOfTrans</th>
              <th>CarrierID</th>
              <th>ModeOfTransID</th>
              <th>POSITION</th>
              <th>DATE LOADED</th>
              <th>TIME LOADED</th>
              {/* <th>D/NOTE NO.</th>
              <th>REMARKS</th> */}
            </tr>
          </thead>
          <tbody>
            {this.props.data.map((preadvise, index) => {
              return (
                <tr key={preadvise.id}>
                  <td>{index + 1}</td>
                  <td>{preadvise.containerNum}</td>
                  <td>{preadvise.containerSize}</td>
                  <td>{preadvise.weight}</td>
                  <td>{preadvise.eqstatus}</td>
                  <td>{preadvise.festatus}</td>
                  <td>{preadvise.pol}</td>
                  <td>{preadvise.pod}</td>
                  {/* <td>{moment(preadvise.sOExpiryDate).format("DD.MM.YYYY")}</td> */}
                  <td>{preadvise.vessel_code}</td>
                  <td>{preadvise.reference_no}</td>
                  <td>
                    {moment(preadvise.preadviseDate).format("DD.MM.YYYY")}
                  </td>
                  <td>{preadvise.partyDeliveringName}</td>
                  <td>
                    {preadvise.gate_in_stack !== "Invalid date" &&
                    preadvise.gate_in_stack !== null
                      ? moment(preadvise.gate_in_stack).format("DD-MMM-YYYY")
                      : ""}
                  </td>
                  <td>
                    {preadvise.gate_in_time !== "Invalid date" &&
                    preadvise.gate_in_time !== null
                      ? moment(preadvise.gate_in_time).format("hh:mm")
                      : ""}
                  </td>
                  <td>{preadvise.bblEIR}</td>
                  <td>{preadvise.gate_in_stack}</td>
                  <td>
                    {preadvise.date_out !== "Invalid date" &&
                    preadvise.date_out !== null
                      ? moment(preadvise.date_out).format("DD-MMM-YYYY")
                      : ""}
                  </td>
                  <td>
                    {preadvise.date_out !== "Invalid date" &&
                    preadvise.date_out !== null
                      ? moment(preadvise.date_out).format("hh:mm")
                      : ""}
                  </td>
                  <td>{preadvise.mode_of_transport}</td>
                  <td>{preadvise.type_of_transport}</td>
                  <td>{preadvise.carrier_id}</td>
                  <td>{preadvise.mode_of_transport_id}</td>
                  <td>{preadvise.bay_position}</td>
                  <td>
                    {preadvise.date_loaded !== "Invalid date" &&
                    preadvise.date_loaded !== null &&
                    preadvise.date_loaded !== ""
                      ? moment(preadvise.date_loaded).format("DD-MMM-YYYY")
                      : ""}
                  </td>
                  <td>
                    {preadvise.time_loaded !== "Invalid date" &&
                    preadvise.time_loaded !== null
                      ? moment(preadvise.time_loaded).format("hh:mm")
                      : ""}
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
    )
  }
}

export default ExportToExcel
