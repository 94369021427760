import Moment from "moment";
import { extendMoment } from "moment-range";
const moment = extendMoment(Moment);
export const columns = [
  {
    Header: "Client",
    id: "clientCode",
    accessor: d => d.clientCode
  },
  {
    Header: "File Billing No.",
    id: "fileBillingNumber",
    accessor: d => d.fileBillingNumber
  },
  {
    Header: "REF NO.",
    id: "preAdviseNum",
    accessor: d => d.preAdviseNum
  },
  {
    Header: "CONTAINER NO.",
    id: "containerNum",
    accessor: d => d.containerNum,
    filter: true
  },

  {
    Header: "SIZE",
    id: "containerSize",
    accessor: d => d.containerSize
  },
  {
    Header: "Operator",
    id: "operatorCode",
    accessor: d => d.operatorCode
  },

  {
    Header: "IN DATE ICD",
    id: "inDateICD",
    accessor: d =>
      d.inDateICD !== "Invalid date" && d.inDateICD !== null
        ? moment(d.inDateICD).format("DD-MMM-YY")
        : ""
  },

  {
    Header: "BBL EIR",
    id: "bblEIR",
    accessor: d => d.bblEIR
  },

  {
    Header: "ETD NBO",
    id: "inDateICD",
    accessor: d =>
      d.inDateICD !== "Invalid date" && d.inDateICD !== null
        ? moment(d.inDateICD).format("DD-MMM-YY")
        : ""
  },

  {
    Header: "ETA MSA",
    id: "etaMSA",

    accessor: d =>
      d.etaMSA !== "Invalid date" && d.etaMSA !== null
        ? moment(d.etaMSA).format("DD-MMM-YY")
        : ""
  },

  {
    Header: "WAGON NO",
    id: "wagonNum",
    accessor: d => d.wagonNum
  },
  {
    Header: "STATUS",
    id: "status",
    accessor: d => d.status
  },

  {
    Header: "GUARANTEE FORM",
    id: "guaranteeForm",
    accessor: d =>
      d.guaranteeForm !== "Invalid date" &&
      d.guaranteeForm !== null &&
      d.guaranteeForm !== ""
        ? moment(d.guaranteeForm).format("DD-MMM-YY")
        : ""
  },
  {
    Header: "GUARANTEE FORM VALIDITY",
    id: "guaranteeFormValidity",
    accessor: d =>
      d.guaranteeFormValidity !== "Invalid date" &&
      d.guaranteeFormValidity !== null &&
      d.guaranteeFormValidity !== ""
        ? moment(d.guaranteeFormValidity).format("DD-MMM-YY")
        : ""
  },
  {
    Header: "POSITION",
    id: "position",
    accessor: d => d.position
  },
  {
    Header: "DATE DELIVERED",
    id: "dateDelivered",
    accessor: d =>
      d.dateDelivered !== "Invalid date" &&
      d.dateDelivered !== null &&
      d.dateDelivered !== ""
        ? moment(d.dateDelivered).format("DD-MMM-YY")
        : ""
  },

  {
    Header: "DELIVERY NOTE",
    id: "deliveryNoteID",
    accessor: d => d.deliveryNoteID
  },
  {
    Header: "TRUCKER",
    id: "truckerName",
    accessor: d => d.truckerName
  },
  {
    Header: "EIR NO",
    id: "eirNum",
    accessor: d => d.eirNum
  },
  {
    Header: "EIR DATE",
    id: "eirDate",
    accessor: d =>
      d.eirDate !== "Invalid date" && d.eirDate !== null && d.eirDate !== ""
        ? moment(d.eirDate).format("DD-MMM-YY")
        : ""
  },
  {
    Header: "INVOICE NO",
    id: "invoiceNum",
    accessor: d => d.invoiceNum
  }
];
