import React, { Component } from "react"
import {
  Form,
  Segment,
  Grid,
  Header,
  Message,
  Divider,
} from "semantic-ui-react"
import gql from "graphql-tag"
import { graphql } from "react-apollo"
import moment from "moment"

import InlineError from "../../shared/InlineError"
// import getAllPreadvices from "./Queries/fetchAllPreadvices";
import PermissionsMessage from "../../shared/PermissionsMessage"

class AddPrestackPreadviseDetails extends Component {
  constructor(props) {
    super(props)
    this.state = {
      id: parseFloat(this.props.match.params.id),

      preAdviseNum: "",
      fileBillingNumber: "",

      loading: false,
      errors: {},
    }
  }
  validate = () => {
    const errors = {}

    if (!this.state.preAdviseNum) errors.preAdviseNum = "Can't be blank"
    if (!this.state.fileBillingNumber)
      errors.fileBillingNumber = "Can't be blank"
    if (Object.keys(errors).length === 0) {
      this.setState({ loading: true })
      this._updateAdmin()
    }
    return errors
  }
  onSubmit = () => {
    const errors = this.validate()
    this.setState({ errors })
  }

  handlefileBillingNumberChange = (date) => {
    this.setState({ fileBillingNumber: date })
  }
  render() {
    // if (!this.props.permissions) return <PermissionsMessage />;
    const { errors, loading } = this.state
    return (
      <Grid
        textAlign="center"
        style={{ height: "100%" }}
        verticalAlign="middle"
      >
        <Grid.Column style={{ maxWidth: 600 }}>
          <Header as="h2" color="green" textAlign="center">
            Add Preadvise Details
          </Header>
          <Divider />
          <Form size="large" onSubmit={this.onSubmit} loading={loading}>
            {errors.global && (
              <Message negative>
                <Message.Header> Something went wrong </Message.Header>
                <p>{errors.global}</p>
              </Message>
            )}

            <Form.Field error={!!errors.fileBillingNumber}>
              <label>File Billing Number</label>{" "}
              <input
                type="text"
                value={this.state.fileBillingNumber}
                rows={2}
                onChange={(e) =>
                  this.setState({ fileBillingNumber: e.target.value })
                }
              />
            </Form.Field>
            <Form.Field error={!!errors.preAdviseNum}>
              <label>Preadvise Number</label>
              <input
                type="text"
                value={this.state.preAdviseNum}
                rows={2}
                onChange={(e) =>
                  this.setState({ preAdviseNum: e.target.value })
                }
              />
              {errors.preAdviseNum && (
                <InlineError text={errors.preAdviseNum} />
              )}
            </Form.Field>
            <Form.Button fluid positive>
              Submit
            </Form.Button>
            <Segment stacked />
          </Form>
        </Grid.Column>
      </Grid>
    )
  }
  _updateAdmin = async () => {
    const { fileBillingNumber, preAdviseNum } = this.state
    await this.props.addDetails({
      variables: {
        id: this.props.match.params.id,
        reference_no: fileBillingNumber,
        preadvise_date: moment().format(),
      },

      refetchQueries: [
        {
          query: gql`
            query getAllDepotPreadvices {
              getAllDepotPreadvices {
                id
                partyDelivering {
                  party_delivering_id
                  party_delivering_name
                }
                voyage {
                  vesselName
                }
                container {
                  container_number
                  operator {
                    operator_id
                    operator_code
                  }
                }
                preadvise_date
                reference_no
              }
            }
          `,
        },
      ],
    })
    this.props.history.push("/prestack/preadvise/list")
  }
}

const addDetails = gql`
  mutation addContainer(
    $id: String
    $reference_no: String
    $preadvise_date: String
  ) {
    updateDepotStack(
      id: $id
      reference_no: $reference_no
      preadvise_date: $preadvise_date
    )
  }
`

export default graphql(addDetails, { name: "addDetails" })(
  AddPrestackPreadviseDetails
)
