import React, { Component } from "react"
import { Segment, Divider, Grid } from "semantic-ui-react"
import { Redirect } from "react-router-dom"
import { connect } from "react-redux"
import Routes from "./HomeRoutes/index"
import Banner from "./Banner"
import isLoggedIn from "./utils/is_logged_in"
import isConfirmed from "./utils/is_confirmed"
import SideBarMenu from "./Sidebar"

import MobileSideBarMenu from "./Mobilesidebar"
import PrestackSidebar from "./PrestackSidebar"

class LandingPage extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }
  WebRoutes = () => (
    <Segment>
      <Banner props={this.props} />
      <Divider clearing />
      <Grid celled>
        <Grid.Row>
          <Grid.Column width={2}>
            <SideBarMenu />
          </Grid.Column>
          <Grid.Column width={14}>
            <Routes />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Segment>
  )
  AppRoutes = () => (
    <Segment>
      <Banner props={this.props} />
      <Divider clearing />
      <Grid celled>
        <Grid.Row>
          <Grid.Column width={2}>
            <MobileSideBarMenu />
          </Grid.Column>
          <Grid.Column width={14}>
            <Routes />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Segment>
  )
  render() {
    console.log(this.props)
    const paths = this.props.location.pathname.split("/")
    console.log("PATHS:::", paths)
    if (paths[1] === "selfservice") {
      // if (!isLoggedIn()) {

      //   return <Redirect to='/selfservice/login'/>
      // }
      // console.log("USER LENGTH:::::", Object.keys(this.props.user).length ===0)
      if (Object.keys(this.props.user).length === 0) {
        console.log("Credentials not found")
        return <Redirect to="/selfservice/login" />
      } else if (!this.props.user.user.user.authorized) {
        console.log("User not authorized")
        return <Redirect to="/selfservice/login" />
      } else if (!this.props.user.user.user.confirmed) {
        console.log("User not confirmed")
        return (
          <Redirect
            to={{
              pathname: "/selfservice/login",
              state: { message: "Pending Account! Please verify your email." },
            }}
          />
        )
      }
    } else {
      // console.log("THIS USER IS NOT MOBILE:::::", Object.keys(this.props.user.mobileUser).length === 0 && this.props.user.mobileUser.constructor === Object)

      if (!isLoggedIn()) {
        return <Redirect to="/login" />
      }
      if (!isConfirmed()) {
        return <Redirect to="/change-password" />
      }
    }

    return (
      <Segment>
        <Banner props={this.props} />
        <Divider clearing />
        <Grid celled>
          <Grid.Row>
            <Grid.Column width={3}>
              {paths[1] === "selfservice" ? (
                <MobileSideBarMenu />
              ) : (
                <SideBarMenu
                  department={this.props.user.user.user.department}
                />
              )}
              {/* <PrestackSidebar/> */}
            </Grid.Column>
            <Grid.Column width={13}>
              <Routes />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
  }
}
export default connect(mapStateToProps, null)(LandingPage)
