import React, { Component } from "react"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory from "react-bootstrap-table2-paginator"
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import cellEditFactory from "react-bootstrap-table2-editor"

import LoadingMessage from "../shared/LoadingMessage"
import withQuery from "../HOC/withQuery"
import axios from "axios"
import getMobilePdfContainers from "./Queries/fetchMobileContainerDetails"
import { saveAs } from "file-saver"

class MobileOrdersArrivalsMsaList extends Component {
  constructor(props) {
    super(props)
    this.state = {
      data: [...this.props.entries],
      pageNum: 1,
    }
  }
  createAndDownloadPdf = (data) => {
    console.log(data)
    data.sendemail = false
    axios
      .post("https://cts.hawilisha.com/pdf/create-pdf", data)
      .then(
        () =>
          axios(`https://cts.hawilisha.com/pdf/fetch-pdf/${data.bblEIR}`, {
            method: "GET",
            responseType: "blob", //Force to receive data in a Blob Format
          })
        // axios.get("http://64.227.21.145/:5000/fetch-pdf", {
        //   responseType: "blob",
        // })
      )
      .then((res) => {
        const pdfBlob = new Blob([res.data], { type: "application/pdf" })
        saveAs(pdfBlob, "newPdf.pdf")
      })
      .catch((error) => {
        console.log(error)
      })
  }
  render() {
    console.log(this.state)
    if (this.state.data) {
      const { data } = this.state
      const CaptionElement = () => (
        <h3
          style={{
            borderRadius: "0.25em",
            textAlign: "center",
            color: "green",
            border: "1px solid green",
            padding: "0.5em",
          }}
        >
          Recieved Containers
        </h3>
      )
      const columns = [
        {
          text: "Container Number",
          dataField: "containerNum",
          sort: true,
        },

        {
          text: "Client Code.",
          dataField: "clientCode",
          sort: true,
          editable: true,
        },

        {
          text: "Operator Code.",
          dataField: "operatorCode",
          sort: true,
          editable: true,
        },

        {
          text: "",
          dataField: "df",
          isDummyField: true,
          formatter: (cellContent, row) => {
            return (
              <button
                class="ui primary basic button"
                onClick={() => this.createAndDownloadPdf(row)}
              >
                View PDF
              </button>
            )
          },
        },
      ]
      const defaultSorted = [
        {
          dataField: "DamageCode",
          order: "desc",
        },
      ]
      const { SearchBar } = Search
      const pagination = paginationFactory({
        sizePerPage: 5,
      })
      return (
        <>
          <CaptionElement />

          <ToolkitProvider
            keyField="id"
            data={data}
            columns={columns}
            defaultSorted={defaultSorted}
            search={{ defaultSearch: "" }}
          >
            {(props) => (
              <div>
                <div align="right">
                  <SearchBar {...props.searchProps} />
                </div>
                <hr />
                <BootstrapTable
                  {...props.baseProps}
                  striped
                  hover
                  condensed
                  pagination={pagination}
                  noDataIndication="Table is Empty"
                  cellEdit={cellEditFactory({
                    mode: "dbclick",
                    blurToSave: true,
                    beforeSaveCell: (oldValue, newValue, row, column) => {
                      console.log("column: ", column)
                      console.log("Before Saving Cell!!:", oldValue)
                      console.log("New Value: ", newValue)
                      console.log("Id: ", row.id)
                      this._editPreadvise(newValue, column, row)
                    },
                  })}
                />
              </div>
            )}
          </ToolkitProvider>
        </>
      )
    } else {
      return <LoadingMessage />
    }
  }
}

export default withQuery(
  MobileOrdersArrivalsMsaList,
  getMobilePdfContainers,
  "getMobilePdfContainers"
)
