import React, { useState } from "react"
import { graphql } from "react-apollo"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory from "react-bootstrap-table2-paginator"
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import * as XLSX from "xlsx"
import { Link } from "react-router-dom"
import { Icon } from "semantic-ui-react"
import withQuery from "../../HOC/withQuery"
import gql from "graphql-tag"
function ShutList(props) {
  const [items, setItems] = useState([...props.entries])
  const [pageNum, setPageNum] = useState(1)

  const CaptionElement = () => (
    <h3
      style={{
        borderRadius: "0.25em",
        textAlign: "center",
        color: "green",
        border: "1px solid green",
        padding: "0.5em",
      }}
    >
      Shutouts
    </h3>
  )
  const { SearchBar, ClearSearchButton } = Search
  const columns = [
    {
      text: "Party Delivering",
      dataField: "partyDelivering.party_delivering_name",
      sort: true,
      headerStyle: {
        backgroundColor: "#c8e6c9",
      },
    },
    {
      text: "Vessel",
      dataField: "voyage.vesselName",
      sort: true,
      headerStyle: {
        backgroundColor: "#c8e6c9",
      },
    },
    {
      text: "Container",
      dataField: "container.container_number",
      sort: true,
      headerStyle: {
        backgroundColor: "#c8e6c9",
      },
    },
    {
      text: "Operator",
      dataField: "container.operator.operator_code",
      sort: true,
      headerStyle: {
        backgroundColor: "#c8e6c9",
      },
    },
    {
      text: "Preadvised",
      dataField: "preadvise_date",
      sort: true,
      headerStyle: {
        backgroundColor: "#c8e6c9",
      },
    },
    {
      text: "Action",
      dataField: "df",
      isDummyField: true,
      headerStyle: {
        backgroundColor: "#c8e6c9",
      },
      formatter: (cellContent, row) => {
        return (
          <Link to={`/prestack/operations/shutlist/${row.id}`}>
            Restow
            <Icon name="angle double right" color="green" />
          </Link>
        )
      },
    },
  ]
  const defaultSorted = [
    {
      dataField: "NO",
      order: "desc",
    },
  ]
  const pagination = paginationFactory({
    sizePerPage: 5,
  })

  return (
    <div>
      <CaptionElement />
      <ToolkitProvider
        keyField="NO"
        data={items}
        columns={columns}
        defaultSorted={defaultSorted}
        search={{ defaultSearch: "" }}
      >
        {(props) => (
          <div>
            <div>
              <div align="right">
                <SearchBar {...props.searchProps} />
                <ClearSearchButton {...props.searchProps} />
              </div>
            </div>
            <hr />
            <div>
              <BootstrapTable
                className="expanded-container"
                {...props.baseProps}
                striped
                hover
                condensed
                pagination={pagination}
                noDataIndication="Table is Empty"
              />
            </div>
          </div>
        )}
      </ToolkitProvider>
    </div>
  )
}
const getPartyDelivering = gql`
  query getAllPrestackOperator {
    getAllPrestackOperator {
      operator_id
      operator_name
      operator_code
    }
  }
`
const getVessels = gql`
  query {
    getAllVessels {
      vessel_id
      vessel_name
    }
  }
`
const shutOutStack = gql`
  query shutOutStack {
    shutOutStack {
      id
      partyDelivering {
        party_delivering_id
        party_delivering_name
      }
      voyage {
        vesselName
      }
      container {
        container_number
        operator {
          operator_id
          operator_code
        }
      }
      preadvise_date
      reference_no
    }
  }
`
export default withQuery(ShutList, shutOutStack, "shutOutStack")
