import React, { Component } from "react";
import gql from "graphql-tag";
import {
  Form,
  Segment,
  Grid,
  Header,
  Message,
  Divider
} from "semantic-ui-react";
import DatePicker from "react-datepicker";
import "rc-time-picker/assets/index.css";
import TimePicker from "rc-time-picker";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";
import { graphql } from "react-apollo";

import PermissionsMessage from "../shared/PermissionsMessage";
import InlineError from "../shared/InlineError";
import getArrivalContainers from "./Queries/fetchAllArrivalsMbs";

class ArrivalMbs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      arrivalDateMsa: "",
      inTimeMsa: "",
      arrivalStatus: true,
      errors: {},
      loading: false
    };
  }
  inTimeMsaChange = time => this.setState({ inTimeMsa: time });
  arrivalDateMsaChange = arrivalDateMsa => this.setState({ arrivalDateMsa });
  validate = () => {
    const errors = {};

    if (!this.state.arrivalDateMsa) errors.arrivalDateMsa = "Can't be blank";
    if (!this.state.inTimeMsa) errors.inTimeMsa = "Can't be blank";

    if (Object.keys(errors).length === 0) {
      this._addArrivals();
    }
    return errors;
  };
  onSubmit = () => {
    const errors = this.validate();

    this.setState({ errors });
  };
  render() {
    if (!this.props.permissions) return <PermissionsMessage />;
    const { errors, loading } = this.state;
    return (
      <>
        {" "}
        <Header as="h3" color="green" textAlign="center">
          Add Arrival Details
        </Header>
        <Form size="large" onSubmit={this.onSubmit} loading={loading}>
          {errors.global && (
            <Message negative>
              <Message.Header> Something went wrong </Message.Header>
              <p>{errors.global}</p>
            </Message>
          )}
          <Grid
            textAlign="center"
            style={{ height: "100%" }}
            columns={2}
            divided
          >
            {" "}
            <Grid.Row>
              <Grid.Column>
                <Divider />

                <Segment stacked>
                  <Form.Field error={!!errors.arrivalDateMsa}>
                    <label>Arrival Date</label>{" "}
                    <DatePicker
                      selected={this.state.arrivalDateMsa}
                      onChange={this.arrivalDateMsaChange}
                    />
                    {errors.arrivalDateMsa && (
                      <InlineError text={errors.arrivalDateMsa} />
                    )}
                  </Form.Field>
                  <Divider />
                  <Form.Field error={!!errors.inTimeMsa}>
                    <label>Arrival Time</label>

                    <TimePicker
                      // style={{ width: 100 }}
                      showSecond={false}
                      defaultValue={moment()}
                      className="xxx"
                      onChange={this.inTimeMsaChange}
                    />
                    {errors.inTimeMsa && (
                      <InlineError text={errors.inTimeMsa} />
                    )}
                  </Form.Field>
                  <Divider />
                </Segment>
              </Grid.Column>
            </Grid.Row>
          </Grid>
          <Form.Button fluid positive>
            Submit
          </Form.Button>
        </Form>
      </>
    );
  }
  _addArrivals = async () => {
    const { arrivalDateMsa, inTimeMsa, arrivalStatus } = this.state;

    await this.props.addMutation({
      variables: {
        id: this.props.match.params.id,
        arrivalDateMSA: arrivalDateMsa,
        inTimeMsa,
        arrivalStatus
      },
      refetchQueries: [{ query: getArrivalContainers }]
    });
    this.props.history.push("/arrivals/mbs/list");
  };
}

const addMutation = gql`
  mutation(
    $id: ID!
    $arrivalDateMSA: String!
    $inTimeMsa: String!
    $arrivalStatus: String!
  ) {
    arrivalMsa(
      id: $id
      arrivalStatus: $arrivalStatus
      inTimeMsa: $inTimeMsa
      arrivalDateMSA: $arrivalDateMSA
    )
  }
`;

export default graphql(addMutation, { name: "addMutation" })(ArrivalMbs);
