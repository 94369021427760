import gql from "graphql-tag"

export default gql`
  query getDepotPdfContainers {
    getDepotPdfContainers {
      id
      gate_in_time
      bbl_eir
      gate_in_stack
      pol
      container_number
      containerSize
      pod
      clientCode
      reference_no
      vessel_code
      mode_of_transport
      dispatch_driver_name
      partyDeliveringName
      operatorCode: clientCode
    }
  }
`
