import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter, Redirect } from "react-router-dom";

import { login } from "../Actions/auth";
import LoginForm from "../Authentication/LoginForm";
import is_logged_in from "../../utils/is_logged_in";
import is_confirmed from "../../utils/is_confirmed";


class LoginPage extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  submit = data => {
    console.log(data);

    this.props.login(data).then(data => {
      if (!is_logged_in()) {
        return <Redirect to="/login" />;
      } else if (!is_confirmed()) {
        this.props.history.push("/change-password");
        // return <Redirect to="/change-password" userId={userId} />;
      } else {
        this.props.history.push("/home");
      }
      console.log("Successfully Login");
    });
  };
  render() {
    return <LoginForm submit={this.submit} />;
  }
}

export default connect(
  null,
  { login }
)(withRouter(LoginPage));
