import React, { Component } from "react";
import { Segment, Header } from "semantic-ui-react";
import Chart from "react-google-charts";
import moment from "moment";
import { graphql } from "react-apollo";
import {flowRight as compose} from 'lodash';
import withQuery from "../modules/HOC/withQuery";
import getDaySummary from "./Queries/fetchSummary";
import gql from "graphql-tag";

class SummaryVisual extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [...this.props.entries],
    };
  }
  render() {
    const { data } = this.state;
    const { getMonthlyContainerSummary } = this.props.monthlysummary;
    if (getMonthlyContainerSummary) {
      getMonthlyContainerSummary[0].recieved.sort((a, b) =>
        a.month.localeCompare(b.month)
      );
      getMonthlyContainerSummary[0].delivered.sort((a, b) =>
        a.month.localeCompare(b.month)
      );
      getMonthlyContainerSummary[0].invoiced.sort((a, b) =>
        a.month.localeCompare(b.month)
      );
    }
    const { getDaysSinceReceipt } = this.props.getDaysSinceReceipt;

    var CurrentDate = moment().format("DD-MM-YYYY");
    console.log("Today:", moment().format("DD-MM-YYYY"));
    const info = [
      [
        "Position",
        "Expected",
        "ICD",
        "On Rail",
        "Mombasa",
        "Delivered",
        "Invoiced",
      ],
      [
        "Containers",
        parseInt(data[0].expected),
        parseInt(data[0].inicd),
        parseInt(data[0].onrail),
        parseInt(data[0].inmombasa),
        parseInt(data[0].delivered),
        parseInt(data[0].invoiced),
      ],
    ];

    if (getDaysSinceReceipt) {
      let lineTitle=[]
      lineTitle.push(["x", "No of Containers"])
      let valuesArr =[]
      for (let j=0; j<getDaysSinceReceipt.length; j++){
        valuesArr.push([parseInt(getDaysSinceReceipt[j].daysin), parseInt(getDaysSinceReceipt[j].numberofcontainers)])
      }
      var sinceRec = lineTitle.concat(valuesArr.reverse())
      console.log(sinceRec)
    }

    if (getMonthlyContainerSummary) {
      let toPlot = [["Months", "Recieved", "Delivered", "Invoiced"]];
      for (var i = 0; i < getMonthlyContainerSummary[0].invoiced.length; i++) {
        toPlot[i + 1] = [
          getMonthlyContainerSummary[0].invoiced[i].month,
          parseInt(getMonthlyContainerSummary[0].recieved[i].containers),
          parseInt(getMonthlyContainerSummary[0].delivered[i].containers),
          parseInt(getMonthlyContainerSummary[0].invoiced[i].containers),
        ];
      }
      if (
        getMonthlyContainerSummary[0].recieved.length >
        getMonthlyContainerSummary[0].delivered.length
      ) {
        toPlot[toPlot.length] = [
          moment(
            getMonthlyContainerSummary[0].recieved[
              getMonthlyContainerSummary[0].recieved.length - 1
            ].month
          ).format("MMM"),
          parseInt(
            getMonthlyContainerSummary[0].recieved[
              getMonthlyContainerSummary[0].recieved.length - 1
            ].containers
          ),
          0,
          0,
        ];
      }
      if (
        getMonthlyContainerSummary[0].recieved.length ===
          getMonthlyContainerSummary[0].delivered.length &&
        getMonthlyContainerSummary[0].delivered.length >
          getMonthlyContainerSummary[0].invoiced.length
      ) {
        toPlot[toPlot.length] = [
          moment(
            getMonthlyContainerSummary[0].recieved[
              getMonthlyContainerSummary[0].recieved.length - 1
            ].month
          ).format("MMM"),
          parseInt(
            getMonthlyContainerSummary[0].recieved[
              getMonthlyContainerSummary[0].recieved.length - 1
            ].containers
          ),
          parseInt(
            getMonthlyContainerSummary[0].delivered[
              getMonthlyContainerSummary[0].delivered.length - 1
            ].containers
          ),
          0,
        ];
      }

      var monthlyInvoiceData = toPlot;
    }
    let hold = [];
    if (monthlyInvoiceData) {
      const rest = monthlyInvoiceData.slice(1);
      const sorter = rest.sort((a, b) => new Date(a[0]) - new Date(b[0]));
      hold.push(monthlyInvoiceData[0]);
      //Made a change to return
      sorter.map((a) => {
        return hold.push([moment(a[0]).format("MMM"), a[1], a[2], a[3]]);
        
      });
    }
    const finalMonthlyInvoiceData = hold;
    return (
      <>
        <Header as="h2" color="green" textAlign="center">
          Dashboard
        </Header>
        <Segment>
          <Segment stacked>
            <Chart
              // width={"500px"}
              height={"450px"}
              chartType="Bar"
              loader={<div>Loading Chart</div>}
              data={info}
              options={{
                // Material design options
                chart: {
                  title: "Container Status",
                  subtitle: `${"Container Position as at " + CurrentDate}`,
                },
              }}
              // For tests
              rootProps={{ "data-testid": "2" }}
            />
          </Segment>
          <Segment stacked>
            <Chart
              height={"450px"}
              chartType="LineChart"
              loader={<div>Loading Chart</div>}
              data={sinceRec}
              options={{
                hAxis: {
                  title: "No of Days"
                },
                vAxis: {
                  title: "Containers "
                },
                chart: {
                  title: 'Container Ageing',
                  subtitle: 'in days',
                },
              }}
             
            />
          </Segment>
          <Segment stacked>
            <Chart
              height={"450px"}
              chartType="Bar"
              loader={<div>Loading Chart</div>}
              data={finalMonthlyInvoiceData}
              formatters={[
                {
                  type: "NumberFormat",
                  column: 1,
                  options: { fractionDigits: 0 },
                },
                {
                  type: "NumberFormat",
                  column: 2,
                  options: { fractionDigits: 0 },
                },
                {
                  type: "NumberFormat",
                  column: 3,
                  options: { fractionDigits: 0 },
                },
              ]}
              options={{
                // Material design options
                showRowNumber: true,
                chart: {
                  title: "Boxback Performance",
                  subtitle: `${
                    "Containers Recieved, Delivered and Invoiced as at " +
                    CurrentDate
                  }`,
                },
              }}
              // For tests
            />
          </Segment>
        </Segment>
      </>
    );
  }
}
const MONTHLYSUMMARY = gql`
  query getMonthlyContainerSummary {
    getMonthlyContainerSummary {
      recieved {
        month
        containers
      }
      delivered {
        month
        containers
      }
      invoiced {
        month
        containers
      }
    }
  }
`;
const CONTAINERSBYDAY = gql`
  query getDaysSinceReceipt {
    getDaysSinceReceipt {
      daysin
      numberofcontainers
    }
  }
`;
export default compose(
  graphql(MONTHLYSUMMARY, {
    name: "monthlysummary",
  }),
  graphql(CONTAINERSBYDAY, {
    name: "getDaysSinceReceipt",
  })
)(withQuery(SummaryVisual, getDaySummary, "getDaySummary"));
