import React, { Component } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import { Icon } from "semantic-ui-react";
import { Link } from "react-router-dom";

import LoadingMessage from "../shared/LoadingMessage";
import withQuery from "../HOC/withQuery";

import getGuarantee from "./Queries/fetchGuarantee";
class GuaranteeList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [...this.props.entries],
      pageNum: 1
    };
  }
  render() {
    if (this.state.data) {
      const { data } = this.state;
      console.log(data);
      const CaptionElement = () => (
        <h3
          style={{
            borderRadius: "0.25em",
            textAlign: "center",
            color: "green",
            border: "1px solid green",
            padding: "0.5em"
          }}
        >
          Guarantee List
        </h3>
      );
      const columns = [
        {
          text: "Client ",
          dataField: "clientCode",
          sort: true
        },
        {
          text: "Container No. ",
          dataField: "containerNum",
          sort: true
        },
        {
          text: "Preadvise No. ",
          dataField: "preAdviseNum",
          sort: true
        },

        {
          text: "Gate In Msa ",
          dataField: "arrivalDateMSA",
          sort: true
        },
        {
          text: "",
          dataField: "df",
          isDummyField: true,
          formatter: (cellContent, row) => {
            return (
              <Link to={`/guarantee/new/${row.id}`}>
                Add Guarantee
                <Icon name="angle double right" color="green" />
              </Link>
            );
          }
        }
      ];
      const defaultSorted = [
        {
          dataField: "clientCode",
          order: "desc"
        }
      ];
      const { SearchBar } = Search;
      const pagination = paginationFactory({
        sizePerPage: 5
      });
      return (
        <>
          <CaptionElement />

          <ToolkitProvider
            keyField="id"
            data={data}
            columns={columns}
            defaultSorted={defaultSorted}
            search={{ defaultSearch: "" }}
          >
            {props => (
              <div>
                <div align="right">
                  <SearchBar {...props.searchProps} />
                </div>
                <hr />
                <BootstrapTable
                  {...props.baseProps}
                  striped
                  hover
                  condensed
                  pagination={pagination}
                  noDataIndication="Table is Empty"
                />
              </div>
            )}
          </ToolkitProvider>
        </>
      );
    } else {
      return <LoadingMessage />;
    }
  }
}

export default withQuery(GuaranteeList, getGuarantee, "getGuarantee");
