import React, { Component } from "react"
import ReactTable from "react-table"
import "react-table/react-table.css"
import gql from "graphql-tag"
import { graphql } from "react-apollo"
import { Header, Segment, Dropdown } from "semantic-ui-react"
import moment from "moment"

import getAllDepotStack from "./fetchCodeco"
import LoadingMessage from "../../shared/LoadingMessage"
import withQuery from "../../HOC/withQuery"
import ExportStatus from "./ExportStatus"
class StatusReport extends Component {
  constructor(props) {
    super(props)
    this.state = {
      data: [...this.props.entries],
      pageNum: 1,
    }
  }
  onPostitionChange = (e, data) => {
    this.setState({ position: data.value })
  }
  render() {
    const positionOptions = [
      { id: 1, value: "GATEIN", text: "GATE IN" },
      { id: 2, value: "GATEOUT", text: "GATE OUT" },
      { id: 3, value: "LOADED", text: "LOADED" },
      { id: 4, value: "RESTOWED", text: "RESTOWED" },
    ]
    if (this.state.data) {
      let { data } = this.state
      console.log(data)
      if (this.state.position) {
        if (this.state.position === "GATEIN") {
          data = data.filter((row) => {
            return (
              row.bblEIR &&
              (row.date_out === "Invalid date" ||
                row.date_out === null ||
                row.date_out === "")
            )
          })
        }
        if (this.state.position === "GATEOUT") {
          data = data.filter((row) => {
            return (
              row.bay_position &&
              (row.date_loaded === "Invalid date" ||
                row.date_loaded === null ||
                row.date_loaded === "")
            )
          })
        }
        if (this.state.position === "LOADED") {
          data = data.filter((row) => {
            return row.date_loaded
          })
        }
        if (this.state.position === "RESTOWED") {
          data = data.filter((row) => {
            return row.status === "RESTOW"
          })
        }
      }

      const columns = [
        {
          Header: "CONTAINER NO.",
          id: "container_number",
          accessor: (d) => d.container.container_number,
        },

        {
          Header: "SIZE",
          id: "container_size",
          accessor: (d) => d.container.container_size,
        },

        {
          Header: "Weight",
          id: "weight",
          accessor: (d) => d.container.weight,
        },

        {
          Header: "EQStatus",
          id: "eQStatus",
          accessor: (d) => d.eqstatus,
        },

        {
          Header: "FEStatus",
          id: "fEStatus",
          accessor: (d) => d.festatus,
        },

        {
          Header: "POL",
          id: "pOL",
          accessor: (d) => d.pol,
        },

        {
          Header: "POD",
          id: "pOD",
          accessor: (d) => d.pod,
        },

        {
          Header: "VESSEL",
          id: "vessel",
          accessor: (d) => d.vessel_code,
        },

        {
          Header: "REF NO.",
          id: "reference_no",
          accessor: (d) => d.reference_no,
        },

        {
          Header: "PRE-ADVISE DATE",
          id: "preadviseDate",
          accessor: (d) => moment(d.preadviseDate).format("DD-MMM-YY"),
        },

        {
          Header: "PARTY DELIVERING",
          id: "partyDeliveringName",
          accessor: (d) => d.partyDelivering.party_delivering_name,
        },

        {
          Header: "GATE IN ",
          id: "gate_in_stack",
          accessor: (d) => moment(d.gate_in_stack).local().format("DD-MM-YYYY"),
        },

        {
          Header: "TIME",
          id: "gate_in_time",

          accessor: (d) => d.gate_in_time,
        },

        {
          Header: "BBL EIR NO.",
          id: "bbl_eir",
          accessor: (d) => d.bbl_eir,
        },

        {
          Header: "ETD STACK",
          id: "gate_in_time",
          accessor: (d) => moment(d.gate_in_time).format("DD-MMM-YY"),
        },
        {
          Header: "TRUCK OUT ID",
          id: "bbl_eir",
          accessor: (d) => d.mode_of_transport_id_out,
        },
        {
          Header: "CARRIER ID OUT",
          id: "bbl_eir",
          accessor: (d) => d.carrier_id_out,
        },
        {
          Header: "DATE LOADED",
          id: "date_loaded",
          accessor: (d) => d.date_loaded,
          Cell: this.renderEditable,
        },
        {
          Header: "TIME LOADED",
          id: "time_loaded",
          accessor: (d) => d.time_loaded,
        },
        {
          Header: "STACKED DAYS",
          id: "stacked_days",
          accessor: (d) => {
            var now = moment().format("YYYY-MM-DD") //todays date
            console.log("NOW", now)
            var end = moment(d.gate_in_stack)
            console.log("END", d.gate_in_stack)
            var duration = moment.duration(end.diff(now))
            var days = duration.asDays()
            console.log("DURATION:::", Math.abs(days))
            if (isNaN(days)) return 0
            return Math.abs(days)
            // moment(d.inDateICD).fromNow()
          },
        },
        {
          Header: "POSITION",
          id: "bay_position",
          accessor: (d) => d.bay_position,
        },
      ]
      return (
        <>
          <Header as="h3" color="green" textAlign="center">
            Daily Status Report
          </Header>
          <Segment.Group horizontal>
            <Segment></Segment>
            <Segment></Segment>
            <Segment>
              <Dropdown
                value={this.state.postition}
                search
                selection
                options={positionOptions}
                onChange={this.onPostitionChange.bind(this)}
              />
            </Segment>
          </Segment.Group>
          <hr color="pink" />
          <ReactTable
            columns={columns}
            data={data}
            pageSizeOptions={[
              5, 10, 20, 25, 50, 100, 250, 500, 1000, 2000, 3000,
            ]}
            sortable
            filterable
            defaultPageSize={5}
            noDataText={"Loading ..."}
            className="-striped -highlight"
          >
            {(state, filteredData, instance) => {
              this.ReactTable = state.pageRows.map((data) => {
                return data._original
              })
              return (
                <div>
                  {filteredData()}
                  <ExportStatus data={this.ReactTable} />
                </div>
              )
            }}
          </ReactTable>
        </>
      )
    } else {
      return <LoadingMessage />
    }
  }
}

export default withQuery(StatusReport, getAllDepotStack, "getAllDepotStack")
