import React, { useState } from "react"
import { graphql } from "react-apollo"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory from "react-bootstrap-table2-paginator"
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css"
import cellEditFactory, { Type } from "react-bootstrap-table2-editor"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import * as XLSX from "xlsx"
import "./depot.css"
import { Link } from "react-router-dom"
import { Icon } from "semantic-ui-react"
import withQuery from "../../HOC/withQuery"
import gql from "graphql-tag"
function PrestackPreadvised(props) {
  const [items, setItems] = useState([...props.entries])
  const [pageNum, setPageNum] = useState(1)

  const CaptionElement = () => (
    <h3
      style={{
        borderRadius: "0.25em",
        textAlign: "center",
        color: "green",
        border: "1px solid green",
        padding: "0.5em",
      }}
    >
      Booking List
    </h3>
  )
  const _editDepotStack = async (newValue, column, row) => {
    const { id } = row
    let args = { [column.dataField]: newValue }

    await props.editDepotStack({
      variables: {
        id,
        ...args,
      },
      // refetchQuery: [{ getPartyDelivering }],
    })
    props.history.push("/prestack/preadvised/list")
  }
  const { SearchBar, ClearSearchButton } = Search
  const columns = [
    {
      text: "Party Delivering",
      dataField: "partyDelivering.party_delivering_name",
      sort: true,
      headerStyle: {
        backgroundColor: "#c8e6c9",
      },
    },
    {
      text: "Vessel",
      dataField: "voyage.vesselName",
      sort: true,
      headerStyle: {
        backgroundColor: "#c8e6c9",
      },
    },
    {
      text: "Container",
      dataField: "container.container_number",
      sort: true,
      headerStyle: {
        backgroundColor: "#c8e6c9",
      },
    },
    {
      text: "Operator",
      dataField: "container.operator.operator_code",
      sort: true,
      headerStyle: {
        backgroundColor: "#c8e6c9",
      },
    },
    {
      text: "Reference",
      dataField: "reference_no",
      sort: true,
      headerStyle: {
        backgroundColor: "#c8e6c9",
      },
    },
    {
      text: "Date Recieved",
      dataField: "gate_in_stack",
      sort: true,
      headerStyle: {
        backgroundColor: "#c8e6c9",
      },
      editable: true,
    },
    // {
    //   text: "Action",
    //   dataField: "df",
    //   isDummyField: true,
    //   headerStyle: {
    //     backgroundColor: "#c8e6c9",
    //   },
    //   formatter: (cellContent, row) => {
    //     return (
    //       <Link to={`/prestack/preadvise/details/${row.id}`}>
    //         Add Preadvise
    //         <Icon name="angle double right" color="green" />
    //       </Link>
    //     )
    //   },
    // },
  ]
  const defaultSorted = [
    {
      dataField: "NO",
      order: "desc",
    },
  ]
  const pagination = paginationFactory({
    sizePerPage: 5,
  })

  return (
    <div>
      <CaptionElement />
      <ToolkitProvider
        keyField="NO"
        data={items}
        columns={columns}
        defaultSorted={defaultSorted}
        search={{ defaultSearch: "" }}
      >
        {(props) => (
          <div>
            <div>
              <div align="right">
                <SearchBar {...props.searchProps} />
                <ClearSearchButton {...props.searchProps} />
              </div>
            </div>
            <hr />
            <div>
              <BootstrapTable
                className="expanded-container"
                {...props.baseProps}
                striped
                hover
                condensed
                pagination={pagination}
                noDataIndication="Table is Empty"
                cellEdit={cellEditFactory({
                  mode: "dbclick",
                  blurToSave: true,
                  beforeSaveCell: (oldValue, newValue, row, column) => {
                    console.log("column: ", column)
                    console.log("Before Saving Cell!!:", oldValue)
                    console.log("New Value: ", newValue)
                    console.log("Id: ", row.id)
                    _editDepotStack(newValue, column, row)
                  },
                })}
              />
            </div>
          </div>
        )}
      </ToolkitProvider>
    </div>
  )
}

const getAllDepotPreadvised = gql`
  query getAllDepotPreadvised {
    getAllDepotPreadvised {
      id
      partyDelivering {
        party_delivering_id
        party_delivering_name
      }
      voyage {
        vesselName
      }
      container {
        container_number
        operator {
          operator_id
          operator_code
        }
      }
      gate_in_stack
      preadvise_date
      reference_no
    }
  }
`
const editDepotStack = gql`
  mutation editDepotStack($id: ID!, $gate_in_stack: String) {
    editDepotStack(id: $id, gate_in_stack: $gate_in_stack)
  }
`
export default graphql(editDepotStack, { name: "editDepotStack" })(
  withQuery(PrestackPreadvised, getAllDepotPreadvised, "getAllDepotPreadvised")
)
