import React, { Component } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import cellEditFactory from "react-bootstrap-table2-editor";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import gql from "graphql-tag";
import { graphql } from "react-apollo";
import { Checkbox } from "semantic-ui-react";

import withQuery from "../HOC/withQuery";
import LoadingMessage from "../shared/LoadingMessage";
import getUsers from "./queries/fetchUsers";
import PermissionsMessage from "../shared/PermissionsMessage";

class UserList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [...this.props.entries],
      pageNum: 1
    };
  }
  handleChange = (e, { checked }) => {
    console.log("Change", checked);
    this.change();
  };
  render() {
    if (!this.props.permissions) return <PermissionsMessage />;
    console.log(this.props);
    if (this.state.data) {
      const { data } = this.state;

      const CaptionElement = () => (
        <h3
          style={{
            borderRadius: "0.25em",
            textAlign: "center",
            color: "green",
            border: "1px solid green",
            padding: "0.5em"
          }}
        >
          User List
        </h3>
      );
      const columns = [
        {
          text: "Name ",
          dataField: "name",
          sort: true
        },
        {
          text: "Email Address",
          dataField: "email",
          sort: true
        },

        {
          text: "User Role",
          dataField: "role",
          sort: true
        },
        {
          text: "Confirmed",
          dataField: "confirmed",
          sort: true,
          editable: true,
          formatter: (cellContent, row) => {
            if (row.confirmed) {
              return <Checkbox checked />;
            } else {
              return <Checkbox />;
            }
          }
        }
      ];
      const defaultSorted = [
        {
          dataField: "name",
          order: "desc"
        }
      ];
      const { SearchBar } = Search;
      const pagination = paginationFactory({
        sizePerPage: 5
      });
      return (
        <>
          <CaptionElement />

          <ToolkitProvider
            keyField="id"
            data={data}
            columns={columns}
            defaultSorted={defaultSorted}
            search={{ defaultSearch: "" }}
          >
            {props => (
              <div>
                <div align="right">
                  <SearchBar {...props.searchProps} />
                </div>
                <hr />
                <BootstrapTable
                  {...props.baseProps}
                  striped
                  hover
                  condensed
                  pagination={pagination}
                  noDataIndication="Table is Empty"
                  cellEdit={cellEditFactory({
                    mode: "dbclick",
                    blurToSave: true,
                    beforeSaveCell: (oldValue, newValue, row, column) => {
                      console.log("column: ", row);
                      console.log("Before Saving Cell!!:", oldValue);
                      console.log("New Value: ", newValue);
                      console.log("Id: ", row.id);
                      this._editUser(newValue, column, row);
                    }
                  })}
                />
              </div>
            )}
          </ToolkitProvider>
        </>
      );
    } else {
      return <LoadingMessage />;
    }
  }
  _editUser = async (newValue, column, row) => {
    const { id, email, userRole, locked } = row;
    // let args = { ...row, [column.dataField]: newValue };
    if (column.dataField === "confirmed") {
      if (newValue === "false") {
        newValue = false;
      } else {
        newValue = true;
      }
    }
    await this.props.editUser({
      variables: {
        id,
        email,
        userRole,
        locked,
        [column.dataField]: newValue
      },
      refetchQueries: [{ query: getUsers }]
    });
    this.props.history.push("/users/list");
  };
}
const EDITUSERMUTATION = gql`
  mutation editUser(
    $id: ID!
    $email: String
    $userRole: String
    $confirmed: Boolean
  ) {
    editUser(id: $id, email: $email, userRole: $userRole, confirmed: $confirmed)
  }
`;
export default graphql(EDITUSERMUTATION, { name: "editUser" })(
  withQuery(UserList, getUsers, "userFeed")
);
