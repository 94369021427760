import React, { Component } from "react";
import gql from "graphql-tag";
import { graphql } from "react-apollo";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import { Icon } from "semantic-ui-react";
import { Link } from "react-router-dom";
import cellEditFactory from "react-bootstrap-table2-editor";

import LoadingMessage from "../shared/LoadingMessage";
import withQuery from "../HOC/withQuery";

import getArrivalContainers from "./Queries/fetchAllArrivalsMbs";
class ArrivalsListMombasa extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [...this.props.entries],
      pageNum: 1
    };
  }
  render() {
    if (this.state.data) {
      const { data } = this.state;
      console.log(data);
      const CaptionElement = () => (
        <h3
          style={{
            borderRadius: "0.25em",
            textAlign: "center",
            color: "green",
            border: "1px solid green",
            padding: "0.5em"
          }}
        >
          Mombasa Container Arrivals List
        </h3>
      );
      const columns = [
        {
          text: "Client Code",
          dataField: "clientCode",
          sort: true
        },

        {
          text: "Container No. ",
          dataField: "containerNum",
          sort: true
        },
        {
          text: "Container Size",
          dataField: "containerSize",
          sort: true
        },
        {
          text: "Gate Out ICD",
          dataField: "outDateICD",
          sort: true,
          editable: true
        },
        {
          text: "Wagon No.",
          dataField: "wagonNum",
          sort: true,
          editable: true
        },
        {
          text: "",
          dataField: "df",
          isDummyField: true,
          formatter: (cellContent, row) => {
            return (
              <Link to={`/transaction/arrival/mbs/${row.id}`}>
                Add Arrival Details
                <Icon name="angle double right" color="green" />
              </Link>
            );
          }
        }
      ];
      const defaultSorted = [
        {
          dataField: "clientCode",
          order: "desc"
        }
      ];
      const { SearchBar } = Search;
      const pagination = paginationFactory({
        sizePerPage: 5
      });
      return (
        <>
          <CaptionElement />

          <ToolkitProvider
            keyField="id"
            data={data}
            columns={columns}
            defaultSorted={defaultSorted}
            search={{ defaultSearch: "" }}
          >
            {props => (
              <div>
                <div align="right">
                  <SearchBar {...props.searchProps} />
                </div>
                <hr />
                <BootstrapTable
                  {...props.baseProps}
                  striped
                  hover
                  condensed
                  pagination={pagination}
                  noDataIndication="Table is Empty"
                  cellEdit={cellEditFactory({
                    mode: "dbclick",
                    blurToSave: true,
                    beforeSaveCell: (oldValue, newValue, row, column) => {
                      console.log("column: ", column);
                      console.log("Before Saving Cell!!:", oldValue);
                      console.log("New Value: ", newValue);
                      console.log("Id: ", row.id);
                      this._editPreadvise(newValue, column, row);
                    }
                  })}
                />
              </div>
            )}
          </ToolkitProvider>
        </>
      );
    } else {
      return <LoadingMessage />;
    }
  }
  _editPreadvise = async (newValue, column, row) => {
    const { id, outDateICD, wagonNum } = row;
    if (this.props.user.role === "SUPERUSER") {
      await this.props.editPreadvise({
        variables: {
          id: id,
          loadingDate: outDateICD,
          wagonNum,

          [column.dataField]: newValue
        },
        refetchQuery: [{ getArrivalContainers }]
      });
      this.props.history.push("/arrivals/mbs/list");
    }
  };
}
const EDITPREADVISE = gql`
  mutation editPreadvise($id: ID!, $outDateICD: String, $wagonNum: String) {
    editPreadvise(id: $id, outDateICD: $outDateICD, wagonNum: $wagonNum)
  }
`;
export default graphql(EDITPREADVISE, { name: "editPreadvise" })(
  withQuery(
    ArrivalsListMombasa,
    getArrivalContainers,
    "getArrivalMbsContainers"
  )
);
