import React, { Component } from "react";
import gql from "graphql-tag";
import { graphql } from "react-apollo";
import {flowRight as compose} from 'lodash';
import {
  Form,
  Divider,
  Dropdown,
  Header,
  Grid,
  Message,
  Segment
} from "semantic-ui-react";

import getUsers from "../User/queries/fetchUsers";
import InlineError from "../shared/InlineError";
import LoadingMessage from "../shared/PermissionsMessage";

var userOptions = [];
class ManagePassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      password: "",
      confirmPassword: "",
      userId: "",
      userName: "",
      errors: {},
      loading: false
    };
  }

  setUserValue = (e, data) => {
    userOptions.forEach(element => {
      if (element.value === data.value) {
        this.setState({ userId: element.id });
        this.setState({ userName: element.value });
      }
    });
  };

  validate = async () => {
    const errors = {};
    console.log("VALIDATE");

    if (!this.state.password) errors.password = "Can't be blank";
    if (!this.state.confirmPassword) errors.confirmPassword = "Can't be blank";
    if (this.state.confirmPassword !== this.state.password)
      errors.confirmPassword = "Passwords must match";
    console.log("ERRORS: ", errors);

    return errors;
  };
  onSubmit = () => {
    console.log("ON SUBMIT");
    const errors = this.validate();
    this.setState({ errors });
    if (Object.keys(errors).length === 0) {
      this.setState({ loading: true });

      this._changePassword();
    }
  };
  render() {
    if (!this.props.permissions) return <LoadingMessage />;
    const { loading, errors } = this.state;

    if (this.props.userFeed.loading === false) {
      let tempOp = this.props.userFeed.userFeed;
      userOptions = [];
      tempOp.map(element => {
        return userOptions.push({
          id: element.id,
          key: element.id,
          text: element.name,
          value: element.name
        });
      });
    }

    return (
      <div className="login-form">
        <style>{`
      body > div,
      body > div > div,
      body > div > div > div.login-form {
        height: 100%;
      }
    `}</style>
        <Grid
          textAlign="center"
          style={{ height: "100%" }}
          verticalAlign="middle"
        >
          <Grid.Column style={{ maxWidth: 450 }}>
            <Header as="h2" color="green" textAlign="center">
              Change Password
            </Header>
            <Divider />
            <Form size="large" onSubmit={this.onSubmit} loading={loading}>
              <Segment stacked>
                {errors.global && (
                  <Message negative>
                    <Message.Header> Something went wrong </Message.Header>
                    <p>{errors.global}</p>
                  </Message>
                )}
                <Form.Field>
                  <label>Pick User</label>
                  <Dropdown
                    value={this.state.userName}
                    search
                    selection
                    options={userOptions}
                    onChange={this.setUserValue.bind(this)}
                  />
                </Form.Field>
                <Form.Field error={!!errors.password}>
                  <label>Password</label>
                  <input
                    type="password"
                    value={this.state.password}
                    onChange={e => this.setState({ password: e.target.value })}
                  />
                  {errors.password && <InlineError text={errors.password} />}
                </Form.Field>
                <Divider />
                <Form.Field error={!!errors.confirmPassword}>
                  <label>Confirm Password</label>
                  <input
                    type="password"
                    value={this.state.confirmPassword}
                    onChange={e =>
                      this.setState({ confirmPassword: e.target.value })
                    }
                  />
                  {errors.confirmPassword && (
                    <InlineError text={errors.confirmPassword} />
                  )}
                </Form.Field>

                <Form.Button fluid positive>
                  Submit
                </Form.Button>
              </Segment>
            </Form>
          </Grid.Column>
        </Grid>
      </div>
    );
  }
  _changePassword = async () => {
    const { password, userId } = this.state;
    this.props.changePassword({
      variables: {
        userId,
        password
      }
    });
    this.props.history.push("/users/list");
  };
}

const CHANGEMUTATION = gql`
  mutation changePassword($password: String!, $userId: ID) {
    changePassword(password: $password, userId: $userId) {
      ok
      token
    }
  }
`;
export default compose(
  graphql(getUsers, { name: "userFeed" }),
  graphql(CHANGEMUTATION, { name: "changePassword" })
)(ManagePassword);
