import React, { Component } from "react";
import {
  Form,
  Segment,
  Grid,
  Header,
  Message,
  Divider,
  Dropdown
} from "semantic-ui-react";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import gql from "graphql-tag";
import { graphql } from "react-apollo";

import InlineError from "../shared/InlineError";
import getAllClients from "./queries/fetchAllClients";
import PermissionsMessage from "../shared/PermissionsMessage";

const serviceTypeOptions =[
  {
    key: 'FLED',
    text: 'FLED',
    value: 'FLED',
  },
  {
    key: 'RELAY',
    text: 'RELAY',
    value: 'RELAY',
  },
]
const clientTypeOptions =[
  {
    key: 'CASH',
    text: 'CASH',
    value: 'CASH',
  },
  {
    key: 'CREDIT',
    text: 'CREDIT',
    value: 'CREDIT',
  },
]
class AddClient extends Component {
  constructor(props) {
    super(props);
    this.state = {
      clientCode: "",
      clientName: "",
      clientPhone: "",
      clientEmailAddress: "",
      clientAddress: "",
      clientContact: "",
      clientKraPin: "",
      clientType: "",
      serviceType: "",
      loading: false,
      errors: {}
    };
  }
  validate = () => {
    const errors = {};

    if (!this.state.clientCode) errors.clientCode = "Can't be blank";
    if (!this.state.clientName) errors.clientName = "Can't be blank";
    if (!this.state.clientPhone) errors.clientPhone = "Can't be blank";
    if (!this.state.clientEmailAddress)
      errors.clientEmailAddress = "Can't be blank";
    if (!this.state.clientAddress) errors.clientAddress = "Can't be blank";
    if (!this.state.clientContact) errors.clientContact = "Can't be blank";
    if (!this.state.clientKraPin) errors.clientKraPin = "Can't be blank";
    if (!this.state.clientContact) errors.clientContact = "Can't be blank";
    if (!this.state.clientKraPin) errors.clientKraPin = "Can't be blank";
    if (!this.state.clientType) errors.clientType = "Can't be blank";
    if (!this.state.serviceType) errors.serviceType = "Can't be blank";
    if (Object.keys(errors).length === 0) {
      this.setState({ loading: true });
      this._createClient();
    }
    return errors;
  };
  onSubmit = () => {
    const errors = this.validate();
    this.setState({ errors });
  };
  setServiceTypeValue=(e, data)=>{
    this.setState({serviceType:data.value})
  }
  setClientTypeValue=(e, data)=>{
    this.setState({clientType:data.value})
  }
  render() {
    if (!this.props.permissions) return <PermissionsMessage />;
    const { errors, loading } = this.state;
    return (
      <Grid
        textAlign="center"
        style={{ height: "100%" }}
        verticalAlign="middle"
      >
        <Grid.Column style={{ maxWidth: 900 }}>
          <Header as="h2" color="green" textAlign="center">
            Create Client
          </Header>
          <Divider />
          <Form size="large" onSubmit={this.onSubmit} loading={loading}>
            {errors.global && (
              <Message negative>
                <Message.Header> Something went wrong </Message.Header>
                <p>{errors.global}</p>
              </Message>
            )}
            <Segment.Group horizontal>
              <Segment>
                <Form.Field error={!!errors.clientName}>
                  <label>Client Name</label>
                  <input
                    value={this.state.clientName}
                    onChange={e =>
                      this.setState({ clientName: e.target.value })
                    }
                  />
                  {errors.clientName && (
                    <InlineError text={errors.clientName} />
                  )}
                </Form.Field>

                <Form.Field error={!!errors.clientCode}>
                  <label>Client Code</label>
                  <input
                    value={this.state.clientCode}
                    type="text"
                    onChange={e =>
                      this.setState({ clientCode: e.target.value })
                    }
                  />
                  {errors.clientCode && (
                    <InlineError text={errors.clientCode} />
                  )}
                </Form.Field>
                <Form.Field error={!!errors.clientPhone}>
                  <PhoneInput
                    placeholder="Enter client phone number"
                    value={this.state.value}
                    onChange={value => this.setState({ clientPhone: value })}
                  />
                  {errors.clientPhone && (
                    <InlineError text={errors.clientPhone} />
                  )}
                </Form.Field>
                <Form.Field error={!!errors.clientEmailAddress}>
                  <label>Client Email </label>
                  <input
                    value={this.state.clientEmailAddress}
                    type="email"
                    onChange={e =>
                      this.setState({ clientEmailAddress: e.target.value })
                    }
                  />
                  {errors.clientEmailAddress && (
                    <InlineError text={errors.clientEmailAddress} />
                  )}
                </Form.Field>
              </Segment>
              <Segment>
                <Form.Field error={!!errors.clientAddress}>
                  <label>Client Address </label>
                  <input
                    value={this.state.clientAddress}
                    type="text"
                    onChange={e =>
                      this.setState({ clientAddress: e.target.value })
                    }
                  />
                  {errors.clientAddress && (
                    <InlineError text={errors.clientAddress} />
                  )}
                </Form.Field>
                <Form.Field error={!!errors.clientContact}>
                  <label>Contact Person </label>
                  <input
                    value={this.state.clientContact}
                    type="text"
                    onChange={e =>
                      this.setState({ clientContact: e.target.value })
                    }
                  />
                  {errors.clientContact && (
                    <InlineError text={errors.clientContact} />
                  )}
                </Form.Field>
                <Form.Field error={!!errors.clientKraPin}>
                  <label>KRA PIN </label>
                  <input
                    value={this.state.clientKraPin}
                    type="text"
                    onChange={e =>
                      this.setState({ clientKraPin: e.target.value })
                    }
                  />
                  {errors.clientKraPin && (
                    <InlineError text={errors.clientKraPin} />
                  )}
                </Form.Field>
                <Form.Field error={!!errors.clientType}>
                  <label>Client Type</label>
                  <Dropdown
                    value={this.state.clientType}
                    search
                    selection
                    options={clientTypeOptions}
                    onChange={this.setClientTypeValue.bind(this)}
                  />
                  {errors.clientType && <InlineError text={errors.clientType} />}
                </Form.Field>
                <Form.Field error={!!errors.serviceType}>
                  <label>Service Type</label>
                  <Dropdown
                    value={this.state.serviceType}
                    search
                    selection
                    options={serviceTypeOptions}
                    onChange={this.setServiceTypeValue.bind(this)}
                  />
                  {errors.serviceType && <InlineError text={errors.serviceType} />}
                </Form.Field>
              </Segment>
            </Segment.Group>
            <Form.Button fluid positive>
              Submit
            </Form.Button>
            <Segment stacked />
          </Form>
        </Grid.Column>
      </Grid>
    );
  }
  _createClient = async () => {
    const {
      clientCode,
      clientName,
      clientPhone,
      clientEmailAddress,
      clientAddress,
      clientContact,
      clientKraPin,
      clientType,
      serviceType
    } = this.state;
    await this.props.addClient({
      variables: {
        clientCode,
        clientName,
        clientPhone,
        clientEmailAddress,
        clientAddress,
        clientContact,
        clientKraPin,
        clientType,
        serviceType
      },

      refetchQueries: [{ query: getAllClients }]
    });
    this.props.history.push("/clients/list");
  };
}

const CREATECLIENT = gql`
  mutation addClient(
    $clientCode: String!
    $clientName: String!
    $clientPhone: String!
    $clientEmailAddress: String!
    $clientAddress: String!
    $clientContact: String!
    $clientKraPin: String
    $clientType: String!
		$serviceType: String!
  ) {
    addClient(
      clientCode: $clientCode
      clientName: $clientName
      clientPhone: $clientPhone
      clientAddress: $clientAddress
      clientEmailAddress: $clientEmailAddress
      clientContact: $clientContact
      clientKraPin: $clientKraPin
      clientType: $clientType
		  serviceType: $serviceType
    )
  }
`;

export default graphql(CREATECLIENT, { name: "addClient" })(AddClient);
