import React, { Component } from "react";
import gql from "graphql-tag";
import {  graphql } from "react-apollo";
import { Grid } from "semantic-ui-react";
import {flowRight as compose} from 'lodash';
import getAllPreadvices from "./Queries/fetchAllPreadvices";
import ClientDetails from "./ClientDetails";
import ContainerDetails from "./ContainerDetails";
import LogisticalDetails from "./LogisticalDetails";
import OtherDetails from "./OtherDetails";
import Confirmation from "./ConfirmDetails";
import Success from "./Success";
import PermissionsMessage from "../shared/PermissionsMessage";

var containerOptions = [];
var clientOptions = [];
var operatorOptions = [];
var EQStatusOptions = [
  { text: "Export", value: "Export" },
  { text: "Import", value: "Import" }
];
var FEStatusOptions = [
  { text: "Empty", value: "Empty" },
  { text: "Full", value: "Full" }
];

var POLOptions = [
  { text: "KEMBA", value: "KEMBA" },
  { text: "KEEMK", value: "KEEMK" },
  { text: "KERNA", value: "KERNA" }
];

var PODOptions = [
  { text: "KEMBA", value: "KEMBA" },
  { text: "KEEMK", value: "KEEMK" },
  { text: "KERNA", value: "KERNA" }
];
class AddPreadvice extends Component {
  constructor(props) {
    super(props);
    this.state = {
      step: 1,
      clientCode: "",
      containerNum: "",
      containerSize: "",

      containerValue: "",
      containerCode: "",
      operatorCode: "",
      weight: "",
      eQStatus: "",
      fEStatus: "",
      pOL: "",
      pOD: "",
      sOExpiryDate: "",
      partyDelivering: "",
      vessel: "",
      preadviseDate: "",
      loading: false,
      errors: {}
    };
  }

  setContainerValue = (e, data) => {
    console.log("containerOptions: ", containerOptions);
    containerOptions.forEach(element => {
      if (element.value === data.value) {
        this.setState({ containerSize: element.id });
        this.setState({ containerCode: element.text });
        this.setState({ containerValue: element.value });
      }

      console.log(this.state);
    });
  };

  setOperatorValue = (e, data) => {
    console.log("operatorOptions: ", operatorOptions);
    operatorOptions.forEach(element => {
      if (element.value === data.value) {
        this.setState({ operatorCode: element.value });
      }

      console.log(this.state);
    });
  };
  setClientValue = (e, data) => {
    console.log("clientOptions: ", clientOptions);
    clientOptions.forEach(element => {
      if (element.value === data.value) {
        this.setState({ clientCode: element.text });
      }

      console.log(this.state);
    });
  };
  setOptionsValue = (e, data) => {
    const { name, value } = data;
    this.setState({ [name]: value });
  };

  handlesPreadviseDateChange = date => {
    this.setState({ preadviseDate: date });
  };
  handlesOExpiryDateChange = date => {
    this.setState({ sOExpiryDate: date });
  };
  nextStep = () => {
    const { step } = this.state;
    this.setState({
      step: step + 1
    });
  };

  prevStep = () => {
    const { step } = this.state;
    this.setState({
      step: step - 1
    });
  };

  handleChange = input => event => {
    const errors = {};

    if (input === "containerNum") {
      const containerNum = event.target.value.trim();

      if (containerNum.length === 11) {
        if (
          containerNum.substring(0, 4).match(/^[a-zA-Z]+$/) == null ||
          containerNum.substring(0, 4).match(/^[a-zA-Z]+$/) === false
        ) {
          errors.containerNum = "Wrong entry for container number";
        } else {
          if (Number.isNaN(parseInt(containerNum.substring(4)))) {
            errors.containerNum = "Wrong entry for container number";
          }
        }
      } else {
        errors.containerNum = "Wrong entry for container number";
      }
    }
    this.setState({ errors });
    this.setState({ [input]: event.target.value });
  };
  validate = () => {
    const errors = {};

    if (!this.state.clientCode) errors.clientCode = "Can't be blank";
    if (!this.state.containerNum) errors.containerNum = "Can't be blank";
    const { containerNum } = this.state;
    // containerNum.length > 11 ||  containerNum.length < 11
    if (containerNum.length === 11) {
      if (
        containerNum.substring(0, 4).match(/^[a-zA-Z]+$/) == null ||
        containerNum.substring(0, 4).match(/^[a-zA-Z]+$/) === false
      ) {
        errors.containerNum = "Wrong entry for container number";
      } else {
        if (Number.isNaN(parseInt(containerNum.substring(4)))) {
          errors.containerNum = "Wrong entry for container number";
        }
      }
    } else {
      errors.containerNum = "Wrong entry for container number";
    }
    if (!this.state.containerSize) errors.containerSize = "Can't be blank";
    if (!this.state.containerCode) errors.containerCode = "Can't be blank";
    if (!this.state.operatorCode) errors.operatorCode = "Can't be blank";
    if (!this.state.weight) errors.weight = "Can't be blank";
    if (!this.state.eQStatus) errors.eQStatus = "Can't be blank";
    if (!this.state.fEStatus) errors.fEStatus = "Can't be blank";
    if (!this.state.pOD) errors.pOD = "Can't be blank";
    if (!this.state.vessel) errors.vessel = "Can't be blank";
    if (!this.state.partyDelivering) errors.partyDelivering = "Can't be blank";
    if (!this.state.sOExpiryDate) errors.sOExpiryDate = "Can't be blank";
    if (!this.state.preadviseDate) errors.preadviseDate = "Can't be blank";

    if (Object.keys(errors).length === 0) {
      console.log("Errors Length: ", errors.length);
      console.log("Errors : ", errors);
      this._createContainer();
    } else {
      this.nextStep();
    }

    return errors;
  };
  onSubmit = () => {
    const errors = this.validate();
    this.setState({ errors });
  };
  setFieldValueOnChange = async (fieldName, value) => {
    await this.setState({ [fieldName]: value });
  };
  render() {
    if (!this.props.permissions) return <PermissionsMessage />;
    const { step } = this.state;
    console.log(this.props);
    const {
      clientCode,
      containerNum,
      containerSize,
      containerValue,
      containerCode,
      operatorCode,
      weight,
      fEStatus,
      eQStatus,
      pOD,
      pOL,
      vessel,
      partyDelivering,
      sOExpiryDate,
      preadviseDate
    } = this.state;

    const values = {
      clientCode,
      containerNum,
      containerSize,
      containerCode,
      containerValue,
      operatorCode,
      weight,
      fEStatus,
      eQStatus,
      pOD,
      pOL,
      vessel,
      partyDelivering,
      sOExpiryDate,
      preadviseDate
    };
    if (this.props.containerFeed.loading === false) {
      let tempOp = this.props.containerFeed.getAllContainers;
      containerOptions = [];
      tempOp.map(element => {
        return containerOptions.push({
          id: element.id,
          key: element.id,
          text: element.containerCode,
          value: element.containerCode
        });
      });
    }

    if (this.props.clientFeed.loading === false) {
      console.log(this.props.clientFeed);
      let tempOp = this.props.clientFeed.getAllClients;
      clientOptions = [];

      tempOp.map(element => {
        return clientOptions.push({
          id: element.id,
          key: element.id,
          text: element.clientName,
          value: element.clientName
        });
      });
    }
    if (this.props.operatorFeed.loading === false) {
      let tempOp = this.props.operatorFeed.getAllOperators;
      operatorOptions = [];
      tempOp.map(element => {
        return operatorOptions.push({
          id: element.id,
          key: element.id,
          text: element.operatorCode,
          value: element.operatorCode
        });
      });
    }
    switch (step) {
      case 1:
        return (
          <Grid
            textAlign="center"
            verticalAlign="middle"
            style={{ height: "100%" }}
          >
            {" "}
            <ClientDetails
              errors={this.state.errors}
              state={this.state}
              handleChange={this.handleChange}
              clientOptions={clientOptions}
              setClientValue={this.setClientValue}
              nextStep={this.nextStep}
              prevStep={this.prevStep}
            />
          </Grid>
        );
      case 2:
        return (
          <Grid
            textAlign="center"
            style={{ height: "100%" }}
            verticalAlign="middle"
          >
            <ContainerDetails
              values={values}
              errors={this.state.errors}
              state={this.state}
              containerOptions={containerOptions}
              operatorOptions={operatorOptions}
              setContainerValue={this.setContainerValue}
              setOperatorValue={this.setOperatorValue}
              handleChange={this.handleChange}
              nextStep={this.nextStep}
              prevStep={this.prevStep}
            />
          </Grid>
        );
      case 3:
        return (
          <Grid
            textAlign="center"
            style={{ height: "100%" }}
            verticalAlign="middle"
          >
            <LogisticalDetails
              EQStatusOptions={EQStatusOptions}
              FEStatusOptions={FEStatusOptions}
              POLOptions={POLOptions}
              PODOptions={PODOptions}
              values={values}
              setOptionsValue={this.setOptionsValue}
              errors={this.state.errors}
              nextStep={this.nextStep}
              prevStep={this.prevStep}
            />
          </Grid>
        );
      case 4:
        return (
          <Grid
            textAlign="center"
            style={{ height: "100%" }}
            verticalAlign="middle"
          >
            <OtherDetails
              values={values}
              errors={this.state.errors}
              state={this.state}
              handleChange={this.handleChange}
              handlesPreadviseDateChange={this.handlesPreadviseDateChange}
              handlesOExpiryDateChange={this.handlesOExpiryDateChange}
              nextStep={this.nextStep}
              prevStep={this.prevStep}
            />
          </Grid>
        );
      case 5:
        return (
          <Grid
            textAlign="center"
            style={{ height: "100%" }}
            verticalAlign="middle"
          >
            <Confirmation
              values={values}
              confirm={this.onSubmit}
              nextStep={this.nextStep}
              prevStep={this.prevStep}
              onSubmit={this.onSubmit}
            />
          </Grid>
        );
      case 6:
        return (
          <Grid
            textAlign="center"
            style={{ height: "100%" }}
            verticalAlign="middle"
          >
            <Success
              errors={this.state.errors}
              nextStep={this.nextStep}
              prevStep={this.prevStep}
            />
          </Grid>
        );

      default:
        return (
          <Grid
            textAlign="center"
            style={{ height: "100%" }}
            verticalAlign="middle"
          >
            Loading ...
          </Grid>
        );
    }
  }
  _createContainer = async () => {
    const {
      clientCode,
      containerNum,
      containerSize,
      operatorCode,
      weight,
      eQStatus,
      fEStatus,
      pOD,
      pOL,
      sOExpiryDate,
      partyDelivering,
      vessel,
      preadviseDate
    } = this.state;

    const result = await this.props.addPreadvise({
      variables: {
        clientCode,
        containerNum,
        containerSize: containerSize,
        operatorCode,
        weight,
        eQStatus,
        fEStatus,
        pOD,
        pOL,
        sOExpiryDate,
        partyDelivering,
        vessel,
        preadviseDate
      },

      refetchQueries: [{ query: getAllPreadvices }]
    });
    console.log("Result:", result);

    if (
      result.data.createPreadvise.message === "Container already in transit"
    ) {
      this.props.history.push("/info");
    }
    this.props.history.push("/transaction/list");
  };
}

const CREATEPREADVISE = gql`
  mutation addContainer(
    $clientCode: String!
    $containerNum: String!
    $containerSize: String!
    $operatorCode: String!
    $weight: String!
    $eQStatus: String!
    $fEStatus: String!
    $pOL: String!
    $pOD: String!
    $vessel: String!
    $partyDelivering: String!
    $sOExpiryDate: String!
    $preadviseDate: String!
  ) {
    createPreadvise(
      clientCode: $clientCode
      containerNum: $containerNum
      containerSize: $containerSize
      weight: $weight
      eQStatus: $eQStatus
      fEStatus: $fEStatus
      pOL: $pOL
      operatorCode: $operatorCode
      pOD: $pOD
      vessel: $vessel
      partyDeliveringName: $partyDelivering
      sOExpiryDate: $sOExpiryDate
      preadviseDate: $preadviseDate
    ) {
      ok
      message
    }
  }
`;

const CONTAINERFEED = gql`
  query getAllContainers {
    getAllContainers {
      id
      containerSize
      containerCode
    }
  }
`;
const CLIENTFEED = gql`
  query getAllClients {
    getAllClients {
      id
      clientCode
      clientName
    }
  }
`;
const OPERATORFEED = gql`
  query getAllClients {
    getAllOperators {
      id
      operatorCode
      operatorName
    }
  }
`;
export default compose(
  graphql(CONTAINERFEED, {
    name: "containerFeed"
  }),
  graphql(CLIENTFEED, {
    name: "clientFeed"
  }),
  graphql(OPERATORFEED, {
    name: "operatorFeed"
  }),
  graphql(CREATEPREADVISE, { name: "addPreadvise" })
)(AddPreadvice);
