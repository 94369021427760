import React, { Component } from "react"
import { Accordion, Menu } from "semantic-ui-react"
import { Link } from "react-router-dom"

export default class SideBarMenu extends Component {
  state = { activeIndex: 0 }

  handleItemClick = (e, { name }) => this.setState({ activeItem: name })

  handleClick = (e, titleProps) => {
    const { index } = titleProps
    const { activeIndex } = this.state
    const newIndex = activeIndex === index ? -1 : index

    this.setState({ activeIndex: newIndex })
  }

  render() {
    console.log("PROPS KWA SIDEBAR::::::::", this.props)
    const { activeIndex } = this.state

    const PreadviceForm = (
      <Menu.Menu>
        <Menu.Item
          name="preadvices"
          as={Link}
          to="/transaction/list"
          onClick={this.handleItemClick}
        >
          Preadvice List
        </Menu.Item>
        <Menu.Item
          name="mobilepreadvices"
          as={Link}
          to="/clienttransaction/list"
          onClick={this.handleItemClick}
        >
          Client Preadvice List
        </Menu.Item>
        <Menu.Item
          name="validaterequests"
          as={Link}
          to="/bookings/list"
          onClick={this.handleItemClick}
        >
          Validate Requests
        </Menu.Item>
        <Menu.Item
          name="createpreadvice"
          as={Link}
          to="/transaction/new"
          onClick={this.handleItemClick}
        >
          Add Preadvice
        </Menu.Item>
        <Menu.Item
          name="createpreadvice"
          as={Link}
          to="/transaction/upload"
          onClick={this.handleItemClick}
        >
          Bulk Upload
        </Menu.Item>
        <Menu.Item
          name="generatereport"
          as={Link}
          to="/mobilereports"
          onClick={this.handleItemClick}
        >
          Generate Mobile Report
        </Menu.Item>
      </Menu.Menu>
    )

    const ReportsForm = (
      <Menu.Menu>
        <Menu.Item
          name="clientReport"
          as={Link}
          to="/clientreports"
          onClick={this.handleItemClick}
        >
          Client Report
        </Menu.Item>
        <Menu.Item
          name="shippingline"
          as={Link}
          to="/shippingline"
          onClick={this.handleItemClick}
        >
          Shipping Line Report
        </Menu.Item>
        <Menu.Item
          name="larareport"
          as={Link}
          to="/lara"
          onClick={this.handleItemClick}
        >
          Lara Report
        </Menu.Item>
        <Menu.Item
          name="mgmtreport"
          as={Link}
          to="/mgmtreport"
          onClick={this.handleItemClick}
        >
          Management Summary
        </Menu.Item>
        <Menu.Item
          name="codeco"
          as={Link}
          to="/report/list"
          onClick={this.handleItemClick}
        >
          Codeco List
        </Menu.Item>
        <Menu.Item
          name="summary"
          as={Link}
          to="/reports"
          onClick={this.handleItemClick}
        >
          Summary List
        </Menu.Item>
        <Menu.Item
          name="archive"
          as={Link}
          to="/archived"
          onClick={this.handleItemClick}
        >
          Archive List
        </Menu.Item>
        <Menu.Item
          name="archivepdf"
          as={Link}
          to="/archivedpdf"
          onClick={this.handleItemClick}
        >
          Create Archive PDF
        </Menu.Item>

        <Menu.Item
          name="containerStatus"
          as={Link}
          to="/reports/visual"
          onClick={this.handleItemClick}
        >
          Container Status
        </Menu.Item>
        <Menu.Item
          name="editDetails"
          as={Link}
          to="/editdetails"
          onClick={this.handleItemClick}
        >
          Audit Trail
        </Menu.Item>
      </Menu.Menu>
    )

    const UserForm = (
      <Menu.Menu>
        <Menu.Item
          name="users"
          as={Link}
          to="/users/list"
          onClick={this.handleItemClick}
        >
          User List
        </Menu.Item>
        <Menu.Item
          name="createuser"
          as={Link}
          to="/users/new"
          onClick={this.handleItemClick}
        >
          Add User
        </Menu.Item>
        <Menu.Item
          name="createuser"
          as={Link}
          to="/prestackusers/new"
          onClick={this.handleItemClick}
        >
          Add Prestack User
        </Menu.Item>
        <Menu.Item
          name="manageuser"
          as={Link}
          to="/users/manage-password"
          onClick={this.handleItemClick}
        >
          Manage User
        </Menu.Item>
        <Menu.Item
          name="changepassword"
          as={Link}
          to="/change-password"
          onClick={this.handleItemClick}
        >
          Change Password
        </Menu.Item>
      </Menu.Menu>
    )
    const ContainersForm = (
      <Menu.Menu>
        <Menu.Item
          name="containers"
          as={Link}
          to="/containers/list"
          onClick={this.handleItemClick}
        >
          Container List
        </Menu.Item>
        <Menu.Item
          name="createcontainer"
          as={Link}
          to="/containers/new"
          onClick={this.handleItemClick}
        >
          Add Container
        </Menu.Item>
      </Menu.Menu>
    )
    const ClientsForm = (
      <Menu.Menu>
        <Menu.Item
          name="clients"
          as={Link}
          to="/clients/list"
          onClick={this.handleItemClick}
        >
          Client List
        </Menu.Item>
        <Menu.Item
          name="createclient"
          as={Link}
          to="/client/new"
          onClick={this.handleItemClick}
        >
          Add Client
        </Menu.Item>
      </Menu.Menu>
    )
    const DamagesForm = (
      <Menu.Menu>
        <Menu.Item
          name="damages"
          as={Link}
          to="/damages/list"
          onClick={this.handleItemClick}
        >
          Damage List
        </Menu.Item>
        <Menu.Item
          name="createdamage"
          as={Link}
          to="/damage/new"
          onClick={this.handleItemClick}
        >
          Add Damage
        </Menu.Item>
      </Menu.Menu>
    )
    const OperatorsForm = (
      <Menu.Menu>
        <Menu.Item
          name="Operators"
          as={Link}
          to="/operators/list"
          onClick={this.handleItemClick}
        >
          Operator List
        </Menu.Item>
        <Menu.Item
          name="createoperator"
          as={Link}
          to="/operator/new"
          onClick={this.handleItemClick}
        >
          Add Operator
        </Menu.Item>
      </Menu.Menu>
    )

    const ArrivalsForm = (
      <Menu.Menu>
        <Menu.Item
          name="arrivalsListNairobi"
          as={Link}
          to="/arrivals/nrb/list"
          onClick={this.handleItemClick}
        >
          Arrivals Nairobi
        </Menu.Item>
        <Menu.Item
          name="arrivalsListMombasa"
          as={Link}
          to="/arrivals/mbs/list"
          onClick={this.handleItemClick}
        >
          Arrivals Mombasa
        </Menu.Item>
        <Menu.Item
          name="createPDF"
          as={Link}
          to="/createpdf"
          onClick={this.handleItemClick}
        >
          Create PDF
        </Menu.Item>
      </Menu.Menu>
    )

    const DeparturesForm = (
      <Menu.Menu>
        <Menu.Item
          name="departuresListNairobi"
          as={Link}
          to="/departures/nrb/list"
          onClick={this.handleItemClick}
        >
          Departures Nairobi
        </Menu.Item>
        <Menu.Item
          name="departuresListMombasa"
          as={Link}
          to="/departures/mbs/list"
          onClick={this.handleItemClick}
        >
          Departures Mombasa
        </Menu.Item>
      </Menu.Menu>
    )

    const GuaranteeForm = (
      <Menu.Menu>
        <Menu.Item
          name="guaranteeList"
          as={Link}
          to="/guarantee/list"
          onClick={this.handleItemClick}
        >
          Guarantee List
        </Menu.Item>
      </Menu.Menu>
    )
    const TruckerForm = (
      <Menu.Menu>
        <Menu.Item
          name="truckerList"
          as={Link}
          to="/truckers/list"
          onClick={this.handleItemClick}
        >
          Truckers List
        </Menu.Item>
        <Menu.Item
          name="createTrucker"
          as={Link}
          to="/trucker/new"
          onClick={this.handleItemClick}
        >
          Add Trucker
        </Menu.Item>
      </Menu.Menu>
    )

    const AdministrationForm = (
      <Menu.Menu>
        <Menu.Item
          name="deliveriesList"
          as={Link}
          to="/delivered/list"
          onClick={this.handleItemClick}
        >
          Deliveries List
        </Menu.Item>
        <Menu.Item
          name="deliveriesListCMA"
          as={Link}
          to="/delivered/cma"
          onClick={this.handleItemClick}
        >
          Deliveries Shipping Line
        </Menu.Item>
        <Menu.Item
          name="deliveriesExport"
          as={Link}
          to="/deliveries/export"
          onClick={this.handleItemClick}
        >
          Export Deliveries
        </Menu.Item>
      </Menu.Menu>
    )
    const InvoicingForm = (
      <Menu.Menu>
        <Menu.Item
          name="invoicesList"
          as={Link}
          to="/invoices/list"
          onClick={this.handleItemClick}
        >
          Invoicing List
        </Menu.Item>
        <Menu.Item
          name="invoicedList"
          as={Link}
          to="/invoiced/list"
          onClick={this.handleItemClick}
        >
          Invoiced List
        </Menu.Item>
        <Menu.Item
          name="invoicesExport"
          as={Link}
          to="/invoices/export"
          onClick={this.handleItemClick}
        >
          Export Invoices
        </Menu.Item>
      </Menu.Menu>
    )
    const CustomerServiceForm = (
      <Menu.Menu>
        <Menu.Item
          name="containerlist"
          as={Link}
          to="/prestack/customerservice/book"
          onClick={this.handleItemClick}
        >
          Book Container
        </Menu.Item>
        <Menu.Item
          name="containerlist"
          as={Link}
          to="/prestack/preadvise/list"
          onClick={this.handleItemClick}
        >
          Preadvise Containers
        </Menu.Item>
        <Menu.Item
          name="containerlist"
          as={Link}
          to="/prestack/preadvised/list"
          onClick={this.handleItemClick}
        >
          Preadvised Containers
        </Menu.Item>
        <Menu.Item
          name="preadvices"
          as={Link}
          to="/prestack/container/upload"
          onClick={this.handleItemClick}
        >
          Bulk Upload
        </Menu.Item>
        <Menu.Item
          name="rebooking"
          as={Link}
          to="/prestack/customerservice/restowlist"
          onClick={this.handleItemClick}
        >
          Rebookings
        </Menu.Item>
        <Menu.Item
          name="containerlist"
          as={Link}
          to="/prestack/container/list"
          onClick={this.handleItemClick}
        >
          Container List
        </Menu.Item>
        <Menu.Item
          name="vessels"
          as={Link}
          to="/prestack/vessels/list"
          onClick={this.handleItemClick}
        >
          Vessels List
        </Menu.Item>

        <Menu.Item
          name="voyages"
          as={Link}
          to="/prestack/voyages/list"
          onClick={this.handleItemClick}
        >
          Voyage List
        </Menu.Item>

        <Menu.Item
          name="partyDelivering"
          as={Link}
          to="/prestack/partydelivering/list"
          onClick={this.handleItemClick}
        >
          Party Delivering
        </Menu.Item>
      </Menu.Menu>
    )
    const ReceptionForm = (
      <Menu.Menu>
        <Menu.Item
          name="arrivals"
          as={Link}
          to="/prestack/operations/arrivals"
          onClick={this.handleItemClick}
        >
          Recieve
        </Menu.Item>
        <Menu.Item
          name="departues"
          as={Link}
          to="/prestack/deliverynote/generate"
          onClick={this.handleItemClick}
        >
          Delivery Notes
        </Menu.Item>
        <Menu.Item
          name="departues"
          as={Link}
          to="/prestack/operations/departures"
          onClick={this.handleItemClick}
        >
          Dispatch
        </Menu.Item>
        <Menu.Item
          name="pdfs"
          as={Link}
          to="/prestack/operations/createpdf"
          onClick={this.handleItemClick}
        >
          Create PDF
        </Menu.Item>
      </Menu.Menu>
    )

    const DepartureForm = (
      <Menu.Menu>
        <Menu.Item
          name="createpreadvice"
          as={Link}
          to="/prestack/operations/berthtallying"
          onClick={this.handleItemClick}
        >
          Berth Tallying
        </Menu.Item>
        <Menu.Item
          name="voyageListing"
          as={Link}
          to="/prestack/expected/voyages"
          onClick={this.handleItemClick}
        >
          Open Voyage
        </Menu.Item>
        <Menu.Item
          name="expectedvoyages"
          as={Link}
          to="/prestack/open/voyages"
          onClick={this.handleItemClick}
        >
          Close Voyage
        </Menu.Item>
      </Menu.Menu>
    )

    const TracingForm = (
      <Menu.Menu>
        <Menu.Item
          name="tracingList"
          as={Link}
          to="/prestack/operations/tracing"
          onClick={this.handleItemClick}
        >
          Tracing List
        </Menu.Item>

        <Menu.Item
          name="voyageListing"
          as={Link}
          to="/prestack/operations/shutlist"
          onClick={this.handleItemClick}
        >
          Shutout List
        </Menu.Item>
      </Menu.Menu>
    )

    const PrestackReportsForm = (
      <Menu.Menu>
        <Menu.Item
          name="reportlist"
          as={Link}
          to="/prestack/reports/list"
          onClick={this.handleItemClick}
        >
          Container Summary
        </Menu.Item>
        <Menu.Item
          name="reportlist"
          as={Link}
          to="/prestack/reports/status"
          onClick={this.handleItemClick}
        >
          Container Status
        </Menu.Item>
        <Menu.Item
          name="audittrail"
          as={Link}
          to="/prestack/reports/audit"
          onClick={this.handleItemClick}
        >
          Audit Trail
        </Menu.Item>
      </Menu.Menu>
    )
    return (
      <Accordion as={Menu} vertical>
        {this.props.department !== "PRESTACK" ? (
          <>
            <Menu.Item>
              <Accordion.Title
                active={activeIndex === 0}
                content="Preadvice Management"
                index={0}
                onClick={this.handleClick}
              />
              <Accordion.Content
                active={activeIndex === 0}
                content={PreadviceForm}
              />
            </Menu.Item>

            <Menu.Item>
              <Accordion.Title
                active={activeIndex === 2}
                content="Arrivals Management"
                index={2}
                onClick={this.handleClick}
              />
              <Accordion.Content
                active={activeIndex === 2}
                content={ArrivalsForm}
              />
            </Menu.Item>

            <Menu.Item>
              <Accordion.Title
                active={activeIndex === 3}
                content="Departures Management"
                index={3}
                onClick={this.handleClick}
              />
              <Accordion.Content
                active={activeIndex === 3}
                content={DeparturesForm}
              />
            </Menu.Item>

            <Menu.Item>
              <Accordion.Title
                active={activeIndex === 5}
                content="Deliveries Management"
                index={5}
                onClick={this.handleClick}
              />
              <Accordion.Content
                active={activeIndex === 5}
                content={AdministrationForm}
              />
            </Menu.Item>
            <Menu.Item>
              <Accordion.Title
                active={activeIndex === 4}
                content="Guarantees Management"
                index={4}
                onClick={this.handleClick}
              />
              <Accordion.Content
                active={activeIndex === 4}
                content={GuaranteeForm}
              />
            </Menu.Item>

            <Menu.Item>
              <Accordion.Title
                active={activeIndex === 6}
                content="Invoices Management"
                index={6}
                onClick={this.handleClick}
              />
              <Accordion.Content
                active={activeIndex === 6}
                content={InvoicingForm}
              />
            </Menu.Item>
            <Menu.Item>
              <Accordion.Title
                active={activeIndex === 8}
                content="Reports "
                index={8}
                onClick={this.handleClick}
              />
              <Accordion.Content
                active={activeIndex === 8}
                content={ReportsForm}
              />
            </Menu.Item>
            <Menu.Item>
              <Accordion.Title
                active={activeIndex === 1}
                content="Container Management"
                index={1}
                onClick={this.handleClick}
              />
              <Accordion.Content
                active={activeIndex === 1}
                content={ContainersForm}
              />
            </Menu.Item>
            <Menu.Item>
              <Accordion.Title
                active={activeIndex === 7}
                content="Truckers Management"
                index={7}
                onClick={this.handleClick}
              />
              <Accordion.Content
                active={activeIndex === 7}
                content={TruckerForm}
              />
            </Menu.Item>

            <Menu.Item>
              <Accordion.Title
                active={activeIndex === 10}
                content="Clients "
                index={10}
                onClick={this.handleClick}
              />
              <Accordion.Content
                active={activeIndex === 10}
                content={ClientsForm}
              />
            </Menu.Item>
            <Menu.Item>
              <Accordion.Title
                active={activeIndex === 12}
                content="Damages "
                index={12}
                onClick={this.handleClick}
              />
              <Accordion.Content
                active={activeIndex === 12}
                content={DamagesForm}
              />
            </Menu.Item>
            <Menu.Item>
              <Accordion.Title
                active={activeIndex === 11}
                content="Operators "
                index={11}
                onClick={this.handleClick}
              />
              <Accordion.Content
                active={activeIndex === 11}
                content={OperatorsForm}
              />
            </Menu.Item>
            <Menu.Item>
              <Accordion.Title
                active={activeIndex === 9}
                content="Users "
                index={9}
                onClick={this.handleClick}
              />
              <Accordion.Content
                active={activeIndex === 9}
                content={UserForm}
              />
            </Menu.Item>
          </>
        ) : (
          <>
            <Menu.Item>
              <Accordion.Title
                active={activeIndex === 10}
                content="Prestack Client Desk"
                index={10}
                onClick={this.handleClick}
              />
              <Accordion.Content
                active={activeIndex === 10}
                content={CustomerServiceForm}
              />
            </Menu.Item>
            <Menu.Item>
              <Accordion.Title
                active={activeIndex === 11}
                content="Prestack Arrivals"
                index={11}
                onClick={this.handleClick}
              />
              <Accordion.Content
                active={activeIndex === 11}
                content={ReceptionForm}
              />
            </Menu.Item>
            <Menu.Item>
              <Accordion.Title
                active={activeIndex === 12}
                content="Prestack Departures"
                index={12}
                onClick={this.handleClick}
              />
              <Accordion.Content
                active={activeIndex === 12}
                content={DepartureForm}
              />
            </Menu.Item>
            <Menu.Item>
              <Accordion.Title
                active={activeIndex === 13}
                content="Prestack Tracing"
                index={13}
                onClick={this.handleClick}
              />
              <Accordion.Content
                active={activeIndex === 13}
                content={TracingForm}
              />
            </Menu.Item>
            <Menu.Item>
              <Accordion.Title
                active={activeIndex === 14}
                content="Prestack Reports"
                index={14}
                onClick={this.handleClick}
              />
              <Accordion.Content
                active={activeIndex === 14}
                content={PrestackReportsForm}
              />
            </Menu.Item>
          </>
        )}
      </Accordion>
    )
  }
}
