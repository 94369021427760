import gql from "graphql-tag"
export default gql`
  query departureDepotStack {
    departureDepotStack {
      id
      container {
        container_number
      }
      partyDelivering {
        party_delivering_id
        party_delivering_name
      }
      reference_no
      gate_in_stack
      seal_number
    }
  }
`
