import gql from "graphql-tag";

export default gql`
  query getAllContainers {
    getAllContainers {
      id
      containerCode
      containerSize
      description
    }
  }
`;
