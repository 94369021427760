import gql from "graphql-tag"
import React, { useState } from "react"
import { Form, Button, Grid, Header, Divider } from "semantic-ui-react"
import { graphql } from "react-apollo"
import DatePicker from "react-datepicker"
import TimePicker from "rc-time-picker"
import "rc-time-picker/assets/index.css"
import "react-datepicker/dist/react-datepicker.css"
import moment from "moment"

import InlineError from "../../shared/InlineError"

const _ = require("lodash")
function CloseVoyage(props) {
  console.log(props)
  const [departure_date, setDeparture] = useState()
  const [departure_date_time, setDepartureTime] = useState()
  const [loading, setLoading] = useState(false)
  const [errors, setErrors] = useState({})

  const submit = (e, data) => {
    setLoading(true)

    const errors = validate()
    console.log(errors)
    setErrors((prevState) => ({ ...prevState, ...errors }))
    setLoading(false)
  }

  const validate = async () => {
    if (!departure_date) errors.departure_date = "Can't be blank"
    if (!departure_date_time) errors.departure_date_time = "Can't be blank"

    if (Object.keys(errors).length === 0) {
      setLoading(true)

      await props.closeVesselVoyage({
        variables: {
          voyage_id: props.match.params.voyage_id,
          actual_departure_time: moment(departure_date_time).format("HH:mm"),
          actual_departure: moment(departure_date).format("YYYY-MM-DD"),
        },
      })
      props.history.push("/prestack/operations/voyages")
    }

    return errors
  }

  return (
    <Grid textAlign="center" style={{ height: "100%" }} verticalAlign="top">
      <Grid.Column style={{ maxWidth: 600 }}>
        <Header as="h1" color="green">
          Enter Other Details
        </Header>
        <Divider />
        <Form size="large" textAlign="center">
          <Form.Field error={!!errors.departure_date}>
            <label>Departure Date</label>{" "}
            <DatePicker selected={departure_date} onChange={setDeparture} />
          </Form.Field>
          <Form.Field error={!!errors.departure_date_time}>
            <label>Departure Time</label>

            <TimePicker
              // style={{ width: 100 }}
              showSecond={false}
              defaultValue={moment()}
              className="xxx"
              onChange={setDepartureTime}
            />
            {errors.departure_date_time && (
              <InlineError text={errors.departure_date_time} />
            )}
          </Form.Field>
          <Form.Button fluid positive onClick={submit}>
            Submit
          </Form.Button>
        </Form>
      </Grid.Column>
    </Grid>
  )
}

const closeVesselVoyage = gql`
  mutation closeVesselVoyage(
    $voyage_id: String
    $actual_departure_time: String
    $actual_departure: String
  ) {
    closeVesselVoyage(
      voyage_id: $voyage_id
      actual_departure_time: $actual_departure_time
      actual_departure: $actual_departure
    )
  }
`
export default graphql(closeVesselVoyage, { name: "closeVesselVoyage" })(
  CloseVoyage
)
