import React, { Component } from "react";
import { Form, Button, Grid, Header, Divider } from "semantic-ui-react";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import InlineError from "../shared/InlineError";

class ShippingDetails extends Component {
  saveAndContinue = e => {
    e.preventDefault();
    console.log("Submitting ....")
    this.props.onSubmit();
  };
  back = e => {
    e.preventDefault();
    this.props.prevStep();
  };
  render() {
    const { values, state, errors } = this.props;
    return (
      <Grid.Column style={{ maxWidth: 600 }}>
        <Header as="h1" color="green" textAlign="center">
          Enter Other Details
        </Header>
        <Divider />
        <Form size="large">
        <Form.Field error={!!errors.weight}>
            <label>Container Weight</label>
            <input
              value={state.weight}
              type="number"
              onChange={this.props.handleChange("weight")}
            />
            {errors.weight && <InlineError text={errors.weight} />}
          </Form.Field>
          <Form.Field error={!!errors.preadviseDate}>
            <label>Preadvise Date</label>{" "}
            <DatePicker
              selected={values.preadviseDate}
              onChange={this.props.handlesPreadviseDateChange}
            />
          </Form.Field>
          {/* <Form.Field error={!!errors.preAdviseNum}>
            <label>Preadvise Number</label>
            <input
              value={state.preAdviseNum}
              type="text"
              onChange={this.props.handleChange("preAdviseNum")}
            />
            {errors.preAdviseNum && (
              <InlineError text={errors.preAdviseNum} />
            )}
          </Form.Field> */}
          <Form.Field error={!!errors.sOExpiryDate}>
            <label>Storage Order Expiry Date</label>{" "}
            <DatePicker
              selected={values.sOExpiryDate}
              onChange={this.props.handlesOExpiryDateChange}
            />
          </Form.Field>
          
          {/* <Form.Field error={!!errors.fileBillingNumber}>
            <label>File Billing No</label>
            <input
              value={state.fileBillingNumber}
              type="text"
              onChange={this.props.handleChange("fileBillingNumber")}
            />
            {errors.fileBillingNumber && (
              <InlineError text={errors.fileBillingNumber} />
            )}
          </Form.Field> */}
          <Form.Field error={!!errors.vessel}>
            <label>Vessel</label>
            <input
              value={state.vessel}
              type="text"
              onChange={this.props.handleChange("vessel")}
            />
            {errors.vessel && (
              <InlineError text={errors.vessel} />
            )}
          </Form.Field>
          <Button onClick={this.back}>Back</Button>
          <Button onClick={this.saveAndContinue}>Submit </Button>
        </Form>
      </Grid.Column>
    );
  }
}

export default ShippingDetails;
