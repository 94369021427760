import React, { Component } from 'react';
import {Grid, Button, Segment, Header, Form, Table, Dropdown} from 'semantic-ui-react'
import {toast} from 'react-toastify'
import InlineError from '../../shared/InlineError';
class StepContainers extends Component {
    saveAndContinue = e => {
        console.log(this.props.values.items)
        if (this.props.values.items.length ===0) {
            toast.error("Forgot to add containers", {position:toast.POSITION.TOP_CENTER,
                autoClose:false
                })
        }else{
            e.preventDefault();
            this.props.onSubmit();
        }
       
      };
    back = e => {
        e.preventDefault();
        this.props.prevStep();
      };
    render() { 
        const {
           errors, values, containerOptions, user
        }=this.props
        return ( 
            <Grid centered columns={2}>
            <Grid.Column textAlign="center" >
    
                <Header color="green" textAlign="center">Enter Containers</Header>
                <Segment>
                  <Form size="large" > 
                
                <Table celled>
                  <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>
                        Container Number
                    </Table.HeaderCell>
                    <Table.HeaderCell>
                        Container Size
                    </Table.HeaderCell>
                    <Table.HeaderCell>
                        Operator
                    </Table.HeaderCell>
                    <Table.HeaderCell>
                        Action
                    </Table.HeaderCell>
                      </Table.Row>
                  </Table.Header>
                  <Table.Body>
                  {values.items.map((o, i) => {
                      let context = this
                    return (<Table.Row key={"item-" + i}>
                        <Table.Cell>
                            {o.containerNum}
                        </Table.Cell>
                        <Table.Cell>
                            {o.containerValue}
                        </Table.Cell>
                        <Table.Cell>
                            {o.operatorCode}
                        </Table.Cell>
                        <Table.Cell>
                        <button
                            onClick={this.props.handleItemDeleted.bind(context, i)}
                        >
                            Delete
                        </button>
                        </Table.Cell> 
                    </Table.Row>
                    )
                    })}
                  </Table.Body>
                </Table>
                <Form.Field error={!!errors.containerNum}>
                    <label>CONTAINER NUMBER</label>
                    <input
                    value={values.containerNum}
                    type="text"
                    // onChange={this.updateMessage.bind(this)}
                     onChange={this.props.handleChange("containerNum")}
                    />
                  {errors.containerNum && <InlineError text={errors.containerNum} />}
                </Form.Field>

                <Form.Field error={!!errors.containerSize}>
                    <label>CONTAINER SIZE</label>
                    <Dropdown
                    value={values.containerValue}
                    search
                    selection
                    options={containerOptions}
                    onChange={this.props.setContainerValue.bind(this)}
                    />
                    {errors.containerSize && (
                    <InlineError text={errors.containerSize} />
                    )}
                </Form.Field>
                {/* <Form.Field error={!!errors.operatorCode}>
                    <label>Operator Code</label>
                    <Dropdown
                    value={values.operatorCode}
                    search
                    selection
                    options={operatorOptions}
                    onChange={this.props.setOperatorValue.bind(this)}
                    />
                    {errors.operatorCode && <InlineError text={errors.operatorCode} />}
                </Form.Field> */}
                    <Form.Button
                    positive fluid
                      onClick={this.props.handleClick.bind(this)}
                    >Add Container</Form.Button>
                    
                </Form>
                <Segment.Group horizontal>
                <Segment>
                    <Button fluid color='instagram'  onClick={this.back}>Back</Button>
                  </Segment>
                  <Segment>
                    <Button fluid color='google plus' onClick={this.saveAndContinue}>Submit </Button>
                    </Segment>
                </Segment.Group> 
                </Segment>
                {/* <Button fluid color='instagram' onClick={this.saveAndContinue}>Save And Continue </Button> */}
                </Grid.Column>
       </Grid>
         );
    }
}
 
export default StepContainers;