import React, { Component } from "react"
import { Accordion, Menu } from "semantic-ui-react"
import { Link } from "react-router-dom"

export default class PrestackSidebar extends Component {
  state = { activeIndex: 0 }
  handleItemClick = (e, { name }) => this.setState({ activeItem: name })

  handleClick = (e, titleProps) => {
    const { index } = titleProps
    const { activeIndex } = this.state
    const newIndex = activeIndex === index ? -1 : index

    this.setState({ activeIndex: newIndex })
  }

  render() {
    const { activeIndex } = this.state
    const CustomerServiceForm = (
      <Menu.Menu>
        <Menu.Item
          name="containerlist"
          as={Link}
          to="/prestack/customerservice/book"
          onClick={this.handleItemClick}
        >
          Book Container
        </Menu.Item>
        <Menu.Item
          name="containerlist"
          as={Link}
          to="/prestack/preadvise/list"
          onClick={this.handleItemClick}
        >
          Preadvise Containers
        </Menu.Item>
        <Menu.Item
          name="containerlist"
          as={Link}
          to="/prestack/preadvised/list"
          onClick={this.handleItemClick}
        >
          Preadvised Containers
        </Menu.Item>
        <Menu.Item
          name="preadvices"
          as={Link}
          to="/prestack/container/upload"
          onClick={this.handleItemClick}
        >
          Bulk Upload
        </Menu.Item>
        <Menu.Item
          name="rebooking"
          as={Link}
          to="/prestack/customerservice/restowlist"
          onClick={this.handleItemClick}
        >
          Rebookingseeee
        </Menu.Item>
        <Menu.Item
          name="containerlist"
          as={Link}
          to="/prestack/container/list"
          onClick={this.handleItemClick}
        >
          Container List
        </Menu.Item>
        <Menu.Item
          name="vessels"
          as={Link}
          to="/prestack/vessels/list"
          onClick={this.handleItemClick}
        >
          Vessels List
        </Menu.Item>

        <Menu.Item
          name="voyages"
          as={Link}
          to="/prestack/voyages/list"
          onClick={this.handleItemClick}
        >
          Voyage List
        </Menu.Item>

        <Menu.Item
          name="partyDelivering"
          as={Link}
          to="/prestack/partydelivering/list"
          onClick={this.handleItemClick}
        >
          Party Delivering
        </Menu.Item>
      </Menu.Menu>
    )
    const ReceptionForm = (
      <Menu.Menu>
        <Menu.Item
          name="arrivals"
          as={Link}
          to="/prestack/operations/arrivals"
          onClick={this.handleItemClick}
        >
          Recieve
        </Menu.Item>
        <Menu.Item
          name="departues"
          as={Link}
          to="/prestack/deliverynote/generate"
          onClick={this.handleItemClick}
        >
          Delivery Notes
        </Menu.Item>
        <Menu.Item
          name="departues"
          as={Link}
          to="/prestack/operations/departures"
          onClick={this.handleItemClick}
        >
          Dispatch
        </Menu.Item>
      </Menu.Menu>
    )

    const DepartureForm = (
      <Menu.Menu>
        <Menu.Item
          name="createpreadvice"
          as={Link}
          to="/prestack/operations/berthtallying"
          onClick={this.handleItemClick}
        >
          Berth Tallying
        </Menu.Item>
        <Menu.Item
          name="voyageListing"
          as={Link}
          to="/prestack/expected/voyages"
          onClick={this.handleItemClick}
        >
          Open Voyage
        </Menu.Item>
        <Menu.Item
          name="expectedvoyages"
          as={Link}
          to="/prestack/open/voyages"
          onClick={this.handleItemClick}
        >
          Close Voyage
        </Menu.Item>
      </Menu.Menu>
    )

    const TracingForm = (
      <Menu.Menu>
        <Menu.Item
          name="tracingList"
          as={Link}
          to="/prestack/operations/tracing"
          onClick={this.handleItemClick}
        >
          Tracing List
        </Menu.Item>

        <Menu.Item
          name="voyageListing"
          as={Link}
          to="/prestack/operations/shutlist"
          onClick={this.handleItemClick}
        >
          Shutout List
        </Menu.Item>
      </Menu.Menu>
    )

    const ReportsForm = (
      <Menu.Menu>
        <Menu.Item
          name="reportlist"
          as={Link}
          to="/prestack/reports/list"
          onClick={this.handleItemClick}
        >
          Container Summary
        </Menu.Item>
        <Menu.Item
          name="reportlist"
          as={Link}
          to="/prestack/reports/status"
          onClick={this.handleItemClick}
        >
          Container Status
        </Menu.Item>
        <Menu.Item
          name="audittrail"
          as={Link}
          to="/prestack/reports/audit"
          onClick={this.handleItemClick}
        >
          Audit Trail
        </Menu.Item>
      </Menu.Menu>
    )
    return (
      <Accordion as={Menu} vertical>
        <Menu.Item>
          <Accordion.Title
            active={activeIndex === 0}
            content="Client Desk"
            index={0}
            onClick={this.handleClick}
          />
          <Accordion.Content
            active={activeIndex === 0}
            content={CustomerServiceForm}
          />
          <Accordion.Title
            active={activeIndex === 1}
            content="Operations"
            index={1}
            onClick={this.handleClick}
          />
          <Accordion.Content
            active={activeIndex === 1}
            content={ReceptionForm}
          />
          <Accordion.Title
            active={activeIndex === 2}
            content="Departures"
            index={2}
            onClick={this.handleClick}
          />
          <Accordion.Content
            active={activeIndex === 2}
            content={DepartureForm}
          />
          <Accordion.Title
            active={activeIndex === 3}
            content="Tracing"
            index={3}
            onClick={this.handleClick}
          />
          <Accordion.Content active={activeIndex === 3} content={TracingForm} />
          <Accordion.Title
            active={activeIndex === 4}
            content="Reports"
            index={4}
            onClick={this.handleClick}
          />
          <Accordion.Content active={activeIndex === 4} content={ReportsForm} />
        </Menu.Item>
      </Accordion>
    )
  }
}
// TracingForm
