import React, { Component } from "react";
import moment from "moment";
import ReactHtmlTableToExcel from "react-html-table-to-excel";

class ExportClientToExcel extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    console.log("Excel Props", this.props.data);
    return (
      <div style={{ marginRight: "25px" }}>
        <ReactHtmlTableToExcel
          id="test-table-xls-button"
          className="export"
          table="table-to-xls"
          fileName="filteredData"
          sheet="tablexls"
          buttonText="Export"
        />
        <table hidden="true" id="table-to-xls">
          <thead>
            <tr>
              <th>NO</th>
              <th>CONTAINER NO.</th>

              <th>OPR</th>
              <th>SIZE</th>
              <th>REF NO.</th>
              <th>FILE BILLING NO.</th>
              <th>PRE-ADVISE DATE</th>
              <th>GATE IN ICD</th>
              <th>TIME</th>
              <th>BBL EIR NO. </th>
              <th>ETD NBO</th>
              <th>GATE OUT ICD</th>
              <th>TIME</th>
              <th>ETA MSA</th>
              <th>GATE IN MSA</th>
              <th>TIME</th>
              <th>WAGON NO.</th>
              <th>POSITION</th>
              <th>DATE DELIVERED</th>
              <th>D/NOTE NO.</th>
              <th>Invoice Number</th>
            </tr>
          </thead>
          <tbody>
            {this.props.data.map((preadvise, index) => {
              return (
                <tr key={preadvise.id}>
                  <td>{index + 1}</td>
                  <td>{preadvise.containerNum}</td>
                  <td>{preadvise.operatorCode}</td>
                  <td>{preadvise.containerSize}</td>
                  <td>{preadvise.preAdviseNum}</td>
                  <td>{preadvise.fileBillingNumber}</td>
                  <td>
                    {moment(preadvise.preadviseDate).format("DD.MM.YYYY")}
                  </td>
                  <td>
                    {preadvise.inDateICD !== "Invalid date" &&
                    preadvise.inDateICD !== null &&
                    preadvise.inTimeICD !== ""
                      ? moment(preadvise.inDateICD).format("DD-MMM-YY")
                      : ""}
                  </td>
                  <td>
                    {preadvise.inTimeICD !== "Invalid date" &&
                    preadvise.inTimeICD !== null &&
                    preadvise.inTimeICD !== ""
                      ? moment(preadvise.inTimeICD).format("hh:mm")
                      : ""}
                  </td>
                  <td>{preadvise.bblEIR}</td>
                  <td>
                    {preadvise.inDateICD !== "Invalid date" &&
                    preadvise.inDateICD !== null &&
                    preadvise.inTimeICD !== ""
                      ? moment(preadvise.inDateICD).format("DD-MMM-YY")
                      : ""}
                  </td>
                  <td>
                    {preadvise.loadingTime !== "Invalid date" &&
                    preadvise.loadingTime !== null
                      ? moment(preadvise.loadingTime).format("DD-MMM-YY")
                      : ""}
                  </td>
                  <td>
                    {preadvise.outTimeICD !== "Invalid date" &&
                    preadvise.outTimeICD !== null
                      ? moment(preadvise.outTimeICD).format("hh:mm")
                      : ""}
                  </td>
                  <td>
                    {preadvise.etaMSA !== "Invalid date" &&
                    preadvise.etaMSA !== null
                      ? moment(preadvise.etaMSA).format("DD-MMM-YY")
                      : ""}
                  </td>
                  <td>
                    {preadvise.arrivalDateMSA !== "Invalid date" &&
                    preadvise.arrivalDateMSA !== null &&
                    preadvise.arrivalDateMSA !== ""
                      ? moment(preadvise.arrivalDateMSA).format("DD-MMM-YY")
                      : ""}
                  </td>
                  <td>
                    {preadvise.inTimeMsa !== "Invalid date" &&
                    preadvise.inTimeMsa !== null
                      ? moment(preadvise.inTimeMsa).format("hh:mm")
                      : ""}
                  </td>
                  <td>{preadvise.wagonNum}</td>
                  <td>{preadvise.position}</td>
                  <td>
                    {preadvise.dateDelivered !== "Invalid date" &&
                    preadvise.dateDelivered !== null &&
                    preadvise.dateDelivered !== ""
                      ? moment(preadvise.dateDelivered).format("DD-MMM-YY")
                      : ""}
                  </td>
                  <td>{preadvise.deliveryNoteID}</td>
                  <td>{preadvise.invoiceNum}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  }
}

export default ExportClientToExcel;
