import React, { useState } from "react"
import gql from "graphql-tag"
import { graphql } from "react-apollo"
import {
  Form,
  Input,
  Message,
  Grid,
  Header,
  Divider,
  Button,
} from "semantic-ui-react"

import InlineError from "../../shared/InlineError"

const addVessel = gql`
  mutation addVessel($vessel_name: String) {
    addVessel(vessel_name: $vessel_name)
  }
`
function AddVessel(props) {
  const [vesselName, setVesselName] = useState()
  const [loading, setLoading] = useState()
  const [errors, setErrors] = useState({})

  const validate = async () => {
    const errors = {}

    if (!vesselName) errors.vesselName = "Can't be blank!"
    if (Object.keys(errors).length === 0) {
      await props.addVessel({
        variables: { vessel_name: vesselName },
        refetchQueries: [
          {
            query: gql`
              query getAllVessels {
                getAllVessels {
                  vessel_id
                  vessel_name
                }
              }
            `,
          },
        ],
      })
      props.history.push("/prestack/vessels/list")
    }
    return errors
  }
  const submit = () => {
    setLoading(true)
    const errors = validate()
    setErrors((prevErrors) => ({ ...prevErrors, ...errors }))
    setLoading(false)
  }
  return (
    <Grid textAlign="center" style={{ height: "100%" }} verticalAlign="top">
      <Grid.Column style={{ maxWidth: 600 }}>
        <Header as="h2" color="green" textAlign="center">
          Enter Vessel Details
        </Header>
        <Divider />
        <Form size="large">
          {errors.global && (
            <Message negative>
              <Message.Header> Something went wrong </Message.Header>
              <p>{errors.global}</p>
            </Message>
          )}
          <Form.Field inline required error={!!errors.vesselName}>
            <label>Vessel Name</label>
            <Input
              focus
              placeholder="MV..."
              value={vesselName}
              onChange={(e) => setVesselName(e.target.value)}
            />
            {errors.vesselName && <InlineError text={errors.vesselName} />}
          </Form.Field>
          <Form.Button positive fluid onClick={submit}>
            Submit
          </Form.Button>
        </Form>
      </Grid.Column>
    </Grid>
  )
}

export default graphql(addVessel, {
  name: "addVessel",
})(AddVessel)
