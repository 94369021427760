import * as React from "react"
import { Route, Switch } from "react-router-dom"
import Authenticate from "../modules/HOC/Authenticate"

import RegisterSuccess from "../modules/Register/ui/RegisterStatus"
import RegisterFail from "../modules/Register/ui/RegisterFail"
import ConfirmFailed from "../modules/ConfirmUser/ConfirmationFailed"
import Home from "../modules/Home/Home"
import PreadviceList from "../modules/Preadvising/PreadviceList"

import AddPreadvice from "../modules/Preadvising/AddPreadvice"
import addPreadviseDetails from "../modules/Preadvising/AddPreadviseDetails"
import ContainerList from "../modules/Containers/ContainerList"
import AddContainer from "../modules/Containers/AddContainer"
import ArrivalsList from "../modules/ArrivalsNrb/ArrivalsList"
import ArrivalsListMombasa from "../modules/ArrivalsMbs/ArrivalsMbsList"
import DepartureNrbList from "../modules/DepaturesNrb/DeparturesNrbList"
import DepartureMbsList from "../modules/DeparturesMsa/DeparturesMbsList"
import GuaranteeList from "../modules/Guarantee/GuaranteeList"
import AddGuarantee from "../modules/Guarantee/AddGuarantee"
import DeliveredList from "../modules/Administration/DeliveredList"
import DeliveryListCMA from "../modules/DeliverPrestack/DeliverCMA"
import addEIR from "../modules/Administration/Administer"
import addEIROPERATIONS from "../modules/DeliverPrestack/AdministerPrestack"
import InvoicesList from "../modules/Administration/Invoices"
import ExportInvoicesList from "../modules/Administration/ExportInvoices"
import addInvoice from "../modules/Administration/AddInvoice"
import TruckerList from "../modules/Trucker/TruckerList"
import AddTrucker from "../modules/Trucker/AddTrucker"
import ArrivalNrb from "../modules/ArrivalsNrb/addArrival"
import DepartureNrb from "../modules/DepaturesNrb/addDeparture"
import ArrivalMbs from "../modules/ArrivalsMbs/addArrival"

import CMACodecoReport from "../modules/DeparturesMsa/CodecoReportCMA"
import CodecoReportMbsList from "../modules/DeparturesMsa/CodecoReportMbsList"
import DepartureMbs from "../modules/DeparturesMsa/addDeparture"
import Create from "../modules/User/Create"
import UserList from "../modules/User/UserList"
import ChangePassword from "../modules/Authentication/ChangePassword"
import RemoveTran from "../modules/Preadvising/remove"
import ClientList from "../modules/Clients/ClientList"
import AddClient from "../modules/Clients/AddClient"
import OperatorList from "../modules/Operator/OperatorList"
import AddOperator from "../modules/Operator/AddOperator"
import Info from "../modules/Preadvising/info"
import ErrorProcessingMessage from "../modules/shared/error"

import SummaryReport from "../Reports/SummaryReport"
import ExportDeliveries from "../modules/Administration/ExportDeliveries"
import InvoicedList from "../modules/Administration/InvoicedList"
import SummaryVisual from "../Reports/SummaryVisual"
import EditDetails from "../modules/Administration/EditDetails"
import ClientReport from "../Reports/ClientReport"
import ShippingLineReport from "../Reports/ShippingLineReport"
import ManagementReport from "../Reports/ManagementSummaryReport"
import ManagePassword from "../modules/Authentication/ManageUserPassword"
import ArchivedReport from "../Reports/ArchivedReport"
import CreatePDF from "../modules/ArrivalsNrb/createPdf"
import DamageList from "../modules/Damages/DamageList"
import AddDamage from "../modules/Damages/AddDamage"
import ArchivedCreatePdf from "../Reports/ArchivedCreatePdf"
import ArchiveDownloads from '../Reports/DowloadPdf'

import MainRequest from "../modules/ClientSelfRequest/MainRequest"
import LaraReport from "../Reports/LaraReport"
import CashOrder from "../modules/ClientSelfRequest/CashClientOrders/StartStep.jsx"
import RelayOrder from "../modules/ClientSelfRequest/MerchantHaulage/StartStep.jsx"

// import ShippingLine from "../modules/ClientSelfRequest/ShippingLine";
import TableOrder from "../modules/ClientSelfRequest/TableOrder"
import StartStep from "../modules/ClientSelfRequest/ShippingLine/StartStep"
import Success from "../modules/ClientSelfRequest/Success"
import Warning from "../modules/ClientSelfRequest/Warning"
import MobileOrdersList from "../modules/MobileOrdersClientView/MobileOrdersList"
import MobileOrdersArrivalsList from "../modules/MobileOrdersClientView/MobileOrdersArrivalsList"
import MobileOrdersInTransit from "../modules/MobileOrdersClientView/MobileOrdersInTransit"
import MobileOrdersArrivalsMsaList from "../modules/MobileOrdersClientView/MobileOrdersArrivalsMsaList"
import MobileOrders from "../modules/Preadvising/MobileOrders"
import AddShippingDetails from "../modules/Preadvising/AddShippingDetails"
import MobileReport from "../modules/Preadvising/ClientContainerReport"
import BookingList from "../modules/Preadvising/BookingList"
import BookingDetails from "../modules/Preadvising/BookingDetails"
import ClientBookingList from "../modules/ClientSelfRequest/BookingManagement/ClientBookingList"
import ClientBookingDetails from "../modules/ClientSelfRequest/BookingManagement/ClientBookingDetails"

import DepotStockUpload from "../modules/PrestackManagement/ContainerReception/DepotStockUpload"
import AddVesselVoyage from "../modules/PrestackManagement/VesselManagement/AddVesselVoyage"
import VoyageList from "../modules/PrestackManagement/VesselManagement/VoyageList.jsx"
import PartyDeliveringList from "../modules/PrestackManagement/PartyDeliveringManagement/PartyDeliveringList"
import PrestackBooked from "../modules/PrestackManagement/ContainerReception/PrestackBooked"
import ArrivalList from "../modules/PrestackManagement/Operations/ArrivalList"
import AddArrivalPrestack from "../modules/PrestackManagement/Operations/AddArrival"
import PrestackRecieved from "../modules/PrestackManagement/ContainerDispatch/PrestackRecieved"
import AddDeparturePrestack from "../modules/PrestackManagement/ContainerDispatch/AddDeparturePrestack"
import PrestackPreadvise from "../modules/PrestackManagement/ContainerReception/PrestackPreadvise"
import AddPrestackPreadviseDetail from "../modules/PrestackManagement/ContainerReception/AddPrestackPreadviseDetail"
import VesselLoading from "../modules/PrestackManagement/BerthTallying/VesselLoading"
import AddVesselLoading from "../modules/PrestackManagement/BerthTallying/AddVesselLoading"
import TracingList from "../modules/PrestackManagement/Tracing/TracingList"
// import TracingDetails from "../modules/PrestackManagement/Tracing/TracingDetails"
import VoyageManagementList from "../modules/PrestackManagement/BerthTallying/OpenVoyageList"
import ShutList from "../modules/PrestackManagement/Tracing/ShutList"
import ShutlistDetails from "../modules/PrestackManagement/Tracing/ShutlistDetails"
import RestowDetails from "../modules/PrestackManagement/Tracing/RestowDetails"
import Vessels from "../modules/PrestackManagement/VesselManagement/VesselList"
import AddVessel from "../modules/PrestackManagement/VesselManagement/AddVessel"
import VesselList from "../modules/PrestackManagement/VesselManagement/VesselList"
import AddPartyDelivering from "../modules/PrestackManagement/PartyDeliveringManagement/AddPartyDelivering"
import CloseVoyage from "../modules/PrestackManagement/BerthTallying/CloseVoyage"
import RestowList from "../modules/PrestackManagement/ContainerReception/RestowList"
import ContainerRestow from "../modules/PrestackManagement/ContainerReception/ContainerRestow"
import BookPrestackContainer from "../modules/PrestackManagement/Booking/BookPrestackContainer"
import PrestackPreadvised from "../modules/PrestackManagement/ContainerReception/PrestackPreadviced.js"
import Codeco from "../modules/PrestackManagement/Reports/Codeco"
import StatusReport from "../modules/PrestackManagement/Reports/StatusReport"
import ExpectedVoyages from "../modules/PrestackManagement/BerthTallying/ExpectedVoyages"
import OpenVoyage from "../modules/PrestackManagement/BerthTallying/OpenVoyage"
import OpenVoyageList from "../modules/PrestackManagement/BerthTallying/OpenVoyageList"
import GenerateDeliveryPdf from "../modules/PrestackManagement/Booking/GenerateDeliveryPdf"
import CreatePrestackUser from "../modules/User/CreatePrestackUser"
import ChangeSelfServicePassword from "../modules/ClientSelfRequest/Authentication/ChangeSelfServicePassword"
import ExportDepotAuditTrail from "../modules/PrestackManagement/Reports/ExportDepotAuditTrail"
import DepotEditDetails from "../modules/PrestackManagement/Reports/DepotEditDetails"
import addDTIArrival from "../modules/PrestackManagement/Operations/addDTIArrival"
import CreatePrestackPdfs from '../modules/PrestackManagement/Operations/createPdf'
import BulkUploadPreadvice from "../modules/Preadvising/BulkUploadPreadvice"
import MonthlyStats from "../Reports/MonthlyStats"
class Routes extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }
  render() {
    return (
      <Switch>
        <Route exact={true} path="/" component={Home} />
        <Route exact={true} path="/home" component={Home} />
        <Route exact={true} path="/selfservice/check" component={Warning} />

        <Route
          exact={true}
          path="/register-confirmation"
          component={RegisterSuccess}
        />
        <Route exact={true} path="/register-failed" component={RegisterFail} />
        <Route exact={true} path="/confirm-failed" component={ConfirmFailed} />
        {/* <Route exact={true} path="/user/confirm/:token" component={ConfirmUser}/> */}
        <Route
          exact={true}
          path="/transaction/list"
          component={Authenticate(PreadviceList, [
            "CUSTOMERSERVICEMSA",
            "CUSTOMERSERVICENRB",
            "SUPERVISOR",
            "SUPERUSER",
            "ADMIN",
          ])}
        />
        <Route
          exact={true}
          path="/clienttransaction/list"
          component={Authenticate(MobileOrders, [
            "CUSTOMERSERVICEMSA",
            "CUSTOMERSERVICENRB",
            "SUPERVISOR",
            "SUPERUSER",
            "ADMIN",
          ])}
        />
        <Route
          exact={true}
          path="/transaction/new"
          component={Authenticate(AddPreadvice, [
            "CUSTOMERSERVICEMSA",
            "CUSTOMERSERVICENRB",
            "SUPERVISOR",
            "SUPERUSER",
            "ADMIN",
          ])}
        />
        <Route
          exact={true}
          path="/transaction/upload"
          component={Authenticate(BulkUploadPreadvice, [
            "CUSTOMERSERVICEMSA",
            "CUSTOMERSERVICENRB",
            "SUPERVISOR",
            "SUPERUSER",
            "ADMIN",
          ])}
        />
        <Route
          exact={true}
          path="/transaction/remove"
          component={Authenticate(RemoveTran, [
            "SUPERVISOR",
            "SUPERUSER",
            "ADMIN",
          ])}
        />
        <Route
          exact={true}
          path="/transaction/new/:id"
          component={Authenticate(addPreadviseDetails, [
            "CUSTOMERSERVICEMSA",
            "CUSTOMERSERVICENRB",
            "SUPERVISOR",
            "SUPERUSER",
            "ADMIN",
          ])}
        />
        <Route
          exact={true}
          path="/clienttransaction/new/:id"
          component={Authenticate(AddShippingDetails, [
            "CUSTOMERSERVICEMSA",
            "CUSTOMERSERVICENRB",
            "SUPERVISOR",
            "SUPERUSER",
            "ADMIN",
          ])}
        />
        <Route exact={true} path="/containers/list" component={ContainerList} />
        <Route
          exact={true}
          path="/containers/new"
          component={Authenticate(AddContainer, [
            "CUSTOMERSERVICENRB",
            "SUPERVISOR",
            "SUPERUSER",
            "ADMIN",
          ])}
        />
        <Route exact={true} path="/clients/list" component={ClientList} />
        <Route
          exact={true}
          path="/client/new"
          component={Authenticate(AddClient, [
            "CUSTOMERSERVICENRB",
            "SUPERVISOR",
            "SUPERUSER",
            "ADMIN",
          ])}
        />
        <Route exact={true} path="/operators/list" component={OperatorList} />
        <Route
          exact={true}
          path="/operator/new"
          component={Authenticate(AddOperator, [
            "CUSTOMERSERVICENRB",
            "SUPERVISOR",
            "SUPERUSER",
            "ADMIN",
          ])}
        />
        <Route
          exact={true}
          path="/arrivals/nrb/list"
          component={Authenticate(ArrivalsList, [
            "CUSTOMERSERVICEMSA",
            "CUSTOMERSERVICENRB",
            "OPERATIONSMSA",
            "OPERATIONSNRB",
            "SUPERVISOR",
            "SUPERUSER",
            "ADMIN",
          ])}
        />
        <Route
          exact={true}
          path="/transaction/arrival/nrb/:id/:containerNum/:preAdviseNum/:clientCode"
          component={Authenticate(ArrivalNrb, [
            "OPERATIONSNRB",
            "SUPERVISOR",
            "SUPERUSER",
            "ADMIN",
          ])}
        />
        <Route
          exact={true}
          path="/arrivals/mbs/list"
          component={Authenticate(ArrivalsListMombasa, [
            "CUSTOMERSERVICEMSA",
            "CUSTOMERSERVICENRB",
            "OPERATIONSMSA",
            "OPERATIONSNRB",
            "SUPERVISOR",
            "SUPERUSER",
          ])}
        />
        <Route
          exact={true}
          path="/transaction/arrival/mbs/:id"
          component={Authenticate(ArrivalMbs, [
            "OPERATIONSMSA",
            "SUPERVISOR",
            "SUPERUSER",
            "ADMIN",
          ])}
        />
        <Route
          exact={true}
          path="/departures/nrb/list"
          component={Authenticate(DepartureNrbList, [
            "CUSTOMERSERVICEMSA",
            "CUSTOMERSERVICENRB",
            "OPERATIONSMSA",
            "OPERATIONSNRB",
            "SUPERVISOR",
            "SUPERUSER",
            "ADMIN",
          ])}
        />
        <Route
          exact={true}
          path="/departures/nrb/:id"
          component={Authenticate(DepartureNrb, [
            "OPERATIONSNRB",
            "SUPERVISOR",
            "SUPERUSER",
            "ADMIN",
          ])}
        />
        <Route
          exact={true}
          path="/departures/mbs/list"
          component={Authenticate(DepartureMbsList, [
            "CUSTOMERSERVICEMSA",
            "CUSTOMERSERVICENRB",
            "OPERATIONSMSA",
            "OPERATIONSNRB",
            "SUPERVISOR",
            "SUPERUSER",
            "ADMIN",
          ])}
        />
        <Route
          exact={true}
          path="/departures/mbs/:id"
          component={Authenticate(DepartureMbs, [
            "OPERATIONSMSA",
            "SUPERVISOR",
            "SUPERUSER",
            "ADMIN",
          ])}
        />
        <Route
          exact={true}
          path="/report/list"
          component={CodecoReportMbsList}
        />
        <Route
          exact={true}
          path="/cmareport/list"
          component={CMACodecoReport}
        />
        <Route
          exact={true}
          path="/guarantee/list"
          component={Authenticate(GuaranteeList, [
            "CUSTOMERSERVICEMSA",
            "CUSTOMERSERVICENRB",
            "OPERATIONSMSA",
            "OPERATIONSNRB",
            "SUPERVISOR",
            "SUPERUSER",
            "ADMIN",
          ])}
        />
        <Route
          exact={true}
          path="/guarantee/new/:id"
          component={Authenticate(AddGuarantee, [
            "CUSTOMERSERVICENRB",
            "CUSTOMERSERVICEMSA",
            "SUPERVISOR",
            "SUPERUSER",
            "ADMIN",
          ])}
        />
        <Route
          exact={true}
          path="/delivered/list"
          component={Authenticate(DeliveredList, [
            "CUSTOMERSERVICEMSA",
            "CUSTOMERSERVICENRB",
            "OPERATIONSMSA",
            "OPERATIONSNRB",
            "SUPERVISOR",
            "SUPERUSER",
            "ADMIN",
          ])}
        />
        <Route
          exact={true}
          path="/delivered/cma"
          component={Authenticate(DeliveryListCMA, [
            "CUSTOMERSERVICEMSA",
            "CUSTOMERSERVICENRB",
            "OPERATIONSMSA",

            "SUPERVISOR",
            "SUPERUSER",
            "ADMIN",
          ])}
        />
        <Route
          exact={true}
          path="/deliveries/export"
          component={Authenticate(ExportDeliveries, [
            "ACCOUNTANT",
            "CUSTOMERSERVICEMSA",
            "CUSTOMERSERVICENRB",
            "OPERATIONSMSA",
            "OPERATIONSNRB",
            "SUPERVISOR",
            "SUPERUSER",
            "ADMIN",
          ])}
        />
        <Route
          exact={true}
          path="/administer/:id"
          component={Authenticate(addEIR, [
            "CUSTOMERSERVICEMSA",
            "OPERATIONSMSA",
            "SUPERVISOR",
            "SUPERUSER",
            "ADMIN",
          ])}
        />
        <Route
          exact={true}
          path="/administerprestack/:id"
          component={Authenticate(addEIROPERATIONS, [
            "CUSTOMERSERVICEMSA",
            "OPERATIONSMSA",
            "SUPERVISOR",
            "SUPERUSER",
            "ADMIN",
          ])}
        />
        <Route exact={true} path="/truckers/list" component={TruckerList} />
        <Route
          exact={true}
          path="/trucker/new"
          component={Authenticate(AddTrucker, [
            "OPERATIONSMSA",
            "OPERATIONSNRB",
            "SUPERVISOR",
            "SUPERUSER",
            "ADMIN",
          ])}
        />

        <Route
          exact={true}
          path="/invoices/list"
          component={Authenticate(InvoicesList, [
            "ACCOUNTANT",
            "CUSTOMERSERVICEMSA",
            "CUSTOMERSERVICENRB",
            "OPERATIONSMSA",
            "OPERATIONSNRB",
            "SUPERVISOR",
            "SUPERUSER",
            "ADMIN",
          ])}
        />
        <Route
          exact={true}
          path="/invoiced/list"
          component={Authenticate(InvoicedList, [
            "ACCOUNTANT",
            "CUSTOMERSERVICEMSA",
            "CUSTOMERSERVICENRB",
            "OPERATIONSMSA",
            "OPERATIONSNRB",
            "SUPERVISOR",
            "SUPERUSER",
            "ADMIN",
          ])}
        />
        <Route
          exact={true}
          path="/invoices/export"
          component={Authenticate(ExportInvoicesList, [
            "ACCOUNTANT",
            "CUSTOMERSERVICEMSA",
            "CUSTOMERSERVICENRB",
            "OPERATIONSMSA",
            "OPERATIONSNRB",
            "SUPERVISOR",
            "SUPERUSER",
            "ADMIN",
          ])}
        />
        <Route
          exact={true}
          path="/addInvoiceNum/:id"
          component={Authenticate(addInvoice, [
            "ACCOUNTANT",
            "SUPERVISOR",
            "SUPERUSER",
            "ADMIN",
          ])}
        />
        <Route
          exact={true}
          path="/users/new"
          component={Authenticate(Create, ["SUPERVISOR", "SUPERUSER", "ADMIN"])}
        />
        <Route
          exact={true}
          path="/prestackusers/new"
          component={Authenticate(CreatePrestackUser, [
            "SUPERVISOR",
            "SUPERUSER",
            "ADMIN",
          ])}
        />
        <Route
          exact={true}
          path="/users/list"
          component={Authenticate(UserList, [
            "SUPERVISOR",
            "SUPERUSER",
            "ADMIN",
          ])}
        />
        <Route
          exact={true}
          path="/change-password"
          component={ChangePassword}
        />
        <Route
          exact={true}
          path="/users/manage-password"
          component={Authenticate(ManagePassword, [
            "SUPERVISOR",
            "SUPERUSER",
            "ADMIN",
          ])}
        />
        <Route exact={true} path="/info" component={Info} />
        <Route exact={true} path="/error" component={ErrorProcessingMessage} />
        <Route exact={true} path="/reports" component={SummaryReport} />
        <Route exact={true} path="/mobilereports" component={MobileReport} />
        <Route exact={true} path="/archived" component={ArchivedReport} />
        <Route exact={true} path="/clientreports" component={ClientReport} />
        <Route
          exact={true}
          path="/shippingline"
          component={ShippingLineReport}
        />
        <Route exact={true} path="/lara" component={LaraReport} />
        <Route exact={true} path="/mgmtreport" component={ManagementReport} />
        <Route exact={true} path="/reports/visual" component={SummaryVisual} />
        <Route exact={true} path='/reports/monthlystats' component={MonthlyStats}/>
        <Route
          exact={true}
          path="/editdetails"
          component={Authenticate(EditDetails, [
            "SUPERVISOR",
            "SUPERUSER",
            "ADMIN",
          ])}
        />
        <Route exact={true} path="/createpdf" component={CreatePDF} />
        <Route exact={true} path="/archivedpdf" component={ArchivedCreatePdf} />
        <Route exact={true} path="/downloadpdf/:id" component={ArchiveDownloads}/>
        <Route
          exact={true}
          path="/damages/list"
          component={Authenticate(DamageList, [
            "CUSTOMERSERVICEMSA",
            "CUSTOMERSERVICENRB",
            "OPERATIONSMSA",
            "OPERATIONSNRB",
            "SUPERVISOR",
            "SUPERUSER",
            "ADMIN",
          ])}
        />
        <Route exact={true} path="/damage/new" component={AddDamage} />
        <Route
          exact={true}
          path="/selfservice/change-password"
          component={ChangeSelfServicePassword}
        />
        <Route
          exact={true}
          path="/selfservice/orderform"
          component={MainRequest}
        />
        <Route
          exact={true}
          path="/selfservice/orderform/cashclient"
          component={CashOrder}
        />
        <Route
          exact={true}
          path="/selfservice/orderform/table"
          component={TableOrder}
        />
        <Route
          exact={true}
          path="/selfservice/orderform/relayorder"
          component={RelayOrder}
        />
        <Route
          exact={true}
          path="/selfservice/orderform/shippingline"
          component={StartStep}
        />
        <Route
          exact={true}
          path="/selfservice/bookings/list"
          component={ClientBookingList}
        />
        <Route
          exact={true}
          // path="/addInvoiceNum/:id"
          path="/selfservice/bookings/details/:containerFileName"
          component={BookingDetails}
        />
        <Route
          exact={true}
          path="/selfservice/transaction/list"
          component={Authenticate(MobileOrdersList, [
            "CUSTOMERSERVICEMSA",
            "CUSTOMERSERVICENRB",
            "SUPERVISOR",
            "SUPERUSER",
            "ADMIN",
          ])}
        />
        <Route
          exact={true}
          path="/selfservice/transaction/arrivals"
          component={Authenticate(MobileOrdersArrivalsList, [
            "CUSTOMERSERVICEMSA",
            "CUSTOMERSERVICENRB",
            "SUPERVISOR",
            "SUPERUSER",
            "ADMIN",
          ])}
        />

        <Route
          exact={true}
          path="/selfservice/transaction/transit"
          component={Authenticate(MobileOrdersInTransit, [
            "CUSTOMERSERVICEMSA",
            "CUSTOMERSERVICENRB",
            "SUPERVISOR",
            "SUPERUSER",
            "ADMIN",
          ])}
        />

        <Route
          exact={true}
          path="/selfservice/transaction/mombasa"
          component={Authenticate(MobileOrdersArrivalsMsaList, [
            "CUSTOMERSERVICEMSA",
            "CUSTOMERSERVICENRB",
            "SUPERVISOR",
            "SUPERUSER",
            "ADMIN",
          ])}
        />
        <Route
          exact={true}
          path="/bookings/list"
          component={Authenticate(BookingList, [
            "CUSTOMERSERVICEMSA",
            "CUSTOMERSERVICENRB",
            "SUPERVISOR",
            "SUPERUSER",
            "ADMIN",
          ])}
        />
        <Route
          exact={true}
          // path="/addInvoiceNum/:id"
          path="/bookings/details/:containerFileName"
          component={Authenticate(BookingDetails, [
            "CUSTOMERSERVICEMSA",
            "CUSTOMERSERVICENRB",
            "SUPERVISOR",
            "SUPERUSER",
            "ADMIN",
          ])}
        />
        <Route path="/selfservice/warning" component={Success} />
        <Route path="/prestack/container/upload" component={DepotStockUpload} />
        <Route path="/prestack/container/list" component={PrestackBooked} />
        <Route
          exact
          path="/prestack/preadvise/list"
          component={PrestackPreadvise}
        />
        <Route
          exact
          path="/prestack/preadvised/list"
          component={PrestackPreadvised}
        />
        <Route
          exact
          path="/prestack/preadvise/details/:id"
          component={AddPrestackPreadviseDetail}
        />
        <Route
          exact
          path="/prestack/operations/arrivals"
          component={ArrivalList}
        />
        <Route
          exact
          path="/prestack/operations/recieve/:id/:clientCode"
          component={AddArrivalPrestack}
        />
        <Route exact={true} path="/prestack/operations/createpdf" component={CreatePrestackPdfs} />
        <Route
          exact
          path="/prestack/operations/recieveDTI/:id/:container_number/:clientCode"
          component={addDTIArrival}
        />
        <Route
          exact
          path="/prestack/operations/departures"
          component={PrestackRecieved}
        />
        <Route
          exact
          path="/prestack/operations/departures/:id/:container_number"
          component={AddDeparturePrestack}
        />
        <Route exact path="/prestack/voyages/new" component={AddVesselVoyage} />
        <Route exact path="/prestack/voyages/list" component={VoyageList} />
        <Route
          exact
          path="/prestack/partydelivering/new"
          component={AddPartyDelivering}
        />
        <Route
          exact
          path="/prestack/partydelivering/list"
          component={PartyDeliveringList}
        />

        <Route
          exact
          path="/prestack/operations/berthtallying"
          component={VesselLoading}
        />
        <Route
          exact
          path="/prestack/operations/berth/:id/:container_number"
          component={AddVesselLoading}
        />
        <Route
          exact
          path="/prestack/operations/tracing"
          component={TracingList}
        />
      
        <Route
          exact
          path="/prestack/operations/voyages"
          component={VoyageManagementList}
        />
        <Route
          exact
          path="/prestack/expected/voyages"
          component={ExpectedVoyages}
        />
        <Route
          exact
          path="/prestack/expected/voyages/:voyage_id"
          component={OpenVoyage}
        />
        <Route exact path="/prestack/open/voyages" component={OpenVoyageList} />
        <Route
          exact
          path="/prestack/open/voyage/:voyage_id"
          component={CloseVoyage}
        />
        <Route
          exact
          path="/prestack/operations/shutlist"
          component={ShutList}
        />
        <Route
          exact
          path="/prestack/operations/shutlist/:id"
          component={RestowDetails}
        />
        <Route exact path="/prestack/vessels/list" component={VesselList} />
        <Route exact path="/prestack/vessels/new" component={AddVessel} />
        <Route
          exact
          path="/prestack/customerservice/restowlist"
          component={RestowList}
        />
        <Route
          exact
          path="/prestack/customerservice/restow/:id"
          component={ContainerRestow}
        />
        <Route
          exact
          path="/prestack/customerservice/book"
          component={BookPrestackContainer}
        />
        <Route exact path="/prestack/reports/list" component={Codeco} />
        <Route exact path="/prestack/reports/status" component={StatusReport} />
        <Route
          exact
          path="/prestack/deliverynote/generate"
          component={GenerateDeliveryPdf}
        />

        <Route
          exact
          path="/prestack/reports/audit"
          component={DepotEditDetails}
        />
      </Switch>
    )
  }
}

export default Routes
