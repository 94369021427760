import React, { Component } from "react";

import ReactHtmlTableToExcel from "react-html-table-to-excel";

class ExportInvoices extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    console.log("Excel Props", this.props.data);
    return (
      <div stylee={{ marginRight: "25px" }}>
        <ReactHtmlTableToExcel
          id="test-table-xls-button"
          className="export"
          table="table-to-xls"
          fileName="filteredData"
          sheet="tablexls"
          buttonText="Export"
        />
        <table hidden="true" id="table-to-xls">
          <thead>
            <tr>
              <th>CLIENT</th>
              <th>CONTAINER NO.</th>
              <th>SIZE</th>
              <th>DATE DELIVERED</th>
              <th>REF NO. </th>
              <th>FILE BILLING NO.</th>
              <th>D/NOTE NO</th>
              <th>VESSEL CODE</th>
              <th>INVOICE NUM</th>
            </tr>
          </thead>
          <tbody>
            {this.props.data.map(preadvise => {
              return (
                <tr key={preadvise.id}>
                  <td>{preadvise.clientCode}</td>
                  <td>{preadvise.containerNum}</td>
                  <td>{preadvise.containerSize}</td>
                  <td>{preadvise.dateDelivered}</td>
                  <td>{preadvise.preAdviseNum}</td>
                  <td>{preadvise.fileBillingNumber}</td>
                  <td>{preadvise.deliveryNoteID}</td>
                  <td>{preadvise.vessel}</td>
                  <td>{preadvise.invoiceNum}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  }
}

export default ExportInvoices;
