import { client } from "../../apollo"
import gql from "graphql-tag"
import store from "store"
import { AUTH_TOKEN } from "../shared/constants"
import {
  USER_LOGGED_IN,
  USER_LOGGED_OUT,
  MOBILEUSER_LOGGED_IN,
  MOBILEUSER_LOGGED_OUT,
} from "../Types/types"

export const userLoggedIn = (user) => ({
  type: USER_LOGGED_IN,
  user,
})
export const userLoggedOut = () => ({
  type: USER_LOGGED_OUT,
})

export const mobileUserLoggedIn = (user) => ({
  type: MOBILEUSER_LOGGED_IN,
  user,
})
export const mobileUserLoggedOut = () => ({
  type: MOBILEUSER_LOGGED_OUT,
})
export const login = (credentials) => (dispatch) => {
  console.log(credentials)
  return client
    .mutate({
      variables: {
        email: credentials.email[0],
        password: credentials.password[0],
      },
      mutation: gql`
        mutation LoginMutation($email: String!, $password: String!) {
          login(email: $email, password: $password) {
            user {
              id
              role
              authorized
              confirmed
              name
              confirmed
              department
            }
            token
            errors {
              path
              message
            }
          }
        }
      `,
    })
    .then((result) => {
      if (result.data.login.errors) {
        store.set("Login", "Wrong credentials")
      } else {
        localStorage.setItem(AUTH_TOKEN, result.data.login.token)
        store.set("userId", result.data.login.user.id)
        store.set("confirmed", result.data.login.user.confirmed)
        store.set("loggedIn", true)
        store.set("token", result.data.login.token)
        store.set("Login", "")
      }

      dispatch(userLoggedIn(result.data.login))
    })
    .catch((error) => {
      console.log(error)
    })
}

export const logout = () => (dispatch) => {
  localStorage.removeItem(AUTH_TOKEN)
  dispatch(userLoggedOut())
}

export const mobileLogout = () => (dispatch) => {
  localStorage.removeItem(AUTH_TOKEN)
  dispatch(mobileUserLoggedOut())
}

export const mobileLogin = (credentials) => (dispatch) => {
  console.log(credentials)
  return client
    .mutate({
      variables: {
        email: credentials.email,
        password: credentials.password,
      },
      mutation: gql`
        mutation MobileLoginMutation($email: String!, $password: String!) {
          login: mobileLogin(email: $email, password: $password) {
            ok
            user: mobileUser {
              id
              authorized
              confirmed
              firstName
              email
              locked
              client {
                id
                clientName
                serviceType
                clientType
              }
            }
            token
            errors {
              path
              message
            }
          }
        }
      `,
    })
    .then((result) => {
      console.log("RESULT: ", result)

      if (result.data.login.errors) {
        store.set("Login", "Wrong credentials")
      } else {
        localStorage.setItem(AUTH_TOKEN, result.data.login.token)
        store.set("userId", result.data.login.user.id)
        store.set("confirmed", result.data.login.user.confirmed)
        store.set("loggedIn", true)
        store.set("token", result.data.login.token)
        store.set("Login", "")
      }
      dispatch(mobileUserLoggedIn(result.data.login))
      return result
    })
    .catch((error) => {
      console.log(error)
      let errors = [{ message: "Username not recognized" }]
      let data = {}
      data = {
        login: {
          ok: false,
          errors,
        },
      }
      return { data: data }
    })
}

export const mobileSignup = (credentials) => (dispatch) => {
  console.log("CREDENTIALS", credentials)
  return client
    .mutate({
      variables: {
        firstName: credentials.userName,
        email: credentials.email,
        phoneNumber: credentials.phoneNumber,
        password: credentials.password,
        companyId: credentials.clientId,
      },
      mutation: gql`
        mutation mobileSignup(
          $firstName: String!
          $email: String!
          $phoneNumber: String!
          $password: String!
          $companyId: String!
        ) {
          mobileSignup(
            firstName: $firstName
            email: $email
            phoneNumber: $phoneNumber
            password: $password
            companyId: $companyId
          ) {
            ok

            errors {
              path
              message
            }
          }
        }
      `,
    })
    .then((result) => {
      // console.log("RESULT FROM AUTH::: ", result);
      // if (result.data.mobileSignup.errors) {
      //   store.set("Login", "Signup not successful");
      // } else {
      //   localStorage.setItem(AUTH_TOKEN, result.data.mobileSignup.token);
      //   store.set("userId", result.data.mobileSignup.mobileUser.id);
      //   store.set("confirmed", result.data.mobileSignup.mobileUser.confirmed);
      //   store.set("loggedIn", true);
      //   store.set("token", result.data.mobileSignup.token);
      //   store.set("Login", "");
      // }

      // dispatch(mobileUserLoggedIn(result.data))
      return result
    })
    .catch((error) => {
      console.log(error)
    })
}
