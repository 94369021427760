import React from "react";
import { client } from "../../../apollo";
import gql from "graphql-tag";
// import AuthService from "../services/auth.service";
import { Link } from "react-router-dom";
import Layout from "./Layout";


const Welcome = (props) => {
  
    if (props.match.path === "/selfservice/confirm/:confirmationToken") {
      console.log(props.match.params.confirmationToken);
      const {confirmationToken}=props.match.params
      client.mutate({
        variables:{confirmationToken:confirmationToken},
        mutation: gql`
        mutation confirmMobileUser($confirmationToken: String!) {
          confirmMobileUser(confirmationToken: $confirmationToken)
        }
        `
      }).then(result=>{
        return result
      })
        // AuthService.verifyUser(props.match.params.confirmationCode);
      }
     
      return (
        <Layout header="Account Activated">
            <Link to="/selfservice/login">Please Login</Link>
        </Layout>
      );
};
 
export default Welcome;