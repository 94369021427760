import gql from "graphql-tag";

export default gql`
  query getAllClients {
    getAllClients {
      id
      clientCode
      clientName
      clientPhone
      clientEmailAddress
      clientAddress
      clientContact
      clientKraPin
      clientType
      serviceType
    }
  }
`;
