import gql from "graphql-tag";

export default gql`
  query getAllClients {
    getAllOperators {
      id
      operatorCode
      operatorName
    }
  }
`;
