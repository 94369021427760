
import gql from 'graphql-tag'
export default gql`
query getMobileOrdersListByUser {
  getMobileOrdersListByUser {
    containerFileName
    booked
    fileLocation
    sOExpiryDate
    partyDeliveringName
    numberbooked
    validationstatus
    remarks
  }
}
`