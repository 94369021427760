import gql from 'graphql-tag'

export default gql`
  query getPreadviseByContainerFileName(
    $containerFileName: String
    ) {
    getPreadviseByContainerFileName(containerFileName: $containerFileName) {
      id
      containerNum
      operatorCode
      clientCode
      containerSize
    }
  }
  `