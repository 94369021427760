import React, { Component } from "react"
import { Form, Message, Header, Divider } from "semantic-ui-react"
import gql from "graphql-tag"
import moment from "moment"
import { graphql } from "react-apollo"
import dispatchDepotStack from "./fetchContainers"
import InlineError from "../../shared/InlineError"
var truckerOptions = []
class AddVesselLoading extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      errors: {},
    }
  }

  onSubmit = () => {
    this._dispatchDepotContainer()
    // const errors = this.validate()
    // console.log("ERRORS::", errors)
    // if (Object.keys(errors).length === 0) {

    // }
    // this.setState({ errors })
  }

  render() {
    return (
      <>
        <Header as="h3" color="green" textAlign="center">
          Confirm {this.props.match.params.container_number}
        </Header>
        <Divider />
        <Form>
          <Form.Button fluid positive onClick={this.onSubmit}>
            Submit
          </Form.Button>
        </Form>
      </>
    )
  }
  _dispatchDepotContainer = async () => {
    await this.props
      .dispatchDepotContainer({
        variables: {
          id: this.props.match.params.id,
          date_loaded: moment().format(),
          time_loaded: moment().format("HH:mm"),
          status: "LOADED",
        },
        refetchQueries: [{ query: dispatchDepotStack }],
      })
      .then((result) => {
        console.log(result)
      })
      .catch((error) => {
        console.log(error)
      })
    this.props.history.push("/prestack/operations/berthtallying")
  }
}

const dispatchDepotContainer = gql`
  mutation dispatchDepotContainer(
    $id: ID!
    $container_number: String
    $date_loaded: String
    $time_loaded: String
    $status: String
  ) {
    dispatchDepotContainer(
      id: $id
      container_number: $container_number
      date_loaded: $date_loaded
      time_loaded: $time_loaded
      status: $status
    )
  }
`
export default graphql(dispatchDepotContainer, {
  name: "dispatchDepotContainer",
})(AddVesselLoading)
