import React, { Component } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import cellEditFactory from "react-bootstrap-table2-editor";
import LoadingMessage from "../shared/LoadingMessage";
import { graphql } from "react-apollo";
import gql from "graphql-tag";
import withQuery from "../HOC/withQuery";

import getAllContainers from "./Queries/fetchAllContainers";

class ContainerList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [...this.props.entries],
      pageNum: 1
    };
  }
  render() {
    if (this.state.data) {
      const { data } = this.state;
      console.log(data);
      const CaptionElement = () => (
        <h3
          style={{
            borderRadius: "0.25em",
            textAlign: "center",
            color: "green",
            border: "1px solid green",
            padding: "0.5em"
          }}
        >
          Container List
        </h3>
      );
      const { SearchBar } = Search;
      const columns = [
        {
          text: "Container Code",
          dataField: "containerCode",
          sort: true,
          editable: true
        },
        {
          text: "Container Size",
          dataField: "containerSize",
          sort: true,
          editable: true
        },
        {
          text: "Description",
          dataField: "description",
          sort: true,
          editable: true
        }
      ];
      const defaultSorted = [
        {
          dataField: "containerCode",
          order: "desc"
        }
      ];
      const pagination = paginationFactory({
        sizePerPage: 5
      });
      return (
        <>
          <CaptionElement />

          <ToolkitProvider
            keyField="id"
            data={data}
            columns={columns}
            defaultSorted={defaultSorted}
            search={{ defaultSearch: "" }}
          >
            {props => (
              <div>
                <div align="right">
                  <SearchBar {...props.searchProps} />
                </div>
                <hr />
                <BootstrapTable
                  {...props.baseProps}
                  striped
                  hover
                  condensed
                  pagination={pagination}
                  noDataIndication="Table is Empty"
                  cellEdit={cellEditFactory({
                    mode: "dbclick",
                    blurToSave: true,
                    beforeSaveCell: (oldValue, newValue, row, column) => {
                      console.log("column: ", column);
                      console.log("Before Saving Cell!!:", oldValue);
                      console.log("New Value: ", newValue);
                      console.log("Id: ", row.id);
                      this._editContainer(newValue, column, row);
                    }
                  })}
                />
              </div>
            )}
          </ToolkitProvider>
        </>
      );
    } else {
      return <LoadingMessage />;
    }
  }
  _editContainer = async (newValue, column, row) => {
    const { id } = row;
    await this.props.editContainer({
      variables: {
        id,
        [column.dataField]: newValue
      },
      refetchQuery: [{ getAllContainers }]
    });
    this.props.history.push("/containers/list");
  };
}

const EDITCONTAINER = gql`
  mutation editContainer(
    $id: ID!
    $containerCode: String
    $description: String
    $containerSize: String
  ) {
    editContainer(
      id: $id
      containerCode: $containerCode
      description: $description
      containerSize: $containerSize
    )
  }
`;

export default graphql(EDITCONTAINER, { name: "editContainer" })(
  withQuery(ContainerList, getAllContainers, "getAllContainers")
);
