import React, { Component } from "react";
import { connect } from "react-redux";

export default function willAuthenticate(ComposedComponent, users) {
  class Authenticate extends Component {
    render() {
      console.log("HOC AUTH:::::", this.props.user.user.user.role)
      if( this.props.user.mobileUser !== undefined && Object.keys(this.props.user.mobileUser).length !== 0 && this.props.user.mobileUser.constructor === Object){
        // console.log("MOBILEUSER:", user);
        let permissions = users.includes(this.props.user.mobileUser.role);
        let user = this.props.user.mobileUser
        return <ComposedComponent {...this.props} permissions={permissions} user={user} />;
      }else{
        // console.log("USER:", user);
        let permissions = users.includes(this.props.user.user.user.role);
        let user = this.props.user.user
        return <ComposedComponent {...this.props} permissions={permissions} user={user} />;
      }
      
    }
  }

  function mapStateToProps(state) {
    return {
      user: state.user
      
    };
  }
  return connect(mapStateToProps, null)(Authenticate);
}
