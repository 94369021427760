import React, { Component } from 'react';
import {Grid, Form, Button, Segment, Header, Dropdown} from 'semantic-ui-react'
import { Document, Page } from 'react-pdf';
import DatePicker from "react-datepicker";
import {toast} from 'react-toastify'
import "rc-time-picker/assets/index.css";
import InlineError from '../../shared/InlineError';

class ShippingDetails extends Component {
    saveAndContinue = e => {
     
      if (
        !this.props.values.clientCode ||
       
        !this.props.values.sOExpiryDate ||
        !this.props.values.file)
        {
          toast.error("Please key in all the details", {position:toast.POSITION.TOP_CENTER,
            autoClose:false
            })
        }
          else{
            e.preventDefault();
            this.props.nextStep();
          }
     
        
      };
    back = e => {
        e.preventDefault();
        this.props.prevStep();
      };
    render() { 
        const {values, clientOptions, 
            
              errors}=this.props
        return ( 
            <Grid centered columns={2}>
                <Grid.Column>
                <Header color="green" textAlign="center"> Other Details</Header>
                <Segment>
                <Form size="large" loading={values.loading}>
                <Form.Field error={!!errors.file}>
                <Document file={values.file} 
                     onLoadSuccess={this.props.onDocumentLoadSuccess} 
                     noData={<h4>Please upload Storing Order/Guarantee Form</h4>}>
                    <Page pageNumber={values.pageNumber} />
                </Document>
        
                {values.file ? <p>Page {values.pageNumber} of {values.numPages}</p> : null}
                <input type="file" onChange={this.props.onFileChange}>
                </input>
                {errors.file && (
                      <InlineError text={errors.file} />
                    )}
                </Form.Field>
                <Form.Field error={!!errors.sOExpiryDate}>
                    <label>STORING ORDER/GUARANTEE FORM EXPIRY DATE</label>{" "}
                    <DatePicker
                      selected={values.sOExpiryDate}
                      onChange={this.props.handlesOExpiryDateChange}
                    />
                    {errors.sOExpiryDate && (
                      <InlineError text={errors.sOExpiryDate} />
                    )}
                  </Form.Field>
               
                <Form.Field error={!!errors.clientCode}>
                    <label>SHIPPING LINE</label>
                    <Dropdown
                    value={values.clientCode}
                    search
                    selection
                    options={clientOptions}
                    onChange={this.props.setClientValue.bind(this)}
                    />
                    {errors.clientCode && <InlineError text={errors.clientCode} />}
                </Form.Field>
                
                
                

                <Form.Field error={!!errors.partyDelivering}>
                    <label>FORWARDER</label>
                    <input
                    value={this.props.user.user.user.client.clientName}
                    type="text"
                    // onChange={this.props.handleChange("partyDelivering")}
                    />
                    {errors.partyDelivering && (
                    <InlineError text={errors.partyDelivering} />
                    )}
                </Form.Field>

                
                <Segment.Group horizontal>
                <Segment>
                    <Button fluid color='instagram'  onClick={this.back}>Back</Button>
                  </Segment>
                  <Segment>
                    <Button fluid color='google plus' onClick={this.saveAndContinue}>Next </Button>
                    </Segment>
                </Segment.Group> 
                    
                </Form>
                </Segment>
                
                </Grid.Column>
            </Grid>
         );
    }
}
 
export default ShippingDetails;