import gql from "graphql-tag"

export default gql`
  query arrivalDepotStack {
    arrivalDepotStack {
      id
      container {
        container_number
      }
      partyDelivering {
        party_delivering_id
        party_delivering_name
      }
      reference_no
      preadvise_date
    }
  }
`
