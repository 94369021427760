import React, { useState } from "react"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory from "react-bootstrap-table2-paginator"
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import { Link } from "react-router-dom"
import { Icon } from "semantic-ui-react"
import withQuery from "../../HOC/withQuery"
import gql from "graphql-tag"
import RestowStack from "./fetchRestowList"
function RestowList(props) {
  const [items, setItems] = useState([...props.entries])
  const [pageNum, setPageNum] = useState(1)

  const CaptionElement = () => (
    <h3
      style={{
        borderRadius: "0.25em",
        textAlign: "center",
        color: "green",
        border: "1px solid green",
        padding: "0.5em",
      }}
    >
      Rebooking List
    </h3>
  )
  const { SearchBar, ClearSearchButton } = Search
  const columns = [
    {
      text: "Container",
      dataField: "container.container_number",
      sort: true,
      headerStyle: {
        backgroundColor: "#c8e6c9",
      },
    },
    {
      text: "Truck No. ",
      dataField: "mode_of_transport_id_out",
      sort: true,
      headerStyle: {
        backgroundColor: "#c8e6c9",
      },
    },
    {
      text: "Date Out",
      dataField: "date_out",
      sort: true,
      headerStyle: {
        backgroundColor: "#c8e6c9",
      },
    },

    {
      text: "Action",
      dataField: "df",
      isDummyField: true,
      headerStyle: {
        backgroundColor: "#c8e6c9",
      },
      formatter: (cellContent, row) => {
        return (
          <Link to={`/prestack/customerservice/restow/${row.id}`}>
            Restow
            <Icon name="angle double right" color="green" />
          </Link>
        )
      },
    },
  ]
  const defaultSorted = [
    {
      dataField: "NO",
      order: "desc",
    },
  ]
  const pagination = paginationFactory({
    sizePerPage: 5,
  })

  return (
    <div>
      <CaptionElement />
      <ToolkitProvider
        keyField="NO"
        data={items}
        columns={columns}
        defaultSorted={defaultSorted}
        search={{ defaultSearch: "" }}
      >
        {(props) => (
          <div>
            <div>
              <div align="right">
                <SearchBar {...props.searchProps} />
                <ClearSearchButton {...props.searchProps} />
              </div>
            </div>
            <hr />
            <div>
              <BootstrapTable
                className="expanded-container"
                {...props.baseProps}
                striped
                hover
                condensed
                pagination={pagination}
                noDataIndication="Table is Empty"
              />
            </div>
          </div>
        )}
      </ToolkitProvider>
    </div>
  )
}

export default withQuery(RestowList, RestowStack, "RestowStack")
