import React, { Component } from "react";
import {
  Form,
  Segment,
  Grid,
  Header,
  Message,
  Divider
} from "semantic-ui-react";
import "react-phone-number-input/style.css";
import gql from "graphql-tag";
import { graphql } from "react-apollo";

import InlineError from "../shared/InlineError";
import getAllOperators from "./Queries/fetchOperators";
import PermissionsMessage from "../shared/PermissionsMessage";
class AddOperator extends Component {
  constructor(props) {
    super(props);
    this.state = {
      operatorCode: "",
      operatorName: "",

      loading: false,
      errors: {}
    };
  }
  validate = () => {
    const errors = {};

    if (!this.state.operatorCode) errors.operatorCode = "Can't be blank";
    if (!this.state.operatorName) errors.operatorName = "Can't be blank";
    if (Object.keys(errors).length === 0) {
      this.setState({ loading: true });
      this._createOperator();
    }
    return errors;
  };
  onSubmit = () => {
    const errors = this.validate();
    this.setState({ errors });
  };
  render() {
    if (!this.props.permissions) return <PermissionsMessage />;
    const { errors, loading } = this.state;
    return (
      <Grid
        textAlign="center"
        style={{ height: "100%" }}
        verticalAlign="middle"
      >
        <Grid.Column style={{ maxWidth: 900 }}>
          <Header as="h2" color="green" textAlign="center">
            Create Operator
          </Header>
          <Divider />
          <Form size="large" onSubmit={this.onSubmit} loading={loading}>
            {errors.global && (
              <Message negative>
                <Message.Header> Something went wrong </Message.Header>
                <p>{errors.global}</p>
              </Message>
            )}

            <Segment>
              <Form.Field error={!!errors.operatorName}>
                <label>Operator Name</label>
                <input
                  value={this.state.operatorName}
                  onChange={e =>
                    this.setState({ operatorName: e.target.value })
                  }
                />
                {errors.operatorName && (
                  <InlineError text={errors.operatorName} />
                )}
              </Form.Field>

              <Form.Field error={!!errors.operatorCode}>
                <label>Operator Code</label>
                <input
                  value={this.state.operatorCode}
                  type="text"
                  onChange={e =>
                    this.setState({ operatorCode: e.target.value })
                  }
                />
                {errors.operatorCode && (
                  <InlineError text={errors.operatorCode} />
                )}
              </Form.Field>
            </Segment>

            <Form.Button fluid positive>
              Submit
            </Form.Button>
            <Segment stacked />
          </Form>
        </Grid.Column>
      </Grid>
    );
  }
  _createOperator = async () => {
    const { operatorCode, operatorName } = this.state;
    await this.props.addOperator({
      variables: {
        operatorCode,
        operatorName
      },
      refetchQueries: [{ query: getAllOperators }]
    });
    this.props.history.push("/operators/list");
  };
}

const CREATEOPERATOR = gql`
  mutation addOperator($operatorCode: String!, $operatorName: String!) {
    addOperator(operatorCode: $operatorCode, operatorName: $operatorName)
  }
`;

export default graphql(CREATEOPERATOR, { name: "addOperator" })(AddOperator);
