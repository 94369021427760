export const auditColumns = [
  // {
  //   Header: "NO",
  //   id: "rowNum",
  //   accessor: d => d.rowNum
  // },
  {
    Header: "CONTAINER NO.",
    id: "containerNum",
    accessor: (d) => d.containerNum,
  },
  {
    Header: "ADDED",
    id: "addedby",
    accessor: (d) => (d.addedby !== null ? d.addedby.name : ""),
  },
  {
    Header: "Extra",
    id: "extradetailsBy",
    accessor: (d) => (d.extradetailsBy !== null ? d.extradetailsBy.name : ""),
  },
  {
    Header: "Arr ICD",
    id: "recievedICDBy",
    accessor: (d) => (d.recievedICDBy !== null ? d.recievedICDBy.name : ""),
  },

  {
    Header: "Dept ICD",
    id: "exitICDby",
    accessor: (d) => (d.exitICDby !== null ? d.exitICDby.name : ""),
  },

  {
    Header: "Arr MSA",
    id: "recievedMSABy",
    accessor: (d) => (d.recievedMSABy !== null ? d.recievedMSABy.name : ""),
  },
  {
    Header: "Guarantee",
    id: "guaranteeBy",
    accessor: (d) => (d.guaranteeBy !== null ? d.guaranteeBy.name : ""),
  },
  {
    Header: "Dept MSA",
    id: "exitMSABy",
    accessor: (d) => (d.exitMSABy !== null ? d.exitMSABy.name : ""),
  },
  {
    Header: "Delivery",
    id: "deliverynotemsaby",
    accessor: (d) =>
      d.deliverynotemsaby !== null ? d.deliverynotemsaby.name : "",
  },
]
