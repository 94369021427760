import React, { Component } from "react";
import gql from "graphql-tag";
import {
  Form,
  Segment,
  Grid,
  Header,
  Message,
  Dropdown,
  Divider,
} from "semantic-ui-react";
import DatePicker from "react-datepicker";
import "rc-time-picker/assets/index.css";
import TimePicker from "rc-time-picker";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";
import {  graphql } from "react-apollo";
import {flowRight as compose} from 'lodash';
import PermissionsMessage from "../shared/PermissionsMessage";
import InlineError from "../shared/InlineError";
import getArrivalContainers from "./Queries/fetchAllArrivalsNrb";

import getTruckers from "../Trucker/Queries/fetchTruckers";
// import getAllDamages from "../Damages/queries/fetchAllDamages";

import axios from "axios";
// import { saveAs } from "file-saver";

// import ReactTags from "react-tag-autocomplete";

var truckerOptions = [];
var damageOptions = [];
class ArrivalNrb extends Component {
  constructor(props) {
    super(props);
    this.state = {
      inDateICD: "",
      inTimeICD: "",
      etdNbo: "",
      modeOfTransport: "",
      transportType: "",
      carrierID: "",
      modeOfTransportId: "",
      bblEIR: "",
      deliveryDriverName: "",
      deliveryDriverID: "",
      damages: [],
      damage_charges: 0,
      containerDamages: [],
      prevdata: this.props.location.state,
      errors: {},
      loading: false,
      bl_no: Math.floor(10000000 + Math.random() * 90000000),
    };
  }
  setTransportMode = (e, data) => {
    this.setState({ modeOfTransport: data.value });
  };
  onTransportTypeChange = (e, data) => {
    this.setState({ transportType: data.value });
  };
  onTimeChange = (time) => this.setState({ inTimeICD: time });
  etdNboChange = (etdNbo) => this.setState({ etdNbo });
  inDateICDChange = (inDateICD) => this.setState({ inDateICD });

  setTruckerValue = (_e, data) => {
    truckerOptions.forEach((element) => {
      if (element.value === data.value) {
        this.setState({ carrierID: element.id });
        this.setState({ truckerValue: element.value });
      }
    });
  };
  setDamageValue = (_e, data) => {
    console.log("DATA ::::::::::", data)
    let charges = 0;
    let damages = [];
    data.value.map((element) => {
      const damagedetail = damageOptions.find(
        (damage) =>  (damage.value === element)
      );
      console.log("DATA ::::::::::",damagedetail)
      let damageobject = {
        id: damagedetail.id,
        damage: damagedetail.value,
        manhours: damagedetail.manhours,
        materialCost: damagedetail.materialCost,
        description: damagedetail.description,
      };
      damages.push(damageobject);
      charges = charges + parseFloat(damagedetail.materialCost);
    });
    this.setState({ damage_charges: charges, damages: damages });
  };

  validate = () => {
    const errors = {};

    if (!this.state.inTimeICD) errors.inTimeICD = "Can't be blank";
    if (!this.state.inDateICD) errors.inDateICD = "Can't be blank";
    if (!this.state.etdNbo) errors.etdNbo = "Can't be blank";
    if (!this.state.modeOfTransport) errors.modeOfTransport = "Can't be blank";
    if (!this.state.transportType) errors.transportType = "Can't be blank";
    if (!this.state.carrierID) errors.carrierID = "Can't be blank";
    if (!this.state.modeOfTransportId)
      errors.modeOfTransportId = "Can't be blank";
    
    if (!this.state.deliveryDriverName)
      errors.deliveryDriverName = "Can't be blank";
    if (!this.state.deliveryDriverID)
      errors.deliveryDriverID = "Can't be blank";

    
    return errors;
  };

  onSubmit = () => {
    console.log("Submitting")
    const errors = this.validate();
    console.log("ERRORS::",errors)
    if (Object.keys(errors).length === 0) {
      this._addArrivals();
      this._addDamages();
      this.createAndDownloadPdf();
    }
    this.setState({ errors });
  };
  createAndDownloadPdf = () => {
    console.log(this.props.location);
   
    let data = {...this.state.prevdata, ...this.state}
    let containerDamages = [];
    this.state.damages.map((damage) => {
      containerDamages.push({
        preadviseNum: this.props.match.params.id,
        DamageCode: damage.id,
        containerNum: this.props.match.params.containerNum,
        BLNumber: this.state.bl_no,
        repairCode: damage.damage,
        manhours: damage.manhours,
        materialCost: damage.materialCost,
        description: damage.description,
      });
    });
    data.sendemail = true;
    data.recievedICDBy = this.props.user.user.name
    data.containerDamages = containerDamages;
    
    console.log("SEND MAIL", data);
    axios
      .post("http://localhost:5000/send-email", data)
      .then()
      .then((res) => {});
    // axios
    //   .post("https://cts.hawilisha.com/pdf/send-email", data)
    //   .then()
    //   .then((res) => {});
  };
  render() {
    // console.log();
    if (!this.props.permissions) return <PermissionsMessage />;
    const { loading, errors } = this.state;
    const modeOfTransportOptions = [
      { id: 1, value: "ROAD", text: "ROAD" },
      { id: 2, value: "RAIL", text: "RAIL" },
    ];
    const transportTypeOptions = [
      { id: 1, value: "TRUCK", text: "TRUCK" },
      { id: 2, value: "TRAIN", text: "TRAIN" },
    ];
    if (this.props.truckerFeed.loading === false) {
      let tempOp = this.props.truckerFeed.getAllTruckers;
      truckerOptions = [];
      tempOp.map((element) => {
        return truckerOptions.push({
          id: element.id,
          key: element.id,
          text: element.truckerName,
          value: element.truckerCode,
        });
      });
    }
    if (this.props.damageFeed.loading === false) {
      let tempOp = this.props.damageFeed.getAllDamages;
      damageOptions = [];
      tempOp.map((element) => {
        return damageOptions.push({
          id: element.id,
          key: element.id,
          value: element.repairCode,
          description: element.description,
          manhours: element.manhours,
          materialCost: element.materialCost,
          displayValue: element.repairCode,
          text: element.repairCode,
        });
      });
    }

    return (
      <>
        {" "}
        <Header as="h3" color="green" textAlign="center">
          Add Arrival Details
        </Header>
        <Form size="large" onSubmit={this.onSubmit} loading={loading}>
          {errors.global && (
            <Message negative>
              <Message.Header> Something went wrong </Message.Header>
              <p>{errors.global}</p>
            </Message>
          )}
          <Grid
            textAlign="center"
            style={{ height: "100%" }}
            columns={2}
            divided
          >
            
            <Grid.Row>
              <Grid.Column>
                <Divider />

                <Segment stacked>
                  <Form.Field error={!!errors.modeOfTransport}>
                    <label>Mode</label>
                    <Dropdown
                      value={this.state.modeOfTransport}
                      search
                      selection
                      options={modeOfTransportOptions}
                      onChange={this.setTransportMode.bind(this)}
                    />
                    {errors.modeOfTransport && (
                      <InlineError text={errors.modeOfTransport} />
                    )}
                  </Form.Field>
                  <Form.Field error={!!errors.inDateICD}>
                    <label>Arrival Date</label>{" "}
                    <DatePicker
                      selected={this.state.inDateICD}
                      onChange={this.inDateICDChange}
                    />
                    {errors.inDateICD && (
                      <InlineError text={errors.inDateICD} />
                    )}
                  </Form.Field>
                  <Divider />
                  <Form.Field error={!!errors.inTimeICD}>
                    <label>Arrival Time</label>

                    <TimePicker
                      // style={{ width: 100 }}
                      showSecond={false}
                      defaultValue={moment()}
                      className="xxx"
                      onChange={this.onTimeChange}
                    />
                    {errors.inTimeICD && (
                      <InlineError text={errors.inTimeICD} />
                    )}
                  </Form.Field>
                  <Divider />
                  <Form.Field error={!!errors.etdNbo}>
                    <label>Departure Day(Est.)</label>

                    <DatePicker
                      selected={this.state.etdNbo}
                      onChange={this.etdNboChange}
                    />
                    {errors.etdNbo && <InlineError text={errors.etdNbo} />}
                  </Form.Field>
                  <Divider />
                  <Form.Field error={!!errors.transportType}>
                    <label>Transport Type</label>
                    <Dropdown
                      value={this.state.transportType}
                      search
                      selection
                      options={transportTypeOptions}
                      onChange={this.onTransportTypeChange.bind(this)}
                    />
                    {errors.transportType && (
                      <InlineError text={errors.transportType} />
                    )}
                  </Form.Field>
                  <Form.Field error={!!errors.modeOfTransportId}>
                    <label>Reg No.</label>
                    <input
                      value={this.state.modeOfTransportId}
                      onChange={(e) =>
                        this.setState({ modeOfTransportId: e.target.value })
                      }
                    />
                    {errors.modeOfTransportId && (
                      <InlineError text={errors.modeOfTransportId} />
                    )}
                  </Form.Field>
                </Segment>
              </Grid.Column>
              <Grid.Column>
                <Divider />

                <Segment stacked>
                  <Form.Field error={!!errors.carrierID}>
                    <label>Carrier ID</label>
                    <Dropdown
                      value={this.state.truckerValue}
                      search
                      selection
                      options={truckerOptions}
                      onChange={this.setTruckerValue.bind(this)}
                    />
                    {errors.carrierID && (
                      <InlineError text={errors.carrierID} />
                    )}
                  </Form.Field>

                  <Form.Field error={!!errors.deliveryDriverName}>
                    <label>Driver Name</label>
                    <input
                      value={this.state.deliveryDriverName}
                      onChange={(e) =>
                        this.setState({ deliveryDriverName: e.target.value })
                      }
                    />
                    {errors.deliveryDriverName && (
                      <InlineError text={errors.deliveryDriverName} />
                    )}
                  </Form.Field>
                  <Form.Field error={!!errors.deliveryDriverID}>
                    <label>Driver ID Num</label>
                    <input
                      value={this.state.deliveryDriverID}
                      onChange={(e) =>
                        this.setState({ deliveryDriverID: e.target.value })
                      }
                    />

                    {errors.deliveryDriverID && (
                      <InlineError text={errors.deliveryDriverID} />
                    )}
                  </Form.Field>
                  <Form.Field error={!!errors.deliveryDriverID}>
                    <label>
                      Damages Material Cost : {this.state.damage_charges}
                    </label>
                    <Dropdown
                      placeholder="Damages"
                      fluid
                      multiple
                      search
                      selection
                      options={damageOptions}
                      onChange={this.setDamageValue.bind(this)}
                    />
                  </Form.Field>
                </Segment>
              </Grid.Column>
            </Grid.Row>
          </Grid>
          <Form.Button fluid positive onClick={
            this.onSubmit
          }>
            Submit
          </Form.Button>
        </Form>
      </>
    );
  }
  _addArrivals = async () => {
    const {
      inDateICD,
      inTimeICD,
      etdNbo,
      modeOfTransport,
      transportType,
      carrierID,
      modeOfTransportId,
      bblEIR,
      deliveryDriverName,
      deliveryDriverID,
    } = this.state;
    await this.props
      .addMutation({
        variables: {
          id: this.props.match.params.id,
          inDateICD: inDateICD,
          inTimeICD,
          etdNbo,
          modeOfTransport,
          transportType,
          carrierID,
          modeOfTransportId,
          bblEIR,
          deliveryDriverName,
          deliveryDriverID,
        },
        refetchQueries: [{ query: getArrivalContainers }],
      })
      .then((result) => {
        console.log(result);
      })
      .catch((error) => {
        console.log(error);
      });
    this.props.history.push("/arrivals/nrb/list");
  };

  _addDamages = async () => {
    this.state.damages.map(async (damage) => {
      await this.props
        .addDamageMutation({
          variables: {
            preadviseNum: this.props.match.params.id,
            DamageCode: damage.id,
            containerNum: this.props.match.params.containerNum,
            BLNumber: this.state.bl_no,
          },
        })
        .then((result) => {
          console.log(result);
        })
        .catch((error) => {
          console.log(error);
        });
    });
  };
}

const addMutation = gql`
  mutation(
    $id: ID!
    $inDateICD: String!
    $inTimeICD: String!
    $etdNbo: String!
    $modeOfTransport: String!
    $transportType: String!
    $carrierID: String!
    $modeOfTransportId: String!
    $bblEIR: String!
    $deliveryDriverName: String!
    $deliveryDriverID: String!
  ) {
    arrivalNrb(
      id: $id
      inDateICD: $inDateICD
      inTimeICD: $inTimeICD
      etdNbo: $etdNbo
      modeOfTransport: $modeOfTransport
      transportType: $transportType
      carrierID: $carrierID
      modeOfTransportId: $modeOfTransportId
      bblEIR: $bblEIR
      deliveryDriverName: $deliveryDriverName
      deliveryDriverID: $deliveryDriverID
    )
  }
`;

const addDamageMutation = gql`
  mutation(
    $preadviseNum: String!
    $DamageCode: ID!
    $BLNumber: ID!
    $containerNum: String!
  ) {
    addContainerDamages(
      preadviseNum: $preadviseNum
      DamageCode: $DamageCode
      BLNumber: $BLNumber
      containerNum: $containerNum
    )
  }
`;
const getAllDamages = gql`
query getAllDamages(
  $client:String
  ) {
  getAllDamages(client: $client){
  	id
    repairCode
    description
    manhours
    materialCost
    client
  }
}
`
export default compose(
  graphql(getTruckers, { name: "truckerFeed" }),
  graphql(getAllDamages, { 
    name: "damageFeed",
    options: (props) => ({ variables: { client: props.match.params.clientCode } })
  } ),
  graphql(addMutation, { name: "addMutation" }),
  graphql(addDamageMutation, { name: "addDamageMutation" })
)(ArrivalNrb);
