import gql from "graphql-tag";

export default gql`
  query getWorkDetails {
    getWorkDetails {
      containerNum
      clientCode
      addedby {
        id
        name
      }
      extradetailsBy {
        id
        name
      }
      recievedICDBy {
        id
        name
      }
      exitICDby {
        id
        name
      }
      recievedMSABy {
        id
        name
      }
      exitMSABy {
        id
        name
      }
      guaranteeBy {
        id
        name
      }
      deliverynotemsaby {
        id
        name
      }
      invoicedBy {
        id
        name
      }
      lastupdatedby {
        id
        name
      }
    }
  }
`;
