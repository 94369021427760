import gql from "graphql-tag";

export default gql`
  query getAllDamages {
    getAllDamages {
      id
      repairCode
      description
      manhours
      materialCost
    }
  }
`;
