import React, { Component } from "react";
import {
  Form,
  Segment,
  Grid,
  Header,
  Message,
  Divider
} from "semantic-ui-react";
import gql from "graphql-tag";
import { graphql } from "react-apollo";
import DatePicker from "react-datepicker";
import { Document, Page } from 'react-pdf';
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";
import "rc-time-picker/assets/index.css";
import TimePicker from "rc-time-picker";
import InlineError from "../shared/InlineError";
import getDeliveryNote from "./Queries/fetchAllDelivered";
import PermissionsMessage from "../shared/PermissionsMessage";

class addEIR extends Component {
  constructor(props) {
    super(props);
    this.state = {
      file: null,
      numPages: 0,
      pageNumber: 1,
      eirNum: "",
      eirDate: "",
      eirTime:"",
      loading: false,
      errors: {}
    };
  }
  validate = () => {
    const errors = {};

    if (!this.state.eirNum) errors.eirNum = "Can't be blank";
    if (!this.state.eirDate) errors.eirDate = "Can't be blank";
    if (!this.state.eirTime) errors.eirTime = "Can't be blank";
    if (!this.state.file) errors.file = "Can't be blank";

    this.setState({ loading: true });
    this._updateAdmin();
    return errors;
  };
  onSubmit = () => {
    const errors = this.validate();
    this.setState({ errors });
  };

  handleeirDateChange = date => {
    this.setState({ eirDate: date });
  };
  handleeirTimeChange = time => {
    this.setState({ eirTime: time });
  };
  onDocumentLoadSuccess = ({ numPages }) => {
    this.setState({ numPages });
  }
  onFileChange = (event) => {
    this.setState({
      file: event.target.files[0]
    });
  }
  nextPage = () => {
     
    const currentPageNumber = this.state.pageNumber;
    let nextPageNumber;
 
    if (currentPageNumber + 1 > this.state.numPages) {
      nextPageNumber = 1;
    } else {
      nextPageNumber = currentPageNumber + 1;
    }
 
    this.setState({
      pageNumber: nextPageNumber
    });
  }
  render() {
    if (!this.props.permissions) return <PermissionsMessage />;
    const { errors, loading } = this.state;
    return (
      <Form onSubmit={this.onSubmit} loading={loading}>
      <Grid
      divided='vertically' celled='internally'>
      
      <Header as="h2" color='teal'>Interchange Details</Header>
      <Grid.Row columns={2}>
      
        <Grid.Column>
        <Form.Field>
                <Document file={this.state.file} 
                     onLoadSuccess={this.onDocumentLoadSuccess} 
                     noData={<h4>Please upload EIR Form</h4>}>
                    <Page pageNumber={this.state.pageNumber} />
                </Document>
        
                {this.state.file ? <p>Page {this.state.pageNumber} of {this.state.numPages}</p> : null}
                <input type="file" onChange={this.onFileChange}>
                </input>
                </Form.Field>
        </Grid.Column>
        <Grid.Column >
        
          <Divider />
          
            {errors.global && (
              <Message negative>
                <Message.Header> Something went wrong </Message.Header>
                <p>{errors.global}</p>
              </Message>
            )}

        
            <Form.Field error={!!errors.eirDate}>
              <label>EIR Date</label>{" "}
              <DatePicker
                selected={this.state.eirDate}
                onChange={this.handleeirDateChange}
              />
            </Form.Field>
            <Divider />
            <Form.Field error={!!errors.eirNum}>
              <label>EIR NUM/DELIVERY NOTE</label>
              <input
                type="text"
                value={this.state.eirNum}
                rows={2}
                onChange={e => this.setState({ eirNum: e.target.value })}
              />
              {errors.eirNum && <InlineError text={errors.eirNum} />}
            </Form.Field>
            <Divider />
                  <Form.Field error={!!errors.eirTime}>
                    <label>Loading Time</label>

                    <TimePicker
                      // style={{ width: 100 }}
                      showSecond={false}
                      defaultValue={moment()}
                      className="xxx"
                      onChange={this.handleeirTimeChange}
                    />
                    {errors.eirTime && (
                      <InlineError text={errors.eirTime} />
                    )}
                  </Form.Field>
            <Form.Button fluid positive>
              Submit
            </Form.Button>
            <Segment stacked />
          
        </Grid.Column>
        
        </Grid.Row>
      </Grid>
      </Form>
    );
  }
  _updateAdmin = async () => {
    const { eirDate, eirNum, eirTime, file } = this.state;
    await this.props.addEIR({
      variables: {
        id: this.props.match.params.id,
        eirDate,
        eirTime,
        eirNum,
        file
      },

      refetchQueries: [{ query: getDeliveryNote }]
    });
    this.props.history.push("/delivered/list");
  };
}

const addEIRMUTATION = gql`
mutation updateAdminMombasa(
  $id: ID!
  $eirNum: String!
  $eirDate: String!
  $eirTime: String!
  $file: Upload
) {
  updateAdminMombasa(
    id: $id
    eirNum: $eirNum
    eirDate: $eirDate
    eirTime: $eirTime
    file: $file
  )
}
`;

export default graphql(addEIRMUTATION, { name: "addEIR" })(addEIR);
