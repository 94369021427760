import React, { Component } from "react";
import { Button, List } from "semantic-ui-react";
import moment from "moment";
import PermissionsMessage from "../shared/PermissionsMessage";

class RemoveTran extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    if (!this.props.permissions) return <PermissionsMessage />;
    console.log("PROPS: ", this.props);
    const {
      values: {
        clientCode,
        containerNum,
        containerCode,
        containerValue,
        operatorCode,
        weight,
        fEStatus,
        eQStatus,
        pOD,
        pOL,
        vessel,
        refNum,
        sOExpiryDate,
        preadviseDate
      }
    } = this.props;
    return (
      <div>
        <h1 className="ui centered">Confirm your Details</h1>
        <p>
          Click Confirm if the following details have been correctly entered
        </p>
        <List className="ui centered">
          <List.Item>
            <List.Content>Client Code: {clientCode}</List.Content>
          </List.Item>
          <List.Item>
            <List.Content>Container No: {containerNum}</List.Content>
          </List.Item>
          {/*   <List.Item>
            <List.Content>Container Size: {containerSize}</List.Content>
          </List.Item> */}
          <List.Item>
            <List.Content>Container Weight: {weight}</List.Content>
          </List.Item>
          <List.Item>
            <List.Content>Container Code : {containerCode} </List.Content>
          </List.Item>
          <List.Item>
            <List.Content>Container Size : {containerValue} </List.Content>
          </List.Item>
          <List.Item>
            <List.Content>Operator Code: {operatorCode}</List.Content>
          </List.Item>
          <List.Item>
            <List.Content>FE Status: {fEStatus}</List.Content>
          </List.Item>
          <List.Item>
            <List.Content>EQ Status: {eQStatus}</List.Content>
          </List.Item>
          <List.Item>
            <List.Content>Place of Destination: {pOD}</List.Content>
          </List.Item>
          <List.Item>
            <List.Content>Place of Loading: {pOL}</List.Content>
          </List.Item>
          <List.Item>
            <List.Content>Vessel: {vessel}</List.Content>
          </List.Item>
          <List.Item>
            <List.Content>Reference Number: {refNum}</List.Content>
          </List.Item>
          <List.Item>
            <List.Content>
              Storage Order Expire:
              {moment(sOExpiryDate).format("MMMM Do YYYY")}
            </List.Content>
          </List.Item>
          <List.Item>
            <List.Content>
              Preadvise Date: {moment(preadviseDate).format("MMMM Do YYYY")}
            </List.Content>
          </List.Item>
        </List>

        <Button onClick={this.back}>Back</Button>
        <Button onClick={this.saveAndContinue}>Confirm</Button>
      </div>
    );
  }
}

export default RemoveTran;
