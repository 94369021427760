import React, { Component } from "react";
import {
  Form,
  Segment,
  Grid,
  Header,
  TextArea,
  Message,
  Divider
} from "semantic-ui-react";
import gql from "graphql-tag";
import { graphql } from "react-apollo";

import InlineError from "../shared/InlineError";
import getAllContainers from "./Queries/fetchAllContainers";
import PermissionsMessage from "../shared/PermissionsMessage";
class AddContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      containerCode: "",
      containerSize: "",
      description: "",
      loading: false,
      errors: {}
    };
  }
  validate = () => {
    const errors = {};

    if (!this.state.containerCode) errors.containerCode = "Can't be blank";
    if (!this.state.containerSize) errors.containerSize = "Can't be blank";
    if (!this.state.description) errors.description = "Can't be blank";

    this.setState({ loading: true });
    this._createContainer();
    return errors;
  };
  onSubmit = () => {
    const errors = this.validate();
    this.setState({ errors });
  };
  render() {
    if (!this.props.permissions) return <PermissionsMessage />;
    const { errors, loading } = this.state;
    return (
      <Grid
        textAlign="center"
        style={{ height: "100%" }}
        verticalAlign="middle"
      >
        <Grid.Column style={{ maxWidth: 600 }}>
          <Header as="h2" color="green" textAlign="center">
            Create Container
          </Header>
          <Divider />
          <Form size="large" onSubmit={this.onSubmit} loading={loading}>
            {errors.global && (
              <Message negative>
                <Message.Header> Something went wrong </Message.Header>
                <p>{errors.global}</p>
              </Message>
            )}
            <Form.Field error={!!errors.containerSize}>
              <label>Container Size</label>
              <input
                value={this.state.containerSize}
                type="number"
                onChange={e => this.setState({ containerSize: e.target.value })}
              />
              {errors.containerSize && (
                <InlineError text={errors.containerSize} />
              )}
            </Form.Field>

            <Form.Field error={!!errors.containerCode}>
              <label>Container Code</label>
              <input
                value={this.state.containerCode}
                type="text"
                onChange={e => this.setState({ containerCode: e.target.value })}
              />
              {errors.containerCode && (
                <InlineError text={errors.containerCode} />
              )}
            </Form.Field>
            <Form.Field error={!!errors.description}>
              <label>Other Details</label>
              <TextArea
                autoHeight
                value={this.state.description}
                rows={2}
                onChange={e => this.setState({ description: e.target.value })}
              />
              {errors.description && <InlineError text={errors.description} />}
            </Form.Field>
            <Form.Button fluid positive>
              Submit
            </Form.Button>
            <Segment stacked />
          </Form>
        </Grid.Column>
      </Grid>
    );
  }
  _createContainer = async () => {
    const { containerCode, containerSize, description } = this.state;
    await this.props.addContainer({
      variables: {
        containerCode,
        containerSize,
        description
      },

      refetchQueries: [{ query: getAllContainers }]
    });
    this.props.history.push("/containers/list");
  };
}

const CREATECONTAINER = gql`
  mutation addContainer(
    $containerCode: String!
    $description: String!
    $containerSize: String!
  ) {
    addContainer(
      containerCode: $containerCode
      description: $description
      containerSize: $containerSize
    )
  }
`;

export default graphql(CREATECONTAINER, { name: "addContainer" })(AddContainer);
