import React, { Component } from "react"
import { Accordion, Menu } from "semantic-ui-react"
import { Link } from "react-router-dom"

export default class MobileSideBarMenu extends Component {
  state = { activeIndex: 0 }

  handleItemClick = (e, { name }) => this.setState({ activeItem: name })

  handleClick = (e, titleProps) => {
    const { index } = titleProps
    const { activeIndex } = this.state
    const newIndex = activeIndex === index ? -1 : index

    this.setState({ activeIndex: newIndex })
  }

  render() {
    const { activeIndex } = this.state

    const PreadviceForm = (
      <Menu.Menu>
        <Menu.Item
          name="preadviceslist"
          as={Link}
          to="/selfservice/orderform"
          onClick={this.handleItemClick}
        >
          New Order
        </Menu.Item>
        <Menu.Item
          name="orderstatus"
          as={Link}
          to="/selfservice/bookings/list"
          onClick={this.handleItemClick}
        >
          Shipping Order Status
        </Menu.Item>
        <Menu.Item
          name="preadviceslist"
          as={Link}
          to="/selfservice/transaction/list"
          onClick={this.handleItemClick}
        >
          Expected
        </Menu.Item>
        <Menu.Item
          name="arrivals"
          as={Link}
          to="/selfservice/transaction/arrivals"
          onClick={this.handleItemClick}
        >
          Gate In ICD
        </Menu.Item>
        {/* <Menu.Item
          name="transit"
          as={Link}
          to="/selfservice/transaction/transit"
          onClick={this.handleItemClick}
        >
          In Transit
        </Menu.Item> */}

        <Menu.Item
          name="mombasa"
          as={Link}
          to="/selfservice/transaction/mombasa"
          onClick={this.handleItemClick}
        >
          Delivered To Depot
        </Menu.Item>
        <Menu.Item
          name="mombasa"
          as={Link}
          to="/selfservice/change-password"
          onClick={this.handleItemClick}
        >
          Change Password
        </Menu.Item>
      </Menu.Menu>
    )

    return (
      <Accordion as={Menu} vertical>
        <Menu.Item>
          <Accordion.Title
            active={activeIndex === 0}
            content="Preadvice Management"
            index={0}
            onClick={this.handleClick}
          />
          <Accordion.Content
            active={activeIndex === 0}
            content={PreadviceForm}
          />
        </Menu.Item>
      </Accordion>
    )
  }
}
