import Moment from "moment";
import { extendMoment } from "moment-range";
const moment = extendMoment(Moment);
export const InvoicedColumns = [
  {
    Header: "CONTAINER NO.",
    id: "containerNum",
    accessor: d => d.containerNum,
    filter: true
  },

  {
    Header: "SIZE",
    id: "containerSize",
    accessor: d => d.containerSize
  },

  {
    Header: "Weight",
    id: "weight",
    accessor: d => d.weight
  },
  {
    Header: "Client",
    id: "clientCode",
    accessor: d => d.clientCode
  },
  {
    Header: "Operator",
    id: "operatorCode",
    accessor: d => d.operatorCode
  },
  {
    Header: "EQStatus",
    id: "eQStatus",
    accessor: d => d.eQStatus
  },

  {
    Header: "FEStatus",
    id: "fEStatus",
    accessor: d => d.fEStatus
  },

  {
    Header: "POL",
    id: "pOL",
    accessor: d => d.pOL
  },

  {
    Header: "POD",
    id: "pOD",
    accessor: d => d.pOD
  },

  {
    Header: "S/O EXPIRY DATE",
    id: "sOExpiryDate",
    accessor: d => moment(d.sOExpiryDate).format("DD-MMM-YY")
  },

  {
    Header: "VESSEL",
    id: "vessel",
    accessor: d => d.vessel
  },

  {
    Header: "REF NO.",
    id: "preAdviseNum",
    accessor: d => d.preAdviseNum
  },
  {
    Header: "File Billing No.",
    id: "fileBillingNumber",
    accessor: d => d.fileBillingNumber
  },

  {
    Header: "PRE-ADVISE DATE",
    id: "preadviseDate",
    accessor: d => moment(d.preadviseDate).format("DD-MMM-YY")
  },

  {
    Header: "PARTY DELIVERING",
    id: "partyDeliveringName",
    accessor: d => d.partyDeliveringName
  },

  {
    Header: "GATE IN ICD",
    id: "inDateICD",
    accessor: d => moment(d.inDateICD).format("DD-MMM-YY")
  },

  {
    Header: "TIME",
    id: "inTimeICD",

    accessor: d => moment(d.inTimeICD).format("h:mm")
  },

  {
    Header: "BBL EIR NO.",
    id: "bblEIR",
    accessor: d => d.bblEIR
  },

  {
    Header: "ETD NBO",
    id: "inDateICD",
    accessor: d => moment(d.inDateICD).format("DD-MMM-YY")
  },

  {
    Header: "GATE OUT ICD",
    id: "loadingTime",
    accessor: d => moment(d.loadingTime).format("DD-MMM-YY")
  },
  {
    Header: "TIME",
    id: "outTimeICD",
    accessor: d => moment(d.outTimeICD).format("h:mm")
  },

  {
    Header: "ETA MSA",
    id: "etaMSA",
    accessor: d => moment(d.etaMSA).format("DD-MMM-YY")
  },
  {
    Header: "GATE IN MSA",
    id: "arrivalDateMSA",
    accessor: d => moment(d.arrivalDateMSA).format("DD-MMM-YY")
  },
  {
    Header: "TIME",
    id: "inTimeMsa",
    accessor: d => moment(d.inTimeMsa).format("h:mm")
  },
  {
    Header: "WAGON NO.",
    id: "wagonNum",
    accessor: d => d.wagonNum
  },
  {
    Header: "ModeOfTrans",
    id: "modeOfTransportOut",
    accessor: d => d.modeOfTransportOutMsa
  },
  {
    Header: "TypeOfTrans",
    id: "transportTypeOut",
    accessor: d => d.transportTypeOutMsa
  },
  {
    Header: "CarrierID",
    id: "carrierIDOutMsa",
    accessor: d => d.carrierIDOutMsa
  },
  {
    Header: "ModeOfTransID",
    id: "modeOfTransportIdOutMsa",
    accessor: d => d.modeOfTransportIdMsa
  },
  {
    Header: "POSITION",
    id: "position",
    accessor: d => d.position
  },
  {
    Header: "DATE DELIVERED",
    id: "dateDelivered",
    accessor: d => moment(d.dateDelivered).format("DD-MMM-YY")
  },
  {
    Header: "D/NOTE NO.",
    id: "deliveryNoteID",
    accessor: d => d.deliveryNoteID
  },
  {
    Header: "Invoice Number",
    id: "invoiceNum",
    accessor: d => d.invoiceNum
  },
  {
    Header: "Remarks",
    id: "remarks",
    accessor: d => d.remarks
  },
  {
    Header: "Client",
    id: "clientCode",
    accessor: d => d.clientCode
  },
  {
    Header: "DAMAGES",
    id: "containerDamageTotal",
    accessor: d => d.containerDamageTotal
  }
];
export const DeliveredColumns = [
  {
    Header: "CONTAINER NO.",
    id: "containerNum",
    accessor: d => d.containerNum
  },

  {
    Header: "SIZE",
    id: "containerSize",
    accessor: d => d.containerSize
  },

  {
    Header: "Weight",
    id: "weight",
    accessor: d => d.weight
  },
  {
    Header: "Client",
    id: "clientCode",
    accessor: d => d.clientCode
  },
  {
    Header: "Operator",
    id: "operatorCode",
    accessor: d => d.operatorCode
  },
  {
    Header: "EQStatus",
    id: "eQStatus",
    accessor: d => d.eQStatus
  },

  {
    Header: "FEStatus",
    id: "fEStatus",
    accessor: d => d.fEStatus
  },

  {
    Header: "POL",
    id: "pOL",
    accessor: d => d.pOL
  },

  {
    Header: "POD",
    id: "pOD",
    accessor: d => d.pOD
  },

  {
    Header: "S/O EXPIRY DATE",
    id: "sOExpiryDate",
    accessor: d => moment(d.sOExpiryDate).format("DD-MMM-YY")
  },

  {
    Header: "VESSEL",
    id: "vessel",
    accessor: d => d.vessel
  },

  {
    Header: "REF NO.",
    id: "preAdviseNum",
    accessor: d => d.preAdviseNum
  },
  {
    Header: "File Billing No.",
    id: "sOExpiryDate",
    accessor: d => d.fileBillingNumber
  },

  {
    Header: "PRE-ADVISE DATE",
    id: "preadviseDate",
    accessor: d => moment(d.preadviseDate).format("DD-MMM-YY")
  },

  {
    Header: "PARTY DELIVERING",
    id: "partyDeliveringName",
    accessor: d => d.partyDeliveringName
  },

  {
    Header: "GATE IN ICD",
    id: "inDateICD",
    accessor: d => moment(d.inDateICD).format("DD-MMM-YY")
  },

  {
    Header: "TIME",
    id: "inTimeICD",

    accessor: d => moment(d.inTimeICD).format("h:mm")
  },

  {
    Header: "BBL EIR NO.",
    id: "bblEIR",
    accessor: d => d.bblEIR
  },

  {
    Header: "ETD NBO",
    id: "inDateICD",
    accessor: d => moment(d.inDateICD).format("DD-MMM-YY")
  },

  {
    Header: "GATE OUT ICD",
    id: "loadingTime",
    accessor: d => moment(d.loadingTime).format("DD-MMM-YY")
  },
  {
    Header: "TIME",
    id: "outTimeICD",
    accessor: d => moment(d.outTimeICD).format("h:mm")
  },

  {
    Header: "ETA MSA",
    id: "etaMSA",
    accessor: d => moment(d.etaMSA).format("DD-MMM-YY")
  },
  {
    Header: "GATE IN MSA",
    id: "arrivalDateMSA",
    accessor: d => moment(d.arrivalDateMSA).format("DD-MMM-YY")
  },
  {
    Header: "TIME",
    id: "inTimeMsa",
    accessor: d => moment(d.inTimeMsa).format("h:mm")
  },
  {
    Header: "WAGON NO.",
    id: "wagonNum",
    accessor: d => d.wagonNum
  },
  {
    Header: "ModeOfTrans",
    id: "modeOfTransportOut",
    accessor: d => d.modeOfTransportOutMsa
  },
  {
    Header: "TypeOfTrans",
    id: "transportTypeOut",
    accessor: d => d.transportTypeOutMsa
  },
  {
    Header: "CarrierID",
    id: "carrierIDOutMsa",
    accessor: d => d.carrierIDOutMsa
  },
  {
    Header: "ModeOfTransID",
    id: "modeOfTransportIdOutMsa",
    accessor: d => d.modeOfTransportIdMsa
  },
  {
    Header: "POSITION",
    id: "position",
    accessor: d => d.position
  },
  {
    Header: "DATE DELIVERED",
    id: "dateDelivered",
    accessor: d => moment(d.dateDelivered).format("DD-MMM-YY")
  },
  {
    Header: "D/NOTE NO.",
    id: "deliveryNoteID",
    accessor: d => d.deliveryNoteID
  },
  {
    Header: "Invoice Number",
    id: "invoiceNum",
    accessor: d => d.invoiceNum
  },
  {
    Header: "Remarks",
    id: "remarks",
    accessor: d => d.remarks
  },
  {
    Header: "Client",
    id: "clientCode",
    accessor: d => d.clientCode
  },
  {
    Header: "DAMAGES",
    id: "containerDamageTotal",
    accessor: d => d.containerDamageTotal
  }
];
export const MombasaColumns = [
  {
    Header: "CONTAINER NO.",
    id: "containerNum",
    accessor: d => d.containerNum
  },

  {
    Header: "SIZE",
    id: "containerSize",
    accessor: d => d.containerSize
  },

  {
    Header: "Weight",
    id: "weight",
    accessor: d => d.weight
  },
  {
    Header: "Client",
    id: "clientCode",
    accessor: d => d.clientCode
  },
  {
    Header: "Operator",
    id: "operatorCode",
    accessor: d => d.operatorCode
  },
  {
    Header: "EQStatus",
    id: "eQStatus",
    accessor: d => d.eQStatus
  },

  {
    Header: "FEStatus",
    id: "fEStatus",
    accessor: d => d.fEStatus
  },

  {
    Header: "POL",
    id: "pOL",
    accessor: d => d.pOL
  },

  {
    Header: "POD",
    id: "pOD",
    accessor: d => d.pOD
  },

  {
    Header: "S/O EXPIRY DATE",
    id: "sOExpiryDate",
    accessor: d => moment(d.sOExpiryDate).format("DD-MMM-YY")
  },

  {
    Header: "VESSEL",
    id: "vessel",
    accessor: d => d.vessel
  },

  {
    Header: "REF NO.",
    id: "preAdviseNum",
    accessor: d => d.preAdviseNum
  },
  {
    Header: "File Billing No.",
    id: "sOExpiryDate",
    accessor: d => d.fileBillingNumber
  },

  {
    Header: "PRE-ADVISE DATE",
    id: "preadviseDate",
    accessor: d => moment(d.preadviseDate).format("DD-MMM-YY")
  },

  {
    Header: "PARTY DELIVERING",
    id: "partyDeliveringName",
    accessor: d => d.partyDeliveringName
  },

  {
    Header: "GATE IN ICD",
    id: "inDateICD",
    accessor: d => moment(d.inDateICD).format("DD-MMM-YY")
  },

  {
    Header: "TIME",
    id: "inTimeICD",

    accessor: d => moment(d.inTimeICD).format("h:mm")
  },

  {
    Header: "BBL EIR NO.",
    id: "bblEIR",
    accessor: d => d.bblEIR
  },

  {
    Header: "ETD NBO",
    id: "inDateICD",
    accessor: d => moment(d.inDateICD).format("DD-MMM-YY")
  },

  {
    Header: "GATE OUT ICD",
    id: "loadingTime",
    accessor: d => moment(d.loadingTime).format("h:mm")
  },
  {
    Header: "TIME",
    id: "outTimeICD",
    accessor: d => moment(d.outTimeICD).format("h:mm")
  },

  {
    Header: "ETA MSA",
    id: "etaMSA",
    accessor: d => moment(d.etaMSA).format("DD-MMM-YY")
  },

  {
    Header: "WAGON NO.",
    id: "wagonNum",
    accessor: d => d.wagonNum
  },
  {
    Header: "GATE IN MSA",
    id: "arrivalDateMSA",
    accessor: d => moment(d.arrivalDateMSA).format("DD-MMM-YY")
  },

  {
    Header: "TIME",
    id: "inTimeMsa",
    accessor: d => moment(d.inTimeMsa).format("h:mm")
  },
  {
    Header: "DAMAGES",
    id: "containerDamageTotal",
    accessor: d => d.containerDamageTotal
  }
];

export const OnRailColumns = [
  {
    Header: "CONTAINER NO.",
    id: "containerNum",
    accessor: d => d.containerNum
  },

  {
    Header: "SIZE",
    id: "containerSize",
    accessor: d => d.containerSize
  },

  {
    Header: "Weight",
    id: "weight",
    accessor: d => d.weight
  },
  {
    Header: "Client",
    id: "clientCode",
    accessor: d => d.clientCode
  },
  {
    Header: "Operator",
    id: "operatorCode",
    accessor: d => d.operatorCode
  },
  {
    Header: "EQStatus",
    id: "eQStatus",
    accessor: d => d.eQStatus
  },

  {
    Header: "FEStatus",
    id: "fEStatus",
    accessor: d => d.fEStatus
  },

  {
    Header: "POL",
    id: "pOL",
    accessor: d => d.pOL
  },

  {
    Header: "POD",
    id: "pOD",
    accessor: d => d.pOD
  },

  {
    Header: "S/O EXPIRY DATE",
    id: "sOExpiryDate",
    accessor: d => moment(d.sOExpiryDate).format("DD-MMM-YY")
  },

  {
    Header: "VESSEL",
    id: "vessel",
    accessor: d => d.vessel
  },

  {
    Header: "REF NO.",
    id: "preAdviseNum",
    accessor: d => d.preAdviseNum
  },
  {
    Header: "File Billing No.",
    id: "sOExpiryDate",
    accessor: d => d.fileBillingNumber
  },

  {
    Header: "PRE-ADVISE DATE",
    id: "preadviseDate",
    accessor: d => moment(d.preadviseDate).format("DD-MMM-YY")
  },

  {
    Header: "PARTY DELIVERING",
    id: "partyDeliveringName",
    accessor: d => d.partyDeliveringName
  },

  {
    Header: "GATE IN ICD",
    id: "inDateICD",
    accessor: d => moment(d.inDateICD).format("DD-MMM-YY")
  },

  {
    Header: "TIME",
    id: "inTimeICD",

    accessor: d => moment(d.inTimeICD).format("h:mm")
  },

  {
    Header: "BBL EIR NO.",
    id: "bblEIR",
    accessor: d => d.bblEIR
  },

  {
    Header: "ETD NBO",
    id: "inDateICD",
    accessor: d => moment(d.inDateICD).format("DD-MMM-YY")
  },

  {
    Header: "GATE OUT ICD",
    id: "loadingTime",
    accessor: d => moment(d.loadingTime).format("h:mm")
  },
  {
    Header: "TIME",
    id: "outTimeICD",
    accessor: d => moment(d.outTimeICD).format("h:mm")
  },

  {
    Header: "ETA MSA",
    id: "etaMSA",
    accessor: d => moment(d.etaMSA).format("DD-MMM-YY")
  },
  {
    Header: "WAGON NO.",
    id: "wagonNum",
    accessor: d => d.wagonNum
  },
  {
    Header: "DAMAGES",
    id: "containerDamageTotal",
    accessor: d => d.containerDamageTotal
  }
];

export const ICDNColumns = [
  {
    Header: "CONTAINER NO.",
    id: "containerNum",
    accessor: d => d.containerNum
  },

  {
    Header: "SIZE",
    id: "containerSize",
    accessor: d => d.containerSize
  },

  {
    Header: "Weight",
    id: "weight",
    accessor: d => d.weight
  },
  {
    Header: "Client",
    id: "clientCode",
    accessor: d => d.clientCode
  },
  {
    Header: "Operator",
    id: "operatorCode",
    accessor: d => d.operatorCode
  },
  {
    Header: "EQStatus",
    id: "eQStatus",
    accessor: d => d.eQStatus
  },

  {
    Header: "FEStatus",
    id: "fEStatus",
    accessor: d => d.fEStatus
  },

  {
    Header: "POL",
    id: "pOL",
    accessor: d => d.pOL
  },

  {
    Header: "POD",
    id: "pOD",
    accessor: d => d.pOD
  },

  {
    Header: "S/O EXPIRY DATE",
    id: "sOExpiryDate",
    accessor: d => moment(d.sOExpiryDate).format("DD-MMM-YY")
  },

  {
    Header: "VESSEL",
    id: "vessel",
    accessor: d => d.vessel
  },

  {
    Header: "REF NO.",
    id: "preAdviseNum",
    accessor: d => d.preAdviseNum
  },
  {
    Header: "File Billing No.",
    id: "sOExpiryDate",
    accessor: d => d.fileBillingNumber
  },

  {
    Header: "PRE-ADVISE DATE",
    id: "preadviseDate",
    accessor: d => moment(d.preadviseDate).format("DD-MMM-YY")
  },

  {
    Header: "PARTY DELIVERING",
    id: "partyDeliveringName",
    accessor: d => d.partyDeliveringName
  },

  {
    Header: "GATE IN ICD",
    id: "inDateICD",
    accessor: d => moment(d.inDateICD).format("DD-MMM-YY")
  },

  {
    Header: "TIME",
    id: "inTimeICD",

    accessor: d => moment(d.inTimeICD).format("h:mm")
  },

  {
    Header: "BBL EIR NO.",
    id: "bblEIR",
    accessor: d => d.bblEIR
  },
  {
    Header: "DAMAGES",
    id: "containerDamageTotal",
    accessor: d => d.containerDamageTotal
  }
];

export const EXPECTEDColumns = [
  {
    Header: "CONTAINER NO.",
    id: "containerNum",
    accessor: d => d.containerNum
  },

  {
    Header: "SIZE",
    id: "containerSize",
    accessor: d => d.containerSize
  },

  {
    Header: "Weight",
    id: "weight",
    accessor: d => d.weight
  },
  {
    Header: "Client",
    id: "clientCode",
    accessor: d => d.clientCode
  },
  {
    Header: "Operator",
    id: "operatorCode",
    accessor: d => d.operatorCode
  },
  {
    Header: "EQStatus",
    id: "eQStatus",
    accessor: d => d.eQStatus
  },

  {
    Header: "FEStatus",
    id: "fEStatus",
    accessor: d => d.fEStatus
  },

  {
    Header: "POL",
    id: "pOL",
    accessor: d => d.pOL
  },

  {
    Header: "POD",
    id: "pOD",
    accessor: d => d.pOD
  },

  {
    Header: "S/O EXPIRY DATE",
    id: "sOExpiryDate",
    accessor: d => moment(d.sOExpiryDate).format("DD-MMM-YY")
  },

  {
    Header: "VESSEL",
    id: "vessel",
    accessor: d => d.vessel
  },

  {
    Header: "REF NO.",
    id: "preAdviseNum",
    accessor: d => d.preAdviseNum
  },
  {
    Header: "File Billing No.",
    id: "sOExpiryDate",
    accessor: d => d.fileBillingNumber
  },

  {
    Header: "PRE-ADVISE DATE",
    id: "preadviseDate",
    accessor: d => moment(d.preadviseDate).format("DD-MMM-YY")
  },

  {
    Header: "PARTY DELIVERING",
    id: "partyDeliveringName",
    accessor: d => d.partyDeliveringName
  }
];

export const GATEINICD = [
  {
    Header: "NO",
    id: "rowNum",
    accessor: d => d.rowNum
  },
  {
    Header: "CONTAINER NO.",
    id: "containerNum",
    accessor: d => d.containerNum
  },
  {
    Header: "SIZE",
    id: "containerSize",
    accessor: d => d.containerSize
  },
  {
    Header: "Weight",
    id: "weight",
    accessor: d => d.weight
  },
  {
    Header: "EQStatus",
    id: "eQStatus",
    accessor: d => d.eQStatus
  },

  {
    Header: "FEStatus",
    id: "fEStatus",
    accessor: d => d.fEStatus
  },

  {
    Header: "POL",
    id: "pOL",
    accessor: d => d.pOL
  },

  {
    Header: "POD",
    id: "pOD",
    accessor: d => d.pOD
  },
  {
    Header: "S/O EXPIRY DATE",
    id: "sOExpiryDate",
    accessor: d => moment(d.sOExpiryDate).format("DD-MMM-YY")
  },

  {
    Header: "VESSEL",
    id: "vessel",
    accessor: d => d.vessel
  },

  {
    Header: "REF NO.",
    id: "preAdviseNum",
    accessor: d => d.preAdviseNum
  },
  {
    Header: "PRE-ADVISE DATE",
    id: "preadviseDate",
    accessor: d => moment(d.preadviseDate).format("DD-MMM-YY")
  },

  {
    Header: "PARTY DELIVERING",
    id: "partyDeliveringName",
    accessor: d => d.partyDeliveringName
  },
  {
    Header: "GATE IN ICD",
    id: "inDateICD",
    accessor: d => moment(d.inDateICD).format("DD-MMM-YY")
  },

  {
    Header: "TIME",
    id: "inTimeICD",

    accessor: d => moment(d.inTimeICD).format("h:mm")
  }
];


export const INVOICEDLARAColumns = [
  {
    Header: "NO",
    id: "rowNum",
    accessor: d => d.rowNum
  },
  {
    Header: "CONTAINER NO.",
    id: "containerNum",
    accessor: d => d.containerNum
  },
  
  {
    Header: "PARTY DELIVERING",
    id: "partyDeliveringName",
    accessor: d => d.partyDeliveringName
  },
  {
    Header: "MEA",
    id: "inTimeICD",
    accessor: d => moment(d.inTimeICD).format("DD-MMM-YY h:mm")
  },

  {
    Header: "MLR",
    id: "inTimeICD",
    accessor: d => moment(d.inTimeICD).format("DD-MMM-YY")
  },

  {
    Header: "MIT",
    id: "loadingTime",
    accessor: d => moment(d.loadingTime).format("DD-MMM-YY")
  },


  {
    Header: "MUR",
    id: "arrivalDateMSA",
    accessor: d => moment(d.inTimeMsa).format("DD-MMM-YY h:mm")
  },


  {
    Header: "MRX",
    id: "dateDelivered",
    accessor: d => moment(d.outTimeMsa).format("DD-MMM-YY h:mm")
  }
];

export const DELIVEREDLARAColumns = [
  {
    Header: "NO",
    id: "rowNum",
    accessor: d => d.rowNum
  },
  {
    Header: "CONTAINER NO.",
    id: "containerNum",
    accessor: d => d.containerNum
  },
  
  {
    Header: "PARTY DELIVERING",
    id: "partyDeliveringName",
    accessor: d => d.partyDeliveringName
  },
  {
    Header: "MEA",
    id: "inTimeICD",
    accessor: d => moment(d.inTimeICD).format("DD-MMM-YY h:mm")
  },


  {
    Header: "MLR",
    id: "inTimeICD",
    accessor: d => moment(d.inTimeICD).format("DD-MMM-YY")
  },

  {
    Header: "MIT",
    id: "loadingTime",
    accessor: d => moment(d.loadingTime).format("DD-MMM-YY")
  },


  {
    Header: "MUR",
    id: "arrivalDateMSA",
    accessor: d => moment(d.inTimeMsa).format("DD-MMM-YY h:mm")
  },

  {
    Header: "MRX",
    id: "dateDelivered",
    accessor: d => moment(d.outTimeMsa).format("DD-MMM-YY h:mm")
  }

];


export const MOMBASALARAColumns = [
  {
    Header: "NO",
    id: "rowNum",
    accessor: d => d.rowNum
  },
  {
    Header: "CONTAINER NO.",
    id: "containerNum",
    accessor: d => d.containerNum
  },
  
  {
    Header: "PARTY DELIVERING",
    id: "partyDeliveringName",
    accessor: d => d.partyDeliveringName
  },
  {
    Header: "MEA",
    id: "inTimeICD",
    accessor: d => moment(d.inTimeICD).format("DD-MMM-YY h:mm")
  },


  {
    Header: "MLR",
    id: "inTimeICD",
    accessor: d => moment(d.inTimeICD).format("DD-MMM-YY")
  },

  {
    Header: "MIT",
    id: "loadingTime",
    accessor: d => moment(d.loadingTime).format("DD-MMM-YY")
  },


  {
    Header: "MUR",
    id: "arrivalDateMSA",
    accessor: d => moment(d.inTimeMsa).format("DD-MMM-YY h:mm")
  },

];


export const ONRAILLARAColumns = [
  {
    Header: "NO",
    id: "rowNum",
    accessor: d => d.rowNum
  },
  {
    Header: "CONTAINER NO.",
    id: "containerNum",
    accessor: d => d.containerNum
  },
  
  {
    Header: "PARTY DELIVERING",
    id: "partyDeliveringName",
    accessor: d => d.partyDeliveringName
  },
  {
    Header: "MEA",
    id: "inTimeICD",
    accessor: d => moment(d.inTimeICD).format("DD-MMM-YY h:mm")
  },


  {
    Header: "MLR",
    id: "inTimeICD",
    accessor: d => moment(d.inTimeICD).format("DD-MMM-YY")
  },

  {
    Header: "MIT",
    id: "loadingTime",
    accessor: d => moment(d.loadingTime).format("DD-MMM-YY")
  }

];


export const ICDNLARAColumns = [
  {
    Header: "NO",
    id: "rowNum",
    accessor: d => d.rowNum
  },
  {
    Header: "CONTAINER NO.",
    id: "containerNum",
    accessor: d => d.containerNum
  },
  
  {
    Header: "PARTY DELIVERING",
    id: "partyDeliveringName",
    accessor: d => d.partyDeliveringName
  },
  {
    Header: "MEA",
    id: "inTimeICD",
    accessor: d => moment(d.inTimeICD).format("DD-MMM-YY h:mm")
  },


  {
    Header: "MLR",
    id: "inTimeICD",
    accessor: d => moment(d.inTimeICD).format("DD-MMM-YY")
  },


];

export const EXPECTEDLARAColumns = [
  {
    Header: "NO",
    id: "rowNum",
    accessor: d => d.rowNum
  },
  {
    Header: "CONTAINER NO.",
    id: "containerNum",
    accessor: d => d.containerNum
  },
  
  {
    Header: "PARTY DELIVERING",
    id: "partyDeliveringName",
    accessor: d => d.partyDeliveringName
  },



];