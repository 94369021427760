import React, { Component } from "react"
import { Link } from "react-router-dom"
import { connect } from "react-redux"
import { toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { Form, Message, Segment } from "semantic-ui-react"
import Layout from "./Layout"
import InlineError from "../../shared/InlineError"
import { mobileLogin } from "../../Actions/auth"

toast.configure()
class SelfServiceLogin extends Component {
  constructor(props) {
    super(props)
    this.state = {
      email: "",
      password: "",
      errors: {},
      loading: false,
    }
  }

  validate = () => {
    const errors = {}
    if (!this.state.email) errors.email = "Invalid email!"
    if (!this.state.password) errors.email = "Can't be blank"
    return errors
  }
  onSubmit = () => {
    const errors = this.validate()
    const { state } = this.props.location
    console.log("PROPS:::", this.props)
    if (Object.keys(errors).length === 0) {
      this.props.mobileLogin(this.state).then((data) => {
        console.log("RESULT:::::", data)
        const { login } = data.data
        if (login.ok === false || login.errors) {
          toast.error(login.errors[0].message, {
            position: toast.POSITION.TOP_CENTER,
            autoClose: false,
          })
        } else if (state) {
          toast.info(this.props.location.state.message, {
            position: toast.POSITION.TOP_CENTER,
            autoClose: false,
          })
        } else {
          this.props.history.push("/selfservice/orderform")
        }
      })
    }
  }

  render() {
    const { errors } = this.state

    return (
      <Layout header="Log in to get started">
        <Form onSubmit={this.onSubmit}>
          <Segment stacked>
            {errors.global && (
              <Message negative>
                <Message.Header>Something went wrong</Message.Header>
                <p>{errors.global}</p>
              </Message>
            )}

            <Form.Field error={!!errors.email}>
              <label htmlFor="email">Email</label>
              <input
                type="email"
                id="email"
                name="email"
                placeholder="example@example.com"
                value={this.state.email}
                onChange={(e) => this.setState({ email: e.target.value })}
              />
              {errors.email && <InlineError text={errors.email} />}
            </Form.Field>
            <Form.Field error={!!errors.password}>
              <label htmlFor="password">Password</label>
              <input
                type="password"
                id="password"
                name="password"
                placeholder="Make it secure"
                value={this.state.password}
                onChange={(e) => this.setState({ password: e.target.value })}
              />
              {errors.password && <InlineError text={errors.password} />}
            </Form.Field>
            <Form.Button fluid positive>
              Submit
            </Form.Button>
            <Message size="big">
              <Link to="/selfservice/signup">Not Registered?</Link>
            </Message>
            <Message size="big">
              <Link to="/selfservice/forgot">Forgot Password?</Link>
            </Message>
          </Segment>
        </Form>
      </Layout>
    )
  }
}

export default connect(null, { mobileLogin })(SelfServiceLogin)
