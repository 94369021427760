import React, { Component } from "react"
import { Form, Dropdown, Message, Header, Divider } from "semantic-ui-react"
import gql from "graphql-tag"
import moment from "moment"
import { graphql } from "react-apollo"
import { flowRight as compose } from "lodash"
import "react-datepicker/dist/react-datepicker.css"
import departureDepotStack from "./fetchContainers"
import InlineError from "../../shared/InlineError"

var truckerOptions = []
var ECHOptions = [
  { text: "BASTA", value: "BASTA" },
  { text: "KPA", value: "KPA" },
]

class AddDeparturePrestack extends Component {
  constructor(props) {
    super(props)
    this.state = {
      dispatch_driver_name: "",
      dispatch_driver_id: "",
      carrier_id_out: "",
      mode_of_transport_id_out: "",
      loading: false,
      errors: {},
    }
  }
  validate = () => {
    const errors = {}
    if (!this.state.carrier_id_out) errors.carrier_id_out = "Can't be blank"
    if (!this.state.mode_of_transport_id_out)
      errors.mode_of_transport_id_out = "Can't be blank"

    return errors
  }
  setTruckerValue = (_e, data) => {
    truckerOptions.forEach((element) => {
      if (element.value === data.value) {
        this.setState({ carrier_id_out: element.id })
        this.setState({ truckerValue: element.value })
      }
    })
  }
  setECHValue = (_e, data) => {
    ECHOptions.forEach((element) => {
      if (element.value === data.value) {
        // this.setState({ carrier_id_out: element.id })
        this.setState({ mode_of_transport_id_out: element.value })
      }
    })
  }
  onSubmit = () => {
    const errors = this.validate()

    if (Object.keys(errors).length === 0) {
      this._recieveDepotContainer()
    }
    this.setState({ errors })
  }
  handleChange = (input) => (event) => {
    this.setState({ [input]: event.target.value })
  }

  render() {
    const { errors } = this.state
    if (this.props.getAllTruckers.loading === false) {
      let tempOp = this.props.getAllTruckers.getAllTruckers
      truckerOptions = []
      tempOp.map((element) => {
        return truckerOptions.push({
          id: element.id,
          key: element.id,
          text: element.truckerName,
          value: element.truckerCode,
        })
      })
    }
    return (
      <>
        <Header as="h1" color="green" textAlign="center">
          Enter Loading details
        </Header>
        <Divider />
        <Form>
          {errors.global && (
            <Message negative>
              <Message.Header> Something went wrong </Message.Header>
              <p>{errors.global}</p>
            </Message>
          )}
          <Header as="h4" color="teal" textAlign="center">
            {this.props.match.params.container_number}
          </Header>
          <Form.Group widths="equal">
            <Form.Field error={!!errors.carrier_id}>
              <label>Carrier ID</label>
              <Dropdown
                value={this.state.truckerValue}
                search
                selection
                options={truckerOptions}
                onChange={this.setTruckerValue.bind(this)}
              />
              {errors.carrier_id && <InlineError text={errors.carrier_id} />}
            </Form.Field>
            <Form.Field error={!!errors.mode_of_transport_id_out}>
              <label>ECH No. </label>
              <Dropdown
                value={this.state.mode_of_transport_id_out}
                search
                selection
                options={ECHOptions}
                onChange={this.setECHValue.bind(this)}
              />
              {errors.mode_of_transport_id_out && (
                <InlineError text={errors.mode_of_transport_id_out} />
              )}
            </Form.Field>
          </Form.Group>
          <Form.Button fluid positive onClick={this.onSubmit}>
            Submit
          </Form.Button>
        </Form>
      </>
    )
  }
  _recieveDepotContainer = async () => {
    const { mode_of_transport_id_out, carrier_id_out } = this.state
    await this.props
      .exitDepotContainer({
        variables: {
          id: this.props.match.params.id,
          date_out: moment().format(),
          time_out: moment().format("HH:mm"),
          carrier_id_out: carrier_id_out,
          mode_of_transport_id_out,
        },
        refetchQueries: [{ query: departureDepotStack }],
      })
      .then((result) => {
        console.log(result)
      })
      .catch((error) => {
        console.log(error)
      })
    this.props.history.push("/prestack/operations/departures")
  }
}
const getAllTruckers = gql`
  query getAllTruckers {
    getAllTruckers {
      id
      truckerName
      truckerLongName
      truckerCode
      telNo
    }
  }
`
const exitDepotContainer = gql`
  mutation exitDepotContainer(
    $id: ID!
    $container_number: String
    $date_out: String
    $time_out: String
    $mode_of_transport_id_out: String
    $carrier_id_out: String
  ) {
    exitDepotContainer(
      id: $id
      container_number: $container_number
      carrier_id_out: $carrier_id_out
      date_out: $date_out
      time_out: $time_out
      mode_of_transport_id_out: $mode_of_transport_id_out
    )
  }
`
export default compose(
  graphql(getAllTruckers, { name: "getAllTruckers" }),
  graphql(exitDepotContainer, {
    name: "exitDepotContainer",
  })
)(AddDeparturePrestack)
