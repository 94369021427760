import React, { Component } from "react";
import { Form, Button, Grid, Header, Divider } from "semantic-ui-react";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

class OtherDetails extends Component {
  saveAndContinue = e => {
    e.preventDefault();
    this.props.nextStep();
  };
  back = e => {
    e.preventDefault();
    this.props.prevStep();
  };
  render() {
    const { values, errors } = this.props;
    return (
      <Grid.Column style={{ maxWidth: 600 }}>
        <Header as="h1" color="green" textAlign="center">
          Enter Other Details
        </Header>
        <Divider />
        <Form size="large">
          <Form.Field error={!!errors.preadviseDate}>
            <label>Preadvise Date</label>{" "}
            <DatePicker
              selected={values.preadviseDate}
              onChange={this.props.handlesPreadviseDateChange}
            />
          </Form.Field>
          <Form.Field error={!!errors.sOExpiryDate}>
            <label>Storage Order Expiry Date</label>{" "}
            <DatePicker
              selected={values.sOExpiryDate}
              onChange={this.props.handlesOExpiryDateChange}
            />
          </Form.Field>
          <Button onClick={this.back}>Back</Button>
          <Button onClick={this.saveAndContinue}>Save And Continue </Button>
        </Form>
      </Grid.Column>
    );
  }
}

export default OtherDetails;
