import React, { Component } from "react";
import {
  Form,
  Button,
  Dropdown,
  Grid,
  Divider,
  Header
} from "semantic-ui-react";

import InlineError from "../shared/InlineError";

class ContainerDetails extends Component {
  saveAndContinue = e => {
    e.preventDefault();
    this.props.nextStep();
  };
  back = e => {
    e.preventDefault();
    this.props.prevStep();
  };
  render() {
    const {
      values,
      errors,
      state,
      containerOptions,
      operatorOptions
    } = this.props;

    return (
      <Grid.Column style={{ maxWidth: 600 }}>
        <Header as="h1" color="green" textAlign="center">
          Enter Container Details
        </Header>
        <Divider />
        <Form>
          <Form.Field error={!!errors.containerNum}>
            <label>Container No</label>
            <input
              value={state.containerNum}
              type="text"
              onChange={this.props.handleChange("containerNum")}
            />
            {errors.containerNum && <InlineError text={errors.containerNum} />}
          </Form.Field>
          <Form.Field error={!!errors.containerSize}>
            <label>Container Size</label>
            <Dropdown
              value={values.containerValue}
              search
              selection
              options={containerOptions}
              onChange={this.props.setContainerValue.bind(this)}
            />
            {errors.containerSize && (
              <InlineError text={errors.containerSize} />
            )}
          </Form.Field>
          <Form.Field error={!!errors.operatorCode}>
            <label>Operator Code</label>
            <Dropdown
              value={state.operatorCode}
              search
              selection
              options={operatorOptions}
              onChange={this.props.setOperatorValue.bind(this)}
            />
            {errors.operatorCode && <InlineError text={errors.operatorCode} />}
          </Form.Field>
          <Form.Field error={!!errors.weight}>
            <label>Container Weight</label>
            <input
              value={state.weight}
              type="number"
              onChange={this.props.handleChange("weight")}
            />
            {errors.weight && <InlineError text={errors.weight} />}
          </Form.Field>
          <Button onClick={this.back}>Back</Button>
          <Button onClick={this.saveAndContinue}>Save And Continue </Button>
        </Form>
      </Grid.Column>
    );
  }
}

export default ContainerDetails;
