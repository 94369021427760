import React, { Component } from "react";
import {
  Form,
  Button,
  Dropdown,
  Grid,
  Header,
  Divider
} from "semantic-ui-react";

import InlineError from "../shared/InlineError";

class LogisticalDetails extends Component {
  saveAndContinue = e => {
    e.preventDefault();
    this.props.nextStep();
  };
  back = e => {
    e.preventDefault();
    this.props.prevStep();
  };
  render() {
    const {
      values,
      errors,
      EQStatusOptions,
      FEStatusOptions,
      POLOptions,
      PODOptions
    } = this.props;
    return (
      <Grid.Column style={{ maxWidth: 600 }}>
        <Header as="h1" color="green" textAlign="center">
          Enter Logistical Details
        </Header>
        <Divider />
        <Form>
          <Form.Field error={!!errors.eQStatus}>
            <label>EQ Status</label>
            <Dropdown
              name={"eQStatus"}
              value={values.eQStatus}
              search
              selection
              options={EQStatusOptions}
              onChange={this.props.setOptionsValue.bind(this)}
            />
            {errors.eQStatus && <InlineError text={errors.eQStatus} />}
          </Form.Field>

          <Form.Field error={!!errors.fEStatus}>
            <label>FE Status</label>
            <Dropdown
              name={"fEStatus"}
              value={values.fEStatus}
              search
              selection
              options={FEStatusOptions}
              onChange={this.props.setOptionsValue.bind(this)}
            />
            {errors.fEStatus && <InlineError text={errors.fEStatus} />}
          </Form.Field>

          <Form.Field error={!!errors.pOL}>
            <label>Place of Loading</label>
            <Dropdown
              name={"pOL"}
              value={values.pOL}
              search
              selection
              options={POLOptions}
              onChange={this.props.setOptionsValue.bind(this)}
            />
            {errors.pOL && <InlineError text={errors.pOL} />}
          </Form.Field>

          <Form.Field error={!!errors.pOD}>
            <label>Place of Destination</label>
            <Dropdown
              name={"pOD"}
              value={values.pOD}
              search
              selection
              options={PODOptions}
              onChange={this.props.setOptionsValue.bind(this)}
            />
            {errors.pOD && <InlineError text={errors.pOD} />}
          </Form.Field>
          <Button onClick={this.back}>Back</Button>
          <Button onClick={this.saveAndContinue}>Save And Continue </Button>
        </Form>
      </Grid.Column>
    );
  }
}

export default LogisticalDetails;
