import React, { Component } from "react";
import gql from "graphql-tag";
import { graphql } from "react-apollo";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import cellEditFactory from "react-bootstrap-table2-editor";
import LoadingMessage from "../shared/LoadingMessage";
import withQuery from "../HOC/withQuery";
import getAllOperators from "./Queries/fetchOperators";

class OperatorList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [...this.props.entries],
      pageNum: 1
    };
  }
  render() {
    if (this.state.data) {
      const { data } = this.state;
      console.log(data);
      const CaptionElement = () => (
        <h3
          style={{
            borderRadius: "0.25em",
            textAlign: "center",
            color: "green",
            border: "1px solid green",
            padding: "0.5em"
          }}
        >
          Operator List
        </h3>
      );
      const { SearchBar } = Search;
      const columns = [
        {
          text: "Operator Code",
          dataField: "operatorCode",
          sort: true,
          editable: true
        },

        {
          text: "Name",
          dataField: "operatorName",
          sort: true,
          editable: true
        }
      ];
      const defaultSorted = [
        {
          dataField: "operatorCode",
          order: "desc"
        }
      ];
      const pagination = paginationFactory({
        sizePerPage: 5
      });
      return (
        <>
          <CaptionElement />

          <ToolkitProvider
            keyField="id"
            data={data}
            columns={columns}
            defaultSorted={defaultSorted}
            search={{ defaultSearch: "" }}
          >
            {props => (
              <div>
                <div align="right">
                  <SearchBar {...props.searchProps} />
                </div>
                <hr />
                <BootstrapTable
                  {...props.baseProps}
                  striped
                  hover
                  condensed
                  pagination={pagination}
                  noDataIndication="Table is Empty"
                  cellEdit={cellEditFactory({
                    mode: "dbclick",
                    blurToSave: true,
                    beforeSaveCell: (oldValue, newValue, row, column) => {
                      console.log("column: ", column);
                      console.log("Before Saving Cell!!:", oldValue);
                      console.log("New Value: ", newValue);
                      console.log("Id: ", row.id);
                      this._editClient(newValue, column, row);
                    }
                  })}
                />
              </div>
            )}
          </ToolkitProvider>
        </>
      );
    } else {
      return <LoadingMessage />;
    }
  }

  _editOperator = async (newValue, column, row) => {
    await this.props.editOperator({
      variables: {
        ...row,
        [column.dataField]: newValue
      },
      refetchQuery: [{ getAllOperators }]
    });
    this.props.history.push("/clients/list");
  };
}

const EDITOPERATOR = gql`
  mutation editOperator(
    $id: ID!
    $operatorCode: String!
    $operatorName: String!
  ) {
    editOperator(
      id: $id
      operatorCode: $operatorCode
      operatorName: $operatorName
    )
  }
`;
export default graphql(EDITOPERATOR, { name: "editOperator" })(
  withQuery(OperatorList, getAllOperators, "getAllOperators")
);
