import gql from "graphql-tag";
export default gql`
  query getDeliveryNote {
    getDeliveryNote {
      id
      clientCode
      preAdviseNum
      containerNum
      guaranteeForm
      guaranteeFormValidity
      remarks
      deliveryNoteID
      truckerDriverName
      modeOfTransportIdMsa
      inDateICD
      dateDelivered
      containerSize
    }
  }
`;
