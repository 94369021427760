// Success.jsx
import React, { Component } from "react";
import {
  Form,
  Grid,
  Header,
  Message,
  Divider
} from "semantic-ui-react";
class Success extends Component {

  render() {
    console.log(this.props);
    const { errors } = this.props;
    return (
      <Grid
        textAlign="center"
        style={{ height: "100%" }}
        verticalAlign="middle"
      >
        <Grid.Column style={{ maxWidth: 600 }}>
          <Header as="h2" color="red" textAlign="center">
            Error Occured
          </Header>
          <Divider />
          <Form size="large" >
            {errors.global ? (
              <Message negative>
                <Message.Header> Something went wrong </Message.Header>
                <p>{errors.global}</p>
              </Message>
            ) : (
              <Message negative>
                <Message.Header> Something went wrong </Message.Header>
                <p>Please review your input</p>
              </Message>
            )}

       
          </Form>
        </Grid.Column>
      </Grid>
    );
  }
}

export default Success;
