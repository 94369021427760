import React, { Component } from "react"
import { Form, Message, Header, Divider } from "semantic-ui-react"
import gql from "graphql-tag"
import moment from "moment"
import { graphql } from "react-apollo"
import DatePicker from "react-datepicker"
import "rc-time-picker/assets/index.css"
import TimePicker from "rc-time-picker"
import { flowRight as compose } from "lodash"
import "react-datepicker/dist/react-datepicker.css"

import InlineError from "../../shared/InlineError"
var truckerOptions = []
class ShutlistDetails extends Component {
  constructor(props) {
    super(props)
    this.state = {
      date_loaded: "",
      time_loaded: "",
      bbl_eir: "",
      remarks: "",
      edt_stack: "",
      loading: false,
      errors: {},
    }
  }
  validate = () => {
    const errors = {}

    if (!this.state.date_loaded) errors.date_loaded = "Can't be blank"
    if (!this.state.time_loaded) errors.time_loaded = "Can't be blank"

    errors.bbl_eir = "Can't be blank"
    if (!this.state.bbl_eir) errors.edt_stack = "Can't be blank"
    if (!this.state.edt_stack) errors.edt_stack = "Can't be blank"
    if (!this.state.remarks) errors.remarks = "Can't be blank"

    return errors
  }
  onSubmit = () => {
    console.log("Submitting")
    const errors = this.validate()
    console.log("ERRORS::", errors)
    if (Object.keys(errors).length === 0) {
      this._recieveDepotContainer()
    }
    this.setState({ errors })
  }
  handleChange = (input) => (event) => {
    this.setState({ [input]: event.target.value })
  }
  setTransportMode = (e, data) => {
    this.setState({ mode_of_transport: data.value })
  }
  ontype_of_transportChange = (e, data) => {
    this.setState({ type_of_transport: data.value })
  }
  setTruckerValue = (_e, data) => {
    truckerOptions.forEach((element) => {
      if (element.value === data.value) {
        this.setState({ carrier_id: element.id })
        this.setState({ truckerValue: element.value })
      }
    })
  }
  time_loadedChange = (time) => this.setState({ time_loaded: time })
  date_loaded_Change = (date_loaded) => this.setState({ date_loaded })
  render() {
    const { loading, errors } = this.state

    if (this.props.getAllTruckers.loading === false) {
      let tempOp = this.props.getAllTruckers.getAllTruckers
      truckerOptions = []
      tempOp.map((element) => {
        return truckerOptions.push({
          id: element.id,
          key: element.id,
          text: element.truckerName,
          value: element.truckerCode,
        })
      })
    }

    return (
      <>
        <Header as="h1" color="green" textAlign="center">
          Enter Loading details
        </Header>
        <Divider />
        <Form>
          {errors.global && (
            <Message negative>
              <Message.Header> Something went wrong </Message.Header>
              <p>{errors.global}</p>
            </Message>
          )}

          {/* <Form.Group widths='equal'>
              
                  <Form.Field error={!!errors.remarks}>
                    <label>Reg No.</label>
                    <input
                      value={this.state.remarks}
                      onChange={(e) =>
                        this.setState({ remarks: e.target.value })
                      }
                    />
                    {errors.remarks && (
                      <InlineError text={errors.remarks} />
                    )}
                  </Form.Field>
                
                </Form.Group> */}
          <Form.Group widths="equal">
            <Form.Field error={!!errors.date_loaded_Change}>
              <label>Loading Date</label>{" "}
              <DatePicker
                selected={this.state.date_loaded}
                onChange={this.date_loaded_Change}
              />
              {errors.date_loaded_Change && (
                <InlineError text={errors.date_loaded_Change} />
              )}
            </Form.Field>

            <Form.Field error={!!errors.time_loadedChange}>
              <label>Loading Time</label>

              <TimePicker
                // style={{ width: 100 }}
                showSecond={false}
                defaultValue={moment()}
                className="xxx"
                onChange={this.time_loadedChange}
              />
              {errors.time_loadedChange && (
                <InlineError text={errors.time_loadedChange} />
              )}
            </Form.Field>
          </Form.Group>
          <Form.Group widths="equal">
            <Form.Field error={!!errors.bbl_eir}>
              <label>Loading EIR</label>
              <input
                value={this.state.bbl_eir}
                type="text"
                onChange={this.handleChange("bbl_eir")}
              />
              {errors.bbl_eir && <InlineError text={errors.bbl_eir} />}
            </Form.Field>

            <Form.Field error={!!errors.edt_stack}>
              <label>EDT Stack</label>
              <input
                value={this.state.edt_stack}
                type="text"
                onChange={this.handleChange("edt_stack")}
              />
              {errors.edt_stack && <InlineError text={errors.edt_stack} />}
            </Form.Field>
          </Form.Group>
          <Form.Field error={!!errors.remarks}>
            <label>Remarks</label>
            <input
              value={this.state.remarks}
              type="text"
              onChange={this.handleChange("remarks")}
            />
            {errors.remarks && <InlineError text={errors.remarks} />}
          </Form.Field>

          <Form.Button fluid positive onClick={this.onSubmit}>
            Submit
          </Form.Button>
        </Form>
      </>
    )
  }
  _recieveDepotContainer = async () => {
    const {
      date_loaded,
      time_loaded,
      mode_of_transport,
      type_of_transport,
      carrier_id,
      mode_of_transport_id,
      bbl_eir,
      edt_stack,
      remarks,
    } = this.state
    await this.props
      .recieveDepotContainer({
        variables: {
          date_loaded,
          time_loaded,
          mode_of_transport,
          type_of_transport,
          carrier_id,
          mode_of_transport_id,
          bbl_eir,
          edt_stack,
          remarks,
        },
      })
      .then((result) => {
        console.log(result)
      })
      .catch((error) => {
        console.log(error)
      })
    this.props.history.push("/prestack/operations/arrivals")
  }
}

const getAllTruckers = gql`
  query getAllTruckers {
    getAllTruckers {
      id
      truckerName
      truckerLongName
      truckerCode
      telNo
    }
  }
`
const dispatchDepotContainer = gql`
  mutation dispatchDepotContainer(
    $date_loaded: String
    $time_loaded: String
    $bbl_eir: String
    $remarks: String
    $edt_stack: String
  ) {
    dispatchDepotContainer(
      date_loaded: $date_loaded
      time_loaded: $time_loaded
      bbl_eir: $bbl_eir
      remarks: $remarks
      edt_stack: $edt_stack
    )
  }
`
export default compose(
  graphql(getAllTruckers, { name: "getAllTruckers" }),
  graphql(dispatchDepotContainer, {
    name: "dispatchDepotContainer",
  })
)(ShutlistDetails)
