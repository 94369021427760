import { isEmpty } from "lodash-es";
import { USER_LOGGED_IN, USER_LOGGED_OUT, MOBILEUSER_LOGGED_IN, MOBILEUSER_LOGGED_OUT } from "../modules/Types/types";

const initialState={
  isAuthenticated:false,
  isClientSide:false,
  user:{}
}

export default function user(state = initialState, action = {}) {
  switch (action.type) {
    case USER_LOGGED_IN:
      
      return {
        isAuthenticated: !isEmpty(action.user),
        user: action.user
      }
    case USER_LOGGED_OUT:
      return {};
    case MOBILEUSER_LOGGED_IN:
      return {
        isAuthenticated: !isEmpty(action.user),
        isClientSide:!isEmpty(action.user),
        user: action.user
      }

    case MOBILEUSER_LOGGED_OUT:
      return {};
    default:
      return state;
  }
}
