import React, { Component } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import cellEditFactory, { Type } from "react-bootstrap-table2-editor";
import gql from "graphql-tag";
import { graphql } from "react-apollo";

import LoadingMessage from "../shared/LoadingMessage";
import withQuery from "../HOC/withQuery";

import getInvoicedReport from "./Queries/fetchAllInvoiced";
const positionOptions = [
  { label: "PRESTACK", text: "PRESTACK" },
  { label: "DEPO", text: "DEPO" }
];
class InvoicedList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [...this.props.entries],

      pageNum: 1
    };
  }

  render() {
    // if (!this.props.permissions) return <PermissionsMessage />;
    if (this.state.data) {
      const { data } = this.state;
      console.log(data);
      const CaptionElement = () => (
        <h3
          style={{
            borderRadius: "0.25em",
            textAlign: "center",
            color: "green",
            border: "1px solid green",
            padding: "0.5em"
          }}
        >
          Invoiced List
        </h3>
      );
      const columns = [
        {
          text: "Client ",
          dataField: "clientCode",
          sort: true
        },
        {
          text: "Container No. ",
          dataField: "containerNum",
          sort: true
        },
        {
          text: "Size ",
          dataField: "containerSize",
          sort: true
        },
        {
          text: "In ICD",
          dataField: "inDateICD",
          sort: true,
          editable: true
        },
        {
          text: "Position",
          dataField: "position",
          sort: true,
          editor: {
            type: Type.SELECT,
            options: [...positionOptions]
          }
        },
        {
          text: "Delivery Date",
          dataField: "dateDelivered",
          sort: true,
          editable: true
        },
        {
          text: "Preadvise No.",
          dataField: "preAdviseNum",
          sort: true
        },

        {
          text: "File Bil No.",
          dataField: "fileBillingNumber",
          sort: true
        },

        {
          text: "Delivery Note",
          dataField: "deliveryNoteID",
          sort: true,
          editable: true
        },
        {
          text: "Vessel",
          dataField: "vessel",
          sort: true
        },
        {
          text: "Invoice Num",
          dataField: "invoiceNum",
          sort: true,
          editable: true
        }
      ];
      const defaultSorted = [
        {
          dataField: "clientCode",
          order: "desc"
        }
      ];
      const { SearchBar } = Search;
      const pagination = paginationFactory({
        sizePerPage: 5
      });
      return (
        <>
          <CaptionElement />

          <ToolkitProvider
            keyField="id"
            data={data}
            columns={columns}
            defaultSorted={defaultSorted}
            search={{ defaultSearch: "" }}
          >
            {props => (
              <div>
                <div align="right">
                  <SearchBar {...props.searchProps} />
                </div>
                <hr />
                <BootstrapTable
                  {...props.baseProps}
                  striped
                  hover
                  condensed
                  pagination={pagination}
                  noDataIndication="Table is Empty"
                  cellEdit={cellEditFactory({
                    mode: "dbclick",
                    blurToSave: true,
                    beforeSaveCell: (oldValue, newValue, row, column) => {
                      console.log("column: ", column);
                      console.log("Before Saving Cell!!:", oldValue);
                      console.log("New Value: ", newValue);
                      console.log("Id: ", row.id);
                      this._editPreadvise(newValue, column, row);
                    }
                  })}
                />
              </div>
            )}
          </ToolkitProvider>
        </>
      );
    } else {
      return <LoadingMessage />;
    }
  }
  _editPreadvise = async (newValue, column, row) => {
    const { id, eirNum, deliveryNodeID, inDateICD, position, invoiceNum } = row;
    let args = { id: id, [column.dataField]: newValue };
    console.log("Arguments", args);
    if (this.props.user.role === "SUPERUSER") {
      await this.props.editPreadvise({
        variables: {
          id,
          eirNum,
          deliveryNodeID,
          inDateICD,
          position,
          invoiceNum,

          [column.dataField]: newValue
        },
        refetchQuery: [{ getInvoicedReport }]
      });
      this.props.history.push("/invoiced/list");
    }
  };
}

const EDITPREADVISE = gql`
  mutation editPreadvise(
    $id: ID!
    $eirNum: String
    $deliveryNoteID: String
    $inDateICD: String
    $position: String
    $invoiceNum: String
  ) {
    editPreadvise(
      id: $id
      eirNum: $eirNum
      deliveryNoteID: $deliveryNoteID
      inDateICD: $inDateICD
      position: $position
      invoiceNum: $invoiceNum
    )
  }
`;

export default graphql(EDITPREADVISE, { name: "editPreadvise" })(
  withQuery(InvoicedList, getInvoicedReport, "getInvoicedReport")
);
